import {
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IoIosNotifications } from 'react-icons/io';
import { IoEyeOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAccessRequest } from 'src/Redux/AccessRequest/slice';
import { formatDate } from 'src/utils/helpers';
import {
  fetchSocketData,
  readNotificationData,
} from '../../../Redux/Notification/Notification';
import { socket } from '../../../Socket';

function Notification() {
  const [messages, setMessages] = useState([] as any);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef: any = React.useRef();
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const location = useLocation();

  // const SOCKET_IO_URL = "http://3.142.172.112/api";
  // const socket = io(SOCKET_IO_URL);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const unreadMessage = messages?.filter((item: any) => !item.isRead);
    setNotificationCount(unreadMessage?.length);
  }, [messages]);

  useEffect(() => {
    // 1
    socket.on('askPermission', (data) => {
      fetchData();
    });

    // 2
    socket.on('reviewFormByApplicant', (data) => {
      fetchData();
    });

    // 3
    socket.on('updateFormByAccountant', (data) => {
      fetchData();
    });

    // 4
    socket.on('initializeApplication', (data) => {
      fetchData();
    });
    //5
    socket.on('permissionGranted', (data) => {
      fetchData();
      if (location.pathname.includes('access-request')) {
        fetchAccessRequest();
      }
    });

    // 6
    socket.on('permissionDenied', (data) => {
      fetchData();
      if (location.pathname.includes('access-request')) {
        fetchAccessRequest();
      }
    });

    // 7
    socket.on('updateFormByApplicant', (data) => {
      fetchData();
    });

    //8
    socket.on('ocrReportGenerated', (data) => {
      fetchData();
    });
    //9
    socket.on('ocrReportGenerationFailed', (data) => {
      fetchData();
    });

    // Cleanup function
    return () => {
      socket.off('askPermission');
      socket.off('reviewFormByApplicant');
      socket.off('updateFormByAccountant');
      socket.off('initializeApplication');
      socket.off('permissionGranted');
      socket.off('permissionDenied');
      socket.off('updateFormByApplicant');
      socket.off('ocrReportGenerated');
      socket.off('ocrReportGenerationFailed');
    };
  }, []);

  const fetchAccessRequest = () => {
    dispatch(getAccessRequest() as any);
  };

  const fetchData = async () => {
    const actionResult = await dispatch(fetchSocketData() as any);
    const response = actionResult?.payload;
    if (actionResult?.payload?.status === 401) {
      navigate('/login');
    } else {
      setMessages(response?.data);
    }
  };

  const handleNotificationRead = async (id: number) => {
    const actionResult = await dispatch(
      readNotificationData({ notificationId: id, allRead: false }) as any
    );
    const response = actionResult?.payload;

    if (response?.status === 401) {
      navigate('/login');
    } else if (response?.status === 200) {
      let updateReadData = messages?.map((item: any) => {
        if (item?.notificationId === id) {
          return { ...item, isRead: true };
        } else {
          return item;
        }
      });
      setMessages(updateReadData);
    }
  };

  const handleMarkAllRead = async () => {
    const actionResult = await dispatch(
      readNotificationData({ allRead: true }) as any
    );
    const response = actionResult?.payload;

    if (response?.status === 401) {
      navigate('/login');
    } else if (response?.status === 200) {
      let updateReadData = messages?.map((item: any) => {
        return { ...item, isRead: true };
      });
      setMessages(updateReadData);
    }
  };

  const renderNotificationData = () => {
    if (messages && messages.length > 0) {
      // Ensures messages is truthy and has length
      // const formattedDate = formatDistanceToNow(new Date(messages.data.createdAt), { addSuffix: true });

      return (
        <Box
          style={{
            display: 'flex',
            gap: '8px',
            flexDirection: 'column',
            padding: '8px 0',
          }}
        >
          {messages.map((data: any, index: any) => {
            const formattedDate = formatDate(data?.createdAt);

            return (
              <Box
                key={index}
                display={'flex'}
                gap={'7px'}
                alignItems={'flex-start'}
                sx={{
                  padding: '16px 10px',
                  boxShadow: ' 0 0 8px -2px rgba(0,0,0,0.3)',
                  cursor: (!data?.isRead || data?.link !== '') && 'pointer',
                  backgroundColor: '#1146844a',
                  borderRadius: '12px',
                  '&:hover': {
                    bgColor: '#f5f5f5',
                  },
                  // '&:active': {
                  //   pointerEvents: data?.isRead ? 'none' : 'auto',
                  // },
                }}
                onClick={() => {
                  handleNotificationRead(data?.notificationId);
                  if (data.link !== '') {
                    navigate(data.link, {
                      state: { applicantId: data?.receiver?.[0]?.userId },
                    });
                    onClose();
                  }
                }}
              >
                <Badge
                  style={{
                    width: '9px',
                    height: '9px',
                  }}
                  colorScheme="blue"
                  variant={'solid'}
                  borderRadius="full"
                  visibility={data?.isRead ? 'hidden' : 'visible'}
                  marginTop={'6px'}
                ></Badge>
                <Box>
                  <Box color="#114684" fontWeight={700}>
                    <Text>{data.notificationType}</Text>
                  </Box>
                  <Text key={index}>{data.message}</Text>
                  <Box fontWeight={400} mt={2} color="#114684" display={'flex'}>
                    <Text>{formattedDate.date} </Text>
                    <Text
                      mx={2}
                      borderLeft="1px solid #114684"
                      height="auto"
                      boxShadow="none"
                    ></Text>
                    <Text>{formattedDate.time}</Text>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      );
    } else {
      return <Text>No Notification Found</Text>; // Corrected typo
    }
  };

  return (
    <>
      <Tooltip
        label="Notification"
        aria-label="Notification Tooltip"
        hasArrow
        bg="gray.300"
        color="black"
      >
        <Box
          bg="#114684"
          _hover={{ bg: '#ffa011' }}
          borderRadius="14px"
          display={'flex'}
          p={'4px'}
          position={'relative'}
        >
          <Icon
            as={IoIosNotifications}
            fontWeight="bold"
            borderRadius="14px"
            fontSize={32}
            color="white"
            cursor={'pointer'}
            onClick={onOpen}
          />
        </Box>
      </Tooltip>
      {messages?.length > 0 && notificationCount > 0 && (
        // <Badge
        //   style={{
        //     width: '20px',
        //     height: '20px',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     fontSize: '11px',
        //   }}
        //   colorScheme="red"
        //   variant="solid"
        //   borderRadius="full"
        //   position="absolute"
        //   top={{ base: '1px', sm: '-1px', md: '5px' }}
        //   right={{ base: '47px', sm: '48px', md: '50px', lg: '51px' }}
        // >
        //   {messages?.length}
        // </Badge>
        <Badge
          style={{
            width: '13px',
            height: '13px',
            fontSize: '11px',
          }}
          colorScheme="red"
          variant="solid"
          borderRadius="full"
          position="absolute"
          top={{ base: '5px', md: '10px' }}
          right={{ base: '50px', md: '55px' }}
        ></Badge>
      )}
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={{ base: 'xs', sm: 'sm', md: 'md' }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Box>
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                marginTop={'20px'}
              >
                <Flex gap={'5px'} alignItems={'center'}>
                  <Heading sx={{ my: 4 }} fontSize={'24px'} fontWeight={'600'}>
                    Notifications
                  </Heading>
                  {messages?.length > 0 && (
                    <Text fontSize={'20px'} fontWeight={'600'}>
                      {`(${notificationCount})`}
                    </Text>
                  )}
                </Flex>
                {messages?.length > 0 && (
                  <Tooltip
                    label="Mark All as Seen"
                    bg="gray.300"
                    color="black"
                    placement="auto"
                  >
                    <Box
                      fontSize={'22px'}
                      cursor={notificationCount > 0 ? 'pointer' : 'auto'}
                      sx={{
                        '&:active': {
                          pointerEvents:
                            notificationCount > 0 ? 'auto' : 'none',
                        },
                      }}
                      onClick={() => handleMarkAllRead()}
                    >
                      <IoEyeOutline color="#E1AD01" />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
              {renderNotificationData()}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Notification;
