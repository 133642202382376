import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { fileUpload } from 'src/Pages/BrokerPages/BondApplicationForm/data';
import { isRejectedActionWithPayload } from 'src/Redux/Broker/Documents/slice';
import { APIStatus } from 'src/types/unions';
import callAPI from 'src/utils/callAPI';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

export interface brokerageDetailsContextType {
  brokerageInfo: Record<string, any>;
  purchasedPlanInfo: Record<string, any>;
}

interface BrokerageState {
  brokerManager: any;
  user: Record<string, any> | null;
  loading: boolean;
  error: any;
  status: APIStatus | null;
  type?: 'GET_ALL_BROKER_BY_BROKERAGE' | 'BROKER_STATUS_UPDATE_BY_BROKERAGE';
  msg: string | null;
  errorResponse: Record<string, any> | null;
}

const initialState: BrokerageState = {
  brokerManager: [],
  user: null,
  loading: false,
  error: null,
  status: null,
  msg: null,
  errorResponse: null,
};

export const Brokerage = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

interface RequestData {
  legalName: string;
  contactNumber: number | null;
  officeAddress: string;
  email: string;
  logoInfo: fileUpload;
}
interface RequestDataCLTabel {
  corporate_license: {
    province: string | null;
    license: number | null;
    // expiryData: Date | null;
  }[];
}

interface RequestDataCL {
  reprimanded_info: string | null;
  hasLicenseSuspensionFine: boolean | null | undefined;
  reprimanded: boolean | null | undefined;
}

interface RequestDataEO {
  insurer: string | null;
  policy: string | null;
  limit: string | null;
  expiryDate: Date | null;
  claims_EO: boolean;
  claims_EO_info: string | null;
}

// brokerage profile update
export const BrokerageUpdate = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestData, { rejectWithValue }) => {
    try {
      // const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// brokerage eo update
export const RequestDataEO = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestDataEO, { rejectWithValue }) => {
    try {
      const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// brokerage cl update
export const BrokerageCl = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestDataCL, { rejectWithValue }) => {
    try {
      // const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// brokerage cl update
export const BrokerageClupdate = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestDataCLTabel, { rejectWithValue }) => {
    try {
      // const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// update get id

// export const fetchBrokerageUserData = createAsyncThunk(
//   'qbo/fetchBrokerageUserData',
//   async () => {
//     try {
//       // const userId = localStorage.getItem('userID');
//       const Token = localStorage.getItem('Token');
//       try {
//         const config = {
//           method: 'get',
//           maxBodyLength: Infinity,
//           url: `${BASEURL}/user/brokerage/get/profile`,
//           headers: {
//             Authorization: `Bearer ${Token}`,
//             'Content-Type': 'application/json',
//           },
//         };
//         try {
//           const response = await axios.request(config);
//           if (!!response?.headers['token']) {
//             localStorage.setItem('Token', response?.headers['token']);
//           }
//           return response.data;
//         } catch (error: any) {
//           if (error?.response?.status === 401) {
//             const result = tokenExipry();
//             return result;
//           }
//           return error?.response?.data;
//         }
//       } catch (error: any) {
//         if (error?.response?.status === 401) {
//           const result = tokenExipry();
//           return result;
//         }
//       }
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const fetchBrokerageUserData = createAsyncThunk(
  'qbo/fetchBrokerageUserData',
  async (_, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        '/user/brokerage/get/profile',
        'GET',
        null,
        false
      );
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

//broker alldata
// export const BrokerAllData = createAsyncThunk(
//   'brokerage/BrokerAllData',
//   async () => {
//     try {
//       const Token = localStorage.getItem('Token');

//       const config = {
//         method: 'get',
//         maxBodyLength: Infinity,
//         url: `${BASEURL}/user/broker/getall`,
//         headers: {
//           Authorization: `Bearer ${Token}`,
//           'Content-Type': 'application/json',
//         },
//       };
//       try {
//         const response = await axios.request(config);

//         return response.data;
//       } catch (error: any) {
//         if (error?.response?.status === 401) {
//           const result = tokenExipry();
//           return result;
//         }
//         return error?.response?.data;
//       }
//     } catch (error) {
//       throw error;
//     }
//   }
// );

//broker alldata updated API call Function
export const BrokerAllData = createAsyncThunk(
  'brokerage/BrokerAllData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await callAPI('/user/broker/getall', 'GET');
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

//broker status update api
export const brokerStatusChange = createAsyncThunk(
  'brokerage/brokerStatusChange',
  async (
    payload: {
      oldBrokerId: number;
      brokerageStatus: string;
      newBrokerId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        '/user/brokerage/update/broker_status',
        'PUT',
        payload,
        false
      );
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const brokerageSlice = createSlice({
  name: 'brokerage',
  initialState,
  reducers: {
    removeBrokerageData(state, action) {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // brokerage update
      .addCase(BrokerageUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        BrokerageUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(BrokerageUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Brokerage get api

      .addCase(fetchBrokerageUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBrokerageUserData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload?.data;
        }
      )
      .addCase(fetchBrokerageUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
      })
      //broker all data
      .addCase(BrokerAllData.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_ALL_BROKER_BY_BROKERAGE';
      })
      .addCase(BrokerAllData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.brokerManager = action.payload?.data;
        state.status = 'succeed';
        state.type = 'GET_ALL_BROKER_BY_BROKERAGE';
      })
      .addCase(BrokerAllData.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.status = 'failed';
        state.type = 'GET_ALL_BROKER_BY_BROKERAGE';
        state.errorResponse = action?.payload!;
      })
      //broker status update
      .addCase(brokerStatusChange.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading';
        state.type = 'BROKER_STATUS_UPDATE_BY_BROKERAGE';
      })
      .addCase(
        brokerStatusChange.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.msg = action.payload?.message;
          state.status = 'succeed';
          state.type = 'BROKER_STATUS_UPDATE_BY_BROKERAGE';
        }
      )
      .addCase(brokerStatusChange.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.status = 'failed';
        state.type = 'BROKER_STATUS_UPDATE_BY_BROKERAGE';
      });
  },
});

export default brokerageSlice.reducer;
export const { removeBrokerageData } = brokerageSlice.actions;
