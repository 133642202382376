import { Box, Button, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { ActionIcon, Progress, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import {
  IconDownload,
  IconEdit,
  IconRefresh,
  IconSquarePlus,
  IconTrash,
} from '@tabler/icons-react';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TableOptions,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import PDFViewer from 'src/Component/Common/pdf/PDFViewer';
import {
  getAccountPayableData,
  refreshAPReport,
} from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import {
  TReportTimeFrame,
  TReportType,
} from 'src/Redux/Broker/Reports/APAgingSummary/state';
import { updateOcrReportRecord } from 'src/Redux/OcrReports/slice';
import { OCRhistoryInfo } from 'src/Redux/OcrReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';
import { getDateFormat, getRandomUUID } from 'src/utils/helpers';
import { getCSVConfigByReportName } from 'src/utils/reports/generateAllReports';
import { formatNumber, validateRequired } from '../MantineTable';
import DeleteBSReportRecordModal from './components/DeleteBSReportRecordModal';
import {
  calculateEachRowTotal,
  convertValuesToNumber,
  updateAPARLastTotalRow,
  updateAPARReportConsecutiveTotals,
} from './utils';

interface APTable {
  tableData: Record<string, any>[] | undefined;
  filterType: TReportTimeFrame;
  isInitialLoading: boolean;
  selectApplicantData: Record<string, any>;
  applicantId: string;
  isEditable: boolean;
  ocrReportId: number | null;
  ocrUpdateLoader: boolean;
  setIsReportDelete: React.Dispatch<React.SetStateAction<boolean>>;
  isValidReport: boolean;
  ocrReportData: Record<string, any>;
  setIsReportAdded: React.Dispatch<React.SetStateAction<boolean>>;
  reportType: TReportType;
  historicalYear: string;
  reportTabOcrId: string | null;
}

interface TAccountPayableRow {
  AccountPayableId: number;
  Vendor: string;
  Current: string;
  '1 - 30': string;
  '31 - 60': string;
  '61 - 90': string;
  '91 and over': string;
  Total: string;
  Label?: string;
  id?: string;
}

const AccountPayableTable = ({
  tableData,
  filterType,
  isInitialLoading,
  selectApplicantData,
  applicantId,
  isEditable,
  ocrReportId,
  ocrUpdateLoader,
  setIsReportDelete,
  isValidReport,
  ocrReportData,
  setIsReportAdded,
  reportType,
  historicalYear,
  reportTabOcrId,
}: APTable) => {
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const ocrReportSlice = useAppSelector((state) => state.ocrReportSlice);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedRow, setSelectedRow] = useState<{
    rowIndex: number;
    rowData: Record<string, any>;
  } | null>(null);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const userId = localStorage.getItem('userID');
  const userType = localStorage.getItem('userType');
  const brokerSlice = useAppSelector((state) => state.broker.brokerProfileData);
  const applicantSlice = useAppSelector((state) => state.applicantProfile);
  const userName =
    userType === 'applicant'
      ? applicantSlice?.user?.data?.companyName
      : userType === 'broker'
        ? brokerSlice?.data?.firstName + ' ' + brokerSlice?.data?.lastName
        : '';
  const isShowPDFPreview =
    (isEditable || isValidReport) &&
    !!ocrReportData?.reportReviewLink &&
    !reportTabOcrId;

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'REFRESH_AP_REPORT') {
          setIsRefresh(false);
          dispatch(
            getAccountPayableData({
              applicantId: Number(applicantId),
              filter: filterType,
              reportType,
              historicalYear: historicalYear,
            })
          );
        }
        return;
      }
      case 'loading': {
        if (accountPayableReport.type === 'REFRESH_AP_REPORT') {
          setIsRefresh(true);
        }
        return;
      }
      case 'failed': {
        if (accountPayableReport.type === 'REFRESH_AP_REPORT') {
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
          setIsRefresh(false);
        }
        return;
      }
    }
  }, [accountPayableReport.status]);

  const columns = useMemo<MRT_ColumnDef<TAccountPayableRow>[]>(() => {
    if (tableData && tableData[0]) {
      console.log(
        'columns-key',
        Object.keys(tableData[0]).filter((elm) => !['id'].includes(elm))
      );
      const totalLabel = Object.keys(tableData[0]).find((el) =>
        el?.toLocaleLowerCase().startsWith('t')
      );
      let colOrder = [
        'Label',
        'Current',
        '1 - 30',
        '31 - 60',
        '61 - 90',
        '91 and over',
        'Total',
      ];

      return colOrder
        .filter((elm) => !['id'].includes(elm))
        .map((key) => {
          return {
            accessorKey: key !== 'Label' ? key.toUpperCase() : key,
            header: key !== 'Label' ? key : 'Vendor',
            Cell: ({ cell }) => (
              <>
                {!!cell.getValue() && !['Vendor', 'Label'].includes(key)
                  ? formatNumber(Number(cell.getValue()))
                  : cell.getValue()}
              </>
            ),
            mantineEditTextInputProps: () => ({
              // onChange: (event: any) => {
              //   console.log('cell', cell, event?.target?.value, column, row);
              // },
              type: !['Vendor', 'Label'].includes(key) ? 'number' : 'text',
              variant: 'default',
              // placeholder: '',
              error: !['Vendor', 'Label'].includes(key)
                ? validationErrors?.[key]
                : validationErrors?.Label,
              onFocus: () => {
                const keyName = key.charAt(0).toUpperCase() + key.slice(1);
                setValidationErrors({
                  ...validationErrors,
                  [keyName]: undefined,
                });
              },
              disabled: [totalLabel].includes(key.toUpperCase()),
            }),
          };
        });
    } else {
      return [
        {
          accessorKey: 'Vendor',
          header: 'Vendor',
        },
        {
          accessorKey: 'Current',
          header: 'Current',
        },
        {
          accessorKey: '1 - 30',
          header: '1 - 30',
        },
        {
          accessorKey: '31 - 60',
          header: '31 - 60',
        },
        {
          accessorKey: '61 - 90',
          header: '61 - 90',
        },
        {
          accessorKey: '91 and over',
          header: '91 and over',
        },
        {
          accessorKey: 'Total',
          header: 'Total',
        },
      ];
    }
  }, [filterType, tableData, validationErrors]);

  // const columns = useMemo<MRT_ColumnDef<TAccountPayableRow>[]>(
  //   () => [
  //     {
  //       accessorKey: 'Vendor',
  //       header: 'Vendor',
  //     },
  //     {
  //       accessorKey: 'Current',
  //       header: 'Current',
  //       Cell: ({ cell }) => (
  //         <>
  //           {cell.getValue() !== ''
  //             ? formatNumber(Number(cell.getValue()))
  //             : cell.getValue()}
  //         </>
  //       ),
  //     },
  //     {
  //       accessorKey: '1 - 30',
  //       header: '1 - 30',
  //       Cell: ({ cell }) => (
  //         <>
  //           {cell.getValue() !== ''
  //             ? formatNumber(Number(cell.getValue()))
  //             : cell.getValue()}
  //         </>
  //       ),
  //     },
  //     {
  //       accessorKey: '31 - 60',
  //       header: '31 - 60',
  //       Cell: ({ cell }) => (
  //         <>
  //           {cell.getValue() !== ''
  //             ? formatNumber(Number(cell.getValue()))
  //             : cell.getValue()}
  //         </>
  //       ),
  //     },
  //     {
  //       accessorKey: '61 - 90',
  //       header: '61 - 90',
  //       Cell: ({ cell }) => (
  //         <>
  //           {cell.getValue() !== ''
  //             ? formatNumber(Number(cell.getValue()))
  //             : cell.getValue()}
  //         </>
  //       ),
  //     },
  //     {
  //       accessorKey: '91 and over',
  //       header: '91 and over',
  //       Cell: ({ cell }) => (
  //         <>
  //           {cell.getValue() !== ''
  //             ? formatNumber(Number(cell.getValue()))
  //             : cell.getValue()}
  //         </>
  //       ),
  //     },
  //     {
  //       accessorKey: 'Total',
  //       header: 'Total',
  //       Cell: ({ cell }) => (
  //         <>
  //           {cell.getValue() !== ''
  //             ? formatNumber(Number(cell.getValue()))
  //             : cell.getValue()}
  //         </>
  //       ),
  //     },
  //   ],
  //   [filterType]
  // );

  const checkTotalInclude = (row: Record<string, any>) => {
    return row?.[isEditable ? 'Label' : 'Vendor']
      ?.toLowerCase()
      .includes('total');
  };

  const getTableBodyRowProps = ({
    row,
  }: {
    row: MRT_Row<TAccountPayableRow>;
  }) => {
    if (tableData) {
      const isLastRow = row.index === tableData?.length - 1;
      const isTotal = checkTotalInclude(row?.original);

      return {
        style: {
          fontWeight: isLastRow || isTotal ? 'bold' : 'normal',
        },
      };
    } else return {};
  };

  const replaceNullUndefined = (obj: Record<string, any>) => {
    if (typeof obj === 'object' && obj !== null) {
      const newObj: any = Array.isArray(obj) ? [] : {};

      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          // Recursively call replaceNullUndefined for each element
          newObj[i] = replaceNullUndefined(obj[i]);
        }
      } else {
        // If obj is an object, iterate through its properties
        for (let key in obj) {
          // Recursively call replaceNullUndefined for each property value
          newObj[key] = replaceNullUndefined(obj[key]);
        }
      }
      return newObj;
    } else {
      return obj === null || obj === undefined ? '' : obj;
    }
  };

  const handleExportData = (...data: any) => {
    const config = getCSVConfigByReportName({
      type: 'Account Payable Aging',
      title: `${accountPayableReport?.data?.reports[0]?.reportInfo?.qboCompanyName} \r\n${accountPayableReport?.data?.reports[0]?.reportInfo?.reportName} Summary \r\n${filterType} \r\nAccounting data last updated at ${accountPayableReport?.data?.reports[0]?.lastUpdateAt ? accountPayableReport?.data?.reports[0]?.lastUpdateAt : new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
      filename: `${selectApplicantData?.companyName} Account Payable Aging Report`,
    });
    const csvConfig = mkConfig(config);

    const refineData = tableData?.map((element: Record<string, any>) => {
      return replaceNullUndefined(element);
    });

    if (refineData && refineData.length > 0) {
      const csv = generateCsv(csvConfig)(refineData);
      download(csvConfig)(csv);
    }
  };

  const handleDeleteOcrRecord = async () => {
    const { id } = selectedRow?.rowData!;
    if (!!ocrReportId && !!id && !!tableData?.length) {
      const removeOcrRecord = tableData.filter((el) => el.id !== id);
      console.log('removeOcrRecord', removeOcrRecord);
      setIsReportDelete(true);
      // await dispatch(deleteOcrReportRecord({ ocrReportId, recordId: id }));

      const totalLabel = Object.keys(removeOcrRecord[0]).find((el) =>
        el?.toLocaleLowerCase().startsWith('t')
      );
      const eachRowTotalUpdate = calculateEachRowTotal(
        removeOcrRecord,
        totalLabel
      );
      const updateConsecutiveTotals =
        updateAPARReportConsecutiveTotals(eachRowTotalUpdate);
      const finalValue = updateAPARLastTotalRow(updateConsecutiveTotals);
      console.log(
        'final',
        eachRowTotalUpdate,
        updateConsecutiveTotals,
        finalValue
      );

      const currentDateTime = moment(new Date()).toISOString();
      const msgFormatDate = getDateFormat(
        currentDateTime,
        'MMM DD,YYYY hh:mm A'
      );
      const history: OCRhistoryInfo = {
        id: getRandomUUID(),
        message: `Row ${selectedRow?.rowData?.Label} deleted on ${msgFormatDate} by ${userName}`,
        user_id: Number(userId),
        row_id: id!,
        date: currentDateTime,
      };
      await dispatch(
        updateOcrReportRecord({
          id: ocrReportId,
          ocrReportData: finalValue,
          history,
        })
      );
    }
    setSelectedRow(null);
    close();
  };

  const openDeleteConfirmModal = (row: MRT_Row<TAccountPayableRow>) => {
    // console.log('delete-row', row);
    setSelectedRow({ rowIndex: row?.index, rowData: row?.original });
    open();
    // return modals.openConfirmModal({
    //   title: `DELETE`,
    //   children: (
    //     <Text>
    //       Are you sure you want to delete {row?.original?.Label}? This action
    //       cannot be undone.
    //     </Text>
    //   ),
    //   labels: { confirm: 'Delete', cancel: 'Cancel' },
    //   confirmProps: { color: 'red' },
    //   onConfirm: () => {},
    // });
  };

  const addRecordBeforeIndex = (
    ocrReportData: Record<string, any>[],
    newRecord: TAccountPayableRow,
    index: number
  ) => {
    // Step 1: Insert the new record at selectedRow?.rowIndex
    const updatedData = [
      ...ocrReportData.slice(0, index),
      newRecord,
      ...ocrReportData.slice(index),
    ];
    // console.log('updatedData', updatedData, newRecord);
    return updatedData;
  };

  const handleRowAdd: MRT_TableOptions<TAccountPayableRow>['onCreatingRowSave'] =
    async ({ exitCreatingMode, row, table, values }) => {
      console.log('onCreateSave', values, row, table);
      const newValidationErrors = validateUser(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      if (!!tableData?.length && !!ocrReportId && !!selectedRow) {
        const numericValues = convertValuesToNumber(values);
        const rowId = getRandomUUID();
        const updatedOcrReportData = addRecordBeforeIndex(
          tableData,
          { ...numericValues, id: rowId },
          selectedRow?.rowIndex
        );
        console.log('updatedOcrReportData', updatedOcrReportData);
        const totalLabel = Object.keys(updatedOcrReportData[0]).find((el) =>
          el?.toLocaleLowerCase().startsWith('t')
        );
        const eachRowTotalUpdate = calculateEachRowTotal(
          updatedOcrReportData,
          totalLabel
        );
        const updateConsecutiveTotals =
          updateAPARReportConsecutiveTotals(eachRowTotalUpdate);
        const finalValue = updateAPARLastTotalRow(updateConsecutiveTotals);
        console.log(
          'final',
          eachRowTotalUpdate,
          updateConsecutiveTotals,
          finalValue
        );

        const currentDateTime = moment(new Date()).toISOString();
        const msgFormatDate = getDateFormat(
          currentDateTime,
          'MMM DD,YYYY hh:mm A'
        );
        const history: OCRhistoryInfo = {
          id: getRandomUUID(),
          message: `Row ${values?.Label} added on ${msgFormatDate} by ${userName}`,
          user_id: Number(userId),
          row_id: rowId!,
          date: currentDateTime,
        };

        setIsReportAdded(true);
        await dispatch(
          updateOcrReportRecord({
            id: ocrReportId,
            ocrReportData: finalValue,
            history,
          })
        );
      }
      setSelectedRow(null);
      exitCreatingMode();
    };

  const handleRowSave: MRT_TableOptions<TAccountPayableRow>['onEditingRowSave'] =
    async ({ table, row, values }) => {
      console.log('onSave', table, row, values, tableData);
      const newValidationErrors = validateUser(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      if (!!tableData?.length && !!ocrReportId) {
        const updatedTData = tableData?.map((el, index) =>
          index === row.index ? { ...values, id: row?.original?.id } : el
        );
        const totalLabel = Object.keys(updatedTData[0]).find((el) =>
          el?.toLocaleLowerCase().startsWith('t')
        );
        console.log('totalLabel', totalLabel);

        // const updatedTotalValue = calculateTotalForAPAR(
        //   updatedTData,
        //   totalLabel
        // );
        // console.log('updatedTotalValue',updatedTotalValue, updatedTData);
        const eachRowTotalUpdate = calculateEachRowTotal(
          updatedTData,
          totalLabel
        );
        const updateConsecutiveTotals =
          updateAPARReportConsecutiveTotals(eachRowTotalUpdate);
        const finalValue = updateAPARLastTotalRow(updateConsecutiveTotals);
        console.log(
          'final',
          eachRowTotalUpdate,
          updateConsecutiveTotals,
          finalValue
        );

        const currentDateTime = moment(new Date()).toISOString();
        const msgFormatDate = getDateFormat(
          currentDateTime,
          'MMM DD,YYYY hh:mm A'
        );
        const history: OCRhistoryInfo = {
          id: getRandomUUID(),
          message: `Row ${values?.Label} updated on ${msgFormatDate} by ${userName}`,
          user_id: Number(userId),
          row_id: row?.original?.id!,
          date: currentDateTime,
        };

        dispatch(
          updateOcrReportRecord({
            id: ocrReportId,
            ocrReportData: finalValue,
            history,
          })
        );
      }
      table.setEditingRow(null); //exit editing mode
    };

  const table = useMantineReactTable({
    columns: columns as any,
    data: (tableData ?? []) as any,
    mantineCreateRowModalProps: {
      className: 'progress-capital',
    },
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: isEditable && !isValidReport,
    getRowId: (row, index) => '' + index,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
      columnOrder:
        isEditable && !isValidReport
          ? [
              'mrt-row-actions',
              ...(tableData
                ? Object.keys(tableData)?.map((key) =>
                    key !== 'Label' ? key : 'Vendor'
                  )
                : []),
            ]
          : [
              'Vendor',
              'Current',
              '1 - 30',
              '31 - 60',
              '61 - 90',
              '91 and over',
              'Total',
            ],
    },
    enableStickyFooter: true,
    defaultColumn: { minSize: 50, maxSize: 50, size: 100 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: isInitialLoading ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      className: 'capital-assets-table',
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    mantineTableBodyRowProps: getTableBodyRowProps,
    enableColumnPinning: true,
    enablePagination: false,
    positionPagination: 'none',
    enableStickyHeader: false,
    enableBottomToolbar: false,
    onCreatingRowCancel: () => {
      setValidationErrors({});
      setSelectedRow(null);
    },
    onEditingRowCancel: () => {
      setValidationErrors({});
    },
    onEditingRowSave: handleRowSave,
    onCreatingRowSave: handleRowAdd,
    renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Add Data</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Edit Data</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 300, //make actions column wider
      },
    },
    renderRowActions: ({ row }) => {
      const isLastRow = row.index === tableData?.length! - 1;
      const isTotal = row?.original?.Label?.toLowerCase().includes('total');
      const isPreviousTotalRow =
        tableData?.[row.index - 1]?.Label?.toLowerCase().includes('total');

      if (!isLastRow && !isTotal) {
        return (
          <Flex gap="md">
            <Tooltip label="Edit" position="right">
              <ActionIcon
                onClick={() => {
                  table.setEditingRow(row);
                }}
                size={'sm'}
              >
                <IconEdit />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Delete" position="right">
              <ActionIcon
                color="red"
                onClick={() => openDeleteConfirmModal(row)}
                size={'sm'}
              >
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          </Flex>
        );
      } else if (!isLastRow && isTotal && !isPreviousTotalRow) {
        return (
          <Tooltip label="Add" position="right">
            <ActionIcon
              onClick={() => {
                setSelectedRow({
                  rowIndex: row?.index,
                  rowData: row?.original,
                });
                table.setCreatingRow(true);
              }}
              size={'sm'}
            >
              <IconSquarePlus />
            </ActionIcon>
          </Tooltip>
        );
      }
    },
    mantineProgressProps: ({ isTopToolbar }) => ({
      color: 'orange',
      variant: '', //if you want to show exact progress value
      sx: {
        display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
      },
    }),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          {!isEditable ? (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                color="lightblue"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={(...data) => handleExportData(data)}
                leftIcon={<IconDownload />}
                variant="filled"
                className="primary-btn"
              >
                Export All Data
              </Button>
              {accountPayableReport?.data?.reports[0] && (
                <Flex flexDirection={'column'}>
                  {!reportTabOcrId && (
                    <Flex alignItems={'flex-start'} gap={1}>
                      <ActionIcon
                        style={{ color: '#114684' }}
                        onClick={() =>
                          dispatch(
                            refreshAPReport({
                              applicantId: applicantId,
                              filter: filterType,
                            })
                          )
                        }
                        size={'sm'}
                      >
                        <Tooltip
                          label={'Refresh Data'}
                          bg="gray.300"
                          color="black"
                          position="right"
                        >
                          <IconRefresh />
                        </Tooltip>
                      </ActionIcon>
                      <Text fontSize={'smaller'}>
                        Accounting data last updated at
                      </Text>
                      <Text fontSize={'smaller'}>
                        {getDateFormat(
                          accountPayableReport?.data?.reports[0]?.updatedAt!,
                          'MMM DD, YYYY hh:mm A'
                        )}
                      </Text>
                    </Flex>
                  )}
                  <Text>
                    <span>A/P Aging Summary as of&nbsp;</span>
                    {/* <Tooltip
                      label={'Start Date'}
                      bg="gray.300"
                      color="black"
                      position="top"
                    >
                      <span>
                        {`${moment(accountPayableReport?.data?.reportInfo?.startPeriod).format('MMMM DD, YYYY')} -`}
                        &nbsp;
                      </span>
                    </Tooltip> */}
                    <Tooltip
                      label={'End Date'}
                      bg="gray.300"
                      color="black"
                      position="right"
                    >
                      <span
                        style={{ cursor: 'pointer' }}
                      >{`${moment(accountPayableReport?.data?.reports[0]?.reportInfo?.reportAsOf).format('MMMM DD, YYYY')}`}</span>
                    </Tooltip>
                  </Text>
                </Flex>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
              }}
            >
              {!isInitialLoading && (
                <Text>
                  <span>A/P Aging Summary as of&nbsp;</span>
                  <Tooltip
                    label={'Report Date'}
                    bg="gray.300"
                    color="black"
                    position="right"
                  >
                    <span style={{ cursor: 'pointer' }}>
                      {moment(
                        ocrReportSlice?.ocrReportList?.[0]?.reportInfo
                          ?.reportExtactDate ??
                          ocrReportSlice?.ocrReportList?.[0]?.reportInfo
                            ?.reportAsOf,
                        'YYYY-MM-DD'
                      ).format('MMMM DD, YYYY')}
                    </span>
                  </Tooltip>
                </Text>
              )}
            </Box>
          )}
          <Flex py={1} alignItems={'center'}>
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            {/* <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box> */}
          </Flex>
        </Flex>
        {isRefresh && <Progress value={100} animate={true} />}
      </Flex>
    ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isFullScreen: isFullScreen,
      isLoading: isInitialLoading || isRefresh,
      isSaving: ocrUpdateLoader,
      columnOrder:
        isEditable && !isValidReport
          ? [
              'mrt-row-actions',
              ...(tableData
                ? Object.keys(tableData)?.map((key) =>
                    key !== 'Label' ? key : 'Vendor'
                  )
                : []),
            ]
          : [
              'Vendor',
              'Current',
              '1 - 30',
              '31 - 60',
              '61 - 90',
              '91 and over',
              'Total',
            ],
    },
  });
  return (
    <>
      <ModalsProvider>
        <Flex gap={5} w={'100%'} flexWrap={{ base: 'wrap', sm: 'nowrap' }}>
          <Box
            w={{
              base: '100%',
              sm: isShowPDFPreview ? '50%' : '100%',
            }}
          >
            <MantineReactTable table={table} key={'account_payable'} />
          </Box>
          {isShowPDFPreview && (
            <Box
              border={'1px solid #000'}
              w={{ base: '100%', sm: '50%' }}
              display={isShowPDFPreview ? 'block' : 'none'}
              height={'fit-content'}
            >
              <PDFViewer
                url={
                  // 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf'
                  ocrReportData?.reportReviewLink
                }
              />
            </Box>
          )}
        </Flex>
      </ModalsProvider>
      <DeleteBSReportRecordModal
        isOpen={opened}
        onClose={close}
        onConfirm={handleDeleteOcrRecord}
        ocrUpdateLoader={ocrUpdateLoader}
        selectedRow={selectedRow}
      />
    </>
  );
};

export default AccountPayableTable;

const checkTotalIncludeValidation = (value: string) => {
  return value?.toLowerCase().includes('total');
};

function validateUser(user: TAccountPayableRow) {
  return {
    Label: !validateRequired(user?.Label!)
      ? 'This field is required'
      : checkTotalIncludeValidation(user?.Label!)
        ? 'Vendor cannot contain the word total'
        : '',
    // Current: !validateRequired(user?.['Current']?.toString()!)
    //   ? 'This field is required'
    //   : '',
    // '1 - 30': !validateRequired(user?.['1 - 30']?.toString()!)
    //   ? 'This field is required'
    //   : '',
    // '31 - 60': !validateRequired(user?.['31 - 60']?.toString()!)
    //   ? 'This field is required'
    //   : '',
    // '61 - 90': !validateRequired(user?.['61 - 90']?.toString()!)
    //   ? 'This field is required'
    //   : '',
    // '91 and over': !validateRequired(user?.['91 and over']?.toString()!)
    //   ? 'This field is required'
    //   : '',
    // Total: !validateRequired(user?.Total?.toString()!)
    //   ? 'This field is required'
    //   : '',
  };
}
