import { Box, Button, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { ActionIcon, Progress, Title, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import {
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TableOptions,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import {
  addWorkingCapitalRow,
  removeworkingCapitalRow,
  updateWorkingCapitalRow,
} from '../../../../Redux/Broker/Reports/WorkingCapital/slice';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import { fixedNumber } from '../../../../utils/helpers';
import { TCapitalRow, getCapitalTotalData } from '../../WorkingCapital';
import { formatNumber } from '../MantineTable';
import { IMantineAssetsTableProps } from './MantineAssetsTable';

export interface IMantineLiabilitiesTableProps
  extends IMantineAssetsTableProps {}

const MantineLiabilitiesTable = ({
  tableData,
  tableOptions,
  filterType,
  selectedApplicantData,
  isInitialLoading,
  totalAssetsAndLiabilities,
}: IMantineLiabilitiesTableProps) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const brokerWorkingCapital = useAppSelector(
    (state) => state.brokerWorkingCapital
  );

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({
    isCreatingUser: false,
    isUpdatingUser: false,
    isDeletingUser: false,
  });
  const [selectedEntryType, setSelectedEntryType] = useState<
    'allowableAssets' | 'currentLiabilities'
  >('currentLiabilities');

  const isLoadingUsersError = false;
  const isFetchingUsers = false;

  const dispatch = useAppDispatch();

  const columns = useMemo<MRT_ColumnDef<TCapitalRow>[]>(
    () => [
      {
        accessorKey: 'keyName',
        header: 'Current Liabilities',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: ({ cell, column, row, table }) => ({
          display:
            filterType === 'Last Fiscal Year' && row.index !== -1
              ? 'none'
              : 'block',
          error: validationErrors?.projectName,
        }),
        footer: `Total Current Liabilities`,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: ({ cell, column, row, table }) => ({
          display:
            filterType === 'Last Fiscal Year' && row.index !== -1
              ? 'none'
              : 'block',
          error: validationErrors?.projectName,
          type: 'number',
        }),
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        footer: `${formatNumber(
          totalAssetsAndLiabilities.totalLiabilities.totalLiabilitiesAmount
        )}`,
      },
      {
        accessorKey: 'adjustment',
        header: 'Adjustment (%)',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: {
          type: 'number',
          error: validationErrors?.adjustment,
          min: 0,
          max: 100,
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)} %</>,
        footer: `${formatNumber(
          totalAssetsAndLiabilities.totalLiabilities.totalLiabilitiesAdjustment
        )} %`,
      },
      {
        accessorKey: 'adjustmentAmount',
        header: 'Revised Amount',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: {
          label: (
            <Flex gap={1}>
              <>Revised Amount</>
            </Flex>
          ),
          error: validationErrors?.adjustmentAmount,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        footer: `${formatNumber(
          totalAssetsAndLiabilities.totalLiabilities
            .totalLiabilitiesAdjustmentAmount
        )}`,
      },
    ],
    [
      validationErrors,
      selectedEntryType,
      JSON.stringify(totalAssetsAndLiabilities),
    ]
  );

  useEffect(() => {
    switch (brokerWorkingCapital.status) {
      case 'succeed': {
        if (
          brokerWorkingCapital.type === 'ADD_WIC_ROW' &&
          brokerWorkingCapital.meta?.type === 'CL'
        ) {
          setIsLoading((state) => ({ ...state, isCreatingUser: false }));
        }
        if (
          (brokerWorkingCapital.type === 'UPDATE_WIC_ROW' &&
            brokerWorkingCapital.meta?.type === 'CL') ||
          brokerWorkingCapital.type === 'REFRESH_WIC_API'
        ) {
          setIsLoading((state) => ({ ...state, isUpdatingUser: false }));
        }
        if (
          brokerWorkingCapital.type === 'REMOVE_WIC_API' &&
          brokerWorkingCapital.meta?.type === 'CL'
        ) {
          setIsLoading((state) => ({ ...state, isDeletingUser: false }));
        }
        if (
          brokerWorkingCapital.type === 'ADD_WIC_ROW' &&
          brokerWorkingCapital.meta?.type === 'CL'
        ) {
          setIsLoading((state) => ({ ...state, isCreatingUser: false }));
        }
        break;
      }
      case 'loading': {
        if (
          brokerWorkingCapital.type === 'GET_WIC_DATA_BY_APPLICATIONID_API' &&
          brokerWorkingCapital.meta?.type === 'CL'
        ) {
        }
        if (
          (brokerWorkingCapital.type === 'UPDATE_WIC_ROW' &&
            brokerWorkingCapital.meta?.type === 'CL') ||
          brokerWorkingCapital.type === 'REFRESH_WIC_API'
        ) {
          setIsLoading((state) => ({ ...state, isUpdatingUser: true }));
        }
        if (
          brokerWorkingCapital.type === 'REMOVE_WIC_API' &&
          brokerWorkingCapital.meta?.type === 'CL'
        ) {
          setIsLoading((state) => ({ ...state, isDeletingUser: true }));
        }
        if (
          brokerWorkingCapital.type === 'ADD_WIC_ROW' &&
          brokerWorkingCapital.meta?.type === 'CL'
        ) {
          setIsLoading((state) => ({ ...state, isCreatingUser: true }));
        }
        break;
      }
      case 'failed': {
        const wiCActionTypes = [
          'UPDATE_WIC_ROW',
          'ADD_WIC_ROW',
          'REMOVE_WIC_API',
        ];

        if (
          typeof brokerWorkingCapital.type === 'string' &&
          wiCActionTypes.includes(brokerWorkingCapital.type) &&
          brokerWorkingCapital.meta?.type === 'CL'
        ) {
          setIsLoading((state) => ({
            isCreatingUser: false,
            isDeletingUser: false,
            isUpdatingUser: false,
          }));
        }
        break;
      }
      default:
        break;
    }
  }, [brokerWorkingCapital.status]);

  const handleCreateWICRow: MRT_TableOptions<TCapitalRow>['onCreatingRowSave'] =
    async ({ values, exitCreatingMode }) => {
      const newValidationErrors = validateUser(values as any);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});

      if (
        brokerWorkingCapital.data?.id &&
        Number(selectedApplicantData.applicantId) >= 0
      ) {
        const { totalAssets, totalLiabilities } = totalAssetsAndLiabilities;
        const {
          totalAssetsAmount,
          totalAssetsAdjustment,
          totalAssetsAdjustmentAmount,
        } = totalAssets;

        const adjustment = fixedNumber(Number(values.adjustment));

        const amount = fixedNumber(Number(values.amount));

        const adjustmentAmount = fixedNumber(
          Number(amount * (1 - Number(adjustment ?? 0) / 100)) || amount
        );

        const totalLiabilitiesAmount = fixedNumber(
          Number(totalLiabilities.totalLiabilitiesAmount) +
            Number(values.amount)
        );

        const totalLiabilitiesAdjustmentAmount = fixedNumber(
          Number(totalLiabilities.totalLiabilitiesAdjustmentAmount) +
            Number(adjustmentAmount)
        );
        const totalLiabilitiesAdjustment = fixedNumber(
          Number(
            (1 - totalLiabilitiesAdjustmentAmount / totalLiabilitiesAmount) *
              100
          )
        );

        const {
          totalCapitalAmount,
          totalCapitalAdjustment,
          totalCapitalAdjustmentAmount,
        } = getCapitalTotalData({
          totalAssetsAmount,
          totalAssetsAdjustment,
          totalAssetsAdjustmentAmount,
          totalLiabilitiesAmount,
          totalLiabilitiesAdjustment,
          totalLiabilitiesAdjustmentAmount,
        });

        dispatch(
          addWorkingCapitalRow({
            documentId: Number(brokerWorkingCapital.data.id),
            totalWIC: {
              amount: totalCapitalAmount,
              adjustment: totalCapitalAdjustment,
              adjustmentAmount: totalCapitalAdjustmentAmount,
            },
            currentLiabilities: {
              ...values,
              amount,
              adjustment,
              adjustmentAmount,
            },
            totalLib: {
              amount: totalLiabilitiesAmount,
              adjustment: totalLiabilitiesAdjustment,
              adjustmentAmount: totalLiabilitiesAdjustmentAmount,
            },
            meta: {
              type: 'CL',
            },
          })
        );
      }
      exitCreatingMode();
    };

  //Edit Action
  const handleEditWICRow: MRT_TableOptions<TCapitalRow>['onEditingRowSave'] =
    async ({ values, exitEditingMode, row }) => {
      const newValidationErrors = validateUser(values as any);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      // await updateUser(values);
      if (
        brokerWorkingCapital.data?.id &&
        Number(selectedApplicantData.applicantId) >= 0
      ) {
        // const progressInfo = calculatedData(values as any);
        const { totalAssets, totalLiabilities } = totalAssetsAndLiabilities;
        const {
          totalAssetsAmount,
          totalAssetsAdjustment,
          totalAssetsAdjustmentAmount,
        } = totalAssets;
        const { original } = row;
        const adjustment = fixedNumber(Number(values.adjustment));
        const amount = fixedNumber(Number(values.amount));
        const adjustmentAmount = fixedNumber(
          Number(amount * (1 - Number(adjustment ?? 0) / 100)) || amount
        );

        const newLiabilitiesAmount = fixedNumber(
          totalLiabilities.totalLiabilitiesAmount -
            Number(original.amount) +
            Number(values.amount)
        );

        const newLiabilitiesAdjustmentAmount = fixedNumber(
          totalLiabilities.totalLiabilitiesAdjustmentAmount -
            Number(original.adjustmentAmount) +
            Number(adjustmentAmount)
        );
        const newLiabilitiesAdjustment = fixedNumber(
          Number(
            (1 - newLiabilitiesAdjustmentAmount / newLiabilitiesAmount) * 100
          )
        );

        const {
          totalCapitalAmount,
          totalCapitalAdjustment,
          totalCapitalAdjustmentAmount,
        } = getCapitalTotalData({
          totalAssetsAmount,
          totalAssetsAdjustment,
          totalAssetsAdjustmentAmount,
          totalLiabilitiesAmount: newLiabilitiesAmount,
          totalLiabilitiesAdjustment: newLiabilitiesAdjustment,
          totalLiabilitiesAdjustmentAmount: newLiabilitiesAdjustmentAmount,
        });

        // const newPayload:ProgressInfoAttributes

        dispatch(
          updateWorkingCapitalRow({
            documentId: Number(brokerWorkingCapital.data.id),
            totalWIC: {
              amount: totalCapitalAmount,
              adjustment: totalCapitalAdjustment,
              adjustmentAmount: totalCapitalAdjustmentAmount,
            },
            currentLiabilities: {
              ...values,
              amount,
              adjustment,
              adjustmentAmount,
            },
            capitalId: row.original.capitalId,
            totalLib: {
              amount: newLiabilitiesAmount,
              adjustment: newLiabilitiesAdjustment,
              adjustmentAmount: newLiabilitiesAdjustmentAmount,
            },
            meta: {
              type: 'CL',
            },
          })
        );
      }
      exitEditingMode();
    };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<TCapitalRow>) =>
    modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete {row.original.keyName}? This action
          cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (
          brokerWorkingCapital.data?.id &&
          Number(row.original.capitalId) >= 0
        ) {
          const { totalAssets, totalLiabilities } = totalAssetsAndLiabilities;
          const {
            totalAssetsAmount,
            totalAssetsAdjustment,
            totalAssetsAdjustmentAmount,
          } = totalAssets;
          const { original } = row;

          const newLiabilitiesAmount = fixedNumber(
            totalLiabilities.totalLiabilitiesAmount - Number(original.amount)
          );

          const newLiabilitiesAdjustmentAmount = fixedNumber(
            totalLiabilities.totalLiabilitiesAdjustmentAmount -
              Number(original.adjustmentAmount)
          );

          const newLiabilitiesAdjustment = fixedNumber(
            Number(
              (1 - newLiabilitiesAdjustmentAmount / newLiabilitiesAmount) * 100
            )
          );

          const {
            totalCapitalAmount,
            totalCapitalAdjustment,
            totalCapitalAdjustmentAmount,
          } = getCapitalTotalData({
            totalAssetsAmount,
            totalAssetsAdjustment,
            totalAssetsAdjustmentAmount,
            totalLiabilitiesAmount: newLiabilitiesAmount,
            totalLiabilitiesAdjustment: newLiabilitiesAdjustment,
            totalLiabilitiesAdjustmentAmount: newLiabilitiesAdjustmentAmount,
          });

          dispatch(
            removeworkingCapitalRow({
              documentId: brokerWorkingCapital.data.id,
              capitalId: Number(row.original.capitalId),
              totalWIC: {
                amount: totalCapitalAmount,
                adjustment: totalCapitalAdjustment,
                adjustmentAmount: totalCapitalAdjustmentAmount,
              },
              type: 'CL',
              totalLib: {
                amount: newLiabilitiesAmount,
                adjustment: newLiabilitiesAdjustment,
                adjustmentAmount: newLiabilitiesAdjustmentAmount,
              },
              meta: {
                type: 'CL',
              },
            })
          );
        }
      },
    });

  const table = useMantineReactTable({
    columns: columns as any,
    data: tableData,
    mantineCreateRowModalProps: {
      className: 'progress-capital',
    },
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, index) => '' + index,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions', 'projectName'] },
    },
    defaultColumn: { minSize: 40, maxSize: 70, size: 40 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 2, //make actions column wider
        maxSize: 2,
        minSize: 2,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
          },
        },
      },
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: isInitialLoading ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    enableColumnPinning: true,
    enablePagination: false,
    positionPagination: 'none',
    enableStickyHeader: false,
    mantineTableFooterProps: {
      className: 'asdfsfdasfdfdsfsf',
    },
    enableBottomToolbar: false,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateWICRow as any,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleEditWICRow as any,
    renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Add New Row</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Edit Data</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderRowActions: ({ row }) => (
      <Flex gap="md">
        <Tooltip label="Edit" position="right">
          <ActionIcon onClick={() => table.setEditingRow(row)} size={'sm'}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete" position="right">
          <ActionIcon
            color="red"
            onClick={() => openDeleteConfirmModal(row as any)}
            size={'sm'}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
              }}
              className="primary-btn"
            >
              Add New Row
            </Button>
          </Box>
          <Flex py={1} alignItems={'center'}>
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            {/* <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box> */}
          </Flex>
        </Flex>
        {Object.keys(isLoading).some((key) => isLoading[key]) && (
          <Progress value={100} animate={true} />
        )}
      </Flex>
    ),
    // renderBottomToolbar: ({ table }) => {
    //   return (
    //     <Flex gap={2}>
    //       <Text>Total Current Liabilities</Text>
    //       <Text>
    //         {totalAssetsAndLiabilities.totalLiabilities.totalLiabilitiesAmount}
    //       </Text>
    //       <Text>
    //         {
    //           totalAssetsAndLiabilities.totalLiabilities
    //             .totalLiabilitiesAdjAmount
    //         }
    //       </Text>
    //     </Flex>
    //   );
    // },
    // renderTopToolbarCustomActions: ({ table }) => (
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       gap: '16px',
    //       padding: '8px',
    //       flexWrap: 'wrap',
    //       alignItems: 'center'
    //     }}
    //   >
    //     <Button
    //       onClick={() => {
    //         table.setCreatingRow(true); //simplest way to open the create row modal with no default values
    //       }}
    //       className='primary-btn'
    //     >
    //       Add New Row
    //     </Button>
    //     <Button
    //       color="lightblue"
    //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
    //       onClick={(...data) => handleExportData(data)}
    //       leftIcon={<IconDownload />}
    //       variant="filled"
    //       className='primary-btn'
    //     >
    //       Export All Data
    //     </Button>
    //     <Text>{progressReport.allWIPdata.updatedInfo}</Text>
    //   </Box>
    // ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isLoading:
        isInitialLoading ||
        Object.keys(isLoading).some((key) => isLoading[key]),
      // showSkeletons: isLoadingData,
      isSaving: Object.keys(isLoading).some((key) => isLoading[key]),
      showAlertBanner: isLoadingUsersError,
      // showProgressBars: isFetchingUsers,
      isFullScreen: isFullScreen,
    },

    ...tableOptions,
  });

  return (
    <>
      <MantineReactTable table={table} key={'working-capital'} />
    </>
  );
};

export default MantineLiabilitiesTable;
const validateRequired = (value: string) => !!value?.length;

const validateUser = (user: TCapitalRow) => {
  return {
    // projectName: !validateRequired(user.keyName)
    //   ? "Field is Required"
    //   : "",
    // lastName: !validateRequired(user.lastName) ? 'Last Name is Required' : '',
    // email: !validateEmail(user.email) ? 'Incorrect Email Format' : '',
  };
};
