import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CMS,
  CMSUpdate,
  RescindFromApplicant,
} from '../../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { RootState, useAppSelector } from '../../../Redux/Store';
import { CmsSchema } from '../../../ValidationSchema/Pages/Index';

import {
  MRT_Cell,
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApplicationById } from 'src/Redux/Applications/slice';
import { useAppToast } from 'src/hooks';
import { getCmsUpdatedValues } from 'src/utils/forms/cms';
import AppSelect from '../../../Component/Common/Micro/AppSelect';
import { getApplicantHistory } from '../../../Redux/Broker/Dashboard/History';
import { countryOptions } from '../../../data/country';
import {
  deepObjectComparison,
  formatDate,
  getRandomUUID,
  getValue,
  handleMinMaxNumberInput,
  preventAlphabetsTyping,
  removeFields,
  trimStringValues,
} from '../../../utils/helpers';
import { IsSubmited } from './Stepper';
import { removeCmsFields } from './data';

export type contractorTable = {
  contractorType: string | null;
  contractPrice: number | null;
  location: string | null;
  id: string | null;
  annualSales: number | null;
};

export type salesPercentageByOwnerTable = {
  ownerType: string | null;
  otherOwnerType?: string | null;
  salesPercentage: number | null;
  id: string;
};

const AboutForm = forwardRef<any, any>(
  (
    {
      isSubmited,
      setIsSubmited,
      setActiveStep,
      formData,
      applicant,
      accountingSystem,
    }: {
      formData: TTAboutFormAPIData;
      isSubmited: IsSubmited;
      setIsSubmited: (pros: IsSubmited) => void;
      setActiveStep: React.Dispatch<React.SetStateAction<number>>;
      applicant: any;
      accountingSystem: TAccountingSystem;
    },
    ref
  ) => {
    console.log('about-formData', formData);

    const Toast = useToast();
    const dispatch = useDispatch();
    const [isReturnToSender, setIsReturnToSender] = useState(false);
    const [loader, setLoader] = useState<{ btnLoader: boolean }>({
      btnLoader: false,
    });
    const [rescindFromApplicant, setRescindFromApplicant] =
      useState<boolean>(false);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [salaselPercentageInput, setSalaselPercentageInput] = useState<any[]>(
      []
    );
    const [salesaTableData, setSalesaTableData] = useState<
      salesPercentageByOwnerTable[]
    >([]);
    const location = useLocation();
    const { showBox, showHistory, applicationStatus, applicantId } =
      location.state || {};

    const navigate = useNavigate();
    // const userData = useSelector(
    //   (state: RootState) => state.createApplicationFormSlice.user
    // );
    // let userGetidData = userData && userData.data;
    // let brokerid = userGetidData && userGetidData.brokerId;
    // let applicationId = userGetidData && userGetidData.applicationId;

    // const data: any = useSelector(
    //   (state: RootState) => state.brokerageDashboard.user
    // );
    const applicantHeaderName = applicant?.companyName;

    const HistoryData: any = useSelector(
      (state: RootState) => state.history.user
    );
    const createApplicationForm = useAppSelector(
      (state: RootState) => state.createApplicationFormSlice
    );
    const toast = useAppToast();
    const currentHistoryData = HistoryData?.data[historyIndex]?.details;

    // let cmsFormData = data && data?.data?.cms;
    // let ongoingapplicationId =
    //   data && data?.data?.accountingSystem?.applicationId;
    // let ongoingbrokerId = data && data?.data?.accountingSystem?.brokerId;

    const paramsData = useParams<{ id: string }>();
    const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
    //applicant fetch
    // const applicantFetch = useSelector(
    //   (state: RootState) => state.notification.user
    // );
    // const applicantFetchData =
    //   applicantFetch && applicantFetch?.data?.applications;
    // const applicantObject = applicantFetchData?.find(
    //   (applicant: any) => applicant.applicationId === ongoingID
    // );

    const userType = localStorage.getItem('userType');
    const isApplicantUser = userType === 'applicant';
    const applicationId = formData?.applicationId;
    const brokerId = formData?.brokerId;

    // useEffect(() => {
    //   if (ongoingID) {
    //     fetchOngoingApplicant();
    //   }
    //   if (!showHistory) {
    //     fetchDashboardData();
    //   }
    // }, [dispatch, ongoingID]);

    // const fetchOngoingApplicant = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let id = !showHistory && ongoingID ? ongoingID : 0;
    //     id = showHistory && applicantId ? applicantId : id;
    //     let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    // const fetchDashboardData = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let actionResult = await dispatch(
    //       fetchDashboardDetails({ applicationFilter: '' }) as any
    //     );
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    const fetchOngoingApplication = async () => {
      dispatch(
        getApplicationById({
          applicationId,
        }) as any
      );
    };

    useEffect(() => {
      const fetchData = async () => {
        if (!!localStorage.getItem('Token')) {
          if (ongoingID && showHistory) {
            let actionResult = await dispatch(
              getApplicantHistory({
                applicationId: ongoingID,
                modelName: 'CMS',
              }) as any
            );
            if (actionResult?.payload?.status === 401) {
              navigate('/login');
            }
          }
        } else {
          navigate('/login');
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
      const { status, type, error, user } = createApplicationForm;

      switch (status) {
        case 'loading': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: true });
          }
          break;
        }
        case 'succeed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'success',
              title: user?.data,
            });
            fetchOngoingApplication();
          }
          break;
        }
        case 'failed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'error',
              title: error,
            });
          }
          break;
        }

        default:
          break;
      }
    }, [createApplicationForm.status]);

    function getcontractorType(cmsFormData: any) {
      let contractorType = [
        'General Building',
        'Paving',
        'Electrical',
        'Excavation',
        'Concrete',
        'Framing',
        'Roofing/Siding/Sheet metal work',
        'Plumbing',
        'HVAC',
        'Carpentry',
        'Masonry/Stonework',
        'Plastering',
        'Painting/ Paper hanging',
        'Tile Setting',
      ];
      if (!contractorType.includes(cmsFormData?.contractorType)) {
        return 'Other(Please describe)';
      } else {
        return cmsFormData?.contractorType || null;
      }
    }

    const getOtherType = (data: any, key: string, returnKey: string) => {
      let otherIndex = data?.findIndex(
        (item: any) => item[`${key}`] === 'Others' || item[`${key}`] === 'Other'
      );
      return otherIndex >= 0 ? data[otherIndex][`${returnKey}`] : null;
    };

    useEffect(() => {
      if (formData) {
        // const historyTypeOfWork = currentHistoryData?.typeOfWork;
        // const typeOfWork = formData?.typeOfWork
        //   ? formData?.typeOfWork?.map((contract1: any, i: number) => {
        //       if (contract1?.type === 'Others') {
        //         return {
        //           type: getValue(
        //             showHistory,
        //             historyTypeOfWork?.[i]?.type,
        //             contract1.type
        //           ),
        //           otherType: getValue(
        //             showHistory,
        //             historyTypeOfWork?.[i]?.otherType,
        //             contract1.otherType
        //           ),
        //         };
        //       } else {
        //         return {
        //           type: getValue(
        //             showHistory,
        //             historyTypeOfWork?.[i]?.type,
        //             contract1.type
        //           ),
        //         };
        //       }
        //     })
        //   : [];
        const historyWorkSublet = currentHistoryData?.workSublet;
        const workSublet = formData?.workSublet
          ? formData?.workSublet?.map((contract1: any, i: number) => {
              if (contract1?.type === 'Others') {
                return {
                  type: getValue(
                    showHistory,
                    historyWorkSublet?.[i]?.type,
                    contract1.type
                  ),
                  otherSublet: getValue(
                    showHistory,
                    historyWorkSublet?.[i]?.otherSublet,
                    contract1.otherSublet
                  ),
                };
              } else {
                return {
                  type: getValue(
                    showHistory,
                    historyWorkSublet?.[i]?.type,
                    contract1.type
                  ),
                };
              }
            })
          : [];
        const historySalesPercentageByOwnerType =
          currentHistoryData?.salesPercentageByOwnerType;
        const salesPercentageByOwnerType = formData?.salesPercentageByOwnerType
          ? formData?.salesPercentageByOwnerType?.map(
              (contract1: any, i: number) => {
                if (contract1?.ownerType === 'Others') {
                  return {
                    ownerType: getValue(
                      showHistory,
                      historySalesPercentageByOwnerType?.[i]?.ownerType,
                      contract1.ownerType
                    ),
                    otherOwnerType: getValue(
                      showHistory,
                      historySalesPercentageByOwnerType?.[i]?.otherOwnerType,
                      contract1?.otherOwnerType
                    ),
                    salesPercentage: getValue(
                      showHistory,
                      historySalesPercentageByOwnerType?.[i]?.salesPercentage,
                      contract1?.salesPercentage
                    ),
                  };
                } else {
                  return {
                    ownerType: getValue(
                      showHistory,
                      historySalesPercentageByOwnerType?.[i]?.ownerType,
                      contract1.ownerType
                    ),

                    salesPercentage: getValue(
                      showHistory,
                      historySalesPercentageByOwnerType?.[i]?.salesPercentage,
                      contract1?.salesPercentage
                    ),
                  };
                }
              }
            )
          : [];

        const contractorType = getcontractorType(
          showHistory ? currentHistoryData : formData
        );
        const historyContractors = currentHistoryData?.contractors;
        let contractors = formData?.contractors
          ? formData?.contractors?.map((contract1: any, i: number) => {
              if (contract1.type === 'Others') {
                return {
                  type: getValue(
                    showHistory,
                    historyContractors?.[i]?.type,
                    contract1.type
                  ),
                  annualSalesPercentage: getValue(
                    showHistory,
                    historyContractors?.[i]?.annualSalesPercentage,
                    contract1.annualSalesPercentage
                  ),
                  otherContractorType: getValue(
                    showHistory,
                    historyContractors?.[i]?.otherContractorType,
                    contract1?.otherContractorType
                  ),
                };
              } else {
                return {
                  type: getValue(
                    showHistory,
                    historyContractors?.[i]?.type,
                    contract1.type
                  ),
                  annualSalesPercentage: getValue(
                    showHistory,
                    historyContractors?.[i]?.annualSalesPercentage,
                    contract1.annualSalesPercentage
                  ),
                };
              }
            })
          : [];
        const historyfixedContract = currentHistoryData?.fixedContract;
        let fixedContractData = showHistory
          ? currentHistoryData?.fixedContract
          : formData?.fixedContract;
        let fixedContract =
          fixedContractData?.length > 0
            ? fixedContractData?.map((item: any, i: number) => {
                return {
                  contractorType: getValue(
                    showHistory,
                    historyfixedContract?.[i]?.contractorType,
                    item.contractorType
                  ),
                  contractPrice: getValue(
                    showHistory,
                    historyfixedContract?.[i]?.contractPrice,
                    item.contractPrice
                  ),
                  location: getValue(
                    showHistory,
                    historyfixedContract?.[i]?.location,
                    item.location
                  ),
                  id: item?.id ?? getRandomUUID(),
                  annualSales: item?.annualSales ?? null,
                };
              })
            : initialValues?.fixedContract;
        const historyWorkOutsideCanada =
          currentHistoryData?.workOutsideCanada?.annualSalesPercentage;
        const annualSalesPercentage = formData?.workOutsideCanada
          ?.annualSalesPercentage
          ? formData?.workOutsideCanada?.annualSalesPercentage?.map(
              (contract1: any, i: number) => ({
                country: getValue(
                  showHistory,
                  historyWorkOutsideCanada?.[i]?.country,
                  contract1?.country
                ),
                percentage: getValue(
                  showHistory,
                  historyWorkOutsideCanada?.[i]?.percentage,
                  contract1?.percentage
                ),
              })
            )
          : initialValues?.workOutsideCanada?.annualSalesPercentage;
        const historyKeyPersonel = currentHistoryData?.keyPersonnel;
        const KeyPersonnel = formData?.keyPersonnel
          ? formData?.keyPersonnel?.map((keyPersonnel: any, i: number) => ({
              name: getValue(
                showHistory,
                historyKeyPersonel?.[i]?.name,
                keyPersonnel?.name
              ),
              position: getValue(
                showHistory,
                historyKeyPersonel?.[i]?.position,
                keyPersonnel?.position
              ),
              hiringDate: getValue(
                showHistory,
                historyKeyPersonel?.[i]?.hiringDate,
                keyPersonnel?.hiringDate
              ),
            }))
          : initialValues?.keyPersonnel;

        fixedContract = fixedContract?.map((item: any) => {
          const matchingContract = contractors?.find(
            (contract: any) => item.contractorType === contract.type
          );
          if (matchingContract) {
            return {
              ...item,
              annualSales: matchingContract.annualSalesPercentage,
            };
          }

          // If no matching contract found, return the original item
          return item;
        });

        let fixedContractCopy = fixedContract?.map((item: any) => {
          if (item?.contractorType === 'Others') {
            return {
              ...item,
              contractorType: getOtherType(
                showHistory
                  ? currentHistoryData?.contractors
                  : formData?.contractors,
                'type',
                'otherContractorType'
              ),
            };
          } else {
            return item;
          }
        });

        const historyManagementControl = currentHistoryData?.managementControl;
        const managementControl = formData?.managementControl
          ? formData?.managementControl?.map((contract1: any, i: number) => {
              if (contract1?.jobType === 'Other') {
                return {
                  jobType: getValue(
                    showHistory,
                    historyManagementControl?.[i]?.jobType,
                    contract1.jobType
                  ),
                  otherJobType: getValue(
                    showHistory,
                    historyManagementControl?.[i]?.otherJobType,
                    contract1.otherJobType
                  ),
                };
              } else {
                return {
                  jobType: getValue(
                    showHistory,
                    historyManagementControl?.[i]?.jobType,
                    contract1.jobType
                  ),
                };
              }
            })
          : [];
        let managementControlRadio =
          managementControl?.length > 0
            ? managementControl?.filter(
                (item: any) =>
                  ![
                    'Other',
                    'Personally',
                    'Through the Construction Management System',
                  ].includes(item?.jobType)
              )
            : null;
        let managementControlCheckbox =
          managementControl?.length > 0
            ? managementControl?.filter((item: any) =>
                [
                  'Other',
                  'Personally',
                  'Through the Construction Management System',
                ].includes(item?.jobType)
              )
            : [];

        const formValues = {
          ...initialValues,
          applicationId: getValue(
            showHistory,
            currentHistoryData?.applicationId,
            formData?.applicationId
          ),
          brokerId: getValue(
            showHistory,
            currentHistoryData?.applicationId,
            formData?.brokerId
          ),
          applicantId: formData?.applicantId,
          keyPersonnel: KeyPersonnel,

          name: getValue(showHistory, currentHistoryData?.name, formData?.name),
          position: getValue(
            showHistory,
            currentHistoryData?.position,
            formData?.position
          ),
          hiringDate: getValue(
            showHistory,
            currentHistoryData?.hiringDate,
            formData?.hiringDate
          ),
          contractorType: getcontractorType(
            showHistory ? currentHistoryData : formData
          ),
          contractorTypeOther: getOtherType(
            showHistory
              ? currentHistoryData?.contractors
              : formData?.contractors,
            'type',
            'otherContractorType'
          ),
          // typeOfWorkOther: getOtherType(
          //   showHistory ? currentHistoryData?.typeOfWork : formData?.typeOfWork,
          //   'type',
          //   'otherType'
          // ),
          workSubletOther: getOtherType(
            showHistory ? currentHistoryData?.workSublet : formData?.workSublet,
            'type',
            'otherSublet'
          ),
          salesPercentageByOwnerTypeOther: getOtherType(
            showHistory
              ? currentHistoryData?.salesPercentageByOwnerType
              : formData?.salesPercentageByOwnerType,
            'ownerType',
            'otherOwnerType'
          ),
          workSublet: workSublet,
          // typeOfWork: typeOfWork,
          salesPercentageByOwnerType: salesPercentageByOwnerType,
          returnToSender: userType === 'broker' ? false : null,
          workOutsideCanada: {
            performWorkOutside: getValue(
              showHistory,
              currentHistoryData?.workOutsideCanada?.performWorkOutside,
              formData?.workOutsideCanada?.performWorkOutside
            ),
            annualSalesPercentage,
          },
          applicationName: getValue(
            showHistory,
            currentHistoryData?.applicationId,
            formData?.applicationName
          ),
          contractors: contractors,
          largestWorkAmount: getValue(
            showHistory,
            currentHistoryData?.largestWorkAmount,
            formData?.largestWorkAmount
          ),
          numberOfContracts: getValue(
            showHistory,
            currentHistoryData?.numberOfContracts,
            formData?.numberOfContracts
          ),
          workSubletPercentage: getValue(
            showHistory,
            currentHistoryData?.workSubletPercentage,
            formData?.workSubletPercentage
          ),
          workUndertakenOwnForces: getValue(
            showHistory,
            currentHistoryData?.workUndertakenOwnForces,
            formData?.workUndertakenOwnForces
          ),
          totalWorkProgramOneTime: getValue(
            showHistory,
            currentHistoryData?.totalWorkProgramOneTime,
            formData?.totalWorkProgramOneTime
          ),
          totalWorkProgram12Months: getValue(
            showHistory,
            currentHistoryData?.totalWorkProgram12Months,
            formData?.totalWorkProgram12Months
          ),
          employeesUnionized: getValue(
            showHistory,
            currentHistoryData?.employeesUnionized,
            formData?.employeesUnionized
          ),
          payUnionScaleWages: getValue(
            showHistory,
            currentHistoryData?.payUnionScaleWages,
            formData?.payUnionScaleWages
          ),
          // estimatedSubletBudget: getValue(
          //   showHistory,
          //   currentHistoryData?.estimatedSubletBudget,
          //   formData?.estimatedSubletBudget
          // ),
          // ownerName: getValue(
          //   showHistory,
          //   currentHistoryData?.ownerName,
          //   formData?.ownerName
          // ),
          // contractPrice: getValue(
          //   showHistory,
          //   currentHistoryData?.contractPrice,
          //   formData?.contractPrice
          // ),
          obtainBondsFromSubcontractors: {
            hasBonds: getValue(
              showHistory,
              currentHistoryData?.obtainBondsFromSubcontractors?.hasBonds,
              formData?.obtainBondsFromSubcontractors?.hasBonds
            ),
            minimumContract: getValue(
              showHistory,
              currentHistoryData?.obtainBondsFromSubcontractors
                ?.minimumContract,
              formData?.obtainBondsFromSubcontractors?.minimumContract
            ),
          },
          // typeOfProjectOwner: {
          //   type: getValue(
          //     showHistory,
          //     currentHistoryData?.typeOfProjectOwner?.type,
          //     formData?.typeOfProjectOwner?.type
          //   ),
          //   otherType: getValue(
          //     showHistory,
          //     currentHistoryData?.typeOfProjectOwner?.otherType,
          //     formData?.typeOfProjectOwner?.otherType
          //   ),
          // },
          geoArea: getValue(
            showHistory,
            currentHistoryData?.geoArea,
            formData?.geoArea
          ),
          workOutsideDetails: {
            workOutside: getValue(
              showHistory,
              currentHistoryData?.workOutsideDetails?.workOutside,
              formData?.workOutsideDetails?.workOutside
            ),
            where: getValue(
              showHistory,
              currentHistoryData?.workOutsideDetails?.where,
              formData?.workOutsideDetails?.where
            ),
            howOften: getValue(
              showHistory,
              currentHistoryData?.workOutsideDetails?.howOften,
              formData?.workOutsideDetails?.howOften
            ),
          },
          managementControlRadio: managementControlRadio
            ? managementControlRadio[0]?.jobType
            : 'Monthly',
          managementControlCheckbox:
            managementControlCheckbox &&
            getTypes(managementControlCheckbox, 'jobType'),
          managementControlOther: getOtherType(
            showHistory ? managementControlCheckbox : managementControlCheckbox,
            'jobType',
            'otherJobType'
          ),
          fixedContract: fixedContract,
          fixedContractorCopy: fixedContractCopy,
        };
        setInitialValues(formValues);
      }
    }, [formData, currentHistoryData]);

    const [initialValues, setInitialValues] = useState<TAboutForm>({
      applicationId: applicationId,

      brokerId: brokerId,
      applicantId: formData?.applicantId,
      keyPersonnel: [
        {
          name: null,
          position: null,
          hiringDate: null,
          resume: null,
        },
      ],
      name: null,
      position: null,
      hiringDate: null,
      contractorType: null,
      contractorTypeOther: null,
      // annualSalesPercentage: null,
      salesPercentageByOwnerTypeOther: null,
      workSubletOther: null,
      // typeOfWorkOther: null,
      // typeOfWork: [
      //   {
      //     type: null,
      //     otherType: null,
      //   },
      // ],
      workSublet: [
        {
          type: null,
          otherSublet: null,
        },
      ],
      salesPercentageByOwnerType: [
        {
          ownerType: null,
          salesPercentage: null,
          id: getRandomUUID(),
        },
      ],
      returnToSender: userType === 'broker' ? false : null,
      applicationName: null,
      contractors: [
        {
          type: null,
          annualSalesPercentage: null,
          otherContractorType: null,
        },
      ],
      largestWorkAmount: null,
      numberOfContracts: null,
      workSubletPercentage: null,
      workUndertakenOwnForces: null,
      totalWorkProgramOneTime: null,
      totalWorkProgram12Months: null,
      employeesUnionized: null,
      payUnionScaleWages: null,
      // estimatedSubletBudget: null,
      workOutsideCanada: {
        performWorkOutside: null,
        annualSalesPercentage: [
          {
            country: null,
            percentage: null,
          },
        ],
      },
      // ownerName: null,
      // contractPrice: null,
      obtainBondsFromSubcontractors: {
        hasBonds: null,
        minimumContract: null,
      },
      // typeOfProjectOwner: {
      //   type: null,
      //   otherType: null,
      // },
      workOutsideDetails: {
        workOutside: null,
        where: null,
        howOften: null,
      },
      geoArea: null,
      managementControlRadio: 'Monthly',
      managementControlCheckbox: [],
      managementControlOther: null,
      fixedContract: [
        {
          contractorType: null,
          contractPrice: null,
          location: null,
          id: getRandomUUID(),
          annualSales: null,
        },
      ],
      fixedContractorCopy: [
        {
          contractorType: null,
          contractPrice: null,
          location: null,
          id: getRandomUUID(),
          annualSales: null,
        },
      ],
    });

    const OnHandelReturnToSender = () => {
      setIsReturnToSender(true);
    };

    const OnHandleReturnToBroker = () => {
      setRescindFromApplicant(true);
      dispatch(
        RescindFromApplicant({
          applicationId: formData?.applicationId,
          formName: 'cms',
        }) as any
      );
    };

    // const fetchId = true;

    const currentCuntry =
      accountingSystem?.country === 'United States' ? 'USA' : 'Canada';

    const formik = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: CmsSchema,
      onSubmit: async (values: TAboutForm) => {
        setIsSubmited({ ...isSubmited, CMS: true });
        let res = '';
        const updatedValues = getCmsUpdatedValues(values);
        try {
          let editedValues: any = {};
          const updatedCmsData = trimStringValues(
            removeFields(updatedValues, removeCmsFields)
          );
          const updatedData: Partial<TAboutForm> = deepObjectComparison(
            updatedCmsData,
            formData
          );

          if (!isReturnToSender) {
            editedValues = {
              ...updatedCmsData,
              updatedData: updatedData,
              applicationId: formData?.applicationId,
              applicantId: formData?.applicantId,
              brokerId: formData?.brokerId,
              applicationName: formData?.applicationName,
            };
            // const trimedValues = trimStringValues(editedValues);
            const actionResult = await dispatch(
              CMSUpdate({
                updatedData: editedValues,
                applicationId: applicationId,
              }) as any
            );
            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              if (resStatus === 401) {
                navigate('/login');
              } else {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              }
            }
          } else if (isReturnToSender) {
            editedValues = {
              ...updatedCmsData,
              updatedData: updatedData,
              returnToSender: userType === 'applicant' ? false : true,
              applicationId: formData?.applicationId,
              applicantId: formData?.applicantId,
              brokerId: formData?.brokerId,
              applicationName: formData?.applicationName,
            };
            // const trimedValues = trimStringValues(editedValues);
            setLoader({ ...loader, btnLoader: true });
            const actionResult = await dispatch(
              CMSUpdate({
                updatedData: editedValues,
                applicationId: applicationId,
              }) as any
            );
            if (userType === 'broker') {
              const response = actionResult?.payload;
              res = response?.msg;
              const resStatus = response?.status;

              if (resStatus === 200) {
                Toast({
                  title: response.msg,
                  status: 'success',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
                fetchOngoingApplication();
              } else {
                if (resStatus === 401) {
                  navigate('/login');
                } else {
                  Toast({
                    title: res,
                    status: 'error',
                    isClosable: true,
                    duration: 3000,
                    position: 'top-right',
                  });
                }
              }
            } else {
              navigate('/');
            }
            setIsReturnToSender(false);
            setLoader({ ...loader, btnLoader: false });
          } else {
            const actionResult = await dispatch(
              CMS({ ...updatedCmsData, updatedData }) as any
            );
            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;
            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              if (resStatus !== 401) {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              } else {
                navigate('/login');
              }
            }
          }
        } catch (error: any) {
          if (res) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      },
    });

    useImperativeHandle(ref, () => ({
      formik: formik,
    }));

    function capitalizeFirstLetter(str: any) {
      const dotIndex = str.indexOf('?.[0]?.');
      const modifiedStr = dotIndex !== -1 ? str.slice(0, dotIndex) : str;
      return (
        modifiedStr.charAt(0).toUpperCase() +
        modifiedStr
          .slice(1)
          .replace(/[A-Z]/g, (match: any) => ' ' + match.toLowerCase()) +
        '.'
      );
    }

    const replaceErrorMessage = (str: any) => {
      let updateStr = str
        .replace(
          /\.(.)/g,
          (match: any, letter: any) => '.' + letter.toUpperCase()
        ) //Uppercase after the dot
        // .replace(/\[0\]\./g, "") // Remove "[0]."
        .replace(/\./g, '') // Remove "."
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Split words by capital letters
        .trim(); // Remove leading/trailing whitespace if any

      return (
        updateStr.charAt(0).toUpperCase() +
        updateStr
          .slice(1)
          .replace(/[A-Z]/g, (match: any) => ' ' + match.toLowerCase())
      );
    };

    const getTypes = (values: any, key: string) => {
      return values?.map((item: any) => item[`${key}`]);
    };

    const handleSaveCell = (cell: MRT_Cell<contractorTable>, value: any) => {
      let fixedContract = formik.values?.fixedContract || [];
      let fixedContractorCopy = formik.values?.fixedContractorCopy || [];

      if (value == '') {
        value = null;
      }
      fixedContract[cell.row.index][cell.column.id as keyof contractorTable] =
        value;
      fixedContractorCopy[cell.row.index][
        cell.column.id as keyof contractorTable
      ] = value;

      formik.setFieldValue('fixedContract', fixedContract);
      formik.setFieldValue('fixedContractorCopy', fixedContractorCopy);
    };

    const handleSaveCellSales = (
      cell: MRT_Cell<salesPercentageByOwnerTable>,
      value: any
    ) => {
      let salesbyOwnerType = formik.values?.salesPercentageByOwnerType || [];
      // @ts-ignore
      salesbyOwnerType[cell.row.index][
        cell.column.id as keyof salesPercentageByOwnerTable
      ] = value;

      formik.setValues({
        ...formik.values,
        salesPercentageByOwnerType: salesbyOwnerType,
      });
    };

    const columns = useMemo<MRT_ColumnDef<contractorTable>[]>(
      () => [
        {
          accessorKey: 'contractorType', //access nested data with dot notation
          size: 2,
          maxSize: 2,
          header: 'Type of construction',
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          mantineTableHeadCellProps: {
            width: '10px !important',
            className: 'px-10',
          },
          mantineTableBodyCellProps: {
            className: 'px-10',
          },
        },
        {
          accessorKey: 'contractPrice',
          size: 40,
          maxSize: 40,
          header:
            'Largest fixed price contract in the last 5 years for this type of construction',
          enableColumnActions: false,
          enableSorting: false,
          mantineEditTextInputProps: {
            placeholder: '$',
          },
          mantineTableBodyCellProps: {
            sx: {
              outline: 'none !important',
            },
          },
        },
        {
          accessorKey: 'location',
          size: 45,
          maxSize: 45,
          header:
            'Location of largest fixed price contract in the last 5 years for this type of construction',
          enableColumnActions: false,
          enableSorting: false,
          mantineEditTextInputProps: {
            placeholder: 'Location',
          },
          mantineTableBodyCellProps: {
            sx: {
              outline: 'none !important',
            },
          },
        },
        {
          accessorKey: 'annualSales', //normal accessorKey
          size: 35,
          maxSize: 35,
          header: '% of annual sales related to this type of construction',
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: !(formik?.values?.fixedContract?.length <= 1),
          mantineEditTextInputProps: ({ row }) => ({
            placeholder: '%',
            min: 0,
            max: 100,
            type: 'number',
            value: formik.values.fixedContract[row.index]?.annualSales ?? '',
            required: true,
          }),

          mantineTableBodyCellProps: {
            sx: {
              outline: 'none !important',
            },
          },
        },
      ],
      [formik?.values?.contractors]
    );
    const columnssalesPercentageByOwner = useMemo<
      MRT_ColumnDef<salesPercentageByOwnerTable>[]
    >(() => {
      return [
        {
          accessorKey: 'ownerType', //access nested data with dot notation
          size: 2,
          maxSize: 2,
          header: 'Owner Type',
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          mantineTableHeadCellProps: {
            width: '10px !important',
            className: 'px-10',
          },
          mantineTableBodyCellProps: {
            className: 'px-10',
          },
          Cell: (props) => {
            return props.row.original.ownerType === 'Others'
              ? values?.salesPercentageByOwnerTypeOther || 'Others'
              : props.row.original.ownerType;
          },
        },
        {
          accessorKey: 'salesPercentage',
          size: 40,
          maxSize: 40,
          header: 'Sales Percentage %',
          enableColumnActions: false,
          enableSorting: false,
          mantineEditTextInputProps: ({ row }) => ({
            placeholder: '%',
            min: 0,
            max: 100,
            type: 'number',
            value:
              salaselPercentageInput[row.index] ??
              formik.values.salesPercentageByOwnerType[row.index]
                ?.salesPercentage,
            required: true,
          }),
          mantineTableBodyCellProps: {
            sx: {
              outline: 'none !important',
            },
          },
        },
      ];
    }, [
      formik?.values?.salesPercentageByOwnerType,
      formik?.values?.salesPercentageByOwnerTypeOther,
      salaselPercentageInput,
      JSON.stringify(salesaTableData),
    ]);

    useEffect(() => {
      let tableData: contractorTable[] = formik?.values?.fixedContract || [];
      if (getTypes(formik?.values?.contractors, 'type').includes('Others')) {
        tableData = formik?.values?.fixedContract.map((item: any) => {
          if (item?.contractorType === 'Others') {
            return {
              ...item,
              contractorType:
                formik.values?.contractorTypeOther &&
                formik.values?.contractorTypeOther?.trim() !== ''
                  ? formik.values?.contractorTypeOther
                  : item?.contractorType,
            };
          } else {
            return item;
          }
        });
      }
      formik?.setFieldValue('fixedContractorCopy', tableData);
    }, [formik?.values?.contractorTypeOther, formik?.values?.fixedContract]);

    useEffect(() => {
      let data = formik?.values?.salesPercentageByOwnerType.map(
        (el) => el.salesPercentage
      );
      setSalaselPercentageInput(data);
      setSalesaTableData(formik?.values?.salesPercentageByOwnerType);
    }, [formik?.values?.salesPercentageByOwnerType]);

    const table = useMantineReactTable({
      columns: columns as any,
      data: formik?.values?.fixedContractorCopy,
      enableEditing: showBox ? false : true,
      editDisplayMode: 'table',
      getRowId: (row, index) => row?.id ?? '',
      mantineTableContainerProps: {
        className: 'contractor-table cms-contractor-table',
      },
      mantineEditTextInputProps: ({ cell, column, row, table }) => ({
        onChange: (event: any) => {
          const data = handleMinMaxNumberInput({
            inputValue: Number(event.target.value),
            min: 0,
            max: 100,
          });
          handleSaveCell(
            cell,
            cell?.column?.id === 'contractPrice' ||
              cell?.column?.id === 'annualSales'
              ? data === 0
                ? ''
                : data
              : event.target.value
          );
        },
        type:
          cell?.column?.id === 'contractPrice' ||
          cell?.column?.id === 'annualSales'
            ? 'number'
            : 'text',
        onKeyDown:
          cell?.column?.id === 'contractPrice' ||
          cell?.column?.id === 'annualSales'
            ? preventAlphabetsTyping
            : () => {},
        variant: 'unstyled',
      }),
      enableToolbarInternalActions: false,
      enableTopToolbar: false,
      enablePagination: false,
      enableBottomToolbar: false,
      enableRowActions: false,
      defaultColumn: { minSize: 4, maxSize: 4, size: 4 },
      mantineTableProps: {
        sx: {
          tableLayout: 'fixed',
          border: 'none',
          boxShadow: 'none',
        },
      },
      mantineTableHeadCellProps: {
        className: 'contractor-head',
      },
    });

    const SalesbyOwnerTypetable = useMantineReactTable({
      columns: columnssalesPercentageByOwner as any,
      data: salesaTableData,
      enableEditing: showBox ? false : true,
      editDisplayMode: 'table',
      getRowId: (row, index) => row.ownerType ?? row.id,
      mantineTableContainerProps: {
        className: 'contractor-table cms-contractor-table',
      },
      mantineEditTextInputProps: ({ cell, column, row, table }) => ({
        onChange: (event: any) => {
          const data = handleMinMaxNumberInput({
            inputValue: Number(event.target.value),
            min: 0,
            max: 100,
          });
          setSalaselPercentageInput((oldData: any[]) => {
            const newData: any[] = [...oldData];
            newData[row.index] = data === 0 ? '' : data;
            return [...newData];
          });

          handleSaveCellSales(cell, Number(data));
        },
        onKeyDown: (e) => {
          if (cell?.column?.id === 'salesPercentage') {
            preventAlphabetsTyping(e);
          }
        },
        variant: 'unstyled',
      }),
      enableToolbarInternalActions: false,
      enableTopToolbar: false,
      enablePagination: false,
      enableBottomToolbar: false,
      enableRowActions: false,
      defaultColumn: { minSize: 4, maxSize: 4, size: 4 },
      mantineTableProps: {
        sx: {
          tableLayout: 'fixed',
          border: 'none',
          boxShadow: 'none',
        },
      },
      mantineTableHeadCellProps: {
        className: 'contractor-head',
      },
    });

    const { values, setFieldValue } = formik;
    const KeyPersonnelBoxAdd = () => {
      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };

        // Ensure keyPersonnel is always an array
        const keyPersonnel = Array.isArray(newValues?.keyPersonnel)
          ? newValues.keyPersonnel
          : [];

        keyPersonnel.push({
          name: null,
          position: null,
          hiringDate: null,
          // resume: string;
        });

        const updatedValues = {
          ...newValues,
          keyPersonnel,
        };

        return updatedValues;
      });
    };

    const countryBoxAdd = () => {
      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };

        let workOutsideCanada = newValues?.workOutsideCanada || {};
        const annualSalesPercentage =
          newValues?.workOutsideCanada.annualSalesPercentage || [];

        annualSalesPercentage?.push({
          country: null,
          percentage: null,
        });

        workOutsideCanada = {
          ...workOutsideCanada,
          annualSalesPercentage,
        };

        return {
          ...newValues,
          workOutsideCanada,
        };
      });
    };
    const KeyPersoneelBoxRemove = (index: number) => {
      if (values.keyPersonnel) {
        formik.setValues((prevValues) => {
          const newValues = { ...prevValues };
          const keyPersonnel = newValues?.keyPersonnel || [];

          if (keyPersonnel?.length > 1) {
            keyPersonnel?.splice(index, 1);
            return {
              ...newValues,
              keyPersonnel,
            };
          }
          return newValues;
        });
      }
    };

    const countryBoxRemove = (index: number) => {
      if (values.workOutsideCanada) {
        formik.setValues((prevValues) => {
          const newValues = { ...prevValues };

          let workOutsideCanada = newValues?.workOutsideCanada || {};
          const annualSalesPercentage =
            workOutsideCanada?.annualSalesPercentage || [];

          if (annualSalesPercentage?.length > 1) {
            annualSalesPercentage?.splice(index, 1);
            workOutsideCanada = {
              ...workOutsideCanada,
              annualSalesPercentage,
            };

            return {
              ...newValues,
              workOutsideCanada,
            };
          }

          return newValues;
        });
      }
    };
    const getYesterday = (): Date => {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return yesterday;
    };

    return (
      <Box
        display="flex"
        gap={4}
        w="min(88rem,95%)"
        // margin={'-236px auto 0'}
        flexDirection={{ base: 'column', lg: 'row' }}
        h={'100%'}
      >
        <Container
          maxW={'container.lg'}
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
          h="auto"
          p="20px"
          borderRadius="20px"
          gap={5}
          bg={'white'}
          // overflow="auto" height="calc(100vh - 150px)" sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box display={'flex'} flexDirection="column" gap={5}>
              <Box>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="center"
                  fontSize={20}
                >
                  About{' '}
                  {applicantHeaderName && applicantHeaderName.length > 0
                    ? `${applicantHeaderName} `
                    : null}
                </Text>
              </Box>
              {/* <Box display={"flex"} flexDirection={"column"} gap={3}> */}
              <Box
                boxShadow="0px 0px 5px 0px rgba(0,0,0,0.3)"
                borderRadius="20px"
                p="15px"
                display={'flex'}
                flexDirection="column"
                gap={5}
              >
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="center"
                    fontSize={20}
                  >
                    General
                  </Text>
                </Box>
                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      display="flex"
                      justifyContent="start"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        In which geographic area do you work?{' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="In which geographic area do you work?"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onChange={formik.handleChange}
                        value={formik.values.geoArea ?? ''}
                        name="geoArea"
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'geoArea' in currentHistoryData?.updatedData &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'geoArea' in currentHistoryData?.updatedData &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Box display={'flex'} flexDir={'column'} gap={3}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Do you ever work outside it?
                          {/* <span style={{ color: "red" }}>
                        {" "}
                        <span style={{ color: "red" }}>*</span>
                      </span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            formik.values?.workOutsideDetails?.workOutside ===
                            true
                          }
                          onChange={(e) => {
                            formik.setFieldValue(
                              'workOutsideDetails.workOutside',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            formik.values?.workOutsideDetails?.workOutside ===
                            false
                          }
                          onChange={(e) => {
                            formik.setFieldValue('workOutsideDetails', {
                              workOutside: !e.target.checked,
                              where: null,
                              howOften: null,
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
                {formik?.values?.workOutsideDetails?.workOutside && (
                  <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                    <Box flex={{ base: '1', md: '1' }}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Where? {/* <span style={{ color: "red" }}>*</span> */}
                        </li>
                      </Text>

                      <Box
                        display="flex"
                        mb={{ base: '20px', md: '5px' }}
                        mt={2}
                      >
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder="Where?"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={formik.values?.workOutsideDetails?.where ?? ''}
                          name="workOutsideDetails.where"
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData
                              ?.workOutsideDetails &&
                            'where' in
                              currentHistoryData?.updatedData
                                ?.workOutsideDetails &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData
                              ?.workOutsideDetails &&
                            'where' in
                              currentHistoryData?.updatedData
                                ?.workOutsideDetails &&
                            'white'
                          }
                        />
                      </Box>
                    </Box>
                    <Box flex={{ base: '1', md: '1' }}>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          How often?{' '}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </li>
                      </Text>

                      <Box
                        display="flex"
                        mb={{ base: '20px', md: '5px' }}
                        mt={2}
                      >
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder="How often?"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={
                            formik.values?.workOutsideDetails?.howOften ?? ''
                          }
                          name="workOutsideDetails.howOften"
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData
                              ?.workOutsideDetails &&
                            'howOften' in
                              currentHistoryData?.updatedData
                                ?.workOutsideDetails &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'howOften' in currentHistoryData?.updatedData &&
                            'white'
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      What type of contractor are you?
                      <span style={{ color: 'red' }}>*</span>
                    </li>
                  </Text>
                  <CheckboxGroup
                    // defaultValue={getTypes(formik?.values?.contractors,"type").filter((item:any) => item !== null)}
                    value={getTypes(formik?.values?.contractors, 'type').filter(
                      (item: any) => item !== null
                    )}
                    onChange={(value) => {
                      let updatedContactors: any = [];
                      let dataTable: any[] = [];

                      value?.forEach((item: any, i: number) => {
                        const data = formik.values?.fixedContract.find(
                          (el) => el.contractorType === item
                        );
                        const data2 = formik.values?.contractors.find(
                          (el) => el.type === item
                        );
                        dataTable.push({
                          contractorType: data?.contractorType ?? item,
                          contractPrice: data?.contractPrice ?? null,
                          location: data?.location ?? null,
                          annualSales:
                            value.length === 1
                              ? 100
                              : data?.annualSales ?? null,
                          id: data?.id ?? getRandomUUID(),
                        });

                        if (item && item !== 'Others') {
                          updatedContactors.push({
                            type: item,
                            annualSalesPercentage:
                              formik.values?.contractors[i]
                                ?.annualSalesPercentage ?? null,
                          });
                        } else {
                          updatedContactors.push({
                            type: data2?.type ?? item,
                            annualSalesPercentage:
                              data2?.annualSalesPercentage ?? null,
                            otherContractorType:
                              data2?.otherContractorType ?? null,
                          });
                        }
                      });
                      formik.setFieldValue('fixedContract', dataTable);
                      formik.setFieldValue('contractors', updatedContactors);
                    }}
                  >
                    <Stack
                      direction="row"
                      display={'flex'}
                      flexWrap={'wrap'}
                      mt={2}
                      color="#114684"
                    >
                      <Checkbox
                        value="General Building"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        General Building
                      </Checkbox>
                      <Checkbox
                        value="Paving"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Paving
                      </Checkbox>
                      <Checkbox
                        value="Electrical"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Electrical
                      </Checkbox>
                      <Checkbox
                        value="Excavation"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Excavation
                      </Checkbox>
                      <Checkbox
                        value="Concrete"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Concrete
                      </Checkbox>
                      <Checkbox
                        value="Framing"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Framing
                      </Checkbox>
                      <Checkbox
                        value="Roofing/Siding/Sheet metal work"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Roofing/Siding/Sheet metal work
                      </Checkbox>
                      <Checkbox
                        value="Plumbing"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Plumbing
                      </Checkbox>
                      <Checkbox
                        value="HVAC"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        HVAC
                      </Checkbox>
                      <Checkbox
                        value="Carpentry"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Carpentry
                      </Checkbox>
                      <Checkbox
                        value="Masonry/Stonework"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Masonry/Stonework
                      </Checkbox>
                      <Checkbox
                        value="Plastering"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Plastering
                      </Checkbox>
                      <Checkbox
                        value="Painting/ Paper hanging"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Painting/ Paper hanging
                      </Checkbox>
                      <Checkbox
                        value="Tile Setting"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Tile Setting
                      </Checkbox>
                      <Checkbox
                        value="Others"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Other (Please describe)
                        <span style={{ color: 'red' }}>*</span>
                      </Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  {formik.touched.contractors &&
                    formik.errors.contractors &&
                    typeof formik.errors.contractors === 'string' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.contractors}
                      </div>
                    )}

                  {getTypes(formik?.values?.contractors, 'type').includes(
                    'Others'
                  ) && (
                    <Box mt={2}>
                      <Box
                        display="flex"
                        mb={{ base: '20px', md: '5px' }}
                        mt={2}
                      >
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder=" Other(Please describe)"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={formik.values.contractorTypeOther ?? ''}
                          name="contractorTypeOther"
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData &&
                            !!getOtherType(
                              showHistory
                                ? currentHistoryData?.updatedData?.contractors
                                : formData?.contractors,
                              'type',
                              'otherContractorType'
                            ) &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData &&
                            !!getOtherType(
                              showHistory
                                ? currentHistoryData?.updatedData?.contractors
                                : formData?.contractors,
                              'type',
                              'otherContractorType'
                            ) &&
                            'white'
                          }
                        />
                      </Box>
                    </Box>
                  )}

                  {formik.touched.contractorTypeOther &&
                    formik.errors.contractorTypeOther && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.contractorTypeOther}
                      </div>
                    )}
                </Box>

                {formik?.values?.contractors?.length > 0 && (
                  <Box
                    maxW={'1550px'}
                    width={'100%'}
                    // minW={'500px'}
                    mx={'auto'}
                  >
                    <MantineReactTable table={table} />
                  </Box>
                )}
                {formik.errors.fixedContract &&
                  formik.values.contractors.length >= 1 && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors?.fixedContract[0] as any)
                        ?.contractorType || formik.errors.fixedContract}
                    </div>
                  )}
                <Box flex={{ base: '1', md: '1' }}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      What was the largest $ amount of work on hand at any one
                      time in the past 5 years?{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </li>
                  </Text>

                  <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                      placeholder=" What was the largest $ amount of work on hand at any one time in the past 5 years?"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={formik.handleChange}
                      value={formik.values.largestWorkAmount ?? ''}
                      name="largestWorkAmount"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'largestWorkAmount' in
                          currentHistoryData?.updatedData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'largestWorkAmount' in
                          currentHistoryData?.updatedData &&
                        'white'
                      }
                    />
                  </Box>
                  {formik.touched.largestWorkAmount &&
                    formik.errors.largestWorkAmount && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.largestWorkAmount}
                      </div>
                    )}
                </Box>
                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        How many contracts did it consist of?{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>
                    <Box>
                      <Box
                        display="flex"
                        mb={{ base: '20px', md: '5px' }}
                        mt={2}
                      >
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="number"
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          onKeyDown={preventAlphabetsTyping}
                          placeholder="How many contracts did it consist of?"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={formik.values?.numberOfContracts ?? ''}
                          name="numberOfContracts"
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'numberOfContracts' in
                              currentHistoryData?.updatedData &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'numberOfContracts' in
                              currentHistoryData?.updatedData &&
                            'white'
                          }
                        />
                      </Box>
                      {formik.touched.numberOfContracts &&
                        formik.errors.numberOfContracts && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors?.numberOfContracts}
                          </div>
                        )}
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        What % of work is usually sublet to others?{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>
                    <Box>
                      <Box
                        display="flex"
                        mb={{ base: '20px', md: '5px' }}
                        mt={2}
                      >
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="number"
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          onKeyDown={preventAlphabetsTyping}
                          placeholder="What % of work is usually sublet to others?"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={formik.values?.workSubletPercentage ?? ''}
                          name="workSubletPercentage"
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'workSubletPercentage' in
                              currentHistoryData?.updatedData &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            'workSubletPercentage' in
                              currentHistoryData?.updatedData &&
                            'white'
                          }
                        />
                      </Box>
                      {formik.touched.workSubletPercentage &&
                        formik.errors.workSubletPercentage && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors?.workSubletPercentage}
                          </div>
                        )}
                    </Box>
                  </Box>
                </Box>

                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        What type of work is usually undertaken with own forces?{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="What type of work is usually undertaken with own forces?"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onChange={formik.handleChange}
                        value={formik.values?.workUndertakenOwnForces ?? ''}
                        name="workUndertakenOwnForces"
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'workUndertakenOwnForces' in
                            currentHistoryData?.updatedData &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'workUndertakenOwnForces' in
                            currentHistoryData?.updatedData &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.workUndertakenOwnForces &&
                      formik.errors.workUndertakenOwnForces && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors?.workUndertakenOwnForces}
                        </div>
                      )}
                  </Box>
                  {/* <Box flex={{ base: "1", md: "1" }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        What type of work is usually sublet?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: "20px", md: "5px" }} mt={2}>
                      <Input
                        fontSize={"13px"}
                        w="100%"
                        type="text"
                        placeholder=" What type of work is usually sublet?"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={"#7f7f7f"}
                        _hover={{ borderColor: "#7f7f7f" }}
                        onChange={formik.handleChange}
                        value={
                          formik.values.largestContracts?.[0]?.workSublet ?? ""
                        }
                        name="largestContracts[0].workSublet"
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.largestContracts?.[0] &&
                          "workSublet" in
                          currentHistoryData?.largestContracts?.[0] &&
                          "#114684"
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.largestContracts?.[0] &&
                          "workSublet" in
                          currentHistoryData?.largestContracts?.[0] &&
                          "white"
                        }
                      />
                    </Box>
                    {formik.touched.largestContracts &&
                      formik.errors.largestContracts &&
                      typeof formik.errors.largestContracts?.[0] !== "string" &&
                      formik.errors.largestContracts?.[0]?.workSublet && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "13px",
                            textAlign: "start",
                            fontWeight: "500",
                          }}
                        >
                          {formik.errors.largestContracts?.[0]?.workSublet}
                        </div>
                      )}
                  </Box> */}
                </Box>
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      To what extent does management control and supervise
                      individual jobs?
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </li>
                  </Text>
                  <Stack
                    direction="row"
                    display={'flex'}
                    flexWrap={'wrap'}
                    mt={2}
                    color="#114684"
                    pl={5}
                    alignItems={'flex-end'}
                    justifyContent={'space-between'}
                  >
                    <RadioGroup
                      value={formik.values?.managementControlRadio}
                      onChange={(value) => {
                        formik.setFieldValue('managementControlRadio', value);
                      }}
                    >
                      <Stack
                        direction="row"
                        display={'flex'}
                        flexWrap={'wrap'}
                        mt={1}
                        color="#114684"
                        alignItems={'flex-end'}
                      >
                        <Text>a.</Text>
                        <Radio
                          value="Daily"
                          size="sm"
                          isReadOnly={showBox}
                          cursor={showBox ? 'not-allowed' : 'pointer'}
                        >
                          Daily
                        </Radio>
                        <Radio
                          value="Weekly"
                          size="sm"
                          isReadOnly={showBox}
                          cursor={showBox ? 'not-allowed' : 'pointer'}
                        >
                          Weekly
                        </Radio>
                        <Radio
                          value="Monthly"
                          size="sm"
                          isReadOnly={showBox}
                          cursor={showBox ? 'not-allowed' : 'pointer'}
                        >
                          Monthly
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <CheckboxGroup
                      value={formik?.values?.managementControlCheckbox}
                      onChange={(value) => {
                        formik.setFieldValue(
                          'managementControlCheckbox',
                          value
                        );
                      }}
                    >
                      <Stack
                        direction="row"
                        display={'flex'}
                        flexWrap={'wrap'}
                        mt={1}
                        color="#114684"
                        alignItems={'flex-end'}
                      >
                        <Text>b.</Text>
                        <Checkbox
                          value="Personally"
                          size="sm"
                          readOnly={showBox}
                          cursor={showBox ? 'not-allowed' : 'pointer'}
                        >
                          Personally
                        </Checkbox>
                        <Checkbox
                          value="Through the Construction Management System"
                          size="sm"
                          readOnly={showBox}
                          cursor={showBox ? 'not-allowed' : 'pointer'}
                        >
                          Through the Construction Management System
                        </Checkbox>
                        <Checkbox
                          value="Other"
                          size="sm"
                          readOnly={showBox}
                          cursor={showBox ? 'not-allowed' : 'pointer'}
                        >
                          Other
                        </Checkbox>
                      </Stack>
                    </CheckboxGroup>
                  </Stack>
                  {formik.values?.managementControlCheckbox?.length > 0 &&
                    (formik.values?.managementControlCheckbox).includes(
                      'Other'
                    ) && (
                      <Box mt={2}>
                        <Box
                          display="flex"
                          mb={{ base: '5px', md: '5px' }}
                          mt={3}
                          pl={5}
                        >
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            placeholder=" Other(Please describe)"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={formik.values.managementControlOther ?? ''}
                            name="managementControlOther"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData
                                      ?.managementControl
                                  : formData?.managementControl,
                                'jobType',
                                'otherJobType'
                              ) &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData
                                      ?.managementControl
                                  : formData?.managementControl,
                                'jobType',
                                'otherJobType'
                              ) &&
                              'white'
                            }
                          />
                        </Box>
                      </Box>
                    )}
                </Box>
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      What type of work is usually sublet?
                      <span style={{ color: 'red' }}>*</span>
                    </li>
                  </Text>
                  <CheckboxGroup
                    // defaultValue={getTypes(formik?.values?.workSublet,"type").filter((item:any) => item !== null)}
                    value={getTypes(formik?.values?.workSublet, 'type').filter(
                      (item: any) => item !== null
                    )}
                    onChange={(value) => {
                      let updatedworkSublet = value.map(
                        (item: any, i: number) => {
                          if (item && item !== 'Others') {
                            return {
                              type: item,
                            };
                          } else {
                            return {
                              type: item,
                              otherSublet:
                                formik.values?.workSublet[i]?.otherSublet ??
                                null,
                            };
                          }
                        }
                      );
                      formik.setFieldValue('workSublet', updatedworkSublet);
                    }}
                  >
                    <Stack
                      direction="row"
                      display={'flex'}
                      flexWrap={'wrap'}
                      mt={2}
                      color="#114684"
                    >
                      <Checkbox
                        value="General Building"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        General Building
                      </Checkbox>
                      <Checkbox
                        value="Paving"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Paving
                      </Checkbox>
                      <Checkbox
                        value="Electrical"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Electrical
                      </Checkbox>
                      <Checkbox
                        value="Excavation"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Excavation
                      </Checkbox>
                      <Checkbox
                        value="Concrete"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Concrete
                      </Checkbox>
                      <Checkbox
                        value="Framing"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Framing
                      </Checkbox>
                      <Checkbox
                        value="Roofing/Siding/Sheet metal work"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Roofing/Siding/Sheet metal work
                      </Checkbox>
                      <Checkbox
                        value="Plumbing"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Plumbing
                      </Checkbox>
                      <Checkbox
                        value="HVAC"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        HVAC
                      </Checkbox>
                      <Checkbox
                        value="Carpentry"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Carpentry
                      </Checkbox>
                      <Checkbox
                        value="Masonry/Stonework"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Masonry/Stonework
                      </Checkbox>
                      <Checkbox
                        value="Plastering"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Plastering
                      </Checkbox>
                      <Checkbox
                        value="Painting/ Paper hanging"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Painting/ Paper hanging
                      </Checkbox>
                      <Checkbox
                        value="Tile Setting"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Tile Setting
                      </Checkbox>
                      <Checkbox
                        value="Others"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Other (Please describe)
                        <span style={{ color: 'red' }}>*</span>
                      </Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  {formik.touched.workSublet && formik.errors.workSublet && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors?.workSublet[0] as any)?.type ||
                        formik.errors.workSublet}
                    </div>
                  )}

                  {getTypes(formik.values?.workSublet, 'type').includes(
                    'Others'
                  ) && (
                    <Box mt={2}>
                      <Box mt={2}>
                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            placeholder=" Other(Please describe)"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={formik.values.workSubletOther ?? ''}
                            name="workSubletOther"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData?.workSublet
                                  : formData?.workSublet,
                                'type',
                                'otherSublet'
                              ) &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData?.workSublet
                                  : formData?.workSublet,
                                'type',
                                'otherSublet'
                              ) &&
                              'white'
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {formik.touched.workSubletOther &&
                    formik.errors.workSubletOther && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.workSubletOther}
                      </div>
                    )}
                </Box>

                {/* </Box> */}
                <Box flex={{ base: '1', md: '1' }}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      {' '}
                      What is the total value of projects ($) that your
                      organization can manage simultaneously?
                    </li>
                  </Text>

                  <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="What is the total value of projects ($) that your organization can manage simultaneously?"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={formik.handleChange}
                      value={formik.values.totalWorkProgramOneTime ?? ''}
                      name="totalWorkProgramOneTime"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'totalWorkProgramOneTime' in
                          currentHistoryData?.updatedData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'totalWorkProgramOneTime' in
                          currentHistoryData?.updatedData &&
                        'white'
                      }
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                    />
                  </Box>
                </Box>
                <Box flex={{ base: '1', md: '1' }}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      {' '}
                      What is the total value of projects ($) that your
                      organization can manage over the next 12 months?
                    </li>
                  </Text>

                  <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder="What is the total value of projects ($) that your organization can manage over the next 12 months?"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={formik.handleChange}
                      value={formik.values.totalWorkProgram12Months ?? ''}
                      name="totalWorkProgram12Months"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'totalWorkProgram12Months' in
                          currentHistoryData?.updatedData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'totalWorkProgram12Months' in
                          currentHistoryData?.updatedData &&
                        'white'
                      }
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                    />
                  </Box>
                </Box>
                <Box display={'flex'} flexDirection="column" gap={3}>
                  <Box
                    display={'flex'}
                    flexDir={{ base: 'column', lg: 'row' }}
                    gap={{ base: 2, lg: 5 }}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      width={'50%'}
                    >
                      <li>
                        Do you obtain bonds from some of your subcontractors?
                        <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>
                    <Stack spacing={5} direction="row" pl={5}>
                      <Checkbox
                        colorScheme="green"
                        fontWeight="500"
                        isChecked={
                          formik.values?.obtainBondsFromSubcontractors
                            ?.hasBonds === true
                        }
                        onChange={(e) =>
                          formik.setFieldValue(
                            'obtainBondsFromSubcontractors.hasBonds',
                            e.target.checked
                          )
                        }
                        readOnly={showBox}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        fontWeight="500"
                        isChecked={
                          formik.values?.obtainBondsFromSubcontractors
                            ?.hasBonds === false
                        }
                        onChange={(e) =>
                          // formik.setFieldValue(
                          //   "obtainBondsFromSubcontractors.hasBonds",
                          //   !e.target.checked
                          // );
                          formik.setFieldValue(
                            'obtainBondsFromSubcontractors',
                            {
                              hasBonds: !e.target.checked,
                              minimumContract: null,
                            }
                          )
                        }
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>
                  {formik.touched.obtainBondsFromSubcontractors?.hasBonds &&
                    formik.errors.obtainBondsFromSubcontractors?.hasBonds && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {replaceErrorMessage(
                          formik.errors?.obtainBondsFromSubcontractors?.hasBonds
                        )}
                      </div>
                    )}
                  {formik.values?.obtainBondsFromSubcontractors?.hasBonds ===
                    true && (
                    <Box mt={2}>
                      <Box
                        flex={{ base: 1, md: '1' }}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                          mb={1}
                        >
                          <li>
                            What is the minimum contract $ value that you
                            require a bond from a subcontractor?
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder="What is the minimum contract $ value that you require a bond from a subcontractor?"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={
                            formik.values.obtainBondsFromSubcontractors
                              ?.minimumContract || ''
                          }
                          name="obtainBondsFromSubcontractors.minimumContract"
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData
                              ?.obtainBondsFromSubcontractors &&
                            'minimumContract' in
                              currentHistoryData?.updatedData
                                ?.obtainBondsFromSubcontractors &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData
                              ?.obtainBondsFromSubcontractors &&
                            'minimumContract' in
                              currentHistoryData?.updatedData
                                ?.obtainBondsFromSubcontractors &&
                            'white'
                          }
                          // onKeyDown={preventAlphabetsTyping}
                          // onWheel={(e) =>
                          //   (e.target as HTMLInputElement).blur()
                          // }
                        />
                        {formik.touched.obtainBondsFromSubcontractors
                          ?.minimumContract &&
                          formik.errors.obtainBondsFromSubcontractors
                            ?.minimumContract && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {
                                formik.errors.obtainBondsFromSubcontractors
                                  ?.minimumContract
                              }
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      List the approximate % of your annual sales for each type
                      of owner for whom you perform work for
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </li>
                  </Text>
                  <CheckboxGroup
                    // defaultValue={getTypes(formik?.values?.salesPercentageByOwnerType,"ownerType").filter((item:any) => item !== null)}
                    value={getTypes(
                      formik?.values?.salesPercentageByOwnerType,
                      'ownerType'
                    ).filter((item: any) => item !== null)}
                    onChange={(value) => {
                      let updatedsalesPercentage: any = [];
                      value?.forEach((item: any, i: number) => {
                        const data =
                          formik.values?.salesPercentageByOwnerType.find(
                            (el) => el.ownerType === item
                          );
                        if (item && item !== 'Others') {
                          updatedsalesPercentage.push({
                            ownerType: item,
                            salesPercentage: data?.salesPercentage ?? null,
                            id: getRandomUUID(),
                          });
                        } else {
                          updatedsalesPercentage.push({
                            ownerType: item,
                            otherOwnerType: data?.otherOwnerType ?? null,
                            salesPercentage: data?.salesPercentage ?? null,
                            id: getRandomUUID(),
                          });
                        }
                      });
                      formik.setFieldValue(
                        'salesPercentageByOwnerType',
                        updatedsalesPercentage
                      );
                    }}
                  >
                    <Stack
                      direction="row"
                      display={'flex'}
                      flexWrap={'wrap'}
                      mt={2}
                      color="#114684"
                    >
                      <Checkbox
                        value="Government"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Government
                      </Checkbox>
                      <Checkbox
                        value="Institutions"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Institutions
                      </Checkbox>
                      <Checkbox
                        value="Utilities"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Utilities
                      </Checkbox>
                      <Checkbox
                        value="General Contractors"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        General Contractors
                      </Checkbox>
                      <Checkbox
                        value="Developers"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Developers
                      </Checkbox>
                      <Checkbox
                        value="Private Residential"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Private Residential
                      </Checkbox>
                      <Checkbox
                        value="Private Companies"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Private Companies
                      </Checkbox>
                      <Checkbox
                        value="Others"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Others
                      </Checkbox>
                    </Stack>
                  </CheckboxGroup>

                  {getTypes(
                    formik.values?.salesPercentageByOwnerType,
                    'ownerType'
                  ).includes('Others') && (
                    <Box mt={2}>
                      <Box mt={2}>
                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            placeholder=" Other(Please describe)"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={
                              formik.values.salesPercentageByOwnerTypeOther ??
                              ''
                            }
                            name="salesPercentageByOwnerTypeOther"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData
                                      ?.salesPercentageByOwnerType
                                  : formData?.salesPercentageByOwnerType,
                                'ownerType',
                                'otherOwnerType'
                              ) &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData
                                      ?.salesPercentageByOwnerType
                                  : formData?.salesPercentageByOwnerType,
                                'ownerType',
                                'otherOwnerType'
                              ) &&
                              'white'
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {formik.touched.salesPercentageByOwnerTypeOther &&
                    formik.errors.salesPercentageByOwnerTypeOther && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.salesPercentageByOwnerTypeOther}
                      </div>
                    )}
                </Box>
                {formik?.values?.salesPercentageByOwnerType?.length > 0 && (
                  <Box
                    maxW={'1550px'}
                    width={'100%'}
                    // minW={'500px'}
                    mx={'auto'}
                  >
                    <MantineReactTable table={SalesbyOwnerTypetable} />
                  </Box>
                )}
                {formik.errors.salesPercentageByOwnerType && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {(formik.errors?.salesPercentageByOwnerType[0] as any)
                      ?.ownerType || formik.errors.salesPercentageByOwnerType}
                  </div>
                )}

                <Box display={'flex'} flexDir={'column'} gap={5}>
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        width={'50%'}
                      >
                        <li>
                          {`Do you perform any work outside of ${currentCuntry}?`}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row" pl={5}>
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.workOutsideCanada.performWorkOutside === true
                          }
                          onChange={(e) => {
                            setFieldValue(
                              'workOutsideCanada.performWorkOutside',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.workOutsideCanada.performWorkOutside ===
                            false
                          }
                          onChange={(e) => {
                            setFieldValue('workOutsideCanada', {
                              performWorkOutside: !e.target.checked,
                              annualSalesPercentage: [
                                {
                                  country: null,
                                  percentage: null,
                                },
                              ],
                            });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {formik.touched.workOutsideCanada?.performWorkOutside &&
                      formik.errors.workOutsideCanada?.performWorkOutside && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            formik.errors.workOutsideCanada?.performWorkOutside
                          )}
                        </div>
                      )}
                  </Box>
                  {values.workOutsideCanada.performWorkOutside && (
                    <Box>
                      {formik?.values?.workOutsideCanada?.annualSalesPercentage?.map(
                        (item: any, i: number) => (
                          <Box
                            display={'flex'}
                            flexDir={{ base: 'column', lg: 'row' }}
                            gap={{ base: 1, lg: 5 }}
                          >
                            <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              flexDir={'column'}
                              gap={3}
                              flex={{ base: '1', md: '1' }}
                            >
                              <Text
                                fontWeight="600"
                                color="#114684"
                                textAlign="start"
                                fontSize={13}
                                // h={"39px"}
                              >
                                List the country(s) you perform work in{' '}
                                <span style={{ color: 'red' }}>*</span>
                              </Text>

                              <Box>
                                <AppSelect
                                  placeholder="List the country(s) you perform work in"
                                  data={countryOptions}
                                  clearable={true}
                                  value={
                                    values.workOutsideCanada
                                      .annualSalesPercentage?.[i]?.country
                                      ? (values.workOutsideCanada
                                          .annualSalesPercentage[i]
                                          .country as string)
                                      : ''
                                  }
                                  onChange={(selectedOptions) => {
                                    setFieldValue(
                                      `workOutsideCanada.annualSalesPercentage[${i}].country`,
                                      selectedOptions
                                    );
                                  }}
                                  searchable
                                  styles={{
                                    root: {
                                      flex: 1,
                                    },
                                    wrapper: {
                                      height: '2.5rem',
                                    },
                                    input: {
                                      readOnly: showBox,
                                      backgroundColor:
                                        showHistory &&
                                        currentHistoryData?.updatedData
                                          ?.workOutsideCanada
                                          ?.annualSalesPercentage?.[i] &&
                                        'country' in
                                          currentHistoryData?.updatedData
                                            ?.workOutsideCanada
                                            ?.annualSalesPercentage?.[i] &&
                                        '#114684',
                                      color:
                                        showHistory &&
                                        currentHistoryData?.updatedData
                                          ?.workOutsideCanada
                                          ?.annualSalesPercentage?.[i] &&
                                        'country' in
                                          currentHistoryData?.updatedData
                                            ?.workOutsideCanada
                                            ?.annualSalesPercentage?.[i] &&
                                        'white',
                                    },
                                  }}
                                />
                                {formik.touched.workOutsideCanada &&
                                  formik.errors.workOutsideCanada &&
                                  formik.errors.workOutsideCanada
                                    .annualSalesPercentage &&
                                  typeof formik.errors.workOutsideCanada
                                    .annualSalesPercentage?.[i] !== 'string' &&
                                  formik.errors.workOutsideCanada
                                    .annualSalesPercentage?.[i] &&
                                  (
                                    formik.errors.workOutsideCanada
                                      .annualSalesPercentage?.[i] as any
                                  )?.country && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '13px',
                                        textAlign: 'start',
                                        fontWeight: '500',
                                      }}
                                    >
                                      {
                                        (
                                          formik.errors.workOutsideCanada
                                            .annualSalesPercentage?.[i] as any
                                        )?.country
                                      }
                                    </div>
                                  )}
                              </Box>
                            </Box>
                            <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              flexDir={'column'}
                              gap={3}
                              flex={{ base: '1', md: '1' }}
                            >
                              <Text
                                fontWeight="600"
                                color="#114684"
                                textAlign="start"
                                fontSize={13}
                              >
                                {`What % of your annual sales account for each
                                country (exclude ${currentCuntry})?`}
                                <span style={{ color: 'red' }}>*</span>
                              </Text>
                              <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems={'flex-start'}
                                gap={'8px'}
                              >
                                <Box
                                  display="flex"
                                  flexDir={'column'}
                                  // mb={{ base: '20px', md: '5px' }}
                                  // mt={2}
                                  flex={{ base: 1, md: 1 }}
                                >
                                  <Input
                                    fontSize={'13px'}
                                    w="100%"
                                    type="number"
                                    onWheel={(e) =>
                                      (e.target as HTMLInputElement).blur()
                                    }
                                    onKeyDown={preventAlphabetsTyping}
                                    placeholder={`What % of your annual sales account for each country (exclude ${currentCuntry})?`}
                                    borderRadius="5px"
                                    focusBorderColor="#7f7f7f"
                                    borderColor={'#7f7f7f'}
                                    _hover={{ borderColor: '#7f7f7f' }}
                                    onChange={(e: any) => {
                                      formik.handleChange(e);
                                      const value = handleMinMaxNumberInput({
                                        inputValue: e.target.value,
                                      });
                                      formik.setFieldValue(
                                        `workOutsideCanada.annualSalesPercentage[${i}].percentage`,
                                        value
                                      );
                                    }}
                                    value={
                                      values.workOutsideCanada
                                        .annualSalesPercentage?.[i]
                                        ?.percentage ?? ''
                                    }
                                    name={`workOutsideCanada.annualSalesPercentage[${i}].percentage`}
                                    readOnly={showBox}
                                    bgColor={
                                      showHistory &&
                                      currentHistoryData?.updatedData
                                        ?.workOutsideCanada
                                        ?.annualSalesPercentage?.[i] &&
                                      'percentage' in
                                        currentHistoryData?.updatedData
                                          ?.workOutsideCanada
                                          ?.annualSalesPercentage?.[i] &&
                                      '#114684'
                                    }
                                    color={
                                      showHistory &&
                                      currentHistoryData?.updatedData
                                        ?.workOutsideCanada
                                        ?.annualSalesPercentage?.[i] &&
                                      'percentage' in
                                        currentHistoryData?.updatedData
                                          ?.workOutsideCanada
                                          ?.annualSalesPercentage?.[i] &&
                                      'white'
                                    }
                                  />

                                  {formik.touched.workOutsideCanada &&
                                    formik.errors.workOutsideCanada &&
                                    formik.errors.workOutsideCanada
                                      .annualSalesPercentage &&
                                    typeof formik.errors.workOutsideCanada
                                      .annualSalesPercentage?.[i] !==
                                      'string' &&
                                    formik.errors.workOutsideCanada
                                      .annualSalesPercentage?.[i] &&
                                    (
                                      formik.errors.workOutsideCanada
                                        .annualSalesPercentage?.[i] as any
                                    )?.percentage && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '13px',
                                          textAlign: 'start',
                                          fontWeight: '500',
                                        }}
                                      >
                                        {
                                          (
                                            formik.errors.workOutsideCanada
                                              .annualSalesPercentage?.[i] as any
                                          )?.percentage
                                        }
                                      </div>
                                    )}
                                </Box>
                                {i === 0 ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop={'10px'}
                                    onClick={() => countryBoxAdd()}
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCirclePlus />
                                  </Box>
                                ) : (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    fontSize="xl"
                                    marginTop={'10px'}
                                    onClick={() => countryBoxRemove(i)}
                                    cursor={showBox ? 'not-allowed' : 'pointer'}
                                    pointerEvents={showBox ? 'none' : 'auto'}
                                  >
                                    <CiCircleMinus />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                </Box>

                <Box display={{ base: 'block', md: 'flex' }} gap={10}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexDir={'column'}
                    gap={5}
                    flex={{ base: '1', md: '1' }}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Are your employees unionized?</li>
                    </Text>

                    <Stack spacing={5} direction="row" pl={5}>
                      <Checkbox
                        colorScheme="green"
                        fontWeight="500"
                        isChecked={formik.values?.employeesUnionized === true}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'employeesUnionized',
                            e.target.checked
                          )
                        }
                        readOnly={showBox}
                      >
                        Yes{' '}
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        fontWeight="500"
                        isChecked={formik.values?.employeesUnionized === false}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'employeesUnionized',
                            !e.target.checked
                          )
                        }
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexDir={'column'}
                    gap={5}
                    flex={{ base: '1', md: '1' }}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li> Do you pay union scale wages?</li>
                    </Text>
                    <Stack spacing={5} direction="row" pl={5}>
                      <Checkbox
                        colorScheme="green"
                        fontWeight="500"
                        isChecked={formik.values?.payUnionScaleWages === true}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'payUnionScaleWages',
                            e.target.checked
                          )
                        }
                        readOnly={showBox}
                      >
                        Yes{' '}
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        fontWeight="500"
                        isChecked={formik.values?.payUnionScaleWages === false}
                        onChange={(e) =>
                          formik.setFieldValue(
                            'payUnionScaleWages',
                            !e.target.checked
                          )
                        }
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>
                </Box>
                {/* <Box flex={{ base: "1", md: "1" }}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      {" "}
                      What is your estimated sublet budget for this project? <span style={{ color: "red" }}>*</span>
                    </li>
                  </Text>

                  <Box display="flex" mb={{ base: "20px", md: "5px" }} mt={2}>
                    <Input
                      fontSize={"13px"}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                      placeholder="What is your estimated sublet budget for this project?"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={"#7f7f7f"}
                      _hover={{ borderColor: "#7f7f7f" }}
                      onChange={formik.handleChange}
                      value={
                        formik.values.estimatedSubletBudget ?? ""
                      }
                      name="estimatedSubletBudget"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        "estimatedSubletBudget" in currentHistoryData?.updatedData &&
                        "#114684"
                      }
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        "estimatedSubletBudget" in currentHistoryData?.updatedData &&
                        "white"
                      }
                    />
                  </Box>
                  {formik.touched.estimatedSubletBudget &&
                    formik.errors.estimatedSubletBudget &&
                    (
                      <div
                        style={{
                          color: "red",
                          fontSize: "13px",
                          textAlign: "start",
                          fontWeight: "500",
                        }}
                      >
                        {formik.errors.estimatedSubletBudget}
                      </div>
                    )} */}

                {/* <Box display={{ base: "block", md: "flex" }} gap={5}>
                  <Box flex={{ base: "1", md: "1" }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li> Name of compnay</li>
                    </Text>

                    <Box display="flex" mb={{ base: "20px", md: "5px" }} mt={2}>
                      <Input
                        fontSize={"13px"}
                        w="100%"
                        type="text"
                        placeholder="Name of compnay"
                        borderRadius="5px"
                        focusBorderColor="#FF7722"
                        borderColor={"#FF7722"}
                        _hover={{ borderColor: "#FF7722" }}
                        onChange={formik.handleChange}
                        value={formik.values.largestSuppliers?.[0]?.name ?? ""}
                        name="largestSuppliers[0].name"
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "name" in currentHistoryData?.largestSuppliers?.[0] &&
                          "#114684"
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "name" in currentHistoryData?.largestSuppliers?.[0] &&
                          "white"
                        }
                      />
                    </Box>
                  </Box>
                  <Box flex={{ base: "1", md: "1" }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Mailing address</li>
                    </Text>

                    <Box display="flex" mb={{ base: "20px", md: "5px" }} mt={2}>
                      <Input
                        fontSize={"13px"}
                        w="100%"
                        type="text"
                        placeholder="Mailing address"
                        borderRadius="5px"
                        focusBorderColor="#FF7722"
                        borderColor={"#FF7722"}
                        _hover={{ borderColor: "#FF7722" }}
                        value={
                          formik?.values?.largestSuppliers?.[0]
                            ?.mailingAddress ?? ""
                        }
                        name="largestSuppliers[0].mailingAddress"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "mailingAddress" in
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "#114684"
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "mailingAddress" in
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "white"
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box display={{ base: "block", md: "flex" }} gap={5}>
                  <Box flex={{ base: "1", md: "1" }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Fax</li>
                    </Text>

                    <Box display="flex" mb={{ base: "20px", md: "5px" }} mt={2}>
                      <Input
                        fontSize={"13px"}
                        w="100%"
                        type="number"
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        onKeyDown={preventAlphabetsTyping}
                        placeholder="Fax"
                        borderRadius="5px"
                        focusBorderColor="#FF7722"
                        borderColor={"#FF7722"}
                        _hover={{ borderColor: "#FF7722" }}
                        value={formik.values.largestSuppliers?.[0]?.fax ?? ""}
                        name="largestSuppliers[0].fax"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "fax" in currentHistoryData?.largestSuppliers?.[0] &&
                          "#114684"
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "fax" in currentHistoryData?.largestSuppliers?.[0] &&
                          "white"
                        }
                      />
                    </Box>
                  </Box>
                  <Box flex={{ base: "1", md: "1" }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li> Phone number</li>
                    </Text>

                    <Box display="flex" mb={{ base: "20px", md: "5px" }} mt={2}>
                      <Input
                        fontSize={"13px"}
                        w="100%"
                        type="number"
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        onKeyDown={preventAlphabetsTyping}
                        placeholder="Phone number"
                        borderRadius="5px"
                        focusBorderColor="#FF7722"
                        borderColor={"#FF7722"}
                        _hover={{ borderColor: "#FF7722" }}
                        value={
                          formik.values.largestSuppliers?.length > 0 &&
                            formik.values.largestSuppliers?.[0]?.phoneNumber !==
                            null
                            ? formik.values.largestSuppliers?.[0]?.phoneNumber
                            : ""
                        }
                        name="largestSuppliers[0].phoneNumber"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "phoneNumber" in
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "#114684"
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "phoneNumber" in
                          currentHistoryData?.largestSuppliers?.[0] &&
                          "white"
                        }
                      />
                    </Box>
                  </Box>
                </Box> */}
                {/* </Box> */}
              </Box>
              {/* <Box
                boxShadow="0px 0px 5px 0px rgba(0,0,0,0.3)"
                borderRadius="20px"
                p="15px"
                display={'flex'}
                flexDirection="column"
                gap={5}
              >
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="center"
                    fontSize={20}
                  >
                    Bond Request - Project Details
                  </Text>
                </Box>
                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        Name of owner <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Name of owner"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onChange={formik.handleChange}
                        value={formik.values.ownerName ?? ''}
                        name="ownerName"
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'ownerName' in currentHistoryData?.updatedData &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'ownerName' in currentHistoryData?.updatedData &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.ownerName && formik.errors.ownerName && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors?.ownerName}
                      </div>
                    )}
                  </Box>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        $ Contract price <span style={{ color: 'red' }}>*</span>
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="number"
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        onKeyDown={preventAlphabetsTyping}
                        placeholder="$ Contract price"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onChange={formik.handleChange}
                        value={formik.values?.contractPrice ?? ''}
                        name="contractPrice"
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'contractPrice' in currentHistoryData?.updatedData &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData?.updatedData &&
                          'contractPrice' in currentHistoryData?.updatedData &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.contractPrice &&
                      formik.errors.contractPrice && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors?.contractPrice}
                        </div>
                      )}
                  </Box>
                </Box>

                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      Type of owner for this project
                      <span style={{ color: 'red' }}>*</span>
                    </li>
                  </Text>
                  <RadioGroup
                    value={formik.values.typeOfProjectOwner?.type ?? ''}
                    onChange={(value) => {
                      if (value === 'Others') {
                        formik.setFieldValue('typeOfProjectOwner.type', value);
                      } else {
                        formik.setFieldValue('typeOfProjectOwner', {
                          type: value,
                          otherType: null,
                        });
                      }
                    }}
                  >
                    <Stack
                      direction="row"
                      display={'flex'}
                      flexWrap={'wrap'}
                      mt={2}
                      color="#114684"
                    >
                      <Radio
                        value="Government"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Government
                      </Radio>
                      <Radio
                        value="Institutions"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Institutions
                      </Radio>
                      <Radio
                        value="Utilities"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Utilities
                      </Radio>
                      <Radio
                        value="General Contractors"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        General Contractors
                      </Radio>
                      <Radio
                        value="Developers"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Developers
                      </Radio>
                      <Radio
                        value="Private Residential"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Private Residential
                      </Radio>
                      <Radio
                        value="Private Companies"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Private Companies
                      </Radio>
                      <Radio
                        value="Others"
                        size="sm"
                        isReadOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Others
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {formik.touched.typeOfProjectOwner?.type &&
                    formik.errors.typeOfProjectOwner?.type && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.typeOfProjectOwner?.type}
                      </div>
                    )}

                  {formik.values?.typeOfProjectOwner?.type === 'Others' && (
                    <Box mt={2}>
                      <Box
                        display="flex"
                        mb={{ base: '20px', md: '5px' }}
                        mt={2}
                      >
                        <Input
                          fontSize={'13px'}
                          w="100%"
                          type="text"
                          placeholder=" Other(Please describe)"
                          borderRadius="5px"
                          focusBorderColor="#7f7f7f"
                          borderColor={'#7f7f7f'}
                          _hover={{ borderColor: '#7f7f7f' }}
                          onChange={formik.handleChange}
                          value={
                            formik.values.typeOfProjectOwner?.otherType ?? ''
                          }
                          name="typeOfProjectOwner.otherType"
                          readOnly={showBox}
                          bgColor={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData
                              ?.typeOfProjectOwner &&
                            'otherType' in
                              currentHistoryData?.updatedData
                                ?.typeOfProjectOwner &&
                            '#114684'
                          }
                          color={
                            showHistory &&
                            currentHistoryData?.updatedData &&
                            currentHistoryData?.updatedData
                              ?.typeOfProjectOwner &&
                            'otherType' in
                              currentHistoryData?.updatedData
                                ?.typeOfProjectOwner &&
                            'white'
                          }
                        />
                      </Box>
                    </Box>
                  )}

                  {formik.touched.typeOfProjectOwner?.otherType &&
                    formik.errors.typeOfProjectOwner?.otherType && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.typeOfProjectOwner?.otherType}
                      </div>
                    )}
                </Box>
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    display="flex"
                    justifyContent="start"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      Type of work <span style={{ color: 'red' }}>*</span>
                    </li>
                  </Text>
                  <CheckboxGroup
                    // defaultValue={getTypes(formik?.values?.typeOfWork,"type").filter((item:any) => item !== null)}
                    value={getTypes(formik?.values?.typeOfWork, 'type').filter(
                      (item: any) => item !== null
                    )}
                    onChange={(value) => {
                      let updatedtypeOfWork = value.map(
                        (item: any, i: number) => {
                          if (item && item !== 'Others') {
                            return {
                              type: item,
                            };
                          } else {
                            return {
                              type: item,
                              otherType:
                                formik.values?.typeOfWork[i]?.otherType ?? null,
                            };
                          }
                        }
                      );
                      formik.setFieldValue('typeOfWork', updatedtypeOfWork);
                    }}
                  >
                    <Stack
                      direction="row"
                      display={'flex'}
                      flexWrap={'wrap'}
                      mt={2}
                      color="#114684"
                    >
                      <Checkbox
                        value="General Building"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        General Building
                      </Checkbox>
                      <Checkbox
                        value="Paving"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Paving
                      </Checkbox>
                      <Checkbox
                        value="Electrical"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Electrical
                      </Checkbox>
                      <Checkbox
                        value="Excavation"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Excavation
                      </Checkbox>
                      <Checkbox
                        value="Concrete"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Concrete
                      </Checkbox>
                      <Checkbox
                        value="Framing"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Framing
                      </Checkbox>
                      <Checkbox
                        value="Roofing/Siding/Sheet metal work"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                        size="sm"
                      >
                        Roofing/Siding/Sheet metal work
                      </Checkbox>
                      <Checkbox
                        value="Plumbing"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Plumbing
                      </Checkbox>
                      <Checkbox
                        value="HVAC"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        HVAC
                      </Checkbox>
                      <Checkbox
                        value="Carpentry"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Carpentry
                      </Checkbox>
                      <Checkbox
                        value="Masonry/Stonework"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Masonry/Stonework
                      </Checkbox>
                      <Checkbox
                        value="Plastering"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Plastering
                      </Checkbox>
                      <Checkbox
                        value="Painting/ Paper hanging"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                        size="sm"
                      >
                        Painting/ Paper hanging
                      </Checkbox>
                      <Checkbox
                        value="Tile Setting"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Tile Setting
                      </Checkbox>
                      <Checkbox
                        value="Others"
                        size="sm"
                        readOnly={showBox}
                        cursor={showBox ? 'not-allowed' : 'pointer'}
                      >
                        Other (Please describe)
                      </Checkbox>
                    </Stack>
                  </CheckboxGroup>
                  {formik.touched.typeOfWork && formik.errors.typeOfWork && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {(formik.errors?.typeOfWork[0] as any)?.ownerType ||
                        formik.errors.typeOfWork}
                    </div>
                  )}

                  {getTypes(formik.values?.typeOfWork, 'type').includes(
                    'Others'
                  ) && (
                    <Box mt={2}>
                      <Box mt={2}>
                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Input
                            fontSize={'13px'}
                            w="100%"
                            type="text"
                            placeholder=" Other(Please describe)"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={formik.handleChange}
                            value={formik.values.typeOfWorkOther ?? ''}
                            name="typeOfWorkOther"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData?.typeOfWork
                                  : formData?.typeOfWork,
                                'type',
                                'otherType'
                              ) &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              !!getOtherType(
                                showHistory
                                  ? currentHistoryData?.updatedData?.typeOfWork
                                  : formData?.typeOfWork,
                                'type',
                                'otherType'
                              ) &&
                              'white'
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {formik.touched.typeOfWorkOther &&
                    formik.errors.typeOfWorkOther && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.typeOfWorkOther}
                      </div>
                    )}
                </Box>
                <Box flex={{ base: '1', md: '1' }}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      {' '}
                      $ What is your estimated sublet budget for this project?{' '}
                      <span style={{ color: 'red' }}>*</span>
                    </li>
                  </Text>

                  <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                      placeholder="$ What is your estimated sublet budget for this project?"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={formik.handleChange}
                      value={formik.values.estimatedSubletBudget ?? ''}
                      name="estimatedSubletBudget"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'estimatedSubletBudget' in
                          currentHistoryData?.updatedData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        currentHistoryData?.updatedData &&
                        'estimatedSubletBudget' in
                          currentHistoryData?.updatedData &&
                        'white'
                      }
                    />
                  </Box>
                  {formik.touched.estimatedSubletBudget &&
                    formik.errors.estimatedSubletBudget && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.estimatedSubletBudget}
                      </div>
                    )}
                </Box>
              </Box> */}
              <Box
                boxShadow="0px 0px 5px 0px rgba(0,0,0,0.3)"
                borderRadius="20px"
                p="15px"
                display={'flex'}
                flexDirection="column"
                gap={5}
              >
                <Box>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="center"
                    fontSize={20}
                  >
                    Key personnel
                  </Text>
                </Box>
                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box
                    display={{ base: 'block', md: 'flex' }}
                    gap={5}
                    width={'100%'}
                    flexDirection={'column'}
                  >
                    {formik?.values?.keyPersonnel?.map(
                      (item: any, i: number) => (
                        <Box
                          display={{ base: 'block', md: 'flex' }}
                          gap={5}
                          width={'100%'}
                        >
                          <Box flex={{ base: '1', md: '1' }}>
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              Name
                              {/* <span style={{ color: 'red' }}>*</span> */}
                            </Text>

                            <Box
                              display="flex"
                              justifyContent={'space-between'}
                              alignItems={'flex-start'}
                              gap={'8px'}
                            >
                              <Box
                                display="flex"
                                flexDir={'column'}
                                // mb={{ base: '20px', md: '5px' }}
                                // mt={2}
                                flex={{ base: 1, md: 1 }}
                              >
                                <Input
                                  value={values.keyPersonnel?.[i]?.name ?? ''}
                                  name={`keyPersonnel[${i}].name`}
                                  readOnly={showBox}
                                  fontSize={'13px'}
                                  w="100%"
                                  type="text"
                                  placeholder="Name"
                                  borderRadius="5px"
                                  focusBorderColor="#7f7f7f"
                                  borderColor={'#7f7f7f'}
                                  _hover={{ borderColor: '#7f7f7f' }}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `keyPersonnel[${i}].name`,
                                      e.target.value
                                    );
                                  }}
                                  bgColor={
                                    showHistory &&
                                    currentHistoryData?.updatedData &&
                                    'name' in currentHistoryData?.updatedData &&
                                    '#114684'
                                  }
                                  color={
                                    showHistory &&
                                    currentHistoryData?.updatedData &&
                                    'name' in currentHistoryData?.updatedData &&
                                    'white'
                                  }
                                />
                              </Box>
                            </Box>
                            {/* {formik?.touched?.keyPersonnel &&
                            formik?.touched?.keyPersonnel[i] &&
                            formik?.touched?.keyPersonnel[i]?.name &&
                            formik?.errors?.keyPersonnel &&
                            formik?.errors?.keyPersonnel[i] ? (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {(formik?.errors as any).keyPersonnel[i].name}
                              </div>
                            ) : null} */}
                          </Box>
                          <Box flex={{ base: '1', md: '1' }}>
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              Position
                            </Text>

                            <Box
                              display="flex"
                              justifyContent={'space-between'}
                              alignItems={'flex-start'}
                              gap={'8px'}
                            >
                              <Box
                                display="flex"
                                flexDir={'column'}
                                flex={{ base: 1, md: 1 }}
                              >
                                <Input
                                  value={
                                    values.keyPersonnel?.[i]?.position ?? ''
                                  }
                                  name={`keyPersonnel[${i}].position`}
                                  fontSize={'13px'}
                                  w="100%"
                                  type="text"
                                  placeholder="Position"
                                  borderRadius="5px"
                                  focusBorderColor="#7f7f7f"
                                  borderColor={'#7f7f7f'}
                                  _hover={{ borderColor: '#7f7f7f' }}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `keyPersonnel[${i}].position`,
                                      e.target.value
                                    );
                                  }}
                                  readOnly={showBox}
                                  bgColor={
                                    showHistory &&
                                    currentHistoryData?.updatedData &&
                                    'position' in
                                      currentHistoryData?.updatedData &&
                                    '#114684'
                                  }
                                  color={
                                    showHistory &&
                                    currentHistoryData?.updatedData &&
                                    'position' in
                                      currentHistoryData?.updatedData &&
                                    'white'
                                  }
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            flex={{ base: '1', md: '1' }}
                            display={'flex'}
                            gap={5}
                            alignItems={'center'}
                          >
                            <Box>
                              <Text
                                fontWeight="600"
                                color="#114684"
                                textAlign="start"
                                fontSize={13}
                              >
                                <li> Hiring date</li>
                              </Text>

                              <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems={'flex-start'}
                                gap={'8px'}
                              >
                                <DatePickerInput
                                  value={
                                    values.keyPersonnel?.[i]?.hiringDate ?? ''
                                  }
                                  onChange={(date: Date | null) => {
                                    formik.setFieldValue(
                                      `keyPersonnel[${i}].hiringDate`,
                                      date
                                    );
                                  }}
                                  locale="en"
                                  placeholder="Hiring date"
                                  className={`react-datepicker-component react-datepicker-input input gray-border ${
                                    showHistory &&
                                    currentHistoryData?.updatedData
                                      ?.keyPersonnel?.[i] &&
                                    'hiringDate' in
                                      currentHistoryData?.updatedData
                                        ?.keyPersonnel?.[i]
                                      ? 'bg-blue'
                                      : ''
                                  }`}
                                  disabled={showBox}
                                  maxDate={getYesterday()}
                                  showOnInputClick={true}
                                />
                              </Box>
                            </Box>
                            <Box>
                              {i === 0 ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop={'10px'}
                                  onClick={() => KeyPersonnelBoxAdd()}
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCirclePlus />
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop={'10px'}
                                  onClick={() => KeyPersoneelBoxRemove(i)}
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCircleMinus />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'end'}>
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    onClick={() => setActiveStep(7)}
                    isDisabled={showBox}
                  >
                    Upload Resume
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              marginTop={'10px'}
              gap={2}
            >
              {!showBox && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={
                    userType === 'broker' && formData?.assignToApplicant
                      ? () => OnHandleReturnToBroker()
                      : () => OnHandelReturnToSender()
                  }
                  type={
                    userType === 'broker' && formData?.assignToApplicant
                      ? 'button'
                      : 'submit'
                  }
                  isLoading={loader?.btnLoader}
                  // isDisabled={
                  //   userType === 'broker' && formData?.assignToApplicant
                  //     ? true
                  //     : false
                  // }
                >
                  {isApplicantUser
                    ? 'Return to Broker'
                    : userType === 'broker' && formData?.assignToApplicant
                      ? 'Edit - Rescind Assignment from Applicant'
                      : 'Assign to applicant'}
                </Button>
              )}
              {!showBox && (
                <Box>
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        </Container>
        {showHistory && (
          <>
            <Box
              height="calc(100vh - 150px)"
              w={{
                base: '100%',
                lg: '360px',
              }}
              sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
              bg={'white'}
              display={'flex'}
              flexDir={'column'}
              gap={'10px'}
              borderRadius={'12px'}
              boxShadow="0 0 14px -6px rgba(0,0,0,0.3)"
              p={4}
            >
              <Box style={{ fontSize: '25px', fontWeight: 'bold' }}>
                History
              </Box>
              <Box
                display={'flex'}
                flexDir={'column'}
                gap={'10px'}
                overflow={'auto'}
              >
                {HistoryData?.data?.map((item: any, index: number) => {
                  const formattedDate = formatDate(item?.createdAt);
                  return (
                    <Box
                      bgColor={historyIndex == index ? '#1146844a' : '#f5f5f5'}
                      p={3}
                      borderRadius={'12px'}
                      cursor={'pointer'}
                      mr={2}
                      border={
                        historyIndex == index
                          ? '1px solid #114684'
                          : '1px solid gray'
                      }
                      onClick={() => setHistoryIndex(index)}
                    >
                      <Box fontWeight={500} mt={2} display={'flex'}>
                        <Box marginRight={1}>Date :</Box>
                        <Text> {formattedDate.date}</Text>
                        <Text
                          mx={2}
                          borderLeft="1px solid #114684"
                          height="auto"
                          boxShadow="none"
                        ></Text>
                        <Text>{formattedDate.time}</Text>
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Status : {item?.applicationStatus}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Initiated By : {item?.initiatedBy}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Updated By : {item?.broker?.firstName}
                        &nbsp;
                        {item?.broker?.lastName}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  }
);

export default AboutForm;
