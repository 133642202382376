import { Box, Button } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import { getS3FileInfo } from 'src/utils/helpers';
import LegalFormAccordian from './LegalFormAccordian';

const LegalFormAccDocumentListing = ({
  isOpen,
  onClose,
  modalTitle,
  formik,
  accordianSectionName,
  accordianState,
  accordianName,
  accordianParentSectionName,
  uploadState,
  name,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
}: {
  isOpen: boolean;
  onClose: () => void;
  modalTitle: string;
  formik: Record<string, any>;
  accordianSectionName: string;
  accordianState: any[];
  accordianName: string;
  accordianParentSectionName: string;
  uploadState: string;
  name: string;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
}) => {
  const toast = useAppToast();
  const {
    S3UploadError,
    S3UploadLoading,
    S3UploadResponse,
    uploadbulkFilesInS3,
  } = useS3FileUpload();
  const [fileList, setFileList] = useState<Record<string, any>>({});
  const fileFields: Record<string, string> = {
    uploadLegalDoc:
      'hasGuarantees.legalAndRegulatoryCompliance.guaranteeAgreementDocumentation.legalDocuments',
    termsOfIndebtedness:
      'hasGuarantees.guaranteeDetails.financialDetails.termsOfIndebtedness', // If directly available in formik.values
    legalDocument: 'hasChangesInManagement.documentation.legalDocumentation',
    shareholderAgreements:
      'hasChangesInManagement.documentation.shareholderAgreements',
    regulatoryFilings: 'hasChangesInManagement.documentation.regulatoryFilings',
    agreementCopy: 'hasBuySellAgreement.legalDocumentation.agreementCopy', // remain
  };

  useEffect(() => {
    if (isOpen) {
      const fileList = Object.keys(fileFields).reduce((acc: any, key: any) => {
        const nestedPath = fileFields[key as any];
        const value = getNestedValue(formik?.values, nestedPath);
        if (value) {
          acc[key as any] = value;
        }
        return acc;
      }, {});

      setFileList(fileList);
    }

    return () => {};
  }, [isOpen]);

  const getNestedValue = (obj: Record<string, any>, path: string) => {
    return path.split('.').reduce((acc, key) => {
      return acc?.[key];
    }, obj);
  };

  const setNestedValue = (
    obj: Record<string, any>,
    path: string,
    value: any
  ) => {
    const keys = path.split('.');
    const lastKey = keys.pop();

    const lastObj = keys.reduce((acc, key) => {
      if (!acc[key]) acc[key] = {}; // Ensure the key exists
      return acc[key];
    }, obj);

    lastObj[lastKey as any] = value;
  };

  const handleUpdateFileList = (uploadState: string, updatedValue: any) => {
    setFileList((prevState) => {
      const newState = { ...prevState };
      setNestedValue(newState, uploadState, updatedValue);
      return newState;
    });
  };

  const blankUploadState = () => {
    // formik.setFieldValue('uploadLegalDoc', []);
    // formik.setFieldValue('termsOfIndebtedness', []);
    // formik.setFieldValue('legalDocument', []);
    // formik.setFieldValue('shareholderAgreements', []);
    // formik.setFieldValue('regulatoryFilings', []);
    // formik.setFieldValue('agreementCopy', []);

    Object.keys(fileFields)?.map((item) => {
      formik.setFieldValue(item, []);
    });
  };

  useEffect(() => {
    if (S3UploadResponse) {
      // console.log('S3UploadResponse???', S3UploadResponse, formik?.values);
      let values = formik?.values;
      const s3resData: Record<string, any> = S3UploadResponse;
      const {
        hasGuaranteesLegalDocuments,
        hasChangesInManagementLegalDocumentation,
        hasChangesInManagementShareholderAgreements,
        hasChangesInManagementRegulatoryFilings,
        hasGuaranteesTermsOfIndebtedness,
        hasBuySellAgreementCopy,
      } = s3resData?.reduce(
        (acc: any, item: any) => {
          switch (item.fieldname) {
            case 'files[hasGuaranteesLegalDocuments]':
              acc.hasGuaranteesLegalDocuments.push(item);
              break;
            case 'files[hasChangesInManagementLegalDocumentation]':
              acc.hasChangesInManagementLegalDocumentation.push(item);
              break;
            case 'files[hasChangesInManagementShareholderAgreements]':
              acc.hasChangesInManagementShareholderAgreements.push(item);
              break;
            case 'files[hasChangesInManagementRegulatoryFilings]':
              acc.hasChangesInManagementRegulatoryFilings.push(item);
              break;
            case 'files[hasGuaranteesTermsOfIndebtedness]':
              acc.hasGuaranteesTermsOfIndebtedness.push(item);
              break;
            case 'files[hasBuySellAgreementCopy]':
              acc.hasBuySellAgreementCopy.push(item);
              break;
            default:
              // Optionally handle unknown fieldnames
              break;
          }
          return acc;
        },
        {
          hasGuaranteesLegalDocuments: [] as any[],
          hasChangesInManagementLegalDocumentation: [] as any[],
          hasChangesInManagementShareholderAgreements: [] as any[],
          hasChangesInManagementRegulatoryFilings: [] as any[],
          hasGuaranteesTermsOfIndebtedness: [] as any[],
          hasBuySellAgreementCopy: [] as any[],
        }
      );

      if (hasGuaranteesLegalDocuments?.length) {
        values = {
          ...values,
          hasGuarantees: {
            ...values?.hasGuarantees,
            legalAndRegulatoryCompliance: {
              ...values?.hasGuarantees?.legalAndRegulatoryCompliance,
              guaranteeAgreementDocumentation: {
                ...values?.hasGuarantees?.legalAndRegulatoryCompliance
                  ?.guaranteeAgreementDocumentation,
                legalDocuments: fileList?.['uploadLegalDoc']
                  ? [
                      ...fileList?.['uploadLegalDoc'],
                      ...getS3FileInfo(hasGuaranteesLegalDocuments),
                    ]
                  : getS3FileInfo(hasGuaranteesLegalDocuments),
              },
            },
          },
        };
        const updatedValue = getNestedValue(
          values,
          'hasGuarantees.legalAndRegulatoryCompliance.guaranteeAgreementDocumentation.legalDocuments'
        );
        handleUpdateFileList('uploadLegalDoc', updatedValue);
      }
      if (hasChangesInManagementLegalDocumentation?.length) {
        values = {
          ...values,
          hasChangesInManagement: {
            ...values?.hasChangesInManagement,
            documentation: {
              ...values?.hasChangesInManagement?.documentation,
              legalDocumentation: fileList?.['legalDocument']
                ? [
                    ...fileList?.['legalDocument'],
                    ...getS3FileInfo(hasChangesInManagementLegalDocumentation),
                  ]
                : getS3FileInfo(hasChangesInManagementLegalDocumentation),
            },
          },
        };
        const updatedValue = getNestedValue(
          values,
          'hasChangesInManagement.documentation.legalDocumentation'
        );
        handleUpdateFileList('legalDocument', updatedValue);
      }
      if (hasChangesInManagementShareholderAgreements?.length) {
        values = {
          ...values,
          hasChangesInManagement: {
            ...values?.hasChangesInManagement,
            documentation: {
              ...values?.hasChangesInManagement?.documentation,
              shareholderAgreements: fileList?.['shareholderAgreements']
                ? [
                    ...fileList?.['shareholderAgreements'],
                    ...getS3FileInfo(
                      hasChangesInManagementShareholderAgreements
                    ),
                  ]
                : getS3FileInfo(hasChangesInManagementShareholderAgreements),
            },
          },
        };
        const updatedValue = getNestedValue(
          values,
          'hasChangesInManagement.documentation.shareholderAgreements'
        );
        handleUpdateFileList('shareholderAgreements', updatedValue);
      }
      if (hasChangesInManagementRegulatoryFilings?.length) {
        values = {
          ...values,
          hasChangesInManagement: {
            ...values?.hasChangesInManagement,
            documentation: {
              ...values?.hasChangesInManagement?.documentation,
              regulatoryFilings: fileList?.['regulatoryFilings']
                ? [
                    ...fileList?.['regulatoryFilings'],
                    ...getS3FileInfo(hasChangesInManagementRegulatoryFilings),
                  ]
                : getS3FileInfo(hasChangesInManagementRegulatoryFilings),
            },
          },
        };
        const updatedValue = getNestedValue(
          values,
          'hasChangesInManagement.documentation.regulatoryFilings'
        );
        handleUpdateFileList('regulatoryFilings', updatedValue);
      }
      if (hasGuaranteesTermsOfIndebtedness?.length) {
        values = {
          ...values,
          hasGuarantees: {
            ...values?.hasGuarantees,
            guaranteeDetails: {
              ...values?.hasGuarantees?.guaranteeDetails,
              financialDetails: {
                ...values?.hasGuarantees?.guaranteeDetails?.financialDetails,
                termsOfIndebtedness: fileList?.['termsOfIndebtedness']
                  ? [
                      ...fileList?.['termsOfIndebtedness'],
                      ...getS3FileInfo(hasGuaranteesTermsOfIndebtedness),
                    ]
                  : getS3FileInfo(hasGuaranteesTermsOfIndebtedness),
              },
            },
          },
        };
        const updatedValue = getNestedValue(
          values,
          'hasGuarantees.guaranteeDetails.financialDetails.termsOfIndebtedness'
        );
        handleUpdateFileList('termsOfIndebtedness', updatedValue);
      }
      if (hasBuySellAgreementCopy?.length) {
        values = {
          ...values,
          hasBuySellAgreement: {
            ...values?.hasBuySellAgreement,
            legalDocumentation: {
              ...values?.hasBuySellAgreement?.legalDocumentation,
              agreementCopy: fileList?.['agreementCopy']
                ? [
                    ...fileList?.['agreementCopy'],
                    ...getS3FileInfo(hasBuySellAgreementCopy),
                  ]
                : getS3FileInfo(hasBuySellAgreementCopy),
            },
          },
        };
        const updatedValue = getNestedValue(
          values,
          'hasBuySellAgreement.legalDocumentation.agreementCopy'
        );
        handleUpdateFileList('agreementCopy', updatedValue);
      }

      // console.log('values', values);
      // console.log('called for uploadState111', uploadState, name);

      // const updatedValue = getNestedValue(values, name);
      // handleUpdateFileList(uploadState, updatedValue);

      formik.setValues(values);
      blankUploadState();

      // submitHandler(values);
    }
    return () => {};
  }, [S3UploadResponse]);

  const handleRemoveFile = (
    file: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    uploadState: string,
    name: string
  ) => {
    const updatedFileList = fileList?.[uploadState]?.filter(
      (item: any) => item?.urlKey !== file?.urlKey
    );
    // console.log(
    //   'updatedFileList',
    //   updatedFileList,
    //   fileList?.[uploadState],
    //   uploadState,
    //   name
    // );
    setFileList({ ...fileList, [uploadState]: updatedFileList });
    // formik?.setFieldValue(name, updatedFileList);
  };

  const handleUpdateDocuments = async () => {
    // console.log('handleUpdateDocuments', fileList, formik);
    try {
      // console.log('submit', formik?.values, formik?.initialValues);
      const isLegalFormDocUpload =
        (formik?.values?.uploadLegalDoc &&
          formik?.values?.uploadLegalDoc?.length) ||
        (formik?.values?.legalDocument &&
          formik?.values?.legalDocument?.length) ||
        (formik?.values?.shareholderAgreements &&
          formik?.values?.shareholderAgreements?.length) ||
        (formik?.values?.regulatoryFilings &&
          formik?.values?.regulatoryFilings?.length) ||
        (formik?.values?.termsOfIndebtedness &&
          formik?.values?.termsOfIndebtedness?.length) ||
        (formik?.values?.agreementCopy &&
          formik?.values?.agreementCopy?.length);

      const formData = new FormData();

      if (
        formik?.values?.uploadLegalDoc &&
        formik?.values?.uploadLegalDoc?.length
      ) {
        formik?.values?.uploadLegalDoc?.forEach((file: File) => {
          formData.append(`files[hasGuaranteesLegalDocuments]`, file);
        });
      }
      if (
        formik?.values?.legalDocument &&
        formik?.values?.legalDocument?.length
      ) {
        formik?.values?.legalDocument?.forEach((file: File) => {
          formData.append(
            `files[hasChangesInManagementLegalDocumentation]`,
            file
          );
        });
      }
      if (
        formik?.values?.shareholderAgreements &&
        formik?.values?.shareholderAgreements?.length
      ) {
        formik?.values?.shareholderAgreements?.forEach((file: File) => {
          formData.append(
            `files[hasChangesInManagementShareholderAgreements]`,
            file
          );
        });
      }
      if (
        formik?.values?.regulatoryFilings &&
        formik?.values?.regulatoryFilings?.length
      ) {
        formik?.values?.regulatoryFilings?.forEach((file: File) => {
          formData.append(
            `files[hasChangesInManagementRegulatoryFilings]`,
            file
          );
        });
      }
      if (
        formik?.values?.termsOfIndebtedness &&
        formik?.values?.termsOfIndebtedness?.length
      ) {
        formik?.values?.termsOfIndebtedness?.forEach((file: File) => {
          formData.append(`files[hasGuaranteesTermsOfIndebtedness]`, file);
        });
      }
      if (
        formik?.values?.agreementCopy &&
        formik?.values?.agreementCopy?.length
      ) {
        formik?.values?.agreementCopy?.forEach((file: File) => {
          formData.append(`files[hasBuySellAgreementCopy]`, file);
        });
      }

      if (isLegalFormDocUpload) {
        console.log('FormData contents:', formData);
        formData.forEach((value, key) => {
          console.log(`${key}:`, value);
        });

        await uploadbulkFilesInS3(formData);

        if (S3UploadError) {
          toast({
            title: 'Erro in file uploading!!',
            status: 'error',
          });
          return;
        }
      }
      // else {
      // console.log('submit!!!', formik.values, fileList);
      let values = formik?.values;
      values = {
        ...values,
        hasGuarantees: {
          ...values?.hasGuarantees,
          guaranteeDetails: {
            ...values?.hasGuarantees?.guaranteeDetails,
            financialDetails: {
              ...values?.hasGuarantees?.guaranteeDetails?.financialDetails,
              termsOfIndebtedness: fileList?.['termsOfIndebtedness'],
            },
          },
          legalAndRegulatoryCompliance: {
            ...values?.hasGuarantees?.legalAndRegulatoryCompliance,
            guaranteeAgreementDocumentation: {
              ...values?.hasGuarantees?.legalAndRegulatoryCompliance
                ?.guaranteeAgreementDocumentation,
              legalDocuments: fileList?.['uploadLegalDoc'],
            },
          },
        },
        hasChangesInManagement: {
          ...values?.hasChangesInManagement,
          documentation: {
            ...values?.hasChangesInManagement?.documentation,
            legalDocumentation: fileList?.['legalDocument'],
            shareholderAgreements: fileList?.['shareholderAgreements'],
            regulatoryFilings: fileList?.['regulatoryFilings'],
          },
        },
        hasBuySellAgreement: {
          ...values?.hasBuySellAgreement,
          legalDocumentation: {
            ...values?.hasBuySellAgreement?.legalDocumentation,
            agreementCopy: fileList?.['agreementCopy'],
          },
        },
      };

      formik.setValues(values);

      toast({
        title: 'Success',
        status: 'success',
      });
      // submitHandler(formik?.values);
      // }
    } catch (error: any) {
      console.log('error', error);
    }
  };

  // const handleUpdateDocuments = async () => {
  //   console.log('handleUpdateDocuments', fileList, formik);
  //   try {
  //     console.log('submit', formik?.values, formik?.initialValues);

  //     // Check if there's any new document uploaded
  //     const isLegalFormDocUpload =
  //       (formik?.values?.uploadLegalDoc &&
  //         formik?.values?.uploadLegalDoc?.length) ||
  //       (formik?.values?.legalDocument &&
  //         formik?.values?.legalDocument?.length) ||
  //       (formik?.values?.shareholderAgreements &&
  //         formik?.values?.shareholderAgreements?.length) ||
  //       (formik?.values?.regulatoryFilings &&
  //         formik?.values?.regulatoryFilings?.length) ||
  //       (formik?.values?.termsOfIndebtedness &&
  //         formik?.values?.termsOfIndebtedness?.length);

  //     const formData = new FormData();

  //     const uploadIfNewFiles = (
  //       field: string,
  //       formikKey: string,
  //       s3Key: string
  //     ) => {
  //       const currentFiles = formik?.values?.[field];
  //       const initialFiles = formik?.initialValues?.[field];

  //       const newFiles = currentFiles?.filter(
  //         (file: any) => !initialFiles?.includes(file)
  //       );
  //       const removedFiles = initialFiles?.filter(
  //         (file: any) => !currentFiles?.includes(file)
  //       );

  //       if (newFiles?.length) {
  //         newFiles.forEach((file: File) => {
  //           formData.append(`files[${s3Key}]`, file);
  //         });
  //       }

  //       // Handle removing files from the fileList
  //       if (removedFiles?.length) {
  //         fileList[field] = currentFiles; // Update the fileList with the remaining files after removing
  //       }
  //     };

  //     // Check and upload files for each document field
  //     uploadIfNewFiles(
  //       'uploadLegalDoc',
  //       'hasGuarantees.legalAndRegulatoryCompliance.guaranteeAgreementDocumentation.legalDocuments',
  //       'hasGuaranteesLegalDocuments'
  //     );
  //     uploadIfNewFiles(
  //       'legalDocument',
  //       'hasChangesInManagement.documentation.legalDocumentation',
  //       'hasChangesInManagementLegalDocumentation'
  //     );
  //     uploadIfNewFiles(
  //       'shareholderAgreements',
  //       'hasChangesInManagement.documentation.shareholderAgreements',
  //       'hasChangesInManagementShareholderAgreements'
  //     );
  //     uploadIfNewFiles(
  //       'regulatoryFilings',
  //       'hasChangesInManagement.documentation.regulatoryFilings',
  //       'hasChangesInManagementRegulatoryFilings'
  //     );
  //     uploadIfNewFiles(
  //       'termsOfIndebtedness',
  //       'hasGuarantees.guaranteeDetails.financialDetails.termsOfIndebtedness',
  //       'hasGuaranteesTermsOfIndebtedness'
  //     );

  //     // If there are new files to upload
  //     if (isLegalFormDocUpload) {
  //       console.log('FormData contents:', formData);
  //       formData.forEach((value, key) => {
  //         console.log(`${key}:`, value);
  //       });

  //       await uploadbulkFilesInS3(formData);

  //       if (S3UploadError) {
  //         toast({
  //           title: 'Error in file uploading!!',
  //           status: 'error',
  //         });
  //         return;
  //       }
  //     }

  //     // Update formik values regardless of new file upload
  //     console.log('submit!!!', formik.values, fileList);
  //     let values = formik?.values;

  //     values = {
  //       ...values,
  //       hasGuarantees: {
  //         ...values?.hasGuarantees,
  //         guaranteeDetails: {
  //           ...values?.hasGuarantees?.guaranteeDetails,
  //           financialDetails: {
  //             ...values?.hasGuarantees?.guaranteeDetails?.financialDetails,
  //             termsOfIndebtedness: [...fileList?.['termsOfIndebtedness']],
  //           },
  //         },
  //         legalAndRegulatoryCompliance: {
  //           ...values?.hasGuarantees?.legalAndRegulatoryCompliance,
  //           guaranteeAgreementDocumentation: {
  //             ...values?.hasGuarantees?.legalAndRegulatoryCompliance
  //               ?.guaranteeAgreementDocumentation,
  //             legalDocuments: [...fileList?.['uploadLegalDoc']],
  //           },
  //         },
  //       },
  //       hasChangesInManagement: {
  //         ...values?.hasChangesInManagement,
  //         documentation: {
  //           ...values?.hasChangesInManagement?.documentation,
  //           legalDocumentation: [...fileList?.['legalDocument']],
  //           shareholderAgreements: [...fileList?.['shareholderAgreements']],
  //           regulatoryFilings: [...fileList?.['regulatoryFilings']],
  //         },
  //       },
  //     };

  //     formik.setValues(values);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        blankUploadState();
        setFileList({});
        onClose();
      }}
      title={modalTitle}
      centered
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'725px'}
      className="global_history"
    >
      <Box margin={'10px 10px 0 10px'} maxW={'725px'} maxH={'600px'}>
        <LegalFormAccordian
          formik={formik}
          accordianState={accordianState}
          accordianName={accordianName}
          accordianSectionName={accordianSectionName}
          isOpen={isOpen}
          accordianParentSectionName={accordianParentSectionName}
          fileList={fileList}
          setFileList={setFileList}
          handleRemoveFile={handleRemoveFile}
          isHistoryMode={isHistoryMode}
          isReadOnly={isReadOnly}
          currentHistoryData={currentHistoryData}
          dataKey="hasGuarantees"
        />

        <Box display={'flex'} justifyContent={'end'} mt={'15px'}>
          <Button
            bg={'#114684'}
            color={'white'}
            _hover={{ bg: '#114684', color: 'white' }}
            type="button"
            onClick={handleUpdateDocuments}
            isLoading={S3UploadLoading}
            isDisabled={isReadOnly}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LegalFormAccDocumentListing;
