import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Modal } from '@mantine/core';

const RefreshWIPReportInfoModal = ({
  isOpen,
  onClose,
  onConfirm,
  refreshLoader,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  refreshLoader: boolean;
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="Refresh"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'md'}
    >
      <Box margin={'10px 10px 0 10px'}>
        <Text>Are you sure you want to refresh?</Text>
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button type="button" onClick={onClose} isDisabled={refreshLoader}>
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="red"
            onClick={onConfirm}
            loadingText="Refresh"
            isLoading={refreshLoader}
            isDisabled={refreshLoader}
          >
            Refresh
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default RefreshWIPReportInfoModal;
