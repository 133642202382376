import moment from 'moment';
import {
  BondApplicationAttributes,
  ContractType,
  bondFormValue,
  taxType,
} from 'src/Pages/BrokerPages/BondApplicationForm/data';
import {
  BondRequestApproveSchema,
  BondRequestRejectSchema,
  rejectBondRequestOptions,
} from 'src/Pages/BrokerPages/BondRequest/components/data';
import * as yup from 'yup';
import { ContractDetails } from '../../Pages/BrokerPages/BondApplicationForm/data';

export const bondApplicationYupSchema = yup.object<BondApplicationAttributes>({
  attention: yup.string().required('Attention is required'),
  finalBondNumber: yup.string().required('Final Bond Number is required'),
  brokerName: yup.string().required('Broker Name is required'),
  brokerPhone: yup
    .string()
    .required('Broker Phone is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
  brokerEmail: yup
    .string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Broker Email is required'),
  applicantName: yup.string().required('Applicant Name is required'),
  applicantPhone: yup
    .string()
    .required('Applicant Phone is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
  applicantEmail: yup
    .string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Applicant Email is required'),
  dateOfRequisition: yup
    .date()
    // .min(new Date(), 'Please choose future date')
    .required('Date of Requisition is required')
    .typeError('Date of Requisition is required'),
  // dateRequired can not be less than date of requisition and must be future date
  dateRequired: yup
    .date()
    .min(
      yup.ref('dateOfRequisition'),
      'Date Required must greater than Date of Requisition'
    )
    .required('Date Required is required'),
  generalContractorName: yup
    .string()
    .required('General Contractor Name is required'),
  generalContractorPhone: yup
    .string()
    .required('General Contractor Phone is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
  generalContractorEmail: yup
    .string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('General Contractor Email is required'),
  engineerOrArchitectName: yup
    .string()
    .required('Engineer Or Architect Name is required'),
  engineerOrArchitectPhone: yup
    .string()
    .required('Engineer Or Architect Phone is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
  engineerOrArchitectEmail: yup
    .string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Engineer Or Architect Email is required'),
  ownerName: yup.string().required('Owner Name is required'),
  ownerPhone: yup
    .string()
    .required('Owner Phone is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
  ownerEmail: yup
    .string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Owner Email is required'),
  typeOfOwner: yup
    .string()
    .required('Type of Owner is required')
    .oneOf(
      [
        'Government',
        'Institutions',
        'Utilities',
        'General Contractors',
        'Developers',
        'Private Residential',
        'Private Companies',
        'Others',
      ],
      'Please select a valid Type of Owner'
    ),
  typeOfOwnerOther: yup.string().when('typeOfOwner', (typeOfOwner, schema) => {
    const typeOfOwnerCheck = Array.isArray(typeOfOwner)
      ? typeOfOwner.includes('Others')
      : typeOfOwner === 'Others';
    return typeOfOwnerCheck
      ? schema.required('This field is required')
      : schema.notRequired();
  }),
  typeOfWork: yup
    .array()
    .of(
      yup
        .string()
        .oneOf(
          [
            'General Building',
            'Paving',
            'Electrical',
            'Excavation',
            'Concrete',
            'Framing',
            'Roofing/Siding/Sheet metal work',
            'Plumbing',
            'HVAC',
            'Carpentry',
            'Masonry/Stonework',
            'Plastering',
            'Painting/ Paper hanging',
            'Tile Setting',
            'Others',
          ],
          'Invalid type of work selected'
        )
    )
    .min(1, 'At least one type of work must be selected')
    .required('Type of Work is required')
    .typeError('Please select a valid Type of Work'),
  typeOfWorkOther: yup.string().when('typeOfWork', ([typeOfWork], schema) => {
    const typeOfWorkCheck = Array.isArray(typeOfWork)
      ? typeOfWork.includes('Others')
      : typeOfWork === 'Others';
    return typeOfWorkCheck
      ? schema.required('This field is required')
      : schema.notRequired();
  }),
  workOnHandsAsOfDate: yup
    .string()
    .required('Work On Hands As Of Date is required'),
  totalBondedJobs: yup.object({
    contractPrice: yup.number().required('Contract Price is required'),
    costToComplete: yup.number().required('Cost To Complete is required'),
  }),
  totalUnbondedJobs: yup.object({
    contractPrice: yup.number().required('Contract Price is required'),
    costToComplete: yup.number().required('Cost To Complete is required'),
  }),
  totalOutstandingBids: yup.object({
    contractPrice: yup.number().required('Contract Price is required'),
    costToComplete: yup.number().required('Cost To Complete is required'),
  }),
  // totalSum: yup.object({
  //   contractPrice: yup.number().required('Contract Price is required'),
  //   costToComplete: yup.number().required('Cost To Complete is required'),
  // }),
  projectName: yup.string().required('Project Name is required'),
  projectLocation: yup.string().required('Project Location is required'),
  projectStartDate: yup
    .date()
    // .min(new Date(), 'Please choose future date')
    .required('Project Start Date is required')
    .typeError('Project Start Date is required'),
  projectEndDate: yup
    .date()
    .typeError('Project End Date is required')
    .min(
      yup.ref('projectStartDate'),
      'Date Required must greater than Project End Date'
    )
    .required('Project Start Date is required'),
  // uploadContract: yup.boolean().required('Upload Contract is required'),
  contractDetails: yup
    .mixed<ContractDetails>()
    .required('Contract Details is required')
    .oneOf(
      ['CCDC', 'AIA', 'ConsensusDocs', 'EJCDC', 'Other'],
      'Invalid Contract Details'
    ),
  contractDetailsOther: yup
    .string()
    .when('contractDetails', (contractDetails, schema) => {
      const contractDetailsCheck = Array.isArray(contractDetails)
        ? contractDetails.includes('Other')
        : contractDetails === 'Other';
      return contractDetailsCheck
        ? schema.required('This field is required')
        : schema.notRequired();
    }),
  privateOwnersClause: yup.boolean().optional().nullable(),
  privateOwnersClauseReferences: yup
    .array()
    .min(1, 'At least one attachment is required')
    .when('privateOwnersClause', (privateOwnersClause, schema) => {
      const privateOwnersClauseCheck = Array.isArray(privateOwnersClause)
        ? privateOwnersClause.includes(true)
        : privateOwnersClause === true;
      console.log('privateOwnersClauseCheck', privateOwnersClauseCheck);

      return privateOwnersClauseCheck
        ? schema.required('Private Owners Clause Attachment is required')
        : schema.notRequired();
    }),
  contractType: yup
    .mixed<ContractType>()
    .required('Contract Type is required')
    .oneOf(['FP', 'LS', 'GMP', 'CP', 'UP', 'CM'], 'Invalid Contract Type'),
  estimatedSubletBudget: yup.number().optional().nullable(),
  // workSublet: yup
  //   .array()
  //   .when('estimatedSubletBudget', (estimatedSubletBudget, schema) => {
  //     const estimatedSubletBudgetCheck = Array.isArray(estimatedSubletBudget)
  //       ? estimatedSubletBudget.every((value) => typeof value === 'number') // Check if array contains any number
  //       : typeof estimatedSubletBudget === 'number'; // Check if it's a number directly

  //     return estimatedSubletBudgetCheck
  //       ? yup.array().of(
  //           yup
  //             .object({
  //               name: yup.string().required('Name is required'),
  //               amount: yup.number().required('Amount is required'),
  //               bonded: yup.boolean().required('Bonded is required'),
  //             })
  //             .required('Work Sublet is required')
  //         )
  //       : yup.object().notRequired();
  //   }),

  workSublet: yup
    .array()
    .when('estimatedSubletBudget', (estimatedSubletBudget, schema) => {
      // Check if estimatedSubletBudget is valid (either a number or an array of numbers)
      const isValidBudget = Array.isArray(estimatedSubletBudget)
        ? estimatedSubletBudget.every((value) => typeof value === 'number') // All elements in array must be numbers
        : typeof estimatedSubletBudget === 'number'; // Or it must be a single number

      if (isValidBudget) {
        return schema
          .of(
            yup
              .object({
                name: yup.string().required('Name is required'),
                amount: yup.number().required('Amount is required'),
                bonded: yup.boolean().required('Bonded is required'),
              })
              .required('Work Sublet item is required')
          )
          .test(
            'sum-of-amounts',
            'The total amount of all sublet work must not exceed the Estimated Sublet Budget',
            (workSublet, context) => {
              if (!Array.isArray(workSublet)) return true;

              // Calculate the total amount of sublet work
              const totalAmount = workSublet.reduce(
                (sum, item) => sum + (item.amount || 0),
                0
              );

              // Ensure the total does not exceed the budget
              const budget = context.parent.estimatedSubletBudget;
              return totalAmount <= budget;
            }
          );
      }

      return schema.notRequired(); // If the budget is invalid, no validation required for workSublet
    }),
  paymentTerms: yup.string().required('Payment Terms is required'),
  holdback: yup.boolean().required('Holdback is required'),
  holdbackPercentage: yup.number().when('holdback', (holdback, schema) => {
    const holdbackCheck = Array.isArray(holdback)
      ? holdback.includes(true)
      : holdback === true;
    return holdbackCheck
      ? schema
          .required('Holdback Percentage is required')
          .min(0, 'Holdback Percentage must be at least 0%')
          .max(100, 'Holdback Percentage must not exceed 100%')
      : schema.notRequired();
  }),
  architectEstimate: yup.number().required('Architect Estimate is required'),
  maintenanceWarrantyTerm: yup
    .number()
    .required('Maintenance Warranty Term is required')
    .max(999, 'Maintenance Warranty Term cannot exceed 3 digits'),
  penaltiesInContract: yup
    .boolean()
    .required('Penalties In Contract is required'),
  // penaltyContranctReferences: yup
  //   .string()
  //   .when('penaltiesInContract', (penaltiesInContract, schema) => {
  //     const penaltiesInContractCheck = Array.isArray(penaltiesInContract)
  //       ? penaltiesInContract.includes(true)
  //       : penaltiesInContract === true;
  //     return penaltiesInContractCheck
  //       ? schema.required('Penalty Contranct References is required')
  //       : schema.notRequired();
  //   }),
  arePenaltiesCapped: yup
    .boolean()
    .when('penaltiesInContract', (penaltiesInContract, schema) => {
      const penaltiesInContractCheck = Array.isArray(penaltiesInContract)
        ? penaltiesInContract.includes(true)
        : penaltiesInContract === true;
      return penaltiesInContractCheck
        ? schema.required('Penalties Capped is required')
        : schema.notRequired();
    }),
  liquidatedDamages: yup.boolean().required('Liquidated Damages is required'),
  // liquidatedDamagesReferences: yup
  //   .string()
  //   .when('liquidatedDamages', (liquidatedDamages, schema) => {
  //     const liquidatedDamagesCheck = Array.isArray(liquidatedDamages)
  //       ? liquidatedDamages.includes(true)
  //       : liquidatedDamages === true;
  //     return liquidatedDamagesCheck
  //       ? schema.required('Liquidated Damages References is required')
  //       : schema.notRequired();
  //   }),
  liquidatedDamagesCapped: yup
    .boolean()
    .when('liquidatedDamages', (liquidatedDamages, schema) => {
      const liquidatedDamagesCheck = Array.isArray(liquidatedDamages)
        ? liquidatedDamages.includes(true)
        : liquidatedDamages === true;
      return liquidatedDamagesCheck
        ? schema.required('Liquidated Damages Capped is required')
        : schema.notRequired();
    }),
  environmentalHazards: yup
    .boolean()
    .required('Environmental Hazards is required'),
  designResponsibility: yup
    .boolean()
    .required('Design Responsibility is required'),
  // designResponsibilityAttachments only required if designResponsibility is true
  // designResponsibilityAttachments: yup
  //   .array()
  //   .when('designResponsibility', (designResponsibility, schema) => {
  //     const designResponsibilityCheck = Array.isArray(designResponsibility)
  //       ? designResponsibility.includes(true)
  //       : designResponsibility === true;
  //     return designResponsibilityCheck
  //       ? schema.required('Design Responsibility Attachments is required')
  //       : schema.notRequired();
  //   }),

  bondForm: yup.object({
    bondFormValue: yup
      .mixed<bondFormValue>()
      .required('Bond Form is required')
      .oneOf(
        [
          'CCDC',
          'Federal',
          'Provincial',
          'AIA',
          'ConsensusDocs',
          'Federal',
          'State',
          'Other',
        ],
        'Invalid Bond Form'
      ),
    otherDetails: yup
      .string()
      .when('bondFormValue', (bondFormValue, schema) => {
        const bondFormValueCheck = Array.isArray(bondFormValue)
          ? bondFormValue.includes('Other')
          : bondFormValue === 'Other';
        return bondFormValueCheck
          ? schema.trim().required('This field is required')
          : schema.notRequired();
      }),
  }),
  bondApplicationType: yup
    .string()
    .required('Application Type is required')
    .oneOf(['Bid', 'Final'], 'Invalid Application Type'),
  bidBondDetails: yup
    .object()
    .when(
      ['bondApplicationType', 'projectEndDate'],
      ([bondApplicationType, projectEndDate], schema) => {
        const applicationTypeCheck = Array.isArray(bondApplicationType)
          ? bondApplicationType.includes('Bid')
          : bondApplicationType === 'Bid';
        return applicationTypeCheck
          ? yup
              .object({
                estimatedContractPrice: yup
                  .number()
                  .required('Estimated Contract Price is required'),
                tenderDate: yup
                  .date()
                  .required('Tender Date is required')
                  .typeError('Tender Date is required')
                  .test(
                    'is-before-projectEndDate',
                    'Tender Date must be before Project End Date',
                    function (value) {
                      return (
                        !value ||
                        !projectEndDate ||
                        moment(value).isSameOrBefore(moment(projectEndDate!))
                      );
                    }
                  ),
                // .max(
                //   yup.ref('projectEndDate'),
                //   'Tender Date must be before Project End Date'
                // ),
                bidBondAmount: yup
                  .number()
                  .required('Bid Bond Amount is required'),
                acceptancePeriod: yup
                  .number()
                  .required('Acceptance Period is required')
                  .max(999, 'Acceptance Period cannot exceed 3 digits'),
                // preQualificationLetter: yup
                //   .string()
                //   .required('Pre Qualification Letter is required'),
                isPreQualificationLetter: yup
                  .boolean()
                  .required('This field is required'),
                additionalComments: yup
                  .string()
                  .required('Additional Comments is required'),
                attachments: yup.array().optional().default([]),
                performanceBondAmount: yup
                  .number()
                  .required('Performance Bond Amount is required'),
                paymentBondAmount: yup
                  .number()
                  .required('Payment Bond Amount is required'),
                maintenanceBondAmount: yup
                  .number()
                  .required('Maintenance Bond Amount is required'),
              })
              .required('Bid Bond Details is required')
          : yup.object().notRequired();
      }
    ),
  finalBondDetails: yup
    .object()
    .when(
      ['bondApplicationType', 'projectEndDate'],
      ([bondApplicationType, projectEndDate], schema) => {
        const applicationTypeCheck = Array.isArray(bondApplicationType)
          ? bondApplicationType.includes('Final')
          : bondApplicationType === 'Final';
        return applicationTypeCheck
          ? yup
              .object({
                contractPrice: yup
                  .number()
                  .required('Contract Price is required'),
                includesTaxes: yup
                  .boolean()
                  .required('Includes Taxes is required'),
                taxType: yup
                  .mixed<taxType>()
                  .required('Tax Type is required')
                  .oneOf(
                    ['Federal', 'Provincial', 'State', 'Local'],
                    'Invalid Tax Type'
                  ),
                contractDate: yup
                  .date()
                  // .min(new Date(), 'Please choose future date')
                  .required('Contract Date is required')
                  .typeError('Contract Date is required')
                  .test(
                    'is-before-projectEndDate',
                    'Contract Date must be before Project End Date',
                    function (value) {
                      return (
                        !value ||
                        !projectEndDate ||
                        moment(value).isSameOrBefore(moment(projectEndDate!))
                      );
                    }
                  ),
                // .max(
                //   yup.ref('projectEndDate'),
                //   'Tender Date must be before Project End Date'
                // ),
                performanceBondAmount: yup
                  .number()
                  .required('Performance Bond Amount is required'),
                paymentBondAmount: yup
                  .number()
                  .required('Payment Bond Amount is required'),
                maintenanceBondAmount: yup
                  .number()
                  .required('Maintenance Bond Amount is required'),
              })
              .required('Final Bond Details is required')
          : yup.object().notRequired();
      }
    ),
  // approvedDate: yup
  //   .date()
  //   .typeError('Approved Date is required')
  //   .optional()
  //   .nullable(),
  // approver: yup.string().optional().nullable(),
  // approvalStamp: yup.string().optional().nullable(),
  consentOfSurety: yup.boolean().optional().nullable(),
  isNegotiated: yup.boolean().optional().nullable(),
  tenderResults: yup
    .object({
      bidders: yup
        .array()
        .of(
          yup.object({
            companyName: yup
              .string()
              .required('Company Name is required')
              .nullable(),
            amount: yup.number().required('Amount is required').nullable(),
          })
        )
        .optional()
        .nullable()
        .max(2, 'You can only have 2 bidders'),
    })
    .nullable(),
});

export const bondRequestRejectYupSchema = yup.object<BondRequestRejectSchema>({
  status: yup.string().required('Status is required'),
  reasonOfRejection: yup
    .array()
    .of(yup.string())
    // .min(1, 'At least one type of reason is required')
    .required('Reason of Rejection is required')
    .typeError('Please select a valid Reason of Rejection'),
  reasonOfRejectionOther: yup
    .string()
    .when('reasonOfRejection', ([reasonOfRejection], schema) => {
      console.log(
        'reasonOfRejection',
        reasonOfRejection,
        reasonOfRejection?.some((val: any) =>
          rejectBondRequestOptions.some(
            (option) => option.value === val && option.isOther
          )
        )
      );

      const reasonOfRejectionCheck = Array.isArray(reasonOfRejection)
        ? reasonOfRejection?.some((val) =>
            rejectBondRequestOptions.some(
              (option) => option.value === val && option.isOther
            )
          )
        : reasonOfRejection === 'Other';
      return reasonOfRejectionCheck
        ? schema.required('This field is required')
        : schema.notRequired();
    }),
});

export const bondRequestApproveYupSchema = yup.object<BondRequestApproveSchema>(
  {
    status: yup.string().required('Status is required'),
    approvedDate: yup
      .date()
      .typeError('Approved Date is required')
      .required('This filed is required'),
    approver: yup.string().required('This filed is required'),
    approvalStamp: yup.string().optional().nullable(),
  }
);
