import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Documents/slice';
import initialState from './state';

// export const getBondRequestDetails = createAsyncThunk(
//   'bondRequestStore/getBondRequestDetails',
//   async (payload: { applicantId: number }, { rejectWithValue }) => {
//     try {
//       const { applicantId } = payload;
//       const res = await callAPI(
//         `bond/get-bond-details?applicantId=${applicantId}`,
//         'GET',
//         null,
//         true
//       );
//       return res;
//     } catch (error: any) {
//       if (!error.data) {
//         throw error;
//       }
//       return rejectWithValue(error.data);
//     }
//   }
// );

// export const createBondRequest = createAsyncThunk(
//   'bondRequestStore/createBondRequest',
//   async ({ formData }: { formData: TBondRequest }, { rejectWithValue }) => {
//     try {
//       const res = await callAPI(`bond/create`, 'POST', formData);

//       return res;
//     } catch (error: any) {
//       if (!error.data) {
//         throw error;
//       }
//       return rejectWithValue(error.data);
//     }
//   }
// );

// export const updateBondRequestDetails = createAsyncThunk(
//   'bondRequestStore/updateBondRequestDetails',
//   async ({ formData }: { formData: TBondRequest }, { rejectWithValue }) => {
//     try {
//       const res = await callAPI(`bond/update-bond-details`, 'PUT', formData);

//       return res;
//     } catch (error: any) {
//       if (!error.data) {
//         throw error;
//       }
//       return rejectWithValue(error.data);
//     }
//   }
// );

export const getBondApplicationDetails = createAsyncThunk(
  'bondRequestStore/getBondApplicationDetails',
  async (payload: { applicantId: number }, { rejectWithValue }) => {
    try {
      const { applicantId } = payload;
      const res = await callAPI(
        `/bondApplication/getByApplicant/${applicantId}`,
        'GET',
        null,
        true
      );

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const deleteBondApplication = createAsyncThunk(
  'bondRequestStore/deleteBondApplication',
  async ({ reportID }: { reportID: number }, { rejectWithValue }) => {
    try {
      const response = await callAPI(
        `/bondApplication/delete/${reportID}`,
        'DELETE'
      );
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const BondApplicationAssignTo = createAsyncThunk(
  'bondRequestStore/BondApplicationAssignTo',
  async (payload: { applicantId: number }, { rejectWithValue }) => {
    try {
      const { applicantId } = payload;
      const res = await callAPI(
        `/bondApplication/assign-bond/${applicantId}`,
        'PUT',
        null,
        true
      );

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);
export const convertToFinalBond = createAsyncThunk(
  'bondRequestStore/convertToFinalBond',
  async (payload: { bondId: number }, { rejectWithValue }) => {
    try {
      const { bondId } = payload;
      const res = await callAPI(
        `/bondApplication/create-final-bond/${bondId}`,
        'PUT',
        null,
        true
      );

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

// export const BondApplicationReject = createAsyncThunk(
//   'bondRequestStore/BondApplicationReject',
//   async (
//     payload: {
//       status: 'rejected';
//       reasonOfRejection: string[];
//       reasonOfRejectionOther: string | null;
//       bondApplicationId: number;
//     },
//     { rejectWithValue }
//   ) => {
//     try {
//       const { bondApplicationId, ...rest } = payload;
//       const res = await callAPI(
//         `/bondApplication/update-status/${bondApplicationId}`,
//         'PUT',
//         rest,
//         true
//       );

//       return res;
//     } catch (error: any) {
//       if (!error.data) {
//         throw error;
//       }
//       return rejectWithValue(error.data);
//     }
//   }
// );
export const BondApplicationApprove = createAsyncThunk(
  'bondRequestStore/BondApplicationApprove',
  async (
    payload: {
      status: 'approved' | 'rejected' | 'downloaded';
      approvedDate?: string;
      approver?: string;
      approvalStamp?: string | null;
      reasonOfRejection?: string[];
      reasonOfRejectionOther?: string | null;
      bondApplicationId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { bondApplicationId, ...rest } = payload;
      const res = await callAPI(
        `/bondApplication/update-status/${bondApplicationId}`,
        'PUT',
        rest,
        true
      );

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const bondRequestStore = createSlice({
  name: 'bondRequestStore',
  initialState,
  reducers: {
    removeBondRequestType(state, action) {
      state.type = null;
      state.error = null;
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    // get bond request details
    builder
      // .addCase(getBondRequestDetails.pending, (state) => {
      //   state.error = null;
      //   state.status = 'loading';
      //   state.type = 'GET_BOND_REQUEST_DETAILS';
      // })
      // .addCase(getBondRequestDetails.fulfilled, (state, action) => {
      //   state.status = 'succeed';
      //   state.type = 'GET_BOND_REQUEST_DETAILS';
      //   state.bondDetails = action.payload.data?.data;
      // })
      // .addCase(getBondRequestDetails.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.type = 'GET_BOND_REQUEST_DETAILS';
      //   state.error = isRejectedActionWithPayload(action);
      // })
      // .addCase(createBondRequest.pending, (state) => {
      //   state.error = null;
      //   state.status = 'loading';
      //   state.type = 'CREATE_BOND_REQUEST';
      // })
      // .addCase(createBondRequest.fulfilled, (state, action) => {
      //   state.status = 'succeed';
      //   state.type = 'CREATE_BOND_REQUEST';
      //   state.msg = action.payload.data?.msg;
      //   state.bondDetails = action.payload.data?.data;
      // })
      // .addCase(createBondRequest.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.type = 'CREATE_BOND_REQUEST';
      //   state.error = isRejectedActionWithPayload(action);
      // })
      // .addCase(updateBondRequestDetails.pending, (state) => {
      //   state.error = null;
      //   state.status = 'loading';
      //   state.type = 'UPDATE_BOND_REQUEST_DETAILS';
      // })
      // .addCase(updateBondRequestDetails.fulfilled, (state, action) => {
      //   state.status = 'succeed';
      //   state.type = 'UPDATE_BOND_REQUEST_DETAILS';
      //   state.msg = action.payload.data?.msg;
      //   state.bondDetails = action.payload.data?.data;
      // })
      // .addCase(updateBondRequestDetails.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.type = 'UPDATE_BOND_REQUEST_DETAILS';
      //   state.error = isRejectedActionWithPayload(action);
      // })
      .addCase(getBondApplicationDetails.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_BOND_APPLICATION_DETAILS';
      })
      .addCase(getBondApplicationDetails.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_BOND_APPLICATION_DETAILS';
        state.bondList = action.payload.data?.data;
      })
      .addCase(getBondApplicationDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_BOND_APPLICATION_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(deleteBondApplication.pending, (state, action) => {
        state.type = 'DELETE_BOND_APPLICATION';
        state.status = 'loading';
      })
      .addCase(deleteBondApplication.fulfilled, (state, action) => {
        state.type = 'DELETE_BOND_APPLICATION';
        state.status = 'succeed';
      })
      .addCase(deleteBondApplication.rejected, (state, action) => {
        state.type = 'DELETE_BOND_APPLICATION';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(BondApplicationAssignTo.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'BOND_APPLICATION_ASSIGN';
      })
      .addCase(BondApplicationAssignTo.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'BOND_APPLICATION_ASSIGN';
        state.bondAssign = action.payload.data?.data;
      })
      .addCase(BondApplicationAssignTo.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'BOND_APPLICATION_ASSIGN';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(BondApplicationApprove.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'BOND_APPLICATION_STATUS_UPDATE';
      })
      .addCase(BondApplicationApprove.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'BOND_APPLICATION_STATUS_UPDATE';
        state.msg = action.payload.data?.message;
      })
      .addCase(BondApplicationApprove.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'BOND_APPLICATION_STATUS_UPDATE';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(convertToFinalBond.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'BOND_APPLICATION_CONVERT_TO_FINAL_BOND';
      })
      .addCase(convertToFinalBond.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'BOND_APPLICATION_CONVERT_TO_FINAL_BOND';
        state.msg = action.payload.data?.message;
      })
      .addCase(convertToFinalBond.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'BOND_APPLICATION_CONVERT_TO_FINAL_BOND';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const bondRequestSlice = bondRequestStore.reducer;
export const { removeBondRequestType } = bondRequestStore.actions;

export default bondRequestSlice;
