import {
  Box,
  Button,
  Container,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/select';
import { useEffect, useState } from 'react';
import { LiaPagerSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ApplicantUpdate,
  BrokerageAllData,
} from '../../../Redux/Applicant/AddBrokerage/AddBrokerage';
import { fetchApplicantUserData } from '../../../Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { RootState } from '../../../Redux/Store';

function ApplicantBroker() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(0);
  const [isBrokerageUpdate, setIsBrokerageUpdate] = useState(false);
  const [isInitializePage, setIsInitializePage] = useState(false);
  const Toast = useToast();
  const userResponse = useSelector(
    (state: RootState) => state?.brokerageID?.user
  );
  let userDropdownData = userResponse && userResponse.data;

  const tabelData = useSelector(
    (state: RootState) => state?.applicantProfile?.user
  );
  let tabelDatas = tabelData && tabelData?.data;

  let legalname = tabelDatas && tabelDatas?.brokerage?.legalName;
  let defaultLabel =
    legalname && legalname.length !== undefined
      ? legalname
      : 'Select Brokerage';
  let tabelDatass = tabelDatas && tabelDatas.brokerage;
  let dataArray = [];
  if (tabelDatass) {
    dataArray.push(tabelDatass);
  }
  const userId = localStorage.getItem('userID');

  useEffect(() => {
    fetchBrokerageAllData();
    fetchApplicantUserdata();
  }, []);

  const fetchBrokerageAllData = async () => {
    if (!!localStorage.getItem('Token')) {
      setIsInitializePage(true);
      const actionResult = await dispatch(BrokerageAllData() as any);
      const response = actionResult?.payload;
      if (response?.payload?.status === 401) {
        navigate('/login');
      }
      setIsInitializePage(false);
    } else {
      navigate('/login');
    }
  };

  const fetchApplicantUserdata = async () => {
    if (!!localStorage.getItem('Token')) {
      setIsInitializePage(true);
      const actionResult = await dispatch(
        fetchApplicantUserData({ applicantId: Number(userId)! }) as any
      );
      const response = actionResult?.payload;
      if (response?.payload?.status === 401) {
        navigate('/login');
      }
      setIsInitializePage(false);
    } else {
      navigate('/login');
    }
  };

  const handleSave = () => {
    setIsBrokerageUpdate(true);
    if (
      !selectedOption ||
      tabelDatas?.brokerage?.brokerageId === selectedOption
    ) {
      setIsBrokerageUpdate(false);
      return;
    }
    const requestData = {
      brokerageId: selectedOption,
    };

    dispatch(ApplicantUpdate(requestData) as any)
      .then((response: any) => {
        const responsedata = response?.payload;
        let res = responsedata?.msg;

        if (responsedata?.status === 200) {
          Toast({
            title: responsedata?.msg,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          if (responsedata?.status === 401) {
            navigate('/login');
          } else {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      })

      .catch((error: any) => {
        const responsedata = error.payload;
        Toast({
          title: responsedata.msg,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      })
      .finally(() => {
        setIsBrokerageUpdate(false);
        fetchBrokerageAllData();
        fetchApplicantUserdata();
      });
  };

  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        marginBottom="auto"
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={20}
            pt="20px"
          >
            Add your brokerage
          </Text>
        </Box>

        <Text
          fontWeight="600"
          color="#114684"
          display="flex"
          justifyContent="start"
          textAlign="center"
          fontSize={18}
          mb={3}
          mt={5}
        >
          Select brokerage
        </Text>
        <Box display="flex" mb={['20px', '5px']}>
          <Box
            mr={3}
            fontSize={25}
            color="#114684"
            m={0}
            border="1.5px solid #0000001a"
            borderRadius="5px 0px 0px 5px"
          >
            <LiaPagerSolid
              style={{
                marginTop: '7px',
                marginLeft: '6px',
                marginRight: '6px',
              }}
            />
          </Box>

          <Select
            name="userData.brokerageId"
            onChange={(e) => setSelectedOption(parseInt(e.target.value))}
            borderRadius="0px 5px 5px 0px"
            fontSize={'13px'}
          >
            <option value="" label={defaultLabel} />
            {Array?.isArray(userDropdownData) &&
              userDropdownData?.map((user?: any, index?: number) => (
                <option
                  key={index}
                  value={user?.brokerageId}
                  label={user?.legalName}
                ></option>
              ))}
          </Select>
        </Box>

        <Flex justifyContent="space-evenly" columnGap={5}>
          <Box mt="20px" w="100%" mb={'20px'}>
            <Button
              isLoading={isBrokerageUpdate}
              loadingText={'Save'}
              type="submit"
              bg="#114684"
              color="white"
              w="100%"
              _hover={{
                bg: '#114684',
                color: 'white',
                cursor:
                  !selectedOption ||
                  tabelDatas?.brokerage?.brokerageId === selectedOption
                    ? 'not-allowed'
                    : 'pointer',
              }}
              onClick={handleSave}
              isDisabled={
                !selectedOption ||
                tabelDatas?.brokerage?.brokerageId === selectedOption
              }
            >
              Save
            </Button>
          </Box>
        </Flex>
      </Container>
      <TableContainer
        border="0px solid black"
        p={5}
        whiteSpace={'break-spaces'}
      >
        <Table size="md">
          <Thead border="1px solid black" bg="#114684">
            <Tr>
              <Th color="white" border="1px solid #0000002e">
                Legal Name
              </Th>
              <Th color="white" border="1px solid #0000002e">
                Email
              </Th>
              <Th color="white" border="1px solid #0000002e">
                Contact Number
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array?.isArray(dataArray) && dataArray?.length > 0
              ? dataArray?.map((user?: any, index?: number) => (
                  <Tr key={index}>
                    <Td border="1px solid #0000002e">{user?.legalName}</Td>
                    <Td border="1px solid #0000002e">{user?.email}</Td>
                    <Td border="1px solid #0000002e">{user?.contactNumber}</Td>
                  </Tr>
                ))
              : !isInitializePage && (
                  <Tr>
                    <Td colSpan={10} textAlign="center">
                      No data available
                    </Td>
                  </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ApplicantBroker;
