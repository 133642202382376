import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface OwnerState {
  user: any;
  loading: boolean;
  error: null;
  formStatus: boolean;
}

const initialState: OwnerState = {
  user: [],
  loading: false,
  error: null,
  formStatus: false,
};

export const Owner = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

// owner account
export const AddOwner = createAsyncThunk(
  'owner/AddOwner',
  async (
    AddOwner: {
      firstName: string;
      middleName: string;
      lastName: string;
      email: string;
      contactNumber: string;
      SIN_SSN: number | null;
      dateOfBirth: Date | null;
      homeAddress: string;
      isVerified: boolean | false;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/user/owner/create`,
          AddOwner,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//owner all data

export const OwnerAllData = createAsyncThunk('owner/OwnerAllData', async () => {
  try {
    const Token = localStorage.getItem('Token');

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${BASEURL}/user/owner/getall`,
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.request(config);
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  } catch (error) {
    throw error;
  }
});

export const OwnerData = createAsyncThunk(
  'owner/OwnerAllData',
  async ({ queryString }: any) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/user/owner/getall${queryString}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

//Delete  data

export const OwnerDelete = createAsyncThunk(
  'owner/OwnerDelete',
  async (userId: string, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');

      const response = await axios.delete(
        `${BASEURL}/user/owner/delete/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      try {
        return userId;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update

export const UpdateOwner = createAsyncThunk(
  'owner/UpdateOwner',
  async (updatedData: any, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/owner/update/${updatedData.ownerId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//id threw get
export const getUserById = createAsyncThunk(
  'owner/getUserById',
  async (userId: string, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');
      const response = await axios.get(`${BASEURL}/user/owner/get/${userId}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Check Owner Form status
export const checkOwnerFormStatus = createAsyncThunk(
  'owner/checkOwnerFormStatus',
  async (
    { applicationId, ownerId }: { applicationId: string; ownerId: string },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const response = await axios.get(
        `${BASEURL}/user/owner/form-status?applicationId=${applicationId}&ownerId=${ownerId}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const ownerSlice = createSlice({
  name: 'owner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // owner account

      .addCase(AddOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddOwner.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(AddOwner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //owner all data
      .addCase(OwnerAllData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(OwnerAllData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(OwnerAllData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //delete
      .addCase(OwnerDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(OwnerDelete.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (Array.isArray(state.user)) {
          state.user = state.user.filter(
            (user: any) => user.ownerId !== action.payload
          );
        }
      })
      .addCase(OwnerDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //update id
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserById.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // update owner
      .addCase(UpdateOwner.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateOwner.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(UpdateOwner.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // owner form status
      .addCase(checkOwnerFormStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        checkOwnerFormStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.formStatus = action.payload?.data?.formStatus || false;
        }
      )
      .addCase(checkOwnerFormStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export default ownerSlice.reducer;
