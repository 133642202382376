import {
  Box,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AppSolidButton } from 'src/Component/Common/Micro/AppButtons';
import { useAppToast } from 'src/hooks';
import { purchaseTopUp, removeType } from 'src/Redux/Plans/slice';
import {
  IStripePlanBase,
  PurchasedPlanInfo,
  stripePrice,
} from 'src/Redux/Plans/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  getCurrencyDigit,
  getPlanName,
  preventAlphabetsTyping,
} from 'src/utils/helpers';

const TopupPlanModal = ({
  isOpen,
  onClose,
  purchasedPlanInfo,
  topupPlanInfo,
}: {
  isOpen: boolean;
  onClose: () => void;
  purchasedPlanInfo: PurchasedPlanInfo | null;
  topupPlanInfo: IStripePlanBase | null;
}) => {
  const dispatch = useAppDispatch();
  const plansStore = useAppSelector((state) => state.plans);
  const toast = useAppToast();
  const [numApplications, setNumApplications] = useState<number | undefined>(
    undefined
  );
  const [topupPrice, setTopupPrice] = useState<{
    amount: number;
    currency: string;
  }>({
    amount: 0,
    currency: 'usd',
  });

  useEffect(() => {
    const { status, type, error, topupStripeURL } = plansStore;

    switch (status) {
      case 'loading': {
        break;
      }
      case 'succeed': {
        if (type === 'PURCHASE_TOP_UP_PLAN' && topupStripeURL) {
          console.log('topupStripeURL', topupStripeURL);
          window.open(`${topupStripeURL}`, '_blank', 'noopener,noreferrer');
        }
        break;
      }

      case 'failed': {
        if (type === 'PURCHASE_TOP_UP_PLAN') {
          toast({
            title: error,
            status: 'error',
          });
          handleCloseModal();
        }
        break;
      }
    }

    return () => {
      if (type === 'PURCHASE_TOP_UP_PLAN') {
        dispatch(removeType({}));
      }
    };
  }, [plansStore.status, plansStore.type]);

  useEffect(() => {
    if (isOpen && !!topupPlanInfo) {
      const { prices }: { prices: stripePrice[] } = topupPlanInfo;
      const planPriceByPayType = Math.trunc(prices?.[0]?.unit_amount / 100);
      setTopupPrice({
        ...topupPrice,
        amount: planPriceByPayType,
        currency: prices[0]?.currency,
      });
    }
  }, [isOpen, topupPlanInfo]);

  const totalPrice = numApplications ? numApplications * topupPrice?.amount : 0;

  const handleCloseModal = () => {
    setNumApplications(undefined);
    onClose();
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setNumApplications(value);
  };

  const handleTopUpPurchase = () => {
    console.log('purchase top up', topupPlanInfo);
    try {
      const { prices }: { prices: stripePrice[] } = topupPlanInfo!;
      const payload = {
        purchasedPlanId: purchasedPlanInfo?.purchasedPlanId!,
        productId: prices?.[0]?.product,
        NoOfApplications: Number(numApplications)!,
        default_price: prices?.[0]?.id,
      };
      dispatch(purchaseTopUp(payload));
    } catch (error) {
      console.log('top up purchase', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => handleCloseModal()} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="md" boxShadow="lg" overflow="hidden">
        <ModalHeader bg="#114684" color="white" py={4} fontSize="lg">
          Top Up Plan
        </ModalHeader>
        <ModalCloseButton color="white" top="10px" right="10px" />
        <ModalBody py={6} px={8}>
          <VStack spacing={4} align="stretch">
            <Box>
              <Text fontSize="md" mb={2}>
                No. of Applications
              </Text>
              <Input
                type="number"
                value={numApplications}
                onChange={handleInputChange}
                onKeyDown={preventAlphabetsTyping}
                placeholder="Enter number of applications"
                size="lg"
                min={1}
              />
            </Box>
            <Divider />
            <Box>
              <Text fontSize={12}>
                Price of one application (As per{' '}
                <Text display={'inline-block'} fontWeight={600}>
                  {getPlanName(purchasedPlanInfo?.name!)} Plan
                </Text>
                )
              </Text>
              <Text fontSize="lg" mt={2}>
                {getCurrencyDigit({
                  number: topupPrice?.amount,
                  options: {
                    currency: topupPrice?.currency,
                  },
                })}
              </Text>
            </Box>
            <Divider />
            <Box>
              <Text fontSize={12}>Total</Text>
              <Text fontSize="lg" fontWeight="bold" mt={2}>
                ${totalPrice}
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter bg="gray.100">
          {/* <AppOutlineButton mr={3} onClick={onClose}>
              Close
            </AppOutlineButton> */}
          <AppSolidButton
            type="button"
            onClick={handleTopUpPurchase}
            isDisabled={
              numApplications === undefined || Number(numApplications) === 0
            }
            _disabled={{
              bg: 'rgba(17, 70, 132, 0.5)',
              cursor: 'not-allowed',
              '&:hover': {
                bg: 'rgba(17, 70, 132, 0.5)',
                cursor: 'not-allowed',
              },
            }}
          >
            Pay
          </AppSolidButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TopupPlanModal;
