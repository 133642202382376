import {
  Box,
  Button,
  Checkbox,
  HStack,
  Input,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FileInput, LoadingOverlay, Modal, MultiSelect } from '@mantine/core';
import { useDidUpdate, useDisclosure } from '@mantine/hooks';
import { PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import { IconFileCv } from '@tabler/icons-react';
import axios from 'axios';
import { useFormik } from 'formik';
import JSZip from 'jszip';
import { jwtDecode } from 'jwt-decode';
import { isArray } from 'lodash';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppToast, useZipFromLinksWithFolder } from 'src/hooks';
import { getApplicationUserkey } from 'src/Redux/Applications/slice';
import {
  getAllReports,
  getReportsStatus,
} from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { IReportStatus } from 'src/Redux/Broker/Reports/APAgingSummary/state';
import { RootState, useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { listAllReports } from 'src/Redux/UploadReports/slice';
import { REPORT_NAME } from 'src/types/enums';
import { REPORT_SHORTFORM } from 'src/types/unions';
import { getPdfPreferenceQuestionName } from 'src/utils/forms/legalForm';
import { decrypt, decryptedPayload } from 'src/utils/helpers';
import { generateAllReports } from 'src/utils/reports/generateAllReports';
import {
  getPdfContent,
  pdfContentCreate,
  UploadPDFLogo,
} from '../../../../Redux/PdfContent/slice';
import ApplicationPDF from '../../../../templates/pdfs/ApplicationPDF';
import { getDocumentTableData } from '../uploadFiles/UploadFilesTable';

const LegalPdfPreferenceInfo = ({
  legalPdfPreference,
}: {
  legalPdfPreference: { question: string; selectedData: number }[];
}) => {
  console.log('LegalPdfPreferenceInfo', legalPdfPreference);
  const checkselectedDataCount = legalPdfPreference?.some(
    (item) => !!item?.selectedData
  );
  if (checkselectedDataCount) {
    return (
      <VStack gap={1}>
        <Text fontWeight={'600'} color={'#114684'} w={'100%'}>
          Selected Preferences count
        </Text>
        <Text w={'100%'}>
          {legalPdfPreference?.length > 0 &&
            legalPdfPreference
              .filter((el) => el.selectedData > 0) // Filter items with selectedData > 0
              .map((el, index) => {
                const { question, selectedData } = el;
                const displayName = getPdfPreferenceQuestionName[question]; // Resolve the display name
                const isLastItem =
                  index ===
                  legalPdfPreference.filter((el) => el.selectedData > 0)
                    .length -
                    1;
                return (
                  <React.Fragment key={question}>
                    {`${displayName}(${selectedData})`}
                    {!isLastItem && ', '}
                  </React.Fragment>
                );
              })}
        </Text>
      </VStack>
    );
  } else {
    return <Text fontSize={'12px'}>No preferences selected for printing.</Text>;
  }
};

export type broketDetails = {
  firstName: string;
  lastName: string;
  contactNumber: number | null;
  email: string;
  brokerageName: string;
};
export interface PdfContentProps {
  applicationId: number | null;
  companyName: string | null;
  userType?: 'applicant' | 'broker';
  brokerInfo?: broketDetails;
  allFormData?: any;
  qboCompanyName: string;
  downloadWithDoc?: boolean;
  user: any;
}

const PdfContent = (props: PdfContentProps) => {
  const {
    userType,
    companyName,
    applicationId,
    brokerInfo,
    allFormData,
    qboCompanyName,
    downloadWithDoc = true,
    user,
  } = props || {};

  const { createZipFromFolderAndLinks, error, loading } =
    useZipFromLinksWithFolder();

  const pdfDownloadRef = useRef<RefObject<PDFDownloadLink>>();

  const [opened, { open, close }] = useDisclosure(false);

  const pdfData = useSelector((state: RootState) => state.PdfContentSlice);
  const reportsStore = useSelector((state: RootState) => state.accountPayable);
  const documentsData = useSelector(
    (state: RootState) => state.uploadReportsSlice
  );
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);

  const Toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState({
    contentLoader: false,
    btnLoader: false,
    reportsStatusLoading: false,
  });
  const [shouldExport, setShouldExport] = useState(false);
  const [reportsStatus, setReportsStatus] = useState<
    | {
        label: REPORT_NAME;
        value: REPORT_SHORTFORM;
        group: string;
      }[]
    | null
  >(null);
  const [pdfFormData, setPdfFormData] =
    useState<Record<string, any>>(allFormData);
  const [applicationKey, setApplicationUserKey] = useState<string | null>(null);
  const toast = useAppToast();

  useEffect(() => {
    if (applicationId && opened) {
      dispatch(
        getApplicationUserkey({
          applicationId,
        })
      );
    }
  }, [applicationId, opened]);

  // useEffect(() => {
  //   if (applicationId && opened) {
  //     setLoader({ ...loader, btnLoader: false, contentLoader: true });
  //     dispatch(getPdfContent({ id: applicationId }) as any);
  //   }
  // }, [applicationId, opened]);

  useEffect(() => {
    const { status, type, currentApplication, userKey } = appliactionsSlice;
    if (applicationId && opened) {
      switch (status) {
        case 'loading':
          if (type === 'GET_APPLICATION_USER_KEY') {
            setLoader({ ...loader, btnLoader: false, contentLoader: true });
          }
          break;
        case 'succeed':
          if (type === 'GET_APPLICATION_USER_KEY') {
            setLoader({ ...loader, btnLoader: false, contentLoader: false });
            let PfFormAPIData = allFormData?.financialInfo;
            const decoded: Record<string, any> = jwtDecode(userKey as string);
            const applicationKey = decrypt(
              decoded?.usKey,
              process.env.REACT_APP_SS_KEY as string
            );
            setApplicationUserKey(applicationKey);

            if (allFormData?.financialInfo?.ownersFinancialInfo) {
              let decodedOwnerDetails = decryptedPayload(
                allFormData?.financialInfo?.ownersFinancialInfo,
                applicationKey
              );
              PfFormAPIData = {
                ...PfFormAPIData,
                ownersFinancialInfo: decodedOwnerDetails,
              };
            }
            setPdfFormData({ ...pdfFormData, financialInfo: PfFormAPIData });
            dispatch(getPdfContent({ id: applicationId }) as any);
          }
          break;
        case 'failed':
          if (type === 'GET_APPLICATION_USER_KEY') {
            setLoader({ ...loader, btnLoader: false, contentLoader: false });
          }
          break;

        default:
          break;
      }
    }
  }, [appliactionsSlice.status, applicationId, opened]);

  useEffect(() => {
    if (pdfData?.data && opened) {
      const { fileData, reportDownloadInfo, legalPdfPreference } =
        pdfData?.data?.data;

      let statusOptions: REPORT_SHORTFORM[] = [];
      if (isArray(reportDownloadInfo)) {
        statusOptions = reportDownloadInfo
          ?.filter((data: IReportStatus) => data.status)
          ?.map((data: IReportStatus) => data.shortForm);
      }

      formik.setValues({
        title: fileData?.title,
        heading: fileData?.heading,
        logo: fileData?.logoUrl,
        urlKey: fileData?.urlKey,
        summary: fileData?.summary,
        contactInfo: fileData?.contactInfo,
        generalBanking: fileData?.generalBanking,
        legal: fileData?.legal,
        personalFinancial: fileData?.personalFinancial,
        about: fileData?.about,
        surety: fileData?.surety,
        insurance: fileData?.insurance,
        // documents: fileData?.documents,
        reportNames: statusOptions ?? [],
        legalPdfPreference: legalPdfPreference ?? [],
      });
    }
  }, [pdfData, opened]);

  const getReportOptions = (data: IReportStatus[]) => {
    return data?.reduce((acc, curr) => {
      const option: any = {
        label: curr.reportName,
        value: curr.shortForm,
      };

      if (curr.status) {
        acc.push(option);
      }

      return acc;
    }, [] as any);
  };

  useEffect(() => {
    const { status, type } = reportsStore;
    switch (status) {
      case 'loading':
        if (type === 'GET_REPORTS_STATUS') {
          setLoader({ ...loader, reportsStatusLoading: true });
        }
        break;
      case 'succeed':
        if (type === 'GET_REPORTS_STATUS') {
          const data = reportsStore.reportsStatus;

          data && setReportsStatus(getReportOptions(data));
          setLoader({ ...loader, reportsStatusLoading: false });
        }
        break;
      case 'failed':
        if (type === 'GET_REPORTS_STATUS') {
          setLoader({ ...loader, reportsStatusLoading: false });
        }
        break;
    }
  }, [reportsStore.status]);

  useEffect(() => {
    if (pdfData && opened) {
      const { status, type, error } = pdfData;
      switch (status) {
        case 'loading': {
          if (type === 'GET_PDF_DATA') {
            setLoader({ ...loader, contentLoader: true });
          }
          break;
        }
        case 'succeed': {
          if (type === 'PDF_CONTENT_CREATE') {
            // Toast({
            //   title: 'Pdf Download Success',
            //   status: 'success',
            //   isClosable: true,
            //   duration: 3000,
            //   position: 'top-right',
            // });
          } else if (type === 'GET_PDF_DATA') {
            setTimeout(() => {
              setLoader({ ...loader, contentLoader: false });
            }, 500);
          }
          break;
        }
        case 'failed': {
          if (type === 'PDF_CONTENT_CREATE') {
            Toast({
              title: error,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            setLoader({ ...loader, btnLoader: false });
          } else if (type === 'GET_PDF_DATA') {
            setLoader({ ...loader, contentLoader: false });
          }
          break;
        }

        default:
          break;
      }
    }
  }, [pdfData?.status, opened]);
  interface FormValues {
    title: string | null;
    heading: string | null;
    logo: File | null;
    urlKey: string | null;
    summary: string | null;
    contactInfo: boolean | null;
    generalBanking: string | null;
    legal: string | null;
    personalFinancial: string | null;
    about: string | null;
    surety: string | null;
    insurance: string | null;
    // documents: string | null;
    reportNames: REPORT_SHORTFORM[];
    legalPdfPreference: { question: string; selectedData: number }[];
  }
  const initialValues: FormValues = {
    title: null,
    heading: null,
    logo: null,
    urlKey: null,
    summary: null,
    contactInfo: true,
    generalBanking: null,
    legal: null,
    personalFinancial: null,
    about: null,
    surety: null,
    insurance: null,
    reportNames: [],
    // documents: null,
    legalPdfPreference: [],
  };

  const dowanloadData = async (values: FormValues) => {
    let bucketData: any = {};
    if (values.logo && typeof values.logo !== 'string') {
      const formdata = new FormData();
      formdata.append('files', values.logo);
      bucketData = await dispatch(UploadPDFLogo(formdata) as any);
    }
    if (
      values.logo &&
      typeof values.logo !== 'string' &&
      bucketData?.payload?.status !== 200
    ) {
      Toast({
        title: bucketData?.payload?.msg,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top-right',
      });
      close();
      return;
    }
    const payload = {
      applicationId: applicationId,
      fileData: {
        title: values?.title,
        logoUrl:
          typeof values.logo !== 'string'
            ? bucketData?.payload?.data[0]?.location
            : values?.logo,
        urlKey:
          typeof values.logo !== 'string'
            ? bucketData?.payload?.data[0]?.key
            : values?.urlKey,
        summary: values?.summary,
        heading: values?.heading,
        contactInfo: values?.contactInfo,
        generalBanking: values?.generalBanking,
        legal: values?.legal,
        personalFinancial: values?.personalFinancial,
        about: values?.about,
        surety: values?.surety,
        insurance: values?.insurance,
        // documents: values?.documents,
        // reportNames: values?.reportNames,
      },
    };
    setLoader({ ...loader, btnLoader: true });
    const actionResult = await dispatch(pdfContentCreate(payload) as any);
    const response = actionResult?.payload;
    const { fileData } = response?.data;
    const resStatus = response?.status;
    const stepperFormLabel = {
      generalBanking: fileData?.generalBanking ?? '',
      legal: fileData?.legal ?? '',
      personalFinancial: fileData?.personalFinancial ?? '',
      about: fileData?.about ?? '',
      surety: fileData?.surety ?? '',
      insurance: fileData?.insurance ?? '',
    };

    updateInstance(
      <ApplicationPDF
        title={fileData?.title ?? ''}
        heading={fileData?.heading ?? ''}
        summary={fileData?.summary ?? ''}
        logo={fileData?.logoUrl ?? ''}
        companyName={companyName ?? ''}
        contactInfo={fileData?.contactInfo}
        brokerInfo={brokerInfo}
        stepperFormLabel={stepperFormLabel}
        allFormData={pdfFormData}
        qboCompanyName={qboCompanyName}
      />
    );
    setShouldExport(true);

    // if (resStatus === 200) {
    //   Toast({
    //     title: response.msg,
    //     status: 'success',
    //     isClosable: true,
    //     duration: 3000,
    //     position: 'top-right',
    //   });
    // } else {
    //   if (resStatus !== 401) {
    //     // Toast({
    //     //   title: res,
    //     //   status: 'error',
    //     //   isClosable: true,
    //     //   duration: 3000,
    //     //   position: 'top-right',
    //     // });
    //   } else {
    //     navigate('/login');
    //   }
    // }
  };

  const submitPdfContent = async (values: FormValues) => {
    try {
      const checkselectedDataCount = values.legalPdfPreference?.some(
        (item) => !!item?.selectedData
      );
      if (checkselectedDataCount) {
        toast({
          title: 'Report will be downloaded in 2-3 mins',
          status: 'info',
        });
      }
      setLoader({
        ...loader,
        btnLoader: true,
      });
      if (applicationId && values.reportNames?.length) {
        dispatch(
          getAllReports({
            applicantId: user.applicantId,
            applicationId,
            reportNames: values.reportNames,
          })
        ).then(async () => {
          dowanloadData(values);
        });
      } else {
        dowanloadData(values);
      }
    } catch (error) {
      console.log('submitPdfContentHandler', error);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: submitPdfContent,
  });

  useEffect(() => {
    if (opened) {
      changeHeight();
    }
  }, [formik.values?.summary, opened]);

  const { values, handleChange, setFieldValue, handleSubmit } = formik;

  const [instance, updateInstance] = usePDF();

  const handleExportData = async () => {
    const { applicationName, applicant } = user || {};

    const zipFolderName = `Documents - ${companyName} - ${applicationName}`;

    if (documentsData && documentsData.applicantReportsData && instance?.url) {
      const { applicantReportsData } = documentsData;

      const zip = new JSZip();

      const tableData = getDocumentTableData({
        applicantReportsData,
      });

      const docsZipData = await createZipFromFolderAndLinks({
        folderWisedata: tableData.map((data) => {
          return {
            mainFolder: data.financial!,
            subFolder: data.financialYearStatement!,
            links: data.files?.map((file) => file.link!)!,
          };
        }),
        zipName: zipFolderName,
        returnData: true,
        downloadZip: false,
      });

      const mainFolderName = zip.folder(zipFolderName);
      // const subFolder = mainFolderName?.folder(data.subFolder);
      if (docsZipData) {
        await mainFolderName?.loadAsync(docsZipData);
      }

      const response = await axios.get(instance.url, {
        responseType: 'arraybuffer',
      });

      zip.file(
        `Application - ${companyName} - ${applicationName}.pdf`,
        response.data
      );

      if (reportsStore.allReportsData) {
        const allReportsBlob = generateAllReports(reportsStore.allReportsData);

        if (allReportsBlob) {
          await Promise.all(
            allReportsBlob?.map(async (report, i) => {
              try {
                zip?.file(
                  `Financial Reports - ${companyName} - ${applicationName}/${report.folderName}/${report.filename}.csv`,
                  report.blob
                );
              } catch (error) {
                console.error(error);
              }
            })
          );
        }
      }

      // Generate the zip content
      const zipContent = await zip.generateAsync({ type: 'blob' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(zipContent);
      downloadLink.download = `${companyName}.zip`;
      downloadLink.click();

      setLoader({ ...loader, btnLoader: false });
      close();
      setShouldExport(false);
    }

    // const links = tableData.map((data) => ({ links: data.files }));
  };

  useDidUpdate(() => {
    if (shouldExport && !instance?.loading && instance?.url) {
      if (downloadWithDoc) handleExportData();
      else {
        // @ts-ignore
        pdfDownloadRef.current?.click();
        setLoader({ ...loader, btnLoader: false });
        close();
      }
    }
  }, [shouldExport, instance?.loading, instance?.url]);

  function changeHeight() {
    if (opened) {
      setTimeout(() => {
        const textarea = document.getElementById('summary');
        if (textarea) {
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + 'px';
        }
      }, 10);
    }
  }

  const disableReportStatus = !reportsStatus?.length;

  return (
    <>
      <Box>
        <Box
          onClick={() => {
            if (userType !== 'applicant' && user.applicantId) {
              downloadWithDoc &&
                user.applicantId &&
                dispatch(
                  listAllReports({
                    applicantId: user.applicantId,
                  }) as any
                );

              dispatch(
                getReportsStatus({
                  applicantId: user.applicantId,
                }) as any
              );

              open();
            } else
              updateInstance(
                <ApplicationPDF
                  allFormData={pdfFormData}
                  qboCompanyName={qboCompanyName}
                />
              );
          }}
        >
          {userType === 'applicant' ? (
            <PDFDownloadLink
              document={
                <ApplicationPDF
                  companyName={companyName ?? ''}
                  allFormData={pdfFormData}
                  qboCompanyName={qboCompanyName}
                />
              }
              fileName={`${companyName}.pdf`}
            >
              {({ blob, url, loading, error }) => <CgFileDocument size={20} />}
            </PDFDownloadLink>
          ) : (
            <CgFileDocument size={20} />
          )}
        </Box>
      </Box>
      <Modal
        opened={opened}
        onClose={() => {
          setLoader({ ...loader, reportsStatusLoading: false });
          close();
        }}
        title="Download PDF"
        centered
        overlayProps={{
          opacity: 0.1,
          blur: 3,
        }}
        size={'xl'}
      >
        <LoadingOverlay
          visible={
            loader.contentLoader ||
            (reportsStore.status === 'loading' &&
              reportsStore.type === 'GET_REPORTS_STATUS')
          }
          overlayBlur={2}
        />
        <form onSubmit={handleSubmit}>
          <Box display={'flex'} flexDirection={'column'} gap={5}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={5}
              style={{
                opacity: loader?.contentLoader ? 0.5 : 1,
              }}
            >
              <LegalPdfPreferenceInfo
                legalPdfPreference={formik.values?.legalPdfPreference}
              />
              <HStack>
                <Box width={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                  >
                    Page Title
                  </Text>
                  <Box mt={2}>
                    <Input
                      fontSize={'13px'}
                      type="text"
                      placeholder="Page Title"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={handleChange}
                      value={values?.heading ?? ''}
                      name="heading"
                    />
                  </Box>
                </Box>
                <Box width={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                  >
                    Subtitle
                  </Text>
                  <Box mt={2}>
                    <Input
                      fontSize={'13px'}
                      type="text"
                      placeholder="Subtitle"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={handleChange}
                      value={values?.title ?? ''}
                      name="title"
                    />
                  </Box>
                </Box>
              </HStack>
              <Box>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  px={1}
                >
                  Brokerage Logo
                </Text>
                <FileInput
                  icon={
                    <IconFileCv
                      style={{ width: 18, height: 18 }}
                      stroke={1.5}
                    />
                  }
                  styles={{
                    input: {
                      border: '1px solid #7f7f7f',
                      marginTop: '8px',
                    },
                  }}
                  // @ts-ignore
                  placeholder={
                    values?.logo
                      ? typeof values?.logo === 'string'
                        ? values?.urlKey?.split('/')?.[1]
                        : values?.logo
                      : 'Brokerage Logo'
                  }
                  // label="Logo"
                  onChange={(value) => {
                    setFieldValue('logo', value);
                  }}
                  // value={
                  //   (typeof values?.logo === 'string'
                  //     ? getLogoFile(values?.logo, values?.urlKey?.split('/')?.[1])
                  //     : values?.logo) as File
                  // }
                  // value={values?.logo}
                  // description="The file format should be - jpeg,png"
                  // withAsterisk
                  // variant="filled"
                  accept=".jpeg,.png,.jpg"
                />
                {/* {values?.logo && typeof values?.logo === 'string' && (
               <>
                 <Box>
                   <Text
                     fontWeight="600"
                     textAlign="start"
                     fontSize={13}
                     px={1}
                   >
                     {values?.urlKey?.split('/')?.[1]}
                   </Text>
                   <Box>edit</Box>
                 </Box>
               </>
             )} */}
              </Box>
              <Box>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  px={1}
                >
                  Summary
                </Text>
                <Box mt={2}>
                  <Textarea
                    rows={4}
                    minH={20}
                    maxH={60}
                    id="summary"
                    onKeyUp={changeHeight}
                    style={{ overflow: 'hidden' }}
                    fontSize={'13px'}
                    placeholder="Summary"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={handleChange}
                    value={values?.summary ?? ''}
                    name="summary"
                  />
                </Box>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                flexWrap={'wrap'}
                gap={3}
              >
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                  px={1}
                >
                  Include your contact information?
                </Text>
                <Stack
                  spacing={5}
                  display={'flex'}
                  flexWrap={'wrap'}
                  direction="row"
                  px={1}
                >
                  <Checkbox
                    isChecked={values?.contactInfo === true}
                    onChange={(e) => {
                      setFieldValue('contactInfo', e.target.checked);
                    }}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    isChecked={values?.contactInfo === false}
                    onChange={(e) => {
                      setFieldValue('contactInfo', !e.target.checked);
                    }}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>
              <Box>
                <MultiSelect
                  description={
                    <HStack
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      gap={0}
                    >
                      <Text
                        sx={{
                          textAlign: 'start',
                          fontSize: 10,
                          px: 1,
                          display: `${disableReportStatus ? 'block' : 'hidden'}`,
                        }}
                      >
                        Kindly generate financial reports using accounting
                        system.
                      </Text>
                      {disableReportStatus && (
                        <Tooltip
                          fontSize="md"
                          w={'max-content'}
                          label={
                            <Text>
                              Reports are unavailable due to the following
                              issues: <br />
                              1. No access to accounting system OR
                              <br />
                              2. Report is not generated by the user within the
                              Report tab
                            </Text>
                          }
                          placement={'auto'}
                        >
                          <Box>
                            <HiMiniInformationCircle
                              size={14}
                              cursor={'pointer'}
                              color="red"
                            />
                          </Box>
                        </Tooltip>
                      )}
                    </HStack>
                  }
                  sx={{
                    label: {},
                    description: {
                      padding: 2,
                      color: 'red',
                    },
                  }}
                  disabled={disableReportStatus}
                  label={
                    <HStack
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      gap={0}
                    >
                      <Text
                        sx={{
                          fontWeight: 600,
                          color: '#114684',
                          textAlign: 'start',
                          fontSize: 13,
                          px: 1,
                        }}
                      >
                        Select financial reports to download
                      </Text>
                      <Tooltip
                        fontSize="md"
                        label="Financial reports can only be downloaded when accessing your accounting system through automated processes."
                        placement={'auto'}
                        // shouldWrapChildren={true}
                      >
                        <Box>
                          <HiMiniInformationCircle
                            size={16}
                            cursor={'pointer'}
                            color="#114684"
                          />
                        </Box>
                      </Tooltip>
                    </HStack>
                  }
                  placeholder={
                    disableReportStatus
                      ? 'Reports unavailable.'
                      : 'Select financial reports to download'
                  }
                  searchable
                  data={reportsStatus ?? []}
                  onChange={(e) => setFieldValue('reportNames', e)}
                  value={values.reportNames}
                />
              </Box>
              <Box>
                <Text
                  fontWeight="600"
                  color="#121212"
                  textAlign="start"
                  fontSize={14}
                  px={1}
                >
                  Change the following Section Heads to:
                </Text>
              </Box>
              <HStack>
                <Box w={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                    width="40%"
                  >
                    General & Banking
                  </Text>
                  <Input
                    mt={2}
                    flex="1"
                    fontSize={'13px'}
                    type="text"
                    placeholder="General & Banking"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={handleChange}
                    value={values?.generalBanking ?? ''}
                    name="generalBanking"
                  />
                </Box>
                <Box w={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                    width="40%"
                  >
                    Legal
                  </Text>
                  <Input
                    mt={2}
                    flex="1"
                    fontSize={'13px'}
                    type="text"
                    placeholder="Legal"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={handleChange}
                    value={values?.legal ?? ''}
                    name="legal"
                  />
                </Box>
              </HStack>

              <HStack>
                <Box w={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                    width="40%"
                  >
                    Personal Financial
                  </Text>
                  <Input
                    mt={2}
                    flex="1"
                    fontSize={'13px'}
                    type="text"
                    placeholder="Personal Financial"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={handleChange}
                    value={values?.personalFinancial ?? ''}
                    name="personalFinancial"
                  />
                </Box>
                <Box w={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                    width="40%"
                  >
                    About
                  </Text>
                  <Input
                    mt={2}
                    flex="1"
                    fontSize={'13px'}
                    type="text"
                    placeholder="About"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={handleChange}
                    value={values?.about ?? ''}
                    name="about"
                  />
                </Box>
              </HStack>
              <HStack>
                <Box w={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                    width="40%"
                  >
                    Surety
                  </Text>
                  <Input
                    mt={2}
                    flex="1"
                    fontSize={'13px'}
                    type="text"
                    placeholder="Surety"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={handleChange}
                    value={values?.surety ?? ''}
                    name="surety"
                  />
                </Box>
                <Box w={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                    px={1}
                    width="40%"
                  >
                    Insurance
                  </Text>
                  <Input
                    mt={2}
                    flex="1"
                    fontSize={'13px'}
                    type="text"
                    placeholder="Insurance"
                    borderRadius="5px"
                    focusBorderColor="#7f7f7f"
                    borderColor={'#7f7f7f'}
                    _hover={{ borderColor: '#7f7f7f' }}
                    onChange={handleChange}
                    value={values?.insurance ?? ''}
                    name="insurance"
                  />
                </Box>
              </HStack>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              marginTop={'10px'}
              gap={2}
            >
              <Button
                bg={'#114684'}
                color={'white'}
                _hover={{ bg: '#114684', color: 'white', cursor: 'pointer' }}
                onClick={close}
                type="button"
              >
                Cancel
              </Button>
              <Button
                bg={'#114684'}
                color={'white'}
                _hover={{
                  bg: '#114684',
                  color: 'white',
                  cursor:
                    loader?.btnLoader || loader?.contentLoader
                      ? 'not-allowed'
                      : 'pointer',
                }}
                type="submit"
                isLoading={loader?.btnLoader}
                isDisabled={loader?.btnLoader}
              >
                Download
              </Button>
              <a
                id="downloadLink"
                ref={pdfDownloadRef as any}
                href={instance?.url || ''}
                target={'_blank'}
                download={companyName}
                className="chakra-button css-bx54k4"
                style={{
                  opacity: 0,
                  pointerEvents: 'none',
                  cursor: 'not-allowed',
                  visibility: 'hidden',
                  display: 'none',
                }}
                rel="noreferrer"
              >
                Download
              </a>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default PdfContent;
