import { Box, Flex } from '@chakra-ui/react';
import { ActionIcon, Badge, Divider, Progress, Tooltip } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ocrReportTable, reportInfo } from 'src/Pages/BrokerPages/OCRReports';

const ReportTabOCRListTable = ({
  tableList,
  tableContentLoader,
}: {
  tableList: Record<string, any>[];
  tableContentLoader: boolean;
}) => {
  const userType = localStorage.getItem('userType');
  const userID = localStorage.getItem('userID');
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<ocrReportTable[]>([]);

  useEffect(() => {
    const ocrTData = getOCRReportTableData(tableList);
    setTableData(ocrTData);
  }, [tableList]);

  const getFiscalYearDate = (reportInfo: reportInfo) => {
    if (
      !!reportInfo?.fiscalYearStartPeriodDate &&
      !!reportInfo?.fiscalYearEndPeriodDate
    ) {
      return `${moment(reportInfo?.fiscalYearStartPeriodDate).format('MMM DD, YYYY')} - ${moment(reportInfo?.fiscalYearEndPeriodDate).format('MMM DD, YYYY')}`;
      // return `${reportInfo?.fiscalYearStartPeriodDate} - ${reportInfo?.fiscalYearEndPeriodDate}`;
    } else {
      return '01/01/2023 - 01/01/2024';
    }
  };

  const getOCRReportTableData = (
    ocrData: Record<string, any>[]
  ): ocrReportTable[] => {
    const data = ocrData?.map((report) => ({
      reportType: `${report.reportInfo.type + ' -'} ${report.reportInfo.reportName}`,
      description: report.reportInfo.timeFrame,
      id: report.id,
      ocrReportData: report.ocrReportData,
      applicantId: report.applicantId,
      // applicationId: report.applicationId,
      reportInfo: report.reportInfo,
      applicantName: report.applicant.companyName,
      fiscalYear: getFiscalYearDate(report?.reportInfo),
      isAssigned: report.isAssigned,
      isOcrReportValid: report.isOcrReportValid,
      createdAt: report.createdAt,
      updatedAt: report.updatedAt,
      assignedAt: report.assignedAt,
      validatedAt: report.validatedAt,
      reportGeneratedId: report.reportGeneratedBy,
    }));
    return data;
  };

  const getOCRReportStatus = (row: any) => {
    const {
      createdAt,
      updatedAt,
      assignedAt,
      validatedAt,
      isOcrReportValid,
      isAssigned,
    } = row.original;
    if (!!validatedAt && isOcrReportValid) {
      return 'Validated';
    } else if (!!assignedAt && isAssigned) {
      return userType === 'broker'
        ? 'Assigned to Applicant'
        : userType === 'applicant'
          ? 'Assigned by Broker'
          : '';
    } else if (!!updatedAt) {
      return 'Draft';
    } else if (!!createdAt) {
      return 'Created';
    } else {
      return 'Created';
    }
  };

  const getReportType = (row: MRT_Row<ocrReportTable>) => {
    const { reportName } = row?.original?.reportInfo;
    const isCombinedFile = reportName?.includes('Combined File');
    const reportKey = isCombinedFile
      ? reportName?.split('-')[0]?.trim()
      : reportName;
    switch (reportKey) {
      case 'Account Receivable Aging': {
        return 'account-receivable';
      }
      case 'Account Payable Aging': {
        return 'account-payable';
      }
      case 'Balance Sheet': {
        return 'balance-sheet';
      }
      case 'Profit and Loss': {
        return 'profit-loss';
      }
      default:
        break;
    }
  };

  const columns = useMemo<MRT_ColumnDef<ocrReportTable>[]>(
    () => [
      {
        accessorKey: 'reportGeneratedId',
        header: 'ID',
        size: 2,
      },
      {
        accessorKey: 'applicantName',
        header: 'Applicant Name',
        size: 20,
      },
      {
        accessorKey: 'fiscalYear',
        header: 'Year',
        size: 20,
      },
      {
        accessorKey: 'reportType',
        header: 'Type',
        size: 20,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'updatedAtTime',
        id: 'updatedAtTime',
        header: 'Time',
        size: 20,
        maxSize: 20,
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { createdAt, updatedAt } = row?.original;
          // return moment(cell.getValue()).format('DD-MM-YYYY HH:MM A');
          return !!updatedAt
            ? moment(updatedAt).format('DD-MM-YYYY hh:mm A')
            : moment(createdAt).format('DD-MM-YYYY hh:mm A');
        },
      },
      {
        accessorKey: 'status',
        id: 'status',
        header: 'Status',
        size: 20,
        maxSize: 20,
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          console.log('row', row);
          return (
            <Badge
              color={'green'}
              variant="filled"
              size="md"
              radius={'sm'}
              h={'20px'}
            >
              {getOCRReportStatus(row)}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    // layoutMode: 'grid',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => row?.id?.toString() ?? '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: false ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      className: 'ocr_report_table',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    // mantineTableHeadCellProps: {
    //   className: 'custom-column',
    // },
    mantineTableHeadCellProps: {
      className: 'custom-column',
      // sx: {
      //   '& .mantine-TableHeadCell-Content': {
      //     justifyContent: 'space-between',
      //   },
      // },
    },
    enableRowActions: true,
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 10, //make actions column wider
        minSize: 10,
        maxSize: 10,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
            boxShadow: 'inset 0 0 0 1px lightgray',
          },
        },
      },
    },
    renderRowActions: ({ row }) => (
      <Flex gap={'lg'} flexWrap={'wrap'}>
        <Tooltip label="View" position="right">
          <ActionIcon
            onClick={() => {
              console.log('row', row);
              const { applicantId, reportInfo } = row?.original;
              const reportType = getReportType(row);
              //   dispatch(removeOcrReportSliceType({}));
              const navigateUrl = `/${userType}/report/${reportType}?reportTabOcrId=${row?.id}&applicantId=${applicantId}&reportType=${reportInfo.type}&reportName=${reportInfo.reportName}&reportTimeFrame=${reportInfo.timeFrame}`;
              navigate(navigateUrl);
            }}
            size={'sm'}
          >
            <IconEye />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbar: ({ table }) => {
      console.log('tableData', tableData);

      return (
        <Flex direction={'column'}>
          <Flex
            py={1}
            alignItems={'center'}
            flexWrap={'wrap'}
            justifyContent={'flex-end'}
          >
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box>
          </Flex>
          {tableContentLoader && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: tableContentLoader,
      isLoading: tableContentLoader,
    },
  });

  return (
    <Box maxW={'1550px'} w={'100%'} py={10} mx={'auto'}>
      <MantineReactTable table={table} key={'ocr-report-list'} />
    </Box>
  );
};

export default ReportTabOCRListTable;
