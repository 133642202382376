import { Box, Button, Heading, Text, Tooltip } from '@chakra-ui/react';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { IconCircleX } from '@tabler/icons-react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { CiLock, CiUnlock } from 'react-icons/ci';
import { FcApproval } from 'react-icons/fc';
import { useAppToast } from 'src/hooks';
import {
  BrokerAllData,
  brokerStatusChange,
} from 'src/Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import AssignPermissionBrokerModal from './Component/AssignPermissionBrokerModal';

export interface BrokerageManagementTable {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: number;
  applicationsUsed: number;
  brokerId: number;
  brokerageId: number;
  brokerageStatus: string;
  id: string;
  draftApplicationCount: number;
}

const BrokerageManagement = () => {
  const dispatch = useAppDispatch();
  const brokerageSlice = useAppSelector((state) => state?.brokerage);
  const toast = useAppToast();
  const [loader, setLoader] = useState<{
    contentLoader: boolean;
  }>({ contentLoader: false });
  const [tableData, setTableData] = useState<BrokerageManagementTable[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedRow, setSelectedRow] =
    useState<MRT_Row<BrokerageManagementTable> | null>(null);
  useEffect(() => {
    dispatch(BrokerAllData() as any);
  }, []);

  useEffect(() => {
    const { status, type, brokerManager, error, msg, errorResponse } =
      brokerageSlice;
    console.log('brokerageSlice', brokerageSlice);

    switch (status) {
      case 'loading': {
        if (type === 'GET_ALL_BROKER_BY_BROKERAGE') {
          setLoader((prev) => ({ ...prev, contentLoader: true }));
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_ALL_BROKER_BY_BROKERAGE' && !!brokerManager.length) {
          const brokerListTableData = getBrokerListTableData(brokerManager);
          setTableData(brokerListTableData);
          setLoader((prev) => ({ ...prev, contentLoader: false }));
        }
        if (type === 'BROKER_STATUS_UPDATE_BY_BROKERAGE') {
          toast({
            title: msg,
            status: 'success',
          });
          dispatch(BrokerAllData() as any);
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_ALL_BROKER_BY_BROKERAGE') {
          if (!!errorResponse && errorResponse?.type === 'INFO') {
            toast({
              title: error,
              status: 'info',
            });
          } else {
            toast({
              title: error,
              status: 'error',
            });
          }
          setLoader((prev) => ({ ...prev, contentLoader: false }));
        }
        if (type === 'BROKER_STATUS_UPDATE_BY_BROKERAGE') {
          toast({
            title: error,
            status: 'error',
          });
        }
        break;
      }
      default:
        break;
    }
  }, [brokerageSlice.status, brokerageSlice.type]);

  const getBrokerListTableData = (brokerListData: Record<string, any>[]) => {
    const Tdata = brokerListData?.map((el) => ({
      firstName: el?.firstName,
      lastName: el?.lastName,
      email: el.email,
      contactNumber: el.contactNumber,
      applicationsUsed: el?.applicationCount,
      brokerId: el?.brokerId,
      brokerageId: el?.brokerageId,
      brokerageStatus: el?.brokerageStatus ?? 'pending',
      id: el.brokerId.toString(),
      draftApplicationCount: el?.draftCount,
    }));

    return Tdata;
  };

  const handleChangeStatus = (
    row: MRT_Row<BrokerageManagementTable>,
    status: string
  ) => {
    const { brokerId } = row?.original;
    if (!['decline', 'revoke'].includes(status)) {
      dispatch(
        brokerStatusChange({ oldBrokerId: brokerId, brokerageStatus: status })
      );
    } else {
      setSelectedRow(row);
      open();
    }
  };
  // const handleRejectReq = (row: MRT_Row<BrokerageManagementTable>) => {
  //   const { brokerId } = row?.original;
  //   dispatch(
  //     brokerStatusChange({ brokerid: brokerId, brokerageStatus: 'decline' })
  //   );
  // };

  const getActionsCellJSX = (row: MRT_Row<BrokerageManagementTable>) => {
    const { brokerageStatus } = row?.original;
    switch (brokerageStatus) {
      case 'pending': {
        return (
          <Box display={'flex'} gap={1}>
            <Tooltip label="Approve" placement={'top'} hasArrow>
              <Box
                minW={'30px'}
                cursor={'pointer'}
                onClick={() => handleChangeStatus(row, 'approved')}
              >
                <FcApproval size={20} />
              </Box>
            </Tooltip>
            {tableData?.length !== 1 && (
              <Tooltip label="Decline" placement={'top'} hasArrow>
                <Box
                  minW={'30px'}
                  cursor={'pointer'}
                  onClick={() => handleChangeStatus(row, 'decline')}
                >
                  <IconCircleX color="red" size={20} />
                </Box>
              </Tooltip>
            )}
          </Box>
        );
      }
      case 'grant':
      case 'revoke':
      case 'approved':
      case 'decline': {
        return (
          <Box display={'flex'} gap={1}>
            <Tooltip label="Revoke Permission" placement={'top'} hasArrow>
              <Button
                minW={'auto'}
                display={'block'}
                padding={0}
                width={30}
                height={30}
                variant={'ghost'}
                cursor={'pointer'}
                onClick={() => handleChangeStatus(row, 'revoke')}
                isDisabled={
                  brokerageStatus === 'decline' ||
                  brokerageStatus === 'revoke' ||
                  tableData?.length === 1
                }
              >
                <CiLock size={20} />
              </Button>
            </Tooltip>
            <Tooltip label="Grant Permission" placement={'top'} hasArrow>
              <Button
                minW={'auto'}
                display={'block'}
                padding={0}
                w={30}
                h={30}
                variant={'ghost'}
                cursor={'pointer'}
                isDisabled={
                  brokerageStatus === 'approved' || brokerageStatus === 'grant'
                }
                onClick={() => handleChangeStatus(row, 'grant')}
              >
                <CiUnlock size={20} />
              </Button>
            </Tooltip>
          </Box>
        );
      }
      default:
        break;
    }
  };

  const columns = useMemo<MRT_ColumnDef<BrokerageManagementTable>[]>(
    () => [
      {
        accessorKey: 'brokerName',
        header: 'Broker Name',
        enableColumnActions: false,
        enableSorting: false,
        // id: 'brokerName',
        // accessorFn: (row) => (row ? `${row.firstName} ${row.lastName}` : ''),
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { firstName, lastName } = row?.original;
          return <Text>{firstName + ' ' + lastName}</Text>;
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'contactNumber',
        header: 'Contact Number',
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'brokerageStatus',
        id: 'brokerageStatus',
        header: 'Status',
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }: { cell: any }) => (
          <Text textTransform={'capitalize'}>
            {cell.getValue() === 'grant'
              ? 'Permission Granted'
              : cell.getValue() === 'revoke'
                ? 'Permission Revoked'
                : cell.getValue()}
          </Text>
        ),
      },
      {
        accessorKey: 'applicationsUsed',
        header: 'No. of Applications Used',
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'draftApplicationCount',
        header: 'No. of Active Applications',
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          return <>{getActionsCellJSX(row)}</>;
        },
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    // layoutMode: 'grid',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => row?.id?.toString() ?? '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: false ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      className: 'ocr_report_table',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 45,
      },
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    enableRowActions: false,
    enableTopToolbar: false,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    // renderTopToolbar: ({ table }) => {
    //   console.log('tableData', tableData);

    //   return (
    //     <Flex direction={'column'}>
    //       <Flex
    //         py={1}
    //         alignItems={'center'}
    //         flexWrap={'wrap'}
    //         justifyContent={'flex-end'}
    //       >
    //         <Flex alignItems={'center'} gap={4} p={3}>
    //           <MRT_GlobalFilterTextInput table={table} />
    //           <MRT_ToolbarInternalButtons table={table} />
    //         </Flex>
    //         <Divider
    //           size="md"
    //           orientation="vertical"
    //           h={40}
    //           style={{ alignSelf: 'center' }}
    //         />
    //         <Box className="pagination-wrapper">
    //           <MRT_TablePagination position="top" table={table} />
    //         </Box>
    //       </Flex>
    //       {tableContentLoader && <Progress value={100} animate={true} />}
    //     </Flex>
    //   );
    // },
    state: {
      isLoading: loader.contentLoader,
    },
  });

  return (
    <>
      <ModalsProvider>
        <Heading
          p="4"
          fontSize={'24px'}
          fontWeight={'600'}
          borderBottomWidth="1px"
        >
          Broker Management
        </Heading>
        <Box maxW={'1550px'} w={'100%'} p={10} mx={'auto'}>
          <MantineReactTable table={table} key={'ocr-report-list'} />
        </Box>
      </ModalsProvider>
      <AssignPermissionBrokerModal
        isOpen={opened}
        onClose={close}
        selectedRow={selectedRow}
        tableData={tableData}
      />
    </>
  );
};

export default BrokerageManagement;
