import { Box, Heading } from '@chakra-ui/react';
import { Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { getAllPlans } from 'src/Redux/Plans/slice';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import PurchaseInfo from '../Billing/index';
import Plans from '../Plans/index';
import SuretyPlans from '../Plans/SuretyPlans';
import { plnasAndPurchaseTabOptios } from './data';

export type TabTypes = 'Plans' | 'Purchases' | 'Surety';

const PlansAndPurchase = () => {
  const dispatch = useAppDispatch();
  const plansStore = useAppSelector((state) => state.plans);
  const [activeTab, setActiveTab] = useState<TabTypes>('Plans');

  useEffect(() => {
    // dispatch(getFaqs());
    // if (!plansStore.faqsData?.length) dispatch(getFaqs());
    if (!plansStore.plans.length) dispatch(getAllPlans());
  }, []);

  return (
    <>
      <Box>
        <Heading
          p="4"
          fontSize={'24px'}
          fontWeight={'600'}
          borderBottomWidth="1px"
        >
          Plan & Purchases
        </Heading>
        <Tabs
          value={activeTab}
          onTabChange={(val) => {
            if (val) {
              setActiveTab(val as TabTypes);
            }
          }}
          my={'10px'}
          styles={{
            tabLabel: { fontSize: '14px' },
            tab: { paddingTop: '10px', paddingBottom: '10px' },
          }}
        >
          <Tabs.List
            style={{
              border: 'unset',
              width: '100%',
              maxWidth: '300px',
            }}
            grow={true}
          >
            {plnasAndPurchaseTabOptios?.map((tab, index) => {
              return (
                <Tabs.Tab key={index?.toString()} value={tab?.value}>
                  {tab?.label}
                </Tabs.Tab>
              );
            })}
          </Tabs.List>
        </Tabs>
      </Box>
      <Box>
        {activeTab === 'Plans' && <Plans />}
        {activeTab === 'Purchases' && (
          <PurchaseInfo setActiveTab={setActiveTab} />
        )}
        {activeTab === 'Surety' && <SuretyPlans />}
      </Box>
    </>
  );
};

export default PlansAndPurchase;
