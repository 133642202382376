import {
  Box,
  Container,
  Divider,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IStripePlanBase,
  planCategory,
  planPayType,
  stripeMetadata,
  stripePrice,
} from 'src/Redux/Plans/state';
import {
  getCurrencyDigit,
  getPlanFeatureList,
  getPlanName,
} from 'src/utils/helpers';
import PaymentForm from './PaymentForm';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    plan,
    planPayType,
    planCategory,
  }: {
    plan: IStripePlanBase;
    planPayType: planPayType;
    planCategory: planCategory;
  } = location.state;
  // const plan = (location.state as { plan?: IStripePlanBase,planPayType: planPayType })?.plan;
  // const planPayType = (location.state as { plan?: IStripePlanBase,planPayType: planPayType })?.plantype;
  const [isVerifying, setIsVerifying] = useState(true);
  const {
    name,
    prices,
    metadata,
  }: { name: string; prices: stripePrice[]; metadata: stripeMetadata } = plan;

  const planPriceByPayType = Math.trunc(prices?.[0]?.unit_amount / 100);
  const planPriceMonthly =
    planPayType === 'monthly'
      ? planPriceByPayType
      : Math.trunc(planPriceByPayType / 12);

  useEffect(() => {
    if (!plan) navigate('/brokerage/plans');
  }, [plan]);

  const handleProceedToPayment = () => {
    setIsVerifying(false);
  };

  const handleGoBack = () => {
    setIsVerifying(true);
  };

  return (
    <Container
      maxW="container.md"
      mt={8}
      display={'flex'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      gap={4}
    >
      <PaymentForm onBack={handleGoBack} />
      <Box
        borderWidth="1px"
        borderRadius={'xl'}
        p={6}
        boxShadow="lg"
        bg={'white'}
        minW={330}
        shadow="base"
        borderColor={'gray.300'}
      >
        <VStack spacing={4} align="start">
          <Text as={'h1'} fontSize={18} lineHeight={2} fontWeight={600} m={0}>
            Selected Plan
          </Text>
          <Divider borderColor={'gray.700'} />
          <Box>
            <Text fontWeight="bold" fontSize="2xl">
              {getPlanName(name)}
            </Text>
            <HStack>
              <Text fontSize="2xl" fontWeight="600" textTransform={'uppercase'}>
                {prices[0]?.currency}
              </Text>
              <Text fontSize="3xl" fontWeight="900">
                {getCurrencyDigit({
                  number: planPriceMonthly,
                  options: {
                    currency: prices[0]?.currency,
                  },
                })}
              </Text>
              <Text fontSize="2xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <Divider borderColor={'gray.700'} />
          <Heading as="h3" size="md">
            Features
          </Heading>
          <List spacing={3}>
            {planCategory === 'subscription' ? (
              getPlanFeatureList(metadata, planPayType)?.map(
                (feature, index) => (
                  <ListItem key={index}>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    {feature}
                  </ListItem>
                )
              )
            ) : (
              <ListItem key={'one-time-app'}>
                <ListIcon as={FaCheckCircle} color="green.500" />1 application
                included
              </ListItem>
            )}
          </List>
          <Divider borderColor={'gray.700'} />
        </VStack>
      </Box>
    </Container>
  );
};

export default Checkout;
