import {
  Box,
  Button,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { purchasePlan, purchaseSuretyPlan } from 'src/Redux/Plans/slice';
import {
  IStripePlanBase,
  planCategory,
  planPayType,
  stripeMetadata,
  stripePrice,
  SubscriptionPlanTypes,
} from 'src/Redux/Plans/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  getCurrencyDigit,
  getPlanFeatureList,
  getPlanName,
} from 'src/utils/helpers';
import PriceWrapper from './PriceWrapper';

interface Props {
  plan: IStripePlanBase;
}

const SuretyPlanCard = ({ plan }: Props) => {
  const textColor = useColorModeValue('gray.900', 'gray.300');
  const dispatch = useAppDispatch();
  const plansStore = useAppSelector((state) => state.plans);
  const [loadingPlan, setLoadingPlan] = useState<string | null>(null);

  useEffect(() => {
    const { status, type, stripeURL } = plansStore;

    switch (status) {
      // case 'loading': {
      //   if (type === 'PURCHASE_SURETY_PLAN') break;
      // }
      case 'succeed': {
        if (type === 'PURCHASE_SURETY_PLAN' && stripeURL) {
          setLoadingPlan(null);
        }
        break;
      }

      case 'failed': {
        if (type === 'PURCHASE_SURETY_PLAN') {
          setLoadingPlan(null);
        }
        break;
      }
    }
  }, [plansStore.status]);

  const handleBuyNow = async () => {
    // navigate('/brokerage/checkout', {
    //   state: { plan, planPayType, planCategory },
    // });
    try {
      const { prices }: { prices: stripePrice[] } = plan;
      setLoadingPlan(prices[0]?.product);
      await dispatch(
        purchaseSuretyPlan({
          productId: prices[0]?.product,
          default_price: prices[0]?.id,
        })
      );
    } catch (error) {
      console.log('error', error);
    }
  };
  const {
    name,
    prices,
    metadata,
  }: { name: string; prices: stripePrice[]; metadata: stripeMetadata } = plan;
  const isPopularPlan =
    getPlanName(name)?.trim() === SubscriptionPlanTypes?.GROWTH?.trim();

  return (
    <PriceWrapper>
      <Box
        position="relative"
        height={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-around'}
        px={4}
        py={8}
        maxW={220}
      >
        {isPopularPlan && (
          <Box
            position="absolute"
            top="0"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Text
              textTransform="uppercase"
              bg="#FFA011"
              px={2}
              py={1}
              color={textColor}
              fontSize="xs"
              fontWeight="600"
              rounded="xl"
            >
              Most Popular
            </Text>
          </Box>
        )}
        <Box mb={4}>
          <Text fontWeight="500" fontSize="2xl">
            {getPlanName(name)}
          </Text>
          <HStack>
            <Text fontSize="3xl" fontWeight="900">
              {getCurrencyDigit({
                number: prices[0]?.unit_amount / 100,
                options: {
                  currency: prices[0]?.currency,
                },
              })}
            </Text>
            {/* {plantype && (
              <Text fontSize="2xl" color="gray.500">
                /{plantype === 'monthly' ? 'month' : 'year'}
              </Text>
            )} */}
          </HStack>
        </Box>
        {/* <List spacing={3} mb={6}>
          {planCategory === 'subscription' ? (
            getPlanFeatureList(metadata, planPayType)?.map((feature, index) => (
              <ListItem key={index}>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {feature}
              </ListItem>
            ))
          ) : (
            <ListItem key={'one-time-app'}>
              <ListIcon as={FaCheckCircle} color="green.500" />1 application
              included
            </ListItem>
          )}
        </List> */}
        <Button
          w="100%"
          colorScheme="#114684"
          bgColor={isPopularPlan ? '#114684' : ''}
          variant={isPopularPlan ? 'solid' : 'outline'}
          sx={{
            borderColor: '#114684',
            color: isPopularPlan ? 'white' : '#114684',
            '&:hover': {
              bg: '#114684',
              color:
                loadingPlan === prices[0]?.product && !isPopularPlan
                  ? '#114684'
                  : 'white',
            },
          }}
          onClick={handleBuyNow}
          isLoading={loadingPlan === prices[0]?.product}
          loadingText={'BUY NOW'}
        >
          BUY NOW
        </Button>
      </Box>
    </PriceWrapper>
  );
};

export default SuretyPlanCard;
