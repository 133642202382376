import { BondApplicationAttributes } from 'src/Pages/BrokerPages/BondApplicationForm/data';
import { BondApplicationAPIData } from 'src/Redux/Broker/BondApplicationForm/state';
import { initialBondApplicationState } from '../../../BondApplicationForm/data';
export interface StepperBondApplicationFormSchema
  extends BondApplicationAttributes {
  assignToApplicant: boolean;
}

export const initialStepperBondApplicationFormState: StepperBondApplicationFormSchema =
  {
    ...initialBondApplicationState,
    assignToApplicant: false,
  };

export interface StepperBondApplicationAPISchema
  extends BondApplicationAPIData {
  assignToApplicant: boolean;
}
