import { Box, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { getOcrHistory } from 'src/Redux/OcrReports/slice';
import { OCRhistoryInfo } from 'src/Redux/OcrReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';

const OCRHistoryRecord = ({
  details,
  index,
}: {
  details: OCRhistoryInfo;
  index: number;
}) => {
  return (
    <Flex direction={'column'} id={details?.id} gap={2}>
      <Text>
        {index + 1}. {details?.message}
      </Text>
      {details?.changes &&
        details?.changes.map((item) => (
          <Flex gap={3} alignItems={'center'}>
            <Tooltip label="Column Name">
              <Text
                style={{
                  backgroundColor: '#fdbfe2',
                  padding: '1px 8px',
                  borderRadius: '3px',
                  color: '#a41752',
                  fontSize: '12px',
                  fontWeight: '700',
                }}
              >
                {item?.columnName}
              </Text>
            </Tooltip>

            <Flex
              gap={3}
              alignItems={'center'}
              style={{
                backgroundColor: '#99f6e4',
                padding: '1px 8px',
                borderRadius: '3px',
                color: '#155f59',
                fontSize: '12px',
                fontWeight: '700',
              }}
            >
              <Tooltip label="Old Value">
                <Text>
                  {item?.oldvalue === null || item?.oldvalue === ''
                    ? 'Nil'
                    : item?.oldvalue}
                </Text>
              </Tooltip>

              <FaLongArrowAltRight />
              <Tooltip label="New Value">
                <Text>{item?.newValue}</Text>
              </Tooltip>
            </Flex>
          </Flex>
        ))}
      {/* <Text color={'gray'} fontSize={'14px'}>
        {getDateFormat(details?.date, 'MMMM DD,YYYY hh:mm A')}
      </Text> */}
    </Flex>
  );
};

const OcrReportHistoryModal = ({
  isOpen,
  onClose,
  ocrReportId,
  ocrReportData,
}: {
  isOpen: boolean;
  onClose: () => void;
  ocrReportId: number;
  ocrReportData: Record<string, any>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ocrHistory, setOcrHistory] = useState<OCRhistoryInfo[]>([]);
  const dispatch = useAppDispatch();
  const ocrReportSlice = useAppSelector((state) => state.ocrReportSlice);
  console.log('ocrReportData', ocrReportData);

  useEffect(() => {
    if (isOpen) {
      dispatch(getOcrHistory({ ocrReportId }));
    }
  }, [isOpen]);

  useEffect(() => {
    const { status, type, ocrHistoryData } = ocrReportSlice;
    switch (status) {
      case 'loading': {
        if (type === 'GET_OCR_REPORT_HISTORY') {
          setLoading(true);
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_OCR_REPORT_HISTORY') {
          console.log('ocrHistoryData', ocrHistoryData);
          setOcrHistory(ocrHistoryData);
          setLoading(false);
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_OCR_REPORT_HISTORY') {
          setLoading(false);
          setOcrHistory([]);
        }
        break;
      }
      default:
        break;
    }
  }, [ocrReportSlice.status]);

  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="History"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'625px'}
      zIndex={300}
      styles={{
        title: {
          fontWeight: 'bold',
          fontSize: '18px',
        },
        header: {
          borderBottom: '1px solid #E2E8F0',
        },
        content: {
          maxHeight: 'calc(100vh - 50px)',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
        body: {
          overflow: 'auto',
        },
      }}
    >
      <Box margin={'10px'} maxW={'625px'} maxH={'600px'}>
        <Flex direction={'column'} gap={2} mb={3}>
          <Flex gap={1}>
            <Text textAlign="start" color="black" fontWeight={'600'}>
              Report Name:
            </Text>
            <Text>{ocrReportData?.reportInfo?.reportName}</Text>
          </Flex>
        </Flex>
        {loading ? (
          <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
            <Spinner size={'md'} />
          </Flex>
        ) : ocrHistory?.length > 0 ? (
          <Flex gap={2} direction={'column'}>
            {ocrHistory?.map((item: any, index: number) => (
              <OCRHistoryRecord details={item} index={index} />
            ))}
          </Flex>
        ) : (
          <Box marginBottom={'10px'}>No Data Found</Box>
        )}
      </Box>
    </Modal>
  );
};

export default OcrReportHistoryModal;
