export interface BondRequestRejectSchema {
  status: 'rejected';
  reasonOfRejection: string[] | null;
  reasonOfRejectionOther: string | null;
}
export const initialBondRequestRejectState: BondRequestRejectSchema = {
  status: 'rejected',
  reasonOfRejection: null,
  reasonOfRejectionOther: null,
};

export const rejectBondRequestOptions = [
  {
    label: 'Principal / Applicant Did Not Have the Lowest Bid',
    value: 'lowest_bid',
    isOther: false,
  },
  {
    label:
      'Principal / Applicant Was Not Awarded the Contract for Reason other than Lowest Bid',
    value: 'not_awarded',
    isOther: true,
  },
  {
    label: 'Project Was Cancelled',
    value: 'project_cancelled',
    isOther: false,
  },
  {
    label: 'Final Bond Not Required',
    value: 'final_bond_not_required',
    isOther: false,
  },
  {
    label: 'Principal / Applicant Declined the Contract',
    value: 'declined_contract',
    isOther: true,
  },
  {
    label: 'Principal / Applicant Withdrew Bid',
    value: 'withdrew_bid',
    isOther: false,
  },
  {
    label:
      'Principal / Applicant Opted for Another Brokerage / Bonding Provider',
    value: 'opted_other_brokerage',
    isOther: false,
  },
  {
    label: 'Principal / Applicant Could Not Secure Financing',
    value: 'could_not_secure_financing',
    isOther: false,
  },
  {
    label: 'Principal / Applicant had Insufficient Collateral',
    value: 'insufficient_collateral',
    isOther: false,
  },
  {
    label: 'Project Owner / Obligee Rejected Bond',
    value: 'owner_rejected_bond',
    isOther: true,
  },
  {
    label: 'Scope of Work Changed',
    value: 'scope_of_work_changed',
    isOther: false,
  },
  {
    label: 'Bond Application Incomplete or Errors in Bid Submission',
    value: 'incomplete_application',
    isOther: true,
  },
  { label: 'Project Delayed', value: 'project_delayed', isOther: false },
  { label: 'Project Reassigned', value: 'project_reassigned', isOther: false },
  {
    label: 'Bonding Capacity Exceeded',
    value: 'capacity_exceeded',
    isOther: true,
  },
  { label: 'Legal or Regulatory Issues', value: 'legal_issues', isOther: true },
  { label: 'Other (Specify)', value: 'other', isOther: true },
];

export interface BondRequestApproveSchema {
  status: 'approved';
  approvedDate: string | null;
  approver: string | null;
  approvalStamp: string | null;
}
export const initialBondRequestApproveState: BondRequestApproveSchema = {
  status: 'approved',
  approvedDate: null,
  approver: null,
  approvalStamp: null,
};
