import { Accordion } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  getAccordianSectionName,
  getFormikStateOfAccordianState,
  getHistoryDataOfAccordianState,
} from '../../utils';
import AccordianContent from './AccordianContent';
import GuaranteesForOtherPartiesAccordian from './GuaranteesForOtherPartiesAccordian';

const LegalFormAccordian = ({
  formik,
  accordianState,
  accordianName,
  accordianSectionName,
  isOpen,
  accordianParentSectionName = '',
  fileList,
  setFileList,
  handleRemoveFile,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
  dataKey,
}: {
  formik: Record<string, any>;
  accordianState: any[];
  accordianName: string;
  accordianSectionName?: string;
  isOpen?: boolean;
  accordianParentSectionName?: string;
  fileList?: Record<string, any>;
  setFileList?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleRemoveFile?: (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string,
    arg3: string
  ) => void;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
  dataKey: 'hasGuarantees' | 'hasRelatedCompanies' | string;
}) => {
  const [defaultOpenAcc, setDefaultOpenAcc] = useState<string[] | undefined>(
    undefined
  );
  // const [accordianSection, setAccordianSection] = useState<string | null>(null);

  // const defaultOpenAccSection = useCallback(() => {
  //   const formikStateOfAcc = getFormikStateOfAccordianState[accordianName];
  //   const historyDataOfAcc = getHistoryDataOfAccordianState(
  //     currentHistoryData?.updatedData,
  //     formikStateOfAcc
  //   );
  //   return historyDataOfAcc ? Object.keys(historyDataOfAcc) : [];
  // }, [JSON.stringify(currentHistoryData?.updatedData)]);

  // useEffect(() => {
  //   setAccordianSection(accordianSectionName ?? null);
  // }, [accordianSectionName]);

  useEffect(() => {
    const formikStateOfAcc = getFormikStateOfAccordianState[accordianName];
    const historyDataOfAcc = getHistoryDataOfAccordianState(
      currentHistoryData?.updatedData,
      formikStateOfAcc
    );
    const defaultOpenAccSection = historyDataOfAcc
      ? Object.keys(historyDataOfAcc)
      : [];

    setDefaultOpenAcc(defaultOpenAccSection);

    return () => {};
  }, [JSON.stringify(currentHistoryData?.updatedData)]);

  useEffect(() => {
    if (formik?.values[dataKey] != null) {
      const validKey = [];
      const keys = Object.keys(formik?.values[dataKey]);

      // Iterate through each key except hasGuarantees
      for (let key of keys) {
        // Ignore the hasGuarantees key
        if (formik?.values[dataKey][key] !== null) {
          validKey.push(key);
        }
      }

      setDefaultOpenAcc(validKey);
    }
  }, [formik?.values]);

  return (
    <>
      <Accordion
        variant="filled"
        multiple={true}
        className="help_accordian_section"
        value={defaultOpenAcc}
        onChange={(value) => {
          setDefaultOpenAcc(value);
        }}
      >
        <>
          {accordianName === 'guaranteesForOtherPartiesAcc' ||
          accordianName === 'relatedCompaniesAcc' ? (
            <>
              {accordianState?.map((item: any, index: number) => {
                const isUploadField = item?.subSection
                  ?.map((item: any) =>
                    item?.fields?.filter((self: any) => self?.type === 'file')
                  )
                  .flat();

                if (isOpen && isUploadField?.length > 0) {
                  return (
                    <Accordion.Item
                      key={item?.id}
                      value={item?.name}
                      className={'help_item'}
                    >
                      <Accordion.Control
                      // disabled={isOpen && item.name !== accordianSectionName}
                      >
                        {getAccordianSectionName[item?.name]}
                      </Accordion.Control>
                      <Accordion.Panel className="accordion-content">
                        <GuaranteesForOtherPartiesAccordian
                          formik={formik}
                          AccordianSection={item?.subSection}
                          accordianSectionName={item?.name}
                          isOpen={isOpen ?? false}
                          accordianState={accordianState}
                          accordianName={accordianName}
                          fileList={fileList}
                          setFileList={setFileList}
                          handleRemoveFile={handleRemoveFile}
                          isHistoryMode={isHistoryMode}
                          isReadOnly={isReadOnly}
                          currentHistoryData={currentHistoryData}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  );
                } else {
                  return (
                    <Accordion.Item
                      key={item?.id}
                      value={item?.name}
                      className={'help_item'}
                    >
                      <Accordion.Control
                      // disabled={isOpen && item.name !== accordianSectionName}
                      >
                        {getAccordianSectionName[item?.name]}
                      </Accordion.Control>
                      <Accordion.Panel className="accordion-content">
                        <GuaranteesForOtherPartiesAccordian
                          formik={formik}
                          AccordianSection={item?.subSection}
                          accordianSectionName={item?.name}
                          isOpen={isOpen ?? false}
                          accordianState={accordianState}
                          accordianName={accordianName}
                          isHistoryMode={isHistoryMode}
                          isReadOnly={isReadOnly}
                          currentHistoryData={currentHistoryData}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  );
                }
              })}
            </>
          ) : accordianName === 'changesInManagementAcc' ? (
            <>
              {accordianState?.map((item: any, index: number) => {
                const isUploadField = item?.fields?.filter(
                  (self: any) => self?.type === 'file'
                );
                if (isOpen && isUploadField?.length > 0) {
                  return (
                    <Accordion.Item
                      key={item?.id}
                      value={item?.name}
                      className={'help_item'}
                    >
                      <Accordion.Control
                      // disabled={isOpen && item.name !== accordianSectionName}
                      >
                        {getAccordianSectionName[item?.name]}
                      </Accordion.Control>
                      <Accordion.Panel className="accordion-content">
                        <AccordianContent
                          formik={formik}
                          AccordianFields={item?.fields}
                          accordianSectionName={item?.name}
                          isOpen={isOpen ?? false}
                          accordianState={accordianState}
                          accordianName={accordianName}
                          fileList={fileList}
                          setFileList={setFileList}
                          handleRemoveFile={handleRemoveFile}
                          isHistoryMode={isHistoryMode}
                          isReadOnly={isReadOnly}
                          currentHistoryData={currentHistoryData}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  );
                } else {
                  if (item?.name === 'changeInOwnership') {
                    return (
                      <Accordion.Item
                        key={item?.id}
                        value={item?.name}
                        className={'help_item'}
                      >
                        <Accordion.Control
                        // disabled={isOpen && item.name !== accordianSectionName}
                        >
                          {getAccordianSectionName[item?.name]}
                        </Accordion.Control>
                        <Accordion.Panel className="accordion-content">
                          <GuaranteesForOtherPartiesAccordian
                            formik={formik}
                            AccordianSection={item?.subSection}
                            accordianSectionName={item?.name}
                            isOpen={isOpen ?? false}
                            accordianState={accordianState}
                            accordianName={accordianName}
                            isHistoryMode={isHistoryMode}
                            isReadOnly={isReadOnly}
                            currentHistoryData={currentHistoryData}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    );
                  } else {
                    return (
                      <Accordion.Item
                        key={item?.id}
                        value={item?.name}
                        className={'help_item'}
                      >
                        <Accordion.Control
                        // disabled={isOpen && item.name !== accordianSectionName}
                        >
                          {getAccordianSectionName[item?.name]}
                        </Accordion.Control>
                        <Accordion.Panel className="accordion-content">
                          <AccordianContent
                            formik={formik}
                            AccordianFields={item?.fields}
                            accordianSectionName={item?.name}
                            isOpen={isOpen ?? false}
                            accordianState={accordianState}
                            accordianName={accordianName}
                            isHistoryMode={isHistoryMode}
                            isReadOnly={isReadOnly}
                            currentHistoryData={currentHistoryData}
                          />
                        </Accordion.Panel>
                      </Accordion.Item>
                    );
                  }
                }
              })}
            </>
          ) : (
            <>
              {accordianState?.map((item: any, index: number) => {
                const isUploadField = item?.fields?.filter(
                  (self: any) => self?.type === 'file'
                );
                if (isOpen && isUploadField?.length > 0) {
                  return (
                    <Accordion.Item
                      key={item?.id}
                      value={item?.name}
                      className={'help_item'}
                    >
                      <Accordion.Control
                      // disabled={isOpen && item.name !== accordianSectionName}
                      >
                        {getAccordianSectionName[item?.name]}
                      </Accordion.Control>
                      <Accordion.Panel className="accordion-content">
                        <AccordianContent
                          formik={formik}
                          AccordianFields={item?.fields}
                          accordianSectionName={item?.name}
                          isOpen={isOpen ?? false}
                          accordianState={accordianState}
                          accordianName={accordianName}
                          fileList={fileList}
                          setFileList={setFileList}
                          handleRemoveFile={handleRemoveFile}
                          isHistoryMode={isHistoryMode}
                          isReadOnly={isReadOnly}
                          currentHistoryData={currentHistoryData}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  );
                } else {
                  return (
                    <Accordion.Item
                      key={item?.id}
                      value={item?.name}
                      className={'help_item'}
                    >
                      <Accordion.Control
                      // disabled={isOpen && item.name !== accordianSectionName}
                      >
                        {getAccordianSectionName[item?.name]}
                      </Accordion.Control>
                      <Accordion.Panel className="accordion-content">
                        <AccordianContent
                          formik={formik}
                          AccordianFields={item?.fields}
                          accordianSectionName={item?.name}
                          isOpen={isOpen ?? false}
                          accordianState={accordianState}
                          accordianName={accordianName}
                          isHistoryMode={isHistoryMode}
                          isReadOnly={isReadOnly}
                          currentHistoryData={currentHistoryData}
                        />
                      </Accordion.Panel>
                    </Accordion.Item>
                  );
                }
              })}
            </>
          )}
        </>
      </Accordion>
    </>
  );
};

export default LegalFormAccordian;
