import { Box } from '@chakra-ui/react';
import AccordianInputLabel from './AccordianInputLabel';
import AccordianUpload from './AccordianUpload';

const AccordianFieldWithUpload = ({
  label,
  name,
  value,
  onChange,
  type,
  uploadState,
  formik,
  accordianSectionName,
  isOpen,
  accordianState,
  accordianName,
  accordianParentSectionName,
  fileList,
  setFileList,
  handleRemoveFile,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
}: {
  label: string;
  name: string;
  value: {
    link: string;
    linkId: string;
    urlKey: string;
  }[];
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type: string;
  uploadState: string;
  formik: Record<string, any>;
  accordianSectionName: string;
  isOpen: boolean;
  accordianState: any[];
  accordianName: string;
  accordianParentSectionName: string;
  fileList?: Record<string, any>;
  setFileList?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleRemoveFile?: (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string,
    arg3: string
  ) => void;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
}) => {
  return (
    <Box
      flex={{ base: '1', md: '1' }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      gap={'5px'}
    >
      <AccordianInputLabel label={label} />

      <AccordianUpload
        uploadState={uploadState}
        formik={formik}
        name={name}
        value={value}
        accordianSectionName={accordianSectionName}
        isOpen={isOpen}
        accordianState={accordianState}
        accordianName={accordianName}
        accordianParentSectionName={accordianParentSectionName}
        fileList={fileList}
        setFileList={setFileList}
        handleRemoveFile={handleRemoveFile}
        isHistoryMode={isHistoryMode}
        isReadOnly={isReadOnly}
        currentHistoryData={currentHistoryData}
      />
    </Box>
  );
};

export default AccordianFieldWithUpload;
