import moment from 'moment';
import { FinancialYearDateRange } from 'src/Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { ocrCombinedReportList } from 'src/Redux/UploadReports/state';
import { REPORT_TIME } from 'src/types/enums';
import {
  bankingOption,
  corporateOrgShareholdersOption,
  financialYearOption,
  otherOptionOptions,
  workersCompensationOption,
} from './data';

export const getSecondDropDownOption = (value: string) => {
  switch (value) {
    case 'Financial Statement':
      return financialYearOption;
    case 'Corporate Organization & Shareholders':
      return corporateOrgShareholdersOption;
    case 'Banking':
      return bankingOption;
    case 'Workers Compensation':
      return workersCompensationOption;
    case 'Key Personnel Resume':
      return financialYearOption;
    case 'Other':
      return otherOptionOptions;
    default:
      return financialYearOption;
  }
};

export const isShoWFinancialReviewType = (
  values: Record<string, any>
): boolean => {
  return (
    values?.financial === 'Financial Statement' &&
    !!values?.financialStatement &&
    [
      'Balance Sheet',
      'Profit and Loss',
      ...Object.values(ocrCombinedReportList)?.map((el) => el.toString()),
    ].includes(values?.financialStatement) &&
    !!values?.financialYear
  );
};

const calculateSelectedPeriod = (mostRecentInterim: {
  start: string;
  end: string;
}) => {
  //e.g Fiscal year: 2/12
  // M1 : Dec 3, 2024 to Jan 2, 2025.
  // M2 : Jan 3, 2025 to Feb 2, 2025.
  // Q1 : Dec 03, 2024 to Mar 02, 2025.
  // H1 : Dec 03, 2024 to Jun 02, 2025.

  const start = moment(mostRecentInterim.start, 'DD MMM YYYY');
  const end = moment(mostRecentInterim.end, 'DD MMM YYYY');

  const monthlyRanges = Array.from({ length: 12 }, (_, i) => ({
    label: `M${i + 1}`,
    startDate: start.clone().add(i, 'months').format('DD MMM YYYY'),
    endDate: start
      .clone()
      .add(i + 1, 'months')
      .subtract(1, 'd')
      .format('DD MMM YYYY'),
  }));

  const quarterlyRanges = [
    {
      label: 'Q1',
      startDate: start.format('DD MMM YYYY'),
      endDate: start
        .clone()
        .add(3, 'months')
        .subtract(1, 'd')
        .format('DD MMM YYYY'),
    },
    {
      label: 'Q2',
      startDate: start.clone().add(3, 'months').format('DD MMM YYYY'),
      endDate: start
        .clone()
        .add(6, 'months')
        .subtract(1, 'd')
        .format('DD MMM YYYY'),
    },
    {
      label: 'Q3',
      startDate: start.clone().add(6, 'months').format('DD MMM YYYY'),
      endDate: start
        .clone()
        .add(9, 'months')
        .subtract(1, 'd')
        .format('DD MMM YYYY'),
    },
    {
      label: 'Q4',
      startDate: start.clone().add(9, 'months').format('DD MMM YYYY'),
      endDate: start
        .clone()
        .add(12, 'months')
        .subtract(1, 'd')
        .format('DD MMM YYYY'),
    },
  ];

  const semiAnnualRanges = [
    {
      label: 'H1',
      startDate: start.format('DD MMM YYYY'),
      endDate: start
        .clone()
        .add(6, 'months')
        .subtract(1, 'd')
        .format('DD MMM YYYY'),
    },
    {
      label: 'H2',
      startDate: start.clone().add(6, 'months').format('DD MMM YYYY'),
      endDate: start
        .clone()
        .add(12, 'months')
        .subtract(1, 'd')
        .format('DD MMM YYYY'),
    },
  ];

  const yearToDateRange = {
    label: 'Year To Date',
    startDate: start.format('DD MMM YYYY'),
    endDate: null,
  };

  return {
    monthlyRanges,
    quarterlyRanges,
    semiAnnualRanges,
    yearToDateRange,
  };
};

export const getSelectedPeriodRange = (
  mostRecentInterim: { start: string; end: string },
  selectedValue: string
) => {
  const result = calculateSelectedPeriod(mostRecentInterim);

  // Combine all ranges into a single array for easy searching
  const allRanges = [
    ...result.monthlyRanges,
    ...result.quarterlyRanges,
    ...result.semiAnnualRanges,
    result.yearToDateRange,
  ];

  // Find the matching range by the label
  const selectedRange = allRanges.find(
    (range) => range.label === selectedValue
  );

  return selectedRange;
};

export const getdateRangeSpan = (
  dateRange: any,
  selectedReportDate: Date | null
) => {
  const selectedDate = moment(selectedReportDate);

  for (const rangeKey in dateRange) {
    const { start, end } = dateRange?.[rangeKey];
    const startDate = moment(start, 'DD MMM YYYY');
    const endDate = moment(end, 'DD MMM YYYY');

    if (selectedDate.isBetween(startDate, endDate, 'day', '[]')) {
      return rangeKey;
    }
  }
  return 'Other';
};

export const getSelectedFinancialYear = (rangeOfSelectedDate: string) => {
  switch (rangeOfSelectedDate) {
    case 'mostRecentInterim': {
      return 'Most Recent Interim Financial Statement';
    }
    case 'mostRecentFiscalYear': {
      return 'Most Recent Fiscal Year End';
    }
    case 'oneYearPrevious': {
      return '1-Year Previous Fiscal Year End';
    }
    case 'twoYearsPrevious': {
      return '2-Year Previous Fiscal Year End';
    }
    case 'Other': {
      return 'Other';
    }
    default:
      break;
  }
};

export const getdateRangeSpanFromStartandEndDate = (
  dateRange: any,
  selectedStartDate: string,
  selectedEndDate: string
) => {
  const startDate = moment(selectedStartDate, 'DD MMM YYYY');
  const endDate = moment(selectedEndDate, 'DD MMM YYYY');
  console.log(
    'test:::',
    dateRange,
    selectedStartDate,
    selectedEndDate,
    startDate,
    endDate
  );
  for (const rangeKey in dateRange) {
    const { start, end } = dateRange?.[rangeKey];
    const rangeStartDate = moment(start, 'DD MMM YYYY');
    const rangeEndDate = moment(end, 'DD MMM YYYY');

    // Check if startDate and endDate are within the range
    const isStartDateValid = startDate.isSameOrAfter(rangeStartDate, 'day');
    const isEndDateValid = endDate.isSameOrBefore(rangeEndDate, 'day');

    if (isStartDateValid && isEndDateValid) {
      return rangeKey;
    }
  }
  return 'Other';
};

export function findStartAndEndDate(
  dateRanges: FinancialYearDateRange | null,
  selectedDate: Date | null
) {
  const selected = moment(selectedDate, 'DD MMM YYYY');
  const referenceStart = moment(
    dateRanges?.twoYearsPrevious?.start,
    'DD MMM YYYY'
  );
  const referenceEnd = moment(dateRanges?.twoYearsPrevious?.end, 'DD MMM YYYY');

  // Calculate the year difference between the reference range and the selected date
  const yearDifference = referenceStart.diff(selected, 'years');
  // console.log('yearDifference', yearDifference);

  // Adjust the reference range backward by the year difference
  const adjustedStart = referenceStart.subtract(yearDifference + 1, 'years');
  const adjustedEnd = referenceEnd.subtract(yearDifference + 1, 'years');

  return {
    start: adjustedStart.format('DD MMM YYYY'),
    end: adjustedEnd.format('DD MMM YYYY'),
  };
}

export const getDateRangeOfTimeFrame = (
  dateRanges: FinancialYearDateRange | null,
  timeFrame: string
) => {
  switch (timeFrame) {
    case REPORT_TIME.RecentFiscal: {
      return {
        startDate: dateRanges?.mostRecentFiscalYear?.start,
        endDate: dateRanges?.mostRecentFiscalYear?.end,
      };
    }
    case REPORT_TIME.Previous1: {
      return {
        startDate: dateRanges?.oneYearPrevious?.start,
        endDate: dateRanges?.oneYearPrevious?.end,
      };
    }
    case REPORT_TIME.Previous2: {
      return {
        startDate: dateRanges?.twoYearsPrevious?.start,
        endDate: dateRanges?.twoYearsPrevious?.end,
      };
    }
    default:
      break;
  }
};
