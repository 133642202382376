import { useSelector } from 'react-redux';
import './App.css';
import { RootState } from './Redux/Store';
import Routing from './Routing/Routing';

export const BASEURL = process.env.REACT_APP_BASE_URL;

function App() {
  const loginState = useSelector((state: RootState) => state.auth.user);

  let userType = loginState.data.userType;
  let token = loginState.data.token;

  return <Routing token={token} userType={userType} />;
}

export default App;
