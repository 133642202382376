import moment from 'moment';
import { FinancialYearDateRange } from 'src/Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { checkLeapYear } from 'src/utils/helpers';

export const calculateDateRanges = (endDate: Date | null) => {
  // Parse endDate using Moment.js

  const end = moment(endDate, 'DD MMMM YYYY');
  const currentYear = moment().year();
  end.year(currentYear);
  end.format('DD MMMM YYYY');
  console.log('calculate fiscalYear endDate:::', end);

  const isTodayAfterGivenEndDate = isTodayGreaterThanEndDate(
    end.format('MM-DD')
  );

  const isTwentyEightFeb = moment(endDate).format('DD/MM') === '28/02';
  const daysToAddMostRecentInterim =
    isTwentyEightFeb &&
    checkLeapYear(
      isTodayAfterGivenEndDate
        ? end.clone().year()
        : end.clone().subtract(1, 'year').year()
    )
      ? 2
      : 1;

  const daysToAddMostRecentFiscalYear =
    isTwentyEightFeb &&
    checkLeapYear(
      isTodayAfterGivenEndDate
        ? end.clone().subtract(1, 'year').year()
        : end.clone().subtract(2, 'year').year()
    )
      ? 2
      : 1;

  const daysToAddoneYearPrevious =
    isTwentyEightFeb &&
    checkLeapYear(
      isTodayAfterGivenEndDate
        ? end.clone().subtract(2, 'year').year()
        : end.clone().subtract(3, 'year').year()
    )
      ? 2
      : 1;

  const daysToAddtwoYearsPrevious =
    isTwentyEightFeb &&
    checkLeapYear(
      isTodayAfterGivenEndDate
        ? end.clone().subtract(3, 'year').year()
        : end.clone().subtract(4, 'year').year()
    )
      ? 2
      : 1;

  // Calculate each required date range
  let ranges: FinancialYearDateRange;

  console.log(
    'isTodayAfterGivenEndDate:::',
    isTodayAfterGivenEndDate,
    daysToAddMostRecentInterim,
    daysToAddMostRecentFiscalYear,
    daysToAddoneYearPrevious,
    daysToAddtwoYearsPrevious
  );

  if (isTodayAfterGivenEndDate) {
    ranges = {
      mostRecentInterim: {
        start: end
          .clone()
          .add(daysToAddMostRecentInterim, 'day')
          .format('DD MMM YYYY'),
        end: end.clone().add(1, 'year').format('DD MMM YYYY'),
      },
      mostRecentFiscalYear: {
        start: end
          .clone()
          .subtract(1, 'year')
          .add(daysToAddMostRecentFiscalYear, 'day')
          .format('DD MMM YYYY'),
        end: end.format('DD MMM YYYY'),
      },
      oneYearPrevious: {
        start: end
          .clone()
          .subtract(2, 'years')
          .add(daysToAddoneYearPrevious, 'day')
          .format('DD MMM YYYY'),
        end: end.clone().subtract(1, 'year').format('DD MMM YYYY'),
      },
      twoYearsPrevious: {
        start: end
          .clone()
          .subtract(3, 'years')
          .add(daysToAddtwoYearsPrevious, 'day')
          .format('DD MMM YYYY'),
        end: end.clone().subtract(2, 'years').format('DD MMM YYYY'),
      },
    };
  } else {
    ranges = {
      mostRecentInterim: {
        start: end
          .clone()
          .subtract(1, 'year')
          .add(daysToAddMostRecentInterim, 'day')
          .format('DD MMM YYYY'),
        end: end.clone().format('DD MMM YYYY'),
      },
      mostRecentFiscalYear: {
        start: end
          .clone()
          .subtract(2, 'year')
          .add(daysToAddMostRecentFiscalYear, 'day')
          .format('DD MMM YYYY'),
        end: end.clone().subtract(1, 'year').format('DD MMM YYYY'),
      },
      oneYearPrevious: {
        start: end
          .clone()
          .subtract(3, 'years')
          .add(daysToAddoneYearPrevious, 'day')
          .format('DD MMM YYYY'),
        end: end.clone().subtract(2, 'year').format('DD MMM YYYY'),
      },
      twoYearsPrevious: {
        start: end
          .clone()
          .subtract(4, 'years')
          .add(daysToAddtwoYearsPrevious, 'day')
          .format('DD MMM YYYY'),
        end: end.clone().subtract(3, 'years').format('DD MMM YYYY'),
      },
    };
  }

  return ranges;
};

const isTodayGreaterThanEndDate = (givenDate: string): boolean => {
  const today = moment().format('MM-DD');
  const compareDate = moment(givenDate).format('MM-DD');
  return today > compareDate;
};
// const isTodayGreaterThanEndDate = (givenDate: string): boolean => {
//   const today = moment().startOf('day'); // Start of today (ignores time)
//   const compareDate = moment(givenDate, 'MM-DD-YYYY').startOf('day'); // Parse given date and normalize to start of day
//   return today.isAfter(compareDate); // Check if today is after the given date
// };
