export const profitLossDiffBgRowList = [
  'income',
  'cost of goods sold',
  'expenses',
  'other income',
  'other expenses',
];

export const profitLossBoldRowList = [
  'gross profit',
  'net income',
  'operating expenses',
  'cost of sales',
];
