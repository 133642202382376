import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import {
  addFocusEventListenerOnInput,
  removeFocusEventListenerOnInput,
} from 'src/utils/helpers';

const AccordianDatePicker = ({
  refState,
  label,
  formik,
  name,
  value,
  isHistoryMode = false,
  isReadOnly,
  currentHistoryData = {},
  accordianName,
}: {
  refState: React.RefObject<DatePickerInput>;
  label: string;
  formik: Record<string, any>;
  name: string;
  value: string;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
  accordianName: string;
}) => {
  const getNestedValue = (obj: Record<string, any>, path: string) => {
    return path.split('.').reduce((currentValue, key) => {
      return currentValue?.[key];
    }, obj);
  };

  const dynamicConditionCheck = (
    obj: Record<string, any>,
    name: string,
    showHistory: boolean,
    className: string,
    defaultClassName: string
  ) => {
    if (
      showHistory &&
      obj?.updatedData &&
      getNestedValue(obj?.updatedData, name) !== undefined &&
      (name?.split('.')?.pop() ?? 'default') in
        getNestedValue(
          obj?.updatedData,
          name.substring(0, name.lastIndexOf('.'))
        )
    ) {
      return className;
    }
    return defaultClassName;
  };

  const bgBlueClassName = dynamicConditionCheck(
    currentHistoryData,
    name,
    isHistoryMode,
    'bg-blue',
    ''
  );

  return (
    <DatePickerInput
      ref={refState}
      value={value !== null ? value : undefined}
      onChange={(date: Date | null) => {
        formik.setFieldValue(name, date);
      }}
      locale="en"
      placeholder={label}
      // className={`react-datepicker-component react-datepicker-input input gray-border`}
      className={`react-datepicker-component react-datepicker-input input gray-border ${bgBlueClassName}`}
      disabled={isReadOnly}
      showOnInputClick={true}
      onShow={() => addFocusEventListenerOnInput(refState)}
      onHide={() => removeFocusEventListenerOnInput(refState)}
      minDate={
        accordianName === 'changesInManagementAcc'
          ? moment().subtract(3, 'years')
          : undefined
      }
      maxDate={moment(new Date()).endOf('d')}
    />
  );
};

export default AccordianDatePicker;
