// Upload report Types's
export enum REPORT_TYPE {
  FS = 'Financial Statement',
  Banking = 'Banking',
  COS = 'Corporate Organization & Shareholders',
  WC = 'Workers Compensation',
  KPR = 'Key Personnel Resume',
}

// Upload report timeFrame
export enum REPORT_TIME {
  Interim = 'Most Recent Interim Financial Statement',
  RecentFiscal = 'Most Recent Fiscal Year End',
  Previous1 = '1-Year Previous Fiscal Year End',
  Previous2 = '2-Year Previous Fiscal Year End',
  asasa = null as any,
}

// Upload report Name's
export enum REPORT_NAME {
  ARA = 'Account Receivable Aging',
  APA = 'Account Payable Aging',
  PL = 'Profit and Loss',
  CF = 'Cashflow',
  COC = 'Corporate Organizational Chart',
  ShareAggrement = 'Shareholder Agreement',
  PNWS = 'Personal Net Worth Statement',
  BCL = 'Bank Conditions Letter',
  BTA = 'Bank Terms and Agreement',
  WCROP = 'Workers Compensation Review of Performance',
  BS = 'Balance Sheet',
  BSPL = 'Balance sheet / Profit and Loss',
}

export enum ApplicationFilter {
  Drafts = 'Drafts',
  AwaitingInput = 'Awaiting Input',
  AwaitingConsent = 'Awaiting Consent',
  ApplicationSubmittedAwaitingUnderwriting = 'Application Submitted. Awaiting Underwriting',
  ApprovedByUnderwriter = 'Approved by Underwriter',
  DeclinedByUnderwriter = 'Declined by Underwriter',
}
