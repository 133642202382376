import { APIStatus } from 'src/types/unions';
import { TBondList, TBondRequest } from './bondRequest';

export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_BOND_APPLICATION_DETAILS'
    | 'DELETE_BOND_APPLICATION'
    | 'BOND_APPLICATION_ASSIGN'
    | 'BOND_APPLICATION_STATUS_UPDATE'
    | 'BOND_APPLICATION_CONVERT_TO_FINAL_BOND'
    | null;
  bondDetails: TBondRequest | null;
  bondList: TBondList[] | [];
  bondAssign: string | null;
  msg: string | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  bondDetails: null,
  msg: null,
  bondList: [],
  bondAssign: null,
};

export default initialState;
