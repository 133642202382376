import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useMemo } from 'react';

const ClientProfileReport = () => {
  const data: any = [];
  const columns = useMemo(
    () => [
      {
        accessorKey: 'agg',
        header: 'Total Aggregate  Bond Facility Limit',
      },
      {
        accessorKey: 'uagg',
        header: 'Total Utilized Aggregate of Bond Facility',
      },
      {
        accessorKey: 'remAgg',
        header: 'Total Remaining Aggregate of Bond Facility',
      },
      {
        accessorKey: 'limit',
        header: 'Single Contract Limit',
      },
      {
        accessorKey: 'renewDate',
        header: 'Renewal Date',
      },
      {
        accessorKey: 'finance',
        header: 'Financial Covenants',
      },
      {
        accessorKey: 'reporting',
        header: 'Client Reporting Requirements',
      },
      {
        accessorKey: 'subDate',
        header: 'Financial Reports Submission Due to Surety (Date)',
      },
      {
        accessorKey: 'reviewDate',
        header: 'Most Recent Financial Review Date',
      },
      {
        accessorKey: 'indem',
        header: 'Indemnity and Security Conditions',
      },
      {
        accessorKey: 'claim',
        header: 'Claims / Incidents',
      },
      {
        accessorKey: 'uFee',
        header: 'Underwriting Fee',
      },
      {
        accessorKey: 'bondFee',
        header: 'Bond Fees',
      },
    ],
    []
  );

  const tableBond = useMantineReactTable({
    columns,
    data,
  });
  return <MantineReactTable table={tableBond} />;
};

export default ClientProfileReport;
