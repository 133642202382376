export const financialOption = [
  { label: 'Financial Statement', value: 'Financial Statement' },
  {
    label: 'Corporate Organization & Shareholders',
    value: 'Corporate Organization & Shareholders',
  },
  { label: 'Banking', value: 'Banking' },
  { label: 'Workers Compensation', value: 'Workers Compensation' },
  { label: 'Key Personnel Resume', value: 'Key Personnel Resume' },
  { label: 'Other', value: 'Other' },
];

export const financialYearOption = [
  {
    label: 'Most Recent Interim Financial Statement',
    value: 'Most Recent Interim Financial Statement',
  },
  {
    label: 'Most Recent Fiscal Year End',
    value: 'Most Recent Fiscal Year End',
  },
  {
    label: '1-Year Previous Fiscal Year End',
    value: '1-Year Previous Fiscal Year End',
  },
  {
    label: '2-Year Previous Fiscal Year End',
    value: '2-Year Previous Fiscal Year End',
  },
  {
    label: 'Historical',
    value: 'Other',
  },
];
export const financialStatementOption = [
  { label: 'Account Receivable Aging', value: 'Account Receivable Aging' },
  { label: 'Account Payable Aging', value: 'Account Payable Aging' },
  { label: 'Balance Sheet', value: 'Balance Sheet' },
  { label: 'Profit and Loss', value: 'Profit and Loss' },
  // { label: 'Cashflow', value: 'Cashflow' },
  {
    label: 'Balance sheet / Profit and Loss',
    value: 'Balance sheet / Profit and Loss',
  },
  // {
  //   label: 'Balance sheet / Profit and Loss / Cash flow',
  //   value: 'Balance sheet / Profit and Loss / Cash flow',
  // },
];
export const corporateOrgShareholdersOption = [
  {
    label: 'Corporate Organizational Chart',
    value: 'Corporate Organizational Chart',
  },
  {
    label: 'Shareholder Agreement (specify name of Shareholder)',
    value: 'Shareholder Agreement',
  },
  {
    label: 'Personal Net Worth Statement (specify name of Shareholder)',
    value: 'Personal Net Worth Statement',
  },
];
export const bankingOption = [
  {
    label: 'Bank Terms and Agreement (specify what bank and for what LOC)',
    value: 'Bank Terms and Agreement',
  },
  {
    label: 'Bank Conditions Letter (specify what bank and for what LOC)',
    value: 'Bank Conditions Letter',
  },
];
export const workersCompensationOption = [
  {
    label: 'Workers Compensation Review of Performance',
    value: 'Workers Compensation Review of Performance',
  },
];

export const otherOptionOptions = [
  {
    label:
      'If the company is presently engaged in any dispute with suppliers or sub trades relative to their work or materials, upload the funding agreement',
    value: 'Copy Of Agreement',
  },
];
export const OtherOptionWithtaxingEntity = [
  ...otherOptionOptions,
  {
    label: `If there are claims paid against a surety bond, upload the taxing entity document`,
    value: 'Taxing Entity Document',
  },
];

export const exportDocumentDownloadOptions = [
  {
    label: 'Export all Documents (except Legal)',
    value: 'All Documents',
  },
  {
    label: 'Export all Legal Documents',
    value: 'Legal Form Documents',
  },
];

export const financialReviewTypeOptions = [
  {
    label: 'Audited',
    value: 'Audited',
  },
  {
    label: 'Review Engagement',
    value: 'Review Engagement',
  },
  {
    label: 'Notice to Reader',
    value: 'Notice to Reader',
  },
  {
    label: 'Internally Reviewed',
    value: 'Internally Reviewed',
  },
];
export const financialPeriodOptions: financialPeriodOptionsType[] = [
  { label: 'M1', value: 'M1', type: 'monthly' },
  { label: 'M2', value: 'M2', type: 'monthly' },
  { label: 'M3', value: 'M3', type: 'monthly' },
  { label: 'M4', value: 'M4', type: 'monthly' },
  { label: 'M5', value: 'M5', type: 'monthly' },
  { label: 'M6', value: 'M6', type: 'monthly' },
  { label: 'M7', value: 'M7', type: 'monthly' },
  { label: 'M8', value: 'M8', type: 'monthly' },
  { label: 'M9', value: 'M9', type: 'monthly' },
  { label: 'M10', value: 'M10', type: 'monthly' },
  { label: 'M11', value: 'M11', type: 'monthly' },
  { label: 'M12', value: 'M12', type: 'monthly' },
  { label: 'Q1', value: 'Q1', type: 'quarterly' },
  { label: 'Q2', value: 'Q2', type: 'quarterly' },
  { label: 'Q3', value: 'Q3', type: 'quarterly' },
  { label: 'Q4', value: 'Q4', type: 'quarterly' },
  { label: 'H1', value: 'H1', type: 'semi-annual' },
  { label: 'H2', value: 'H2', type: 'semi-annual' },
  { label: 'Year To Date', value: 'Year To Date', type: 'year-to-date' },
  { label: 'Custom Range', value: 'Custom Range', type: 'custom-range' },
];

export const financialPeriodOptionsRange: FinancialOptionType[] = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
  {
    label: 'Semi annual',
    value: 'semi-annual',
  },
  {
    label: 'Year to Date',
    value: 'year-to-date',
  },
  {
    label: 'Custom Range',
    value: 'custom-range',
  },
];

export type financialPeriodOptionsType = {
  label: string;
  value:
    | 'M1'
    | 'M2'
    | 'M3'
    | 'M4'
    | 'M5'
    | 'M6'
    | 'M7'
    | 'M8'
    | 'M9'
    | 'M10'
    | 'M11'
    | 'M12'
    | 'Q1'
    | 'Q2'
    | 'Q3'
    | 'Q4'
    | 'H1'
    | 'H2'
    | 'Year To Date'
    | 'Custom Range';
  type: FinancialOptionType['value'];
};

export type FinancialOptionType = {
  label: string;
  value:
    | 'monthly'
    | 'quarterly'
    | 'semi-annual'
    | 'year-to-date'
    | 'custom-range';
};
