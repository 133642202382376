import {
  Box,
  Button,
  Container,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

function QboSuccess() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      p={2}
    >
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        // w={["96%", "96%", "auto  "]}
        p="22px"
        borderRadius="20px"
      >
        <Flex flexDirection={'column'} gap={'10px'}>
          <Box
            mt={5}
            fontSize={'100px'}
            display={'flex'}
            justifyContent={'center'}
            color={'green'}
          >
            <FaCheckCircle />
          </Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={26}
            pt="20px"
            pb="20px"
          >
            Successfully connected to QBO
          </Text>
          <Text color="#114684" fontWeight={'600'}>
            Is Applicant's company name same as QBO company name?
          </Text>

          <RadioGroup
            color="#114684"
            mb={'20px'}
            fontSize={18}
            fontWeight={'600'}
          >
            <Stack direction="row" gap={5} justifyContent={'center'}>
              <Radio colorScheme="green" value="1">
                Yes
              </Radio>
              <Radio colorScheme="red" value="2">
                No
              </Radio>
            </Stack>
          </RadioGroup>
          <Button colorScheme="purple" size="lg">
            Continue
          </Button>
        </Flex>
      </Container>
    </Box>
  );
}

export default QboSuccess;
