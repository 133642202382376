import { Box } from '@chakra-ui/react';
import { DatePickerInput } from 'rc-datepicker';
import AccordianDatePicker from './AccordianDatePicker';
import AccordianInputLabel from './AccordianInputLabel';

const AccordianFieldWithDatePicker = ({
  label,
  name,
  value,
  onChange,
  type,
  refState,
  formik,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
  accordianName,
}: {
  label: string;
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type: string;
  refState: React.RefObject<DatePickerInput>;
  formik: Record<string, any>;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
  accordianName: string;
}) => {
  return (
    <Box
      flex={{ base: '1', md: '1' }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      gap={'5px'}
    >
      <AccordianInputLabel label={label} />

      <AccordianDatePicker
        refState={refState}
        formik={formik}
        label={label}
        name={name}
        value={value}
        isHistoryMode={isHistoryMode}
        isReadOnly={isReadOnly}
        currentHistoryData={currentHistoryData}
        accordianName={accordianName}
      />
    </Box>
  );
};

export default AccordianFieldWithDatePicker;
