import { Box, Button, Flex } from '@chakra-ui/react';
import { Checkbox, Modal, Stack, Text } from '@mantine/core';
import moment from 'moment';
import { useState } from 'react';
import { updateProjectStatus } from 'src/Redux/Broker/Reports/WorkInProgress/slice';
import {
  WIPUploadProjectList,
  uploadTemplteType,
} from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch } from 'src/Redux/Store';

const WIPProjectListModal = ({
  isOpen,
  onClose,
  isUpdateStatusLoader,
  uploadTemplte,
}: {
  isOpen: boolean;
  onClose: () => void;
  isUpdateStatusLoader: boolean;
  uploadTemplte: uploadTemplteType;
}) => {
  const dispatch = useAppDispatch();
  const [selectedProjectId, setSelectedProjectId] = useState<string[]>([]);

  const handleUpdateProjectStatus = (selectedProjectId: string[]) => {
    const { reportId, projectList } = uploadTemplte;
    const selectedProjectList: WIPUploadProjectList[] = projectList
      ?.filter((item) => selectedProjectId.includes(item.uniqueProjectId))
      ?.map((item) => ({ ...item, projectStatus: 'completed' }));
    const payload = {
      id: reportId,
      projectList: selectedProjectList,
    };
    dispatch(updateProjectStatus(payload));
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setSelectedProjectId([]);
        onClose();
      }}
      title="WIP Report"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'md'}
    >
      <Box>
        <Checkbox.Group
          value={selectedProjectId}
          label={
            <Text weight={'bold'}>
              Below active project are already there in you WIP report as
              of&nbsp;
              {moment(uploadTemplte?.reportAsOf).format('MMMM DD, YYYY')}
            </Text>
          }
          onChange={setSelectedProjectId}
        >
          <Stack mt="xs">
            {uploadTemplte?.projectList?.map((item, index) => (
              <Checkbox
                value={item?.uniqueProjectId}
                label={item?.projectName}
              />
            ))}
          </Stack>
        </Checkbox.Group>
        <Text size={'sm'} mt={4} weight={'bold'}>
          Do you want to mark all this project completed..??
        </Text>
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              setSelectedProjectId([]);
              onClose();
            }}
            isDisabled={isUpdateStatusLoader}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            onClick={() => handleUpdateProjectStatus(selectedProjectId)}
            loadingText="Completed"
            isDisabled={!selectedProjectId.length}
            isLoading={isUpdateStatusLoader}
          >
            Completed
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default WIPProjectListModal;
