import { Box, Button, Flex } from '@chakra-ui/react';
import { Checkbox, LoadingOverlay, Modal, Stack } from '@mantine/core';
import { MRT_Row } from 'mantine-react-table';
import { useState } from 'react';
import { useAppDispatch } from 'src/Redux/Store';
import { regenerateOCRReport } from 'src/Redux/UploadReports/slice';
import { uploadTable } from '../UploadFilesTable';

const RestoreOCRReportSelectionModal = ({
  isOpen,
  onClose,
  isFetchOcrList,
  combinedFileOcrlist,
  row,
  isRegenerateOCR,
}: {
  isOpen: boolean;
  onClose: () => void;
  isFetchOcrList: boolean;
  combinedFileOcrlist: Record<string, any>[];
  row: MRT_Row<uploadTable>;
  isRegenerateOCR: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [selectedOcrId, setSelectedOcrId] = useState<string[]>([]);

  const restoreOCRFromCombinedFile = (selectedOcrReportId: number[]) => {
    const { id } = row?.original;
    console.log('selectedOcrReportId', selectedOcrReportId);
    dispatch(
      regenerateOCRReport({ reportId: id, ocrReportId: selectedOcrReportId })
    );
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setSelectedOcrId([]);
        onClose();
      }}
      title="Regenerate Report"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'md'}
    >
      <Box pos={'relative'}>
        <LoadingOverlay
          visible={isFetchOcrList}
          zIndex={1000}
          overlayBlur={0.5}
          loaderProps={{ size: 'sm' }}
        />
        <Box>
          <Checkbox.Group
            value={selectedOcrId}
            label="Select Reports to Regenerate"
            onChange={setSelectedOcrId}
            mih={'150px'}
          >
            <Stack mt="xs">
              {combinedFileOcrlist?.length > 0 &&
                combinedFileOcrlist?.map((item, index) => (
                  <Checkbox
                    value={item?.id?.toString()}
                    label={item?.reportInfo?.reportName}
                  />
                ))}
            </Stack>
          </Checkbox.Group>
          <Flex
            justifyContent={'flex-end'}
            gap={2}
            alignItems={'center'}
            mt={3}
          >
            <Button
              type="button"
              onClick={() => {
                setSelectedOcrId([]);
                onClose();
              }}
              isDisabled={isRegenerateOCR}
            >
              Cancel
            </Button>
            <Button
              type="button"
              colorScheme="blue"
              onClick={() =>
                restoreOCRFromCombinedFile(
                  selectedOcrId?.map((item) => Number(item))
                )
              }
              loadingText="Regenerate"
              isDisabled={!selectedOcrId.length}
              isLoading={isRegenerateOCR}
            >
              Regenerate
            </Button>
          </Flex>
        </Box>
      </Box>
    </Modal>
  );
};

export default RestoreOCRReportSelectionModal;
