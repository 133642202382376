import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import PDFForm from './PDFForm';

export const NewData = {
  status: 200,
  msg: 'Success',
  data: {
    id: 1,
    attention: 'Esse voluptas et des',
    finalBondNumber: '690',
    brokerName: 'Kareem Strickland',
    brokerPhone: 9876543210,
    brokerEmail: 'mohinib+3@a5econsulting.net',
    applicantName: 'Alec Holden',
    applicantPhone: 9876543210,
    applicantEmail: 'mohinib+3@a5econsulting.net',
    dateOfRequisition: '2025-01-16T04:24:41.000Z',
    dateRequired: '2025-01-16T04:24:44.000Z',
    generalContractorName: 'Tatiana Conley',
    generalContractorPhone: 9876543210,
    generalContractorEmail: 'mohinib+3@a5econsulting.net',
    engineerOrArchitectName: 'Graham Gonzalez',
    engineerOrArchitectPhone: 9876543210,
    engineerOrArchitectEmail: 'dhaval03@yopmail.com',
    ownerName: 'Ingrid Anthony',
    ownerPhone: 9876543210,
    ownerEmail: 'vebila@mailinator.com',
    typeOfOwner: 'Private Companies',
    typeOfWork: ['General Building', 'Electrical'],
    workOnHandsAsOfDate: '06-May-1973',
    totalBondedJobs: {
      contractPrice: 382,
      costToComplete: 53,
    },
    totalUnbondedJobs: {
      contractPrice: 706,
      costToComplete: 58,
    },
    totalOutstandingBids: {
      contractPrice: 447,
      costToComplete: 78,
    },
    totalSum: {
      contractPrice: 947,
      costToComplete: 72,
    },
    projectName: 'Lillian Fernandez',
    projectLocation: 'Vaughan, ON L6A 1S6, Canada',
    projectLocationCountry: 'Canada',
    projectStartDate: '2025-01-22T04:24:54.000Z',
    projectEndDate: '2025-01-24T04:25:01.000Z',
    uploadContract: null,
    contractDetails: 'Other',
    contractDetailsOther: 'fg',
    contractType: 'UP',
    estimatedSubletBudget: 28,
    workSublet: {
      name: '334',
      amount: 34,
      bonded: false,
    },
    paymentTerms: 'Other',
    holdback: true,
    holdbackPercentage: 34,
    architectEstimate: 38,
    maintenanceWarrantyTerm: 97,
    penaltiesInContract: false,
    penaltyContranctReferences: null,
    arePenaltiesCapped: null,
    liquidatedDamages: false,
    liquidatedDamagesReferences: null,
    liquidatedDamagesCapped: null,
    environmentalHazards: false,
    designResponsibility: false,
    designResponsibilityReferences: 'Cumque irure dolorem',
    bondForm: {
      otherDetails: null,
      bondFormValue: 'CCDC',
    },
    applicationType: 'Bid',
    bidBondDetails: {
      tenderDate: '2025-01-13T04:24:32.333Z',
      attachments: [],
      bidBondAmount: 44,
      acceptancePeriod: 58,
      additionalComments: 'Ex esse ut aliquip c',
      estimatedContractPrice: 833,
      preQualificationLetter: 'Sit asperiores esse ',
      isPreQualificationLetter: true,
    },
    finalBondDetails: {
      taxType: null,
      contractDate: null,
      contractPrice: null,
      includesTaxes: null,
      paymentBondAmount: null,
      maintenanceBondAmount: null,
      performanceBondAmount: null,
    },
    premiumCalculation: 'Eos dolor id elit ',
    approvedDate: '2025-01-15T04:26:13.000Z',
    approver: 'Quo impedit ullamco',
    approvalStamp: 'Quis nulla ad aliqui',
    consentOfSurety: false,
    tenderResults: {
      bidders: [
        {
          amount: 13,
          companyName: 'Manning and Rice LLC',
        },
      ],
      isNegotiated: false,
    },
    applicantId: 42,
    brokerId: 17,
    createdBy: 17,
    createdByType: 'broker',
    createdAt: '2025-01-20T04:26:15.000Z',
    status: 'pending',
    assignedBy: null,
    assignedTo: null,
    assignedAt: null,
    bondNumber: null,
    applicationId: null,
  },
};

let logo =
  'https://subtraid-insurance.s3.us-east-2.amazonaws.com/documents/brokerageLogo.png-1737712172118?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOUTPGHTQU6%2F20250124%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20250124T100009Z&X-Amz-Expires=604800&X-Amz-Signature=eed711507f37ab7e8a10374e78258c473ba58a310a3732bb8366a44bde77166b&X-Amz-SignedHeaders=host&x-id=GetObject';

const App: React.FC = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <PDFForm data={NewData?.data} logo={logo} />
      </PDFViewer>
    </div>
  );
};

export default App;
