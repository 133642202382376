import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { APIStatus } from 'src/types/unions';
import { BASEURL } from '../../App';
import callAPI, { axiosInstance } from '../../utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Documents/slice';

interface DataValues {
  email: string;
  id: number;
  token: string;
  userType: string;
  onboarding: boolean;
}
interface UserValues {
  data: DataValues;
  msg: string;
  status: number;
}

interface AuthState {
  verifyUserData: {
    data: {
      email: string;
    };
    msg: string;
  };
  user: UserValues;
  loading: boolean;
  error: any;
  status: APIStatus | null;
  type?: 'GET_ISLOGIN' | 'GET_ISLOGIN_ADMIN' | 'VERIFY_EMAIL';
}

const initialState: AuthState = {
  user: {
    data: {
      email: '',
      id: 0,
      token: '',
      userType: '',
      onboarding: false,
    },
    msg: '',
    status: 0,
  },
  loading: false,
  error: null,
  status: null,
  verifyUserData: {
    data: {
      email: '',
    },
    msg: '',
  },
};

export const Auth = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

//login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (
    loginUser: { email: string; password: string; userType: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `${BASEURL}/auth/login`,
        'POST',
        loginUser
      );
      const { token } = response?.data?.data;
      localStorage.setItem('Token', JSON.stringify(token));
      Cookies.set('token', token);
      axiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${token}`;
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);
//admin login
export const adminLogin = createAsyncThunk(
  'auth/adminLogin',
  async (payload: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await callAPI(`${BASEURL}/admin/login`, 'POST', payload);
      const { token } = response?.data?.data;
      localStorage.setItem('Token', JSON.stringify(token));
      Cookies.set('token', token);
      axiosInstance.defaults.headers.common['Authorization'] =
        `Bearer ${token}`;
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);
//email send
export const ForgetPassword = createAsyncThunk(
  'auth/ForgetPassword',
  async (
    ForgetPassword: {
      userType: string;
      email: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${BASEURL}/auth/forgotPassword`,
        ForgetPassword
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Reset password
export const ResetPassword = createAsyncThunk(
  'auth/ResetPassword',
  async (
    ResetPassword: {
      userType: string;
      // email?: string;
      password: string;
      id: string;
      token: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${BASEURL}/auth/resetPassword  `,
        ResetPassword
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//Applicant account
export const ApplicatAccountUser = createAsyncThunk(
  'auth/ApplicatAccount',
  async (
    ApplicatAccountData: {
      userType: string;
      userData: {
        companyName: string;
        email: string;
        password: string;
        termCondition: boolean;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${BASEURL}/auth/register/user`,
        ApplicatAccountData
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Broker account
export const BrokerAccountUser = createAsyncThunk(
  'auth/BrokerAccountUser',
  async (
    BrokerAccountData: {
      userType: string;
      userData: {
        firstName: string;
        lastName: string;
        contactNumber: string;
        email: string;
        password: string;
        brokerageId: number;
        termCondition: boolean;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${BASEURL}/auth/register/user`,
        BrokerAccountData
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Broker get api

// Brokererage Account
export const BrokererageAccountUser = createAsyncThunk(
  'auth/BrokererageAccountUser',
  async (
    BrokererageAccount: {
      userType: string;
      userData: {
        legalName: string;
        contactNumber: string;
        email: string;
        password: any;
        termCondition: boolean;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${BASEURL}/auth/register/user`,
        BrokererageAccount
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//verify email api on activation link
export const verifyUserEmail = createAsyncThunk(
  'auth/verifyUserEmail',
  async ({ token }: { token: string }, { rejectWithValue }) => {
    try {
      const res = await callAPI(`auth/verify-email/${token}`, 'GET');
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.user = initialState.user;
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_ISLOGIN';
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
        state.status = 'succeed';
        state.type = 'GET_ISLOGIN';
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'failed';
        state.type = 'GET_ISLOGIN';
      })
      // admin Login
      .addCase(adminLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_ISLOGIN_ADMIN';
      })
      .addCase(adminLogin.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
        state.status = 'succeed';
        state.type = 'GET_ISLOGIN_ADMIN';
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.status = 'failed';
        state.type = 'GET_ISLOGIN_ADMIN';
      })
      //verify user email
      .addCase(verifyUserEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading';
        state.type = 'VERIFY_EMAIL';
      })
      .addCase(
        verifyUserEmail.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.verifyUserData = action.payload;
          state.status = 'succeed';
          state.type = 'VERIFY_EMAIL';
        }
      )
      .addCase(verifyUserEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = isRejectedActionWithPayload(action);
        state.status = 'failed';
        state.type = 'VERIFY_EMAIL';
      })

      // Foeget password
      .addCase(ForgetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        ForgetPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(ForgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // reset password
      .addCase(ResetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ResetPassword.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(ResetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Applicat account
      .addCase(ApplicatAccountUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        ApplicatAccountUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(ApplicatAccountUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Broker account
      .addCase(BrokerAccountUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        BrokerAccountUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(BrokerAccountUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Brokererage Account
      .addCase(BrokererageAccountUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        BrokererageAccountUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )

      .addCase(BrokererageAccountUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export const { resetAuth } = authSlice.actions;

export default authSlice.reducer;
