import { Box, Flex, Text } from '@chakra-ui/react';
import { Button, Modal } from '@mantine/core';

const ApplicationSixMonthOldModal = ({
  isOpen,
  onClose,
  onConfirm,
  onDecline,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onDecline: () => void;
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="Application"
      centered
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      // size={isLoading || appHistoryData.length === 0 ? 'lg' : 'auto'}
      size={'lg'}
      className="global_history"
    >
      <Box margin={'10px 10px 0 10px'} maxW={'725px'} maxH={'600px'}>
        <Text
          textAlign="start"
          color="black"
          fontWeight={'600'}
          fontSize={'14px'}
          mb={3}
        >
          The information in this application is now 6 months old. Would you
          like to proceed as is or create a new application? Creating a new
          application will incur a fee.
        </Text>
        <Flex justifyContent={'flex-end'} gap={2} mt={4}>
          <Button type="button" onClick={onDecline}>
            Proceed as is
          </Button>
          <Button type="button" onClick={onConfirm}>
            Create New
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ApplicationSixMonthOldModal;
