import {
  Box,
  Button,
  Container,
  Flex,
  Stack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { HoverCard, List } from '@mantine/core';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { BsBank2, BsInfoSquare } from 'react-icons/bs';
import { GiClawHammer } from 'react-icons/gi';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { MdArrowBack, MdErrorOutline } from 'react-icons/md';
import { TbShieldDollar, TbUserDollar } from 'react-icons/tb';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IApplication } from 'src/Redux/Applications/application';
import {
  getApplicationById,
  getApplicationUserkey,
} from 'src/Redux/Applications/slice';
import {
  AccoutingSystemSchema,
  CmsSchema,
  CreditBureauSchema,
  InsuranceSchema,
  PersonalFinancialInfoSchema,
  suretyvalidationSchema,
} from 'src/ValidationSchema/Pages/Index';
import { useAppToast } from 'src/hooks';
import { decrypt, decryptedPayload, deepSortObject } from 'src/utils/helpers';
import Swal from 'sweetalert2';
import '../../../App.css';
import NotFoundPage from '../../../Component/Common/404';
import { OwnerData } from '../../../Redux/Applicant/Owner/Owner';
import {
  CheckQboStatus,
  ClearQboStatus,
} from '../../../Redux/Applicant/Qbo/Qbo';
import {
  SubmitApplication,
  SubmitApplicationBroker,
  SubmitPersonalFinanceForms,
} from '../../../Redux/Broker/Dashboard/BrokerageDashboard';
import { fetchDashboardDetails } from '../../../Redux/Notification/Notification';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../Redux/Store';
import AboutForm from './AboutForm';
import AccoutingSystem from './AccoutingSystem';
import Documents from './Documents';
import Insurance from './Insurance';
import LegalForm from './LegalForm';
import OwnerPersonalFinanceInfo from './OwnerPersonalFinanceInfo';
import { FormSkeleton } from './Skeletons';
import StepperBondApplicationForm from './StepperBondApplicationForm';
import Surety from './Surety';

interface FormProps {
  onNext: () => void;
}

interface Step {
  title: string;
  form: React.ComponentType<FormProps>;
  isEnable: boolean;
}

export interface IsSubmited {
  [key: string]: boolean;
}

const Stepper = () => {
  const stepRef = useRef<any>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [applicationData, setApplicationData] = useState<IApplication | null>(
    null
  );
  const [isSubmited, setIsSubmited] = useState<IsSubmited>({
    'Accounting System': false,
    'Credit Bureau': false,
    'Personal Financial Info': false,
    CMS: false,
    Surety: false,
    Insurance: false,
    Documents: false,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const location = useLocation();
  const Toast = useToast();
  const toast = useAppToast();
  const [isvalidd, setIsValidd] = useState(false);
  const [applicationKey, setApplicationUserKey] = useState<string | null>(null);
  //ownerdata
  const dispatch = useAppDispatch();
  const paramsData = useParams<{ id: string }>();
  const [invalidSections, setInvalidSections] = useState<string[]>([]);

  const applicationId = paramsData.id ? parseInt(paramsData.id, 10) : null;

  // const userState = useAppSelector((state: RootState) => state.owner.user);
  // let ownerData = userState && userState.data;
  // let ownerData = userState && userState.data?.length ? userState.data : [{}];
  // const applicationData = useAppSelector(
  //   (state: RootState) => state.brokerageDashboard.user
  // );
  // const currentApplicationId = applicationData?.data?.applicationId;
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);

  // const applicantObject = ownerData?.find(
  //   (applicant: any) => applicant.applicantId === applicationId
  // );
  const { showHistory, applicationStatus } = location.state || {};
  // const applicantFetch = useAppSelector(
  //   (state: RootState) => state.notification.user
  // );

  const brokerageDashboard: any = useAppSelector(
    (state: RootState) => state.brokerageDashboard
  );
  const qboState = useAppSelector((state: RootState) => state.qbo);

  // const { data } = brokerageDashboard?.user || {};
  // let AccoutingdFormData = data && data?.accountingSystem;
  // const {
  //   accountingSystem,
  //   creditBureau,
  //   cms,
  //   insurance,
  //   surety,
  //   financialInfo,
  // } = data || {};
  // const AccoutingdFormData = data && data?.accountingSystem;
  // const CreditBureauFormdata = data && data?.creditBureau;
  // const CmsData = data && data?.cms;
  // // const PersonalfinanacialData = data && data?.fi
  // const insuranceData = data && data?.insurance;
  // const suretyData = data && data?.surety;
  // const PersonalFinancialinfoData = data && data?.financialInfo;
  // let uploadData=datan&&datan.data.
  // const applicantFetchData =
  //   applicantFetch && applicantFetch?.data?.applications;
  // const applicantData = applicantFetchData?.find(
  //   (applicant: any) => applicant.applicationId === applicationId
  // );

  const validref = useRef(true);
  useEffect(() => {
    if (applicationId) {
      dispatch(
        getApplicationUserkey({
          applicationId,
        })
      );

      // Check QBO Status
      if (!qboState.escReason) {
        dispatch(CheckQboStatus(applicationId) as any);
      }
    }
  }, [applicationId]);
  // useEffect(() => {
  //   if (applicationId)
  //     dispatch(
  //       getApplicationById({
  //         applicationId,
  //       })
  //     );
  // }, [applicationId]);

  useEffect(() => {
    const { status, type, currentApplication, userKey } = appliactionsSlice;
    switch (status) {
      case 'loading':
        if (
          type === 'GET_APPLICATION_BY_ID' ||
          type === 'GET_APPLICATION_USER_KEY'
        ) {
          setIsLoading(true);
        }
        break;
      case 'succeed':
        if (type === 'GET_APPLICATION_BY_ID' && currentApplication) {
          setApplicationData(currentApplication);
          setIsLoading(false);
        }
        if (type === 'GET_APPLICATION_USER_KEY') {
          setIsLoading(false);
          const decoded: Record<string, any> = jwtDecode(userKey as string);
          const applicationKey = decrypt(
            decoded?.usKey,
            process.env.REACT_APP_SS_KEY as string
          );
          setApplicationUserKey(applicationKey);

          if (applicationId) {
            dispatch(
              getApplicationById({
                applicationId,
              })
            );
          }
        }
        break;
      case 'failed':
        if (
          type === 'GET_APPLICATION_BY_ID' ||
          type === 'GET_APPLICATION_USER_KEY'
        ) {
          setIsLoading(false);
        }
        break;

      default:
        break;
    }
  }, [appliactionsSlice.status]);

  useEffect(() => {
    if (applicationData && applicationKey) {
      const stepsWithForm = steps?.map((item, i) => {
        switch (item?.title) {
          case 'General & Banking': {
            return {
              ...item,
              form: () => (
                <AccoutingSystem
                  setIsSubmited={setIsSubmited}
                  isSubmited={isSubmited}
                  ref={stepRef}
                  formData={applicationData?.accountingSystem}
                />
              ),
              isEnable:
                UserType === 'broker'
                  ? true
                  : applicationData?.accountingSystem?.assignToApplicant ??
                    item?.isEnable,
            };
          }
          case 'Legal': {
            return {
              ...item,
              form: () => (
                <LegalForm
                  setIsSubmited={setIsSubmited}
                  isSubmited={isSubmited}
                  ref={stepRef}
                  formData={applicationData?.creditBureau}
                  accountingSystem={applicationData?.accountingSystem}
                  setActiveStep={setActiveStep}
                  applicationData={applicationData}
                />
              ),
              isEnable:
                UserType === 'broker'
                  ? true
                  : applicationData?.creditBureau?.assignToApplicant ??
                    item?.isEnable,
            };
          }
          case 'Personal Financial': {
            let PfFormAPIData = applicationData?.financialInfo;

            if (applicationData?.financialInfo?.ownersFinancialInfo) {
              let decodedOwnerDetails = decryptedPayload(
                applicationData?.financialInfo?.ownersFinancialInfo,
                applicationKey
              );
              PfFormAPIData = {
                ...PfFormAPIData,
                ownersFinancialInfo: decodedOwnerDetails,
              };
            }
            console.log('PfFormAPIData', PfFormAPIData);

            return {
              ...item,
              form: () => (
                <>
                  <OwnerPersonalFinanceInfo
                    applicationId={applicationId}
                    setIsSubmited={setIsSubmited}
                    setActiveStep={setActiveStep}
                    isSubmited={isSubmited}
                    ref={stepRef}
                    formData={PfFormAPIData}
                  />
                </>
              ),
              isEnable:
                UserType === 'broker'
                  ? true
                  : applicationData?.financialInfo?.assignToApplicant ??
                    item?.isEnable,
            };
          }
          case 'About': {
            return {
              ...item,
              form: () => (
                <AboutForm
                  setIsSubmited={setIsSubmited}
                  isSubmited={isSubmited}
                  ref={stepRef}
                  setActiveStep={setActiveStep}
                  formData={applicationData?.cms}
                  applicant={applicationData?.applicant}
                  accountingSystem={applicationData?.accountingSystem}
                />
              ),
              isEnable:
                UserType === 'broker'
                  ? true
                  : applicationData?.cms?.assignToApplicant ?? item?.isEnable,
            };
          }
          case 'Bond Application': {
            return {
              ...item,
              form: () => (
                <StepperBondApplicationForm
                  setIsSubmited={setIsSubmited}
                  isSubmited={isSubmited}
                  ref={stepRef}
                  formData={applicationData?.bondApplication}
                  applicant={applicationData?.applicant}
                  broker={applicationData?.broker}
                />
              ),
              // isEnable:
              //   UserType === 'broker'
              //     ? true
              //     : applicationData?.bondApplication?.assignToApplicant ??
              //       item?.isEnable,
              isEnable: true,
            };
          }
          case 'Surety': {
            return {
              ...item,
              form: () => (
                <Surety
                  setIsSubmited={setIsSubmited}
                  isSubmited={isSubmited}
                  ref={stepRef}
                  formData={applicationData?.surety}
                />
              ),
              isEnable:
                UserType === 'broker'
                  ? true
                  : applicationData?.surety?.assignToApplicant ??
                    item?.isEnable,
            };
          }
          case 'Insurance': {
            return {
              ...item,
              form: () => (
                <Insurance
                  setIsSubmited={setIsSubmited}
                  isSubmited={isSubmited}
                  ref={stepRef}
                  formData={applicationData?.insurance}
                  setformData={setApplicationData}
                />
              ),
              isEnable:
                UserType === 'broker'
                  ? true
                  : applicationData?.insurance?.assignToApplicant ??
                    item?.isEnable,
            };
          }
          default:
            return item;
        }
      });
      setSteps(stepsWithForm);
    }
  }, [applicationData]);

  // const fetchOwnerData = async () => {
  //   if (!!localStorage.getItem("Token")) {
  //     const actionResult = await dispatch(OwnerAllData() as any);
  //     const response = actionResult?.payload;
  //     if (response?.payload?.status === 401) {
  //       navigate("/login");
  //     }
  //   } else {
  //     navigate("/login");
  //   }
  // };
  type NestedObject = { [key: string]: any };
  const UserType = localStorage.getItem('userType');
  useEffect(() => {
    fetchOwnerDataWithQueryString();
  }, [UserType, applicationData?.applicant]);

  useEffect(() => {
    if (brokerageDashboard) {
      const { type, status, submitApplication, error } = brokerageDashboard;
      console.log('test', type, status, submitApplication);

      switch (status) {
        case 'succeed':
          if (type === 'SUBMITTED_APPLICATION_STATUS_CHANGE') {
            toast({
              title: submitApplication?.data,
              status: 'success',
            });
            navigate('/');
          }
          return;
        case 'failed':
          if (type === 'SUBMITTED_APPLICATION_STATUS_CHANGE') {
            toast({
              title: error,
              status: 'error',
            });
          }
          return;
      }
    }
  }, [brokerageDashboard?.status]);
  // const keysToIgnore: string[] = [
  //   'bureauId',
  //   'applicantId',
  //   'assignToApplicant',
  //   'createdAt',
  //   'updatedAt',
  //   'updatedData',
  //   'returnToSender',
  //   'workOutsideCanada',
  // ];

  const removeIdKeys = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map((item) => removeIdKeys(item));
    } else if (obj !== null && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj)
          .map(([key, value]) => [
            key,
            key === 'id' ? undefined : removeIdKeys(value),
          ])
          .filter(([key]) => key !== undefined)
      );
    } else {
      return obj;
    }
  };

  const arraysEqual = (
    array1: Record<string, any>[],
    array2: Record<string, any>[]
  ): boolean => {
    if (array1.length !== array2.length) return false;

    const cleanedArray1 = array1.map((item) => removeIdKeys(item));
    const cleanedArray2 = array2.map((item) => removeIdKeys(item));

    const compareObjects = (obj1: any, obj2: any): boolean => {
      if (typeof obj1 !== typeof obj2) return false;
      if (typeof obj1 !== 'object' || obj1 === null || obj2 === null)
        return obj1 === obj2;

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;

      for (const key of keys1) {
        if (!compareObjects(obj1[key], obj2[key])) return false;
      }

      return true;
    };

    for (let i = 0; i < cleanedArray1.length; i++) {
      if (!compareObjects(cleanedArray1[i], cleanedArray2[i])) return false;
    }

    return true;
  };

  function normalizeObject(
    obj: NestedObject,
    keysToIgnore: string[]
  ): NestedObject {
    const normalizedObj: NestedObject = {};
    for (const key in obj) {
      if (keysToIgnore.includes(key)) {
        continue;
      }
      if (
        typeof obj[key] === 'object' &&
        !Array.isArray(obj[key]) &&
        obj[key] !== null
      ) {
        normalizedObj[key] = normalizeObject(obj[key], keysToIgnore);
      } else {
        normalizedObj[key] = obj[key];
      }
    }
    return normalizedObj;
  }

  function areObjectsEqual(
    obj1: NestedObject,
    obj2: NestedObject,
    keysToIgnore: string[]
  ): boolean {
    const normalizedObj1 = normalizeObject(obj1, keysToIgnore);
    const normalizedObj2 = normalizeObject(obj2, keysToIgnore);

    const sortedObj1 = deepSortObject(normalizedObj1);
    const sortedObj2 = deepSortObject(normalizedObj2);

    return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2);
  }
  const fetchOwnerDataWithQueryString = async () => {
    if (!!localStorage.getItem('Token')) {
      if (UserType === 'broker') {
        const applicantId = applicationData?.applicant?.applicantId;
        if (!applicantId) return;
        const actionResult = await dispatch(
          OwnerData({
            queryString: `?applicantId=${applicantId}`,
          }) as any
        );
        const response = actionResult?.payload;
        if (response?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        const actionResult = await dispatch(
          OwnerData({ queryString: `` }) as any
        );
        const response = actionResult?.payload;
        if (response?.payload?.status === 401) {
          navigate('/login');
        }
      }
    } else {
      navigate('/login');
    }
  };

  const getOtherType = (data: any, key: string, returnKey: string) => {
    let otherIndex = data?.findIndex(
      (item: any) => item[`${key}`] === 'Others' || item[`${key}`] === 'Other'
    );
    return otherIndex >= 0 ? data[otherIndex][`${returnKey}`] : null;
  };

  useEffect(() => {
    const validateData = async () => {
      try {
        const isValidInfoByForms: Record<string, boolean> = {
          Accontingsys: false, //G&B
          creditBureau: false, //legal
          cms: false, //about
          insurance: false,
          surety: false,
          personalfinancialinfo: false,
        };
        let sections: any = [];
        if (applicationData?.accountingSystem) {
          const isAccontingValid = await AccoutingSystemSchema.validate(
            applicationData?.accountingSystem
          );
          isValidInfoByForms.Accontingsys = !!isAccontingValid;
        }
        if (applicationData?.creditBureau) {
          const isCreditBureauValid = await CreditBureauSchema(
            applicationData?.creditBureau
          ).validate(applicationData?.creditBureau);
          isValidInfoByForms.creditBureau = !!isCreditBureauValid;
        }
        if (applicationData?.financialInfo && applicationKey) {
          let PfFormAPIData = applicationData?.financialInfo;

          if (applicationData?.financialInfo?.ownersFinancialInfo) {
            let decodedOwnerDetails = decryptedPayload(
              applicationData?.financialInfo?.ownersFinancialInfo,
              applicationKey
            );
            PfFormAPIData = {
              ...PfFormAPIData,
              ownersFinancialInfo: decodedOwnerDetails,
            };
          }
          const isPersonalFinancialInfoValid =
            await PersonalFinancialInfoSchema.validate(PfFormAPIData);
          isValidInfoByForms.personalfinancialinfo =
            !!isPersonalFinancialInfoValid;
        }
        if (applicationData?.cms) {
          let updatedCms = {
            ...applicationData?.cms,
            workSubletOther: getOtherType(
              applicationData?.cms?.workSublet,
              'type',
              'otherSublet'
            ),
            managementControlOther: getOtherType(
              applicationData?.cms?.managementControl,
              'jobType',
              'otherJobType'
            ),
            contractorTypeOther: getOtherType(
              applicationData?.cms?.contractors,
              'type',
              'otherContractorType'
            ),
            // typeOfWorkOther: getOtherType(
            //   applicationData?.cms?.typeOfWork,
            //   'type',
            //   'otherType'
            // ),
            salesPercentageByOwnerTypeOther: getOtherType(
              applicationData?.cms?.salesPercentageByOwnerType,
              'ownerType',
              'otherOwnerType'
            ),
          };
          const isCmsValid = await CmsSchema.validate(updatedCms);
          isValidInfoByForms.cms = !!isCmsValid;
        }
        if (applicationData?.surety) {
          const isSuretyValid = await suretyvalidationSchema.validate(
            applicationData?.surety
          );
          isValidInfoByForms.surety = !!isSuretyValid;
        }
        if (applicationData?.insurance) {
          const isInsurenceValid = await InsuranceSchema.validate(
            applicationData?.insurance
          );
          isValidInfoByForms.insurance = !!isInsurenceValid;
        }

        const isFormsValid = Object.values(isValidInfoByForms).every(
          (el) => el === true
        );
        Object.entries(isValidInfoByForms).forEach(([k, d]) => {
          console.log('key=', k);
          console.log('kd=', d);
          if (!d) {
            switch (k) {
              case 'Accontingsys':
                sections.push('General & Banking');
                break;
              case 'creditBureau':
                sections.push('Legal');
                break;
              case 'personalfinancialinfo':
                sections.push('Personal Financial');
                break;
              case 'cms':
                sections.push('About');
                break;
              case 'surety':
                sections.push('Surety');
                break;
              case 'insurance':
                sections.push('Insurance');
                break;
            }
          }
        });
        console.log('isValidInfoByForms=', sections);
        console.error(isValidInfoByForms);
        setIsValidd(isFormsValid);
        setInvalidSections(sections);
      } catch (error: any) {
        console.error('Validation error for finish:', error.message);
        setIsValidd(false);
      }
    };

    validateData();
  }, [activeStep, applicationData, applicationKey]);

  const formSteps: Step[] = [
    {
      title: 'General & Banking',
      form: () => (
        <AccoutingSystem
          setIsSubmited={setIsSubmited}
          isSubmited={isSubmited}
          ref={stepRef}
        />
      ),
      isEnable: true,
    },
    {
      title: 'Legal',
      form: () => (
        <LegalForm
          setIsSubmited={setIsSubmited}
          isSubmited={isSubmited}
          ref={stepRef}
        />
      ),
      isEnable: true,
    },
    {
      title: 'Personal Financial',
      form: () => (
        <>
          <OwnerPersonalFinanceInfo
            applicationId={applicationId}
            setIsSubmited={setIsSubmited}
            setActiveStep={setActiveStep}
            isSubmited={isSubmited}
            ref={stepRef}
          />
        </>
      ),
      isEnable: true,
    },
    {
      title: 'About',
      form: () => (
        <AboutForm
          setIsSubmited={setIsSubmited}
          isSubmited={isSubmited}
          ref={stepRef}
          setActiveStep={setActiveStep}
        />
      ),
      isEnable: true,
    },
    {
      title: 'Bond Application',
      form: () => (
        <StepperBondApplicationForm
          setIsSubmited={setIsSubmited}
          isSubmited={isSubmited}
          ref={stepRef}
          setActiveStep={setActiveStep}
        />
      ),
      isEnable: true,
    },
    {
      title: 'Surety',
      form: () => (
        <Surety
          setIsSubmited={setIsSubmited}
          isSubmited={isSubmited}
          ref={stepRef}
        />
      ),
      isEnable: true,
    },
    {
      title: 'Insurance',
      form: () => (
        <Insurance
          setIsSubmited={setIsSubmited}
          isSubmited={isSubmited}
          ref={stepRef}
        />
      ),
      isEnable: true,
    },
    {
      title: 'Documents',
      form: () => (
        <Documents
          setIsSubmited={setIsSubmited}
          isSubmited={isSubmited}
          ref={stepRef}
        />
      ),
      isEnable: true,
    },
  ];
  const [steps, setSteps] = useState<Step[]>(formSteps);

  // function getSteps(stepObj: any) {
  //   switch (stepObj?.title) {
  //     case 'General & Banking': {
  //       return {
  //         ...stepObj,
  //         isEnable: applicantData?.accountingSystem?.assignToApplicant,
  //       };
  //     }
  //     case 'Legal': {
  //       return {
  //         ...stepObj,
  //         isEnable: applicantData?.creditBureau?.assignToApplicant,
  //       };
  //     }
  //     case 'Personal Financial': {
  //       return {
  //         ...stepObj,
  //         isEnable: applicantData?.financialInfo?.assignToApplicant,
  //       };
  //     }
  //     case 'About': {
  //       return { ...stepObj, isEnable: applicantData?.cms?.assignToApplicant };
  //     }
  //     case 'Surety': {
  //       return {
  //         ...stepObj,
  //         isEnable: applicantData?.surety?.assignToApplicant,
  //       };
  //     }
  //     case 'Insurance': {
  //       return {
  //         ...stepObj,
  //         isEnable: applicantData?.insurance?.assignToApplicant,
  //       };
  //     }
  //     case 'Documents': {
  //       return {
  //         ...stepObj,
  //         isEnable: true,
  //       };
  //     }
  //     default: {
  //       return { ...stepObj, isEnable: true };
  //     }
  //   }
  // }

  useEffect(() => {
    if (UserType === 'applicant') {
      // let updatedSteps = steps?.map((item: any) => {
      //   return getSteps(item);
      // });
      // setSteps(updatedSteps);

      const activeFormIndex = steps.findIndex((item: any) => item?.isEnable);
      setActiveStep(activeFormIndex > 0 ? activeFormIndex : 0);
    }
    // else {
    // let updatedSteps = steps?.map((item) => {
    //   return { ...item, isEnable: true };
    // });
    // setSteps(updatedSteps);
    // }
  }, [steps]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNext = () => {
    const keysone = stepRef?.current?.formik?.values;
    if (activeStep === 2) {
      // handleSubmitPersonalFinanceForms(true);
      let PfFormAPIData: Record<string, any> = {};

      if (
        applicationData?.financialInfo?.ownersFinancialInfo &&
        applicationKey
      ) {
        let decodedOwnerDetails = decryptedPayload(
          applicationData?.financialInfo?.ownersFinancialInfo,
          applicationKey
        );
        PfFormAPIData = {
          ...applicationData?.financialInfo,
          ownersFinancialInfo: decodedOwnerDetails,
        };
      } else {
        PfFormAPIData = { ...applicationData?.financialInfo };
      }
      const isSameOwnerDetails = arraysEqual(
        stepRef?.current?.PFFormOwnerData,
        PfFormAPIData?.ownersFinancialInfo ??
          stepRef?.current?.PFOwnerInitialDetails
      );
      console.log(
        'areEqual',
        isSameOwnerDetails,
        stepRef?.current?.PFFormOwnerData,
        PfFormAPIData?.ownersFinancialInfo,
        stepRef?.current?.PFOwnerInitialDetails
      );
      validref.current = isSameOwnerDetails;
    } else {
      if (activeStep === 0) {
        const valid = areObjectsEqual(
          stepRef?.current?.formik?.initialValues,
          stepRef?.current?.formik?.values,
          ['createdAt', 'updatedAt', 'returnToSender', 'assignToApplicant']
        );
        validref.current = valid;
      } else if (activeStep === 1) {
        const valid = areObjectsEqual(
          stepRef?.current?.formik?.initialValues,
          stepRef?.current?.formik?.values,
          [
            'bureauId',
            'applicantId',
            'assignToApplicant',
            'createdAt',
            'updatedAt',
            'updatedData',
            'returnToSender',
            'workOutsideCanada',
            'taxingEntityDoc',
            'applicationName',
            'receivershipOrBankruptcyAcc',
            'guaranteesForOtherPartiesAcc',
            'relatedCompaniesAcc',
            'changesInManagementAcc',
            'bondClaimsAcc',
            'buySellAgreementAcc',
            'uploadLegalDoc',
            'regulatoryFilings',
            'shareholderAgreements',
            'legalDocument',
            'agreementCopy',
            'courtFilings',
            'termsOfIndebtedness',
            'courtFilings',
            'paymentPlanUpload',
            'outstandingJudgmentsAcc',
          ]
        );
        validref.current = valid;
      } else if (activeStep === 3) {
        // const updatedValues = getCmsUpdatedValues(keysone);
        // const updatedCmsData = trimStringValues(
        //   removeFields(updatedValues, removeCmsFields)
        // );
        const removeFieldsKeys = [
          'applicantId',
          'assignToApplicant',
          'cmsId',
          'createdAt',
          'returnToSender',
          'updatedAt',
        ];
        // if (
        //   JSON.stringify(
        //     removeFields(deepSortObject(updatedCmsData), removeFieldsKeys)
        //   ) !==
        //   JSON.stringify(
        //     removeFields(deepSortObject(CmsFormData), removeFieldsKeys)
        //   )
        // ) {
        //   localStorage.setItem('isModify', 'false');
        // } else {
        //   localStorage.setItem('isModify', 'true');
        // }
        const valid = areObjectsEqual(
          stepRef?.current?.formik?.initialValues,
          stepRef?.current?.formik?.values,
          removeFieldsKeys
        );
        validref.current = valid;
      } else if (activeStep === 5) {
        const valid = areObjectsEqual(
          stepRef?.current?.formik?.initialValues,
          stepRef?.current?.formik?.values,
          [
            'applicantId',
            'assignToApplicant',
            'createdAt',
            'suretyId',
            'returnToSender',
            'updatedAt',
            'applicationName',
          ]
        );
        validref.current = valid;
      } else if (activeStep === 6) {
        const valid = areObjectsEqual(
          stepRef?.current?.formik?.initialValues,
          stepRef?.current?.formik?.values,
          [
            'applicantId',
            'assignToApplicant',
            'createdAt',
            'insuranceId',
            'returnToSender',
            'updatedAt',
          ]
        );
        validref.current = valid;
      }
      //new condition add for bond application form add at index 4
      // else if (activeStep === 4) {
      //   const valid = areObjectsEqual(
      //     stepRef?.current?.formik?.initialValues,
      //     stepRef?.current?.formik?.values,
      //     [
      //       'bondNumber',
      //       'brokerId',
      //       'createdAt',
      //       'insuranceId',
      //       'returnToSender',
      //       'updatedAt',
      //     ]
      //   );
      //   validref.current = valid;
      // }
    }

    console.log('validref', validref, stepRef);

    if (validref.current) {
      setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    } else {
      if (stepRef?.current?.formik?.isValid) {
        stepRef?.current?.formik?.handleSubmit();
        setTimeout(() => {
          setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
        }, 1000);
      } else {
        stepRef?.current?.formik?.handleSubmit();
      }
    }
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const CurrentForm = steps[activeStep].form;
  const userTypeLatest = localStorage.getItem('userType');
  const tokenLatest = localStorage.getItem('Token');

  let userResponse = userTypeLatest;
  let tokenResponse = tokenLatest;
  const navigate = useNavigate();

  // const OnHandelFinish = () => {
  //   navigate("/");
  // };

  const brokerSubmitApplication = () => {
    Swal.fire({
      title: `Do you want to update the application status?`,
      icon: 'info',
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: '#28a745',
      confirmButtonText: 'Yes',
      denyButtonColor: 'red',
      denyButtonAriaLabel: 'No',
      denyButtonText: 'No',
      showCloseButton: true,
      customClass: {
        confirmButton: 'btn-green',
        denyButton: 'btn-red',
      },
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Has the application been Approved?',
          icon: 'info',
          showCancelButton: false,
          showDenyButton: true,
          confirmButtonColor: '#28a745',
          confirmButtonText: 'Yes',
          denyButtonColor: 'red',
          denyButtonAriaLabel: 'No',
          denyButtonText: 'No',
          showCloseButton: true,
          customClass: {
            confirmButton: 'btn-green',
            cancelButton: 'btn-red',
          },
          allowOutsideClick: false,
        }).then(async (secondResult) => {
          try {
            if (
              secondResult.isConfirmed &&
              applicationData &&
              applicationData?.accountingSystem?.applicationId
            ) {
              await dispatch(
                SubmitApplicationBroker({
                  applicationId:
                    applicationData?.accountingSystem?.applicationId,
                  isApproved: secondResult.isConfirmed,
                }) as any
              );
            } else if (
              secondResult.isDenied &&
              applicationData &&
              applicationData?.accountingSystem?.applicationId
            ) {
              await dispatch(
                SubmitApplicationBroker({
                  applicationId:
                    applicationData?.accountingSystem?.applicationId,
                  isApproved: false,
                }) as any
              );
            }
          } catch (error) {
            // Handle error
            console.error('API call error:', error);
          }
        });
      }
    });
  };

  const OnHandelFinish = async () => {
    stepRef?.current?.formik?.handleSubmit();
    // if (stepRef?.current?.formik?.isValid) {
    if (
      UserType === 'broker' &&
      applicationStatus === 'Application Submitted. Awaiting Underwriting'
    ) {
      brokerSubmitApplication();
    } else {
      Swal.fire({
        title: `Is ${UserType === 'broker' ? 'broker' : 'applicant'} the final approver?`,
        icon: 'info',
        showCancelButton: false,
        showDenyButton: true,
        confirmButtonColor: '#28a745',
        confirmButtonText: 'Yes',
        denyButtonColor: 'red',
        denyButtonAriaLabel: 'No',
        denyButtonText: 'No',
        showCloseButton: true,
        customClass: {
          confirmButton: 'btn-green',
          denyButton: 'btn-red',
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            text: 'You are taking full responsibility and accuracy of the application, are you sure you want to be the final approver?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#28a745',
            confirmButtonText: 'Yes',
            cancelButtonColor: 'red',
            cancelButtonText: 'No',
            showCloseButton: true,
            customClass: {
              confirmButton: 'btn-green',
              cancelButton: 'btn-red',
            },
            allowOutsideClick: false,
          }).then(async (secondResult) => {
            try {
              if (
                secondResult.isConfirmed &&
                applicationData &&
                applicationData?.accountingSystem?.applicationId
              ) {
                const actionResult = await dispatch(
                  SubmitApplication({
                    applicationId:
                      applicationData?.accountingSystem?.applicationId,
                    isApproved: secondResult.isConfirmed,
                  }) as any
                );
                const response = actionResult?.payload;
                // Handle success response
                if (response?.status === 200) {
                  Swal.fire({
                    title: `Application is now complete and ready to download.`,
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    confirmButtonColor: '#28a745',
                    confirmButtonText: 'Yes',
                    cancelButtonColor: 'red',
                    cancelButtonText: 'No',
                    showCloseButton: true,
                    customClass: {
                      confirmButton: 'btn-green',
                      cancelButton: 'btn-red',
                    },
                    allowOutsideClick: false,
                  }).then(async (thirdResult) => {
                    if (thirdResult.dismiss) {
                      navigate('/');
                    } else {
                      // User clicked "No" on the final dialog
                      // Do something else if needed
                    }
                  });
                } else if (response?.status === 500) {
                  Toast({
                    title: response?.data,
                    icon: 'error',
                    isClosable: true,
                    duration: 3000,
                    position: 'top-right',
                  });
                } else if (response?.status === 401) {
                  navigate('/login');
                }
              }
            } catch (error) {
              // Handle error
              console.error('API call error:', error);
            }
          });
        } else if (
          result.isDenied &&
          applicationData &&
          applicationData?.accountingSystem?.applicationId
        ) {
          const actionResult = await dispatch(
            SubmitApplication({
              applicationId: applicationData?.accountingSystem?.applicationId,
              isApproved: false,
            }) as any
          );
          const response = actionResult?.payload;
          if (response?.status === 200) {
            Toast({
              title: response.msg,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else if (response?.status === 500) {
            Toast({
              title: response?.data,
              icon: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else if (response?.status === 401) {
            navigate('/login');
          }
        }
      });
    }
    // }
  };

  const fetchApplicationData = async () => {
    if (!!localStorage.getItem('Token')) {
      let actionResult = await dispatch(
        fetchDashboardDetails({
          applicationFilter: applicationStatus || 'Drafts',
        }) as any
      );
      if (actionResult?.payload?.status === 401) {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  };

  // useEffect(() => {
  //   fetchApplicationData();
  // }, []);

  const handleSubmitPersonalFinanceForms = async (flag: boolean) => {
    await dispatch(SubmitPersonalFinanceForms(flag) as any);
  };

  const handleNavigateBack = () => {
    dispatch(ClearQboStatus());
    navigate(-1);
  };

  // return (
  //   <Stack spacing={4} align="center" display={'block'}>
  //     {!paramsData.id ? (
  //       <NotFoundPage token={tokenResponse} userType={userResponse} />
  //     ) : (
  //       <Box sx={{ p: 5 }}>
  //         <Box
  //           bg={'#114684'}
  //           borderRadius={10}
  //           h={['470px', '430px', '375px', '330px']}
  //           p={5}
  //           display={'flex'}
  //           justifyContent={'space-between'}
  //           alignItems={'flex-start'}
  //         >
  //           <Tooltip label="Go back" placement="right" hasArrow>
  //             <Box
  //               style={{
  //                 background: '#ffa011',
  //                 borderRadius: '50%',
  //                 padding: '3px',
  //                 cursor: 'pointer',
  //               }}
  //             >
  //               <MdArrowBack
  //                 onClick={handleNavigateBack}
  //                 color="#114684"
  //                 size={35}
  //               />
  //             </Box>
  //           </Tooltip>
  //           <Container
  //             maxW="container.lg"
  //             justifyContent={'center'}
  //             alignItems={'flex-start'}
  //             display="flex"
  //             flexWrap={'wrap'}
  //             gap="10px"
  //             pt={3}
  //           >
  //             {/* <Tooltip label="Go back" placement="right" hasArrow>
  //               <Box
  //                 style={{
  //                   position: 'absolute',
  //                   left: '40px',
  //                   background: '#ffa011',
  //                   borderRadius: '50%',
  //                   padding: '3px',
  //                   cursor: 'pointer',
  //                 }}
  //               >
  //                 <MdArrowBack
  //                   onClick={handleNavigateBack}
  //                   color="#114684"
  //                   size={35}
  //                 />
  //               </Box>
  //             </Tooltip> */}

  //             {!isMobile
  //               ? steps.map((step, index) => (
  //                   <React.Fragment key={index}>
  //                     <Box
  //                       onClick={
  //                         step.isEnable ? () => setActiveStep(index) : () => {}
  //                       }
  //                       cursor={step.isEnable ? 'pointer' : 'not-allowed'}
  //                       opacity={!step.isEnable ? 0.5 : 1}
  //                     >
  //                       <Box
  //                         borderRadius="full"
  //                         bg={
  //                           index === activeStep && step.isEnable
  //                             ? '#ffa011'
  //                             : 'white'
  //                         }
  //                         color={
  //                           index === activeStep && step.isEnable
  //                             ? '#114684'
  //                             : '#ffa011'
  //                         }
  //                         fontWeight="bold"
  //                         p={2}
  //                         textAlign="center"
  //                         cursor={step.isEnable ? 'pointer' : 'not-allowed'}
  //                       >
  //                         {step.title}
  //                       </Box>
  //                     </Box>
  //                     {index < steps.length - 1 && (
  //                       <Progress
  //                         flex="1"
  //                         mt={4}
  //                         colorScheme="blue"
  //                         value={index < activeStep ? 100 : 0}
  //                         borderRadius="full"
  //                         minWidth={'20px'}
  //                         maxWidth={'20px'}
  //                         size="sm"
  //                       />
  //                     )}
  //                   </React.Fragment>
  //                 ))
  //               : steps.map((step, index) => (
  //                   <React.Fragment key={index}>
  //                     <Box
  //                       display={'flex'}
  //                       alignItems={'center'}
  //                       justifyContent={'center'}
  //                       flexDirection={'column'}
  //                       maxWidth={'35px'}
  //                       onClick={() => setActiveStep(index)}
  //                       cursor={step.isEnable ? 'pointer' : 'not-allowed'}
  //                       opacity={!step.isEnable ? 0.5 : 1}
  //                     >
  //                       <Box
  //                         borderRadius="100%"
  //                         display={'flex'}
  //                         alignItems={'center'}
  //                         justifyContent={'center'}
  //                         width={'34px'}
  //                         height={'34px'}
  //                         bg={
  //                           index === activeStep && step.isEnable
  //                             ? '#ffa011'
  //                             : 'white'
  //                         }
  //                         color={
  //                           index === activeStep && step.isEnable
  //                             ? '#114684'
  //                             : '#ffa011'
  //                         }
  //                         fontWeight="bold"
  //                         textAlign="center"
  //                         cursor={step.isEnable ? 'pointer' : 'not-allowed'}
  //                       >
  //                         {index + 1}
  //                       </Box>
  //                       <Box
  //                         color={index === activeStep ? '#ffa011' : 'white'}
  //                         fontSize={{ base: '12px', md: '14px' }}
  //                       >
  //                         {step.title}
  //                       </Box>
  //                     </Box>

  //                     {index < steps.length - 1 && (
  //                       <Progress
  //                         flex="1"
  //                         mt={4}
  //                         mx={2}
  //                         colorScheme="blue"
  //                         value={index < activeStep ? 100 : 0}
  //                         borderRadius="full"
  //                         minWidth={'20px'}
  //                         maxWidth={'20px'}
  //                         size="sm"
  //                       />
  //                     )}
  //                   </React.Fragment>
  //                 ))}
  //           </Container>
  //           {!isLoading && (
  //             <Flex direction={'column'} gap={2}>
  //               <Text color={'white'}>
  //                 Created Date:{' '}
  //                 {moment(applicationData?.createdAt).format('MM/DD/YYYY')}
  //               </Text>
  //               {applicationData?.updatedAt && (
  //                 <Text color={'white'}>
  //                   Updated Date:{' '}
  //                   {moment(applicationData?.updatedAt).format('MM/DD/YYYY')}
  //                 </Text>
  //               )}
  //             </Flex>
  //           )}
  //         </Box>
  //         <Box>
  //           {isLoading ? (
  //             <>
  //               <Box
  //                 display="flex"
  //                 gap={4}
  //                 w="min(88rem,95%)"
  //                 margin={'-236px auto 0'}
  //                 flexDirection={{ base: 'column', lg: 'row' }}
  //               >
  //                 <Container
  //                   maxW={'container.lg'}
  //                   boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
  //                   h="auto"
  //                   p="20px"
  //                   borderRadius="20px"
  //                   gap={5}
  //                   bg={'white'}
  //                 >
  //                   <FormSkeleton />
  //                 </Container>
  //               </Box>
  //             </>
  //           ) : (
  //             <CurrentForm onNext={handleNext} />
  //           )}
  //         </Box>
  //         {!isLoading && !showHistory && (
  //           <Container
  //             maxW="container.lg"
  //             mt={2}
  //             display="flex"
  //             justifyContent={'space-between'}
  //           >
  //             <Box>
  //               {activeStep > 0 && (
  //                 <Button
  //                   onClick={handlePrev}
  //                   bg={'#114684'}
  //                   w="100%"
  //                   color={'white'}
  //                   _hover={{ bg: '#114684', color: 'white' }}
  //                   isDisabled={!steps[+(activeStep - 1)].isEnable}
  //                 >
  //                   Previous
  //                 </Button>
  //               )}
  //             </Box>
  //             <Box>
  //               {activeStep < steps.length - 1 ? (
  //                 <Button
  //                   onClick={handleNext}
  //                   bg={'#114684'}
  //                   color={'white'}
  //                   _hover={{ bg: '#114684', color: 'white' }}
  //                   // isDisabled={!isSubmited[steps[activeStep].title]}
  //                   isDisabled={!steps[+(activeStep + 1)].isEnable}
  //                 >
  //                   Next
  //                 </Button>
  //               ) : (
  //                 applicationStatus !== 'Approved by Underwriter' &&
  //                 applicationStatus !== 'Declined by Underwriter' && (
  //                   <Tooltip
  //                     label="Please fill all the required fields."
  //                     isDisabled={isvalidd}
  //                   >
  //                     <Button
  //                       bg={'#114684'}
  //                       // isDisabled
  //                       color={'white'}
  //                       _hover={{ bg: '#114684', color: 'white' }}
  //                       onClick={OnHandelFinish}
  //                       isDisabled={!isvalidd}
  //                     >
  //                       Finish
  //                     </Button>
  //                   </Tooltip>
  //                 )
  //               )}
  //             </Box>
  //           </Container>
  //         )}
  //       </Box>
  //     )}
  //   </Stack>
  // );

  const getIcon = (step: number) => {
    switch (step) {
      case 0:
        return <BsBank2 size={20} />;
      case 1:
        return <GiClawHammer size={20} />;
      case 2:
        return <TbUserDollar size={20} />;
      case 3:
        return <BsInfoSquare size={20} />;
      case 4:
        return <LiaFileInvoiceDollarSolid size={20} />;
      case 5:
        return <TbShieldDollar size={20} />;
      case 6:
        return <IoDocumentAttachOutline size={20} />;
    }
  };
  return (
    <Stack spacing={4} align="center" display={'block'}>
      {!paramsData.id ? (
        <NotFoundPage token={tokenResponse} userType={userResponse} />
      ) : (
        <Flex direction="column" pl={5} pr={5}>
          <Flex
            bg={'#114684'}
            borderRadius={10}
            p={5}
            justifyContent={'space-between'}
            alignItems={'center'}
            position={'sticky'}
            top={0}
            width={'100%'}
            zIndex={200}
          >
            <Tooltip label="Go back" placement="right" hasArrow>
              <Box
                style={{
                  background: '#ffa011',
                  borderRadius: '50%',
                  padding: '3px',
                  cursor: 'pointer',
                }}
              >
                <MdArrowBack
                  onClick={handleNavigateBack}
                  color="#114684"
                  size={35}
                />
              </Box>
            </Tooltip>
            {!isLoading && (
              <VStack gap={2}>
                <Text color={'white'}>
                  Created Date:{' '}
                  {moment(applicationData?.createdAt).format('MM/DD/YYYY')}
                </Text>
                {applicationData?.updatedAt && (
                  <Text color={'white'}>
                    Updated Date:{' '}
                    {moment(applicationData?.updatedAt).format('MM/DD/YYYY')}
                  </Text>
                )}
              </VStack>
            )}
          </Flex>
          <Container maxW={'100%'}>
            <Flex flexWrap={'nowrap'} gap={3}>
              <Box
                width={'20%'}
                p={'20px 0'}
                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"
                pos={'sticky'}
                top={'84px'}
                left={'0'}
                height={'calc(100vh - 84px)'}
              >
                {steps.map((step, index) => (
                  <Flex
                    key={index}
                    direction={'column'}
                    p={2}
                    mb={'5px'}
                    gap={4}
                    width={'100%'}
                    cursor={step.isEnable ? 'pointer' : 'not-allowed'}
                    _hover={{ bg: '#ffa011' }}
                    className="group"
                    bg={
                      index === activeStep && step.isEnable
                        ? '#ffa011'
                        : 'white'
                    }
                    color={
                      index === activeStep && step.isEnable
                        ? '#114684'
                        : '#ffa011'
                    }
                  >
                    <Box
                      pl={15}
                      display="flex"
                      flexDirection="row"
                      gap={4}
                      alignItems="center"
                      opacity={!step.isEnable ? 0.5 : 1}
                      onClick={
                        step.isEnable ? () => setActiveStep(index) : undefined
                      }
                      _groupHover={{
                        color: '#114684',
                      }}
                    >
                      {/* Step Icon */}
                      {/* <Box
                        width="40px"
                        height="40px"
                        borderRadius="full"
                        bg={
                          index === activeStep && step.isEnable
                            ? '#ffa011'
                            : 'white'
                        }
                        color={
                          index === activeStep && step.isEnable
                            ? '#114684'
                            : '#ffa011'
                        }
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontWeight="bold"
                        mb={2}
                        border="2px solid #ffa011"
                      >
                        {index + 1}k
                      </Box> */}
                      {getIcon(index)}

                      {/* Step Title */}
                      <Box
                        textAlign="center"
                        fontSize={{ base: '12px', md: '16px' }}
                        // color={index === activeStep ? '#114684' : 'white'}
                        color={'#114684'}
                        fontWeight={index === activeStep ? 'bold' : 'normal'}
                        _groupHover={{ fontWeight: 'bold' }}
                      >
                        {step.title}
                      </Box>
                    </Box>

                    {/* Progress Connector */}
                    {/* {index < steps.length - 1 && (
                      <Box
                        width="4px"
                        height="20px"
                        bg={index < activeStep ? '#114684' : 'lightgray'}
                        borderRadius="full"
                      />
                    )} */}
                  </Flex>
                ))}
              </Box>
              <Box width={'80%'} flexGrow={1} overflow={'auto'} pt={'20px'}>
                <Flex
                  direction={'column'}
                  gap={2}
                  height={'100%'}
                  minH={'930px'}
                >
                  {isLoading ? (
                    <Box
                      display="flex"
                      gap={4}
                      w="min(88rem,95%)"
                      // margin={'-236px auto 0'}
                      flexDirection={{ base: 'column', lg: 'row' }}
                      h={'100%'}
                    >
                      <Container
                        maxW={'container.lg'}
                        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
                        h="auto"
                        p="20px"
                        borderRadius="20px"
                        gap={5}
                        bg={'white'}
                      >
                        <FormSkeleton />
                      </Container>
                    </Box>
                  ) : (
                    <CurrentForm onNext={handleNext} />
                  )}
                  {!isLoading && !showHistory && (
                    <Box w="min(88rem,95%)">
                      <Container
                        maxW="container.lg"
                        p="20px"
                        display={'flex'}
                        justifyContent={'space-between'}
                      >
                        <Box>
                          {activeStep > 0 && (
                            <Button
                              onClick={handlePrev}
                              bg={'#114684'}
                              w="100%"
                              color={'white'}
                              _hover={{ bg: '#114684', color: 'white' }}
                              isDisabled={!steps[+(activeStep - 1)].isEnable}
                            >
                              Previous
                            </Button>
                          )}
                        </Box>
                        <Box>
                          {activeStep < steps.length - 1 ? (
                            <Button
                              onClick={handleNext}
                              bg={'#114684'}
                              color={'white'}
                              _hover={{ bg: '#114684', color: 'white' }}
                              // isDisabled={!isSubmited[steps[activeStep].title]}
                              isDisabled={!steps[+(activeStep + 1)].isEnable}
                            >
                              Next
                            </Button>
                          ) : (
                            applicationStatus !== 'Approved by Underwriter' &&
                            applicationStatus !== 'Declined by Underwriter' &&
                            // <Tooltip
                            //   label="Please fill all the required fields."
                            //   isDisabled={isvalidd}
                            // >
                            (isvalidd ? (
                              <Button
                                bg={'#114684'}
                                // isDisabled
                                color={'white'}
                                _hover={{ bg: '#114684', color: 'white' }}
                                onClick={OnHandelFinish}
                                isDisabled={!isvalidd}
                              >
                                Finish
                              </Button>
                            ) : (
                              <HoverCard
                                initiallyOpened={!isvalidd}
                                width={'350px'}
                                shadow="md"
                                arrowPosition="center"
                                position="top"
                                withArrow={true}
                              >
                                <HoverCard.Target>
                                  <Button
                                    bg={'#fee2e2'}
                                    color={'#dc2626'}
                                    _hover={{ bg: '#fecaca' }}
                                  >
                                    Invalid Form
                                  </Button>
                                </HoverCard.Target>
                                <HoverCard.Dropdown p={'15px'}>
                                  <Text size="sm">
                                    There are some issues in following sections
                                    of the form:
                                  </Text>
                                  <List
                                    mt={'10px'}
                                    icon={<MdErrorOutline color="#dc2626" />}
                                    spacing="xs"
                                    size="md"
                                    center
                                  >
                                    {invalidSections.map((section) => (
                                      <List.Item>{section}</List.Item>
                                    ))}
                                  </List>
                                </HoverCard.Dropdown>
                              </HoverCard>
                            ))

                            // </Tooltip>
                          )}
                        </Box>
                      </Container>
                    </Box>
                  )}
                </Flex>
              </Box>
            </Flex>
          </Container>
        </Flex>
      )}
    </Stack>
  );
};

export default React.memo(Stepper);
