import { BondApplicationAttributes } from 'src/Pages/BrokerPages/BondApplicationForm/data';
import { APIStatus } from 'src/types/unions';

export interface BondApplicationAPIData extends BondApplicationAttributes {
  id: number;
  brokerId: number;
  createdBy: number;
  createdByType: string | null;
  createdAt: Date | null;
  assignedBy: number | null;
  assignedTo: number | null;
  assignedAt: Date | null;
}

export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_BOND_APPLICATION_FORM_DETAILS'
    | 'CREATE_BOND_APPLICATION_FORM'
    | 'UPDATE_BOND_APPLICATION_FORM_DETAILS'
    | null;
  bondApplicationData: BondApplicationAPIData | null;
  msg: string | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  bondApplicationData: null,
  msg: null,
};

export default initialState;
