import { APIStatus } from '../../types/unions';

export enum ocrCombinedReportList {
  BalanceSheetProfitLoss = 'Balance sheet / Profit and Loss',
  BalanceSheetProfitLossCashFlow = 'Balance sheet / Profit and Loss / Cash flow',
}

export interface IReportLinks {
  link: string;
  urlKey: string;
  comment: string | null;
  linkId?: number | null;
}

export interface IUploadReportsData {
  id: number;
  applicationId: number;
  reportInfo: {
    fiscalYearEndPeriodDate: string;
    fiscalYearStartPeriodDate: string;
    reportAsOf: string | null;
    reportName: string;
    timeFrame: string | null;
    type: string;
    financialReviewType: string;
    startDate: string | null;
    endDate: string | null;
    financialPeriod?: string;
  };
  reportLinks: IReportLinks[];
  reportUploadedBy: number;
  updatedInfo: string;
  hasDeletedOcrReports: boolean;
  reportStatus: string | null;
  history?: Array<{
    user_id: any;
    message: string;
    id: string;
    date: string;
  }> | null;
}

interface IInitialState {
  error: any;
  uploadReportsData?: IUploadReportsData | null;
  applicantReportsData?: IUploadReportsData[] | null;
  status: APIStatus | null;
  type?:
    | 'POST_UPLOAD_REPORTS'
    | 'GET_APPLICANT_REPORTS'
    | 'DELETE_REPORT'
    | 'UPDATE_REPORT'
    | 'REGENERATE_OCR_FROM_ORIGINAL_REPORT'
    | 'GET_OCR_REPORT_LIST_BY_REPORTID'
    | 'POST_UPLOAD_REPORTS_FINANCIAL'
    | null;
  // ocrReportData: {
  //   jsonData: Record<string, any>[];
  //   reportReviewLink: string;
  // } | null;
  ocrListOfCombinedReport: Record<string, any>[];
}

const initialState: IInitialState = {
  error: null,
  status: null,
  uploadReportsData: null,
  applicantReportsData: null,
  // ocrReportData: null,
  ocrListOfCombinedReport: [],
};

export default initialState;
