import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getToken } from 'src/utils/helpers';
import callAPI from '../../../utils/callAPI';
import initialState from './state';

export function isRejectedActionWithPayload(
  action: any
): action is { payload: { errorMessage: string } } {
  return action.payload && typeof action.payload.msg === 'string'
    ? action.payload.msg
    : '';
}

export type getReportListDataType = {
  applicationId: number;
  type: string | null;
  timeFrame: string | null;
  reportName: string | null;
};

export const getReportList = createAsyncThunk(
  'documentSlice/getReportList',
  async (reportData: getReportListDataType, { rejectWithValue }) => {
    try {
      const response = await callAPI('report/find', 'POST', reportData, true);

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const getLegalFormDocuments = createAsyncThunk(
  'documentSlice/getLegalFormDocuments',
  async (payload: { applicationId: number }, { rejectWithValue }) => {
    try {
      const response = await callAPI(
        `report/legal/docs/${payload?.applicationId}`,
        'GET',
        null,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const getPdffromServer = createAsyncThunk(
  'documentSlice/getPdffromServer',
  async ({ applicationId }: { applicationId: number }, { rejectWithValue }) => {
    try {
      // const response = await callAPI(
      //   'http://192.168.1.44:3001/api/pdfSummary/pdf',
      //   'POST',
      //   { applicationId },
      //   true,
      //   'application/pdf'
      // );
      // const blob = await response.data.blob();
      // console.log('blob', blob);

      // const url = window.URL.createObjectURL(blob);
      // console.log('url', url);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${getToken()}`);
      myHeaders.append('Cookie', `token=${getToken()}`);

      const raw = JSON.stringify({
        applicationId: 2,
      });

      const requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/pdfSummary/pdf`, requestOptions)
        .then((response) => {
          console.log(response);
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'file.pdf'; // Set the desired file name
            document.body.appendChild(a); // Append anchor to body
            a.click();
            a.remove(); // Remove anchor from body

            // Revoke the object URL after download
            window.URL.revokeObjectURL(url);
          });

          return response.text();
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const documentFormSlice = createSlice({
  name: 'documentSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReportList.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.data = action.payload.data;
      })
      .addCase(getReportList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getReportList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })

      //GET Legal form all documents
      .addCase(getLegalFormDocuments.fulfilled, (state, action) => {
        state.type = 'GET_LEGAL_FORM_DOCUMENTS';
        state.status = 'succeed';
        state.legalFormDocuments = action.payload.data;
      })
      .addCase(getLegalFormDocuments.pending, (state) => {
        state.type = 'GET_LEGAL_FORM_DOCUMENTS';
        state.status = 'loading';
      })
      .addCase(getLegalFormDocuments.rejected, (state, action) => {
        state.type = 'GET_LEGAL_FORM_DOCUMENTS';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const documentSlice = documentFormSlice.reducer;

export default documentSlice;
