import { Input } from '@chakra-ui/react';
import { debounce } from 'lodash';

export const getNestedValue = (obj: Record<string, any>, path: string) => {
  return path.split('.').reduce((currentValue, key) => {
    return currentValue?.[key];
  }, obj);
};

export const dynamicConditionCheck = (
  obj: Record<string, any>,
  name: string,
  showHistory: boolean,
  color: string = '#114684',
  defaultColor: string = 'white'
) => {
  if (
    showHistory &&
    obj?.updatedData &&
    getNestedValue(obj?.updatedData, name) !== undefined &&
    (name?.split('.')?.pop() ?? 'default') in
      getNestedValue(obj?.updatedData, name.substring(0, name.lastIndexOf('.')))
  ) {
    return color;
  }
  return defaultColor;
};

const AccordianInput = ({
  name,
  value,
  label,
  isHistoryMode = false,
  isReadOnly,
  currentHistoryData = {},
  formik,
}: {
  name: string;
  value: string;
  label: string;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
  formik: Record<string, any>;
}) => {
  const bgColor = dynamicConditionCheck(
    currentHistoryData,
    name,
    isHistoryMode,
    '#114684',
    'transparent'
  );

  const color = dynamicConditionCheck(
    currentHistoryData,
    name,
    isHistoryMode,
    'white',
    'black'
  );

  const updateNestedValue = (obj: any, keyPath: string, newValue: any) => {
    const keys = keyPath.split('.'); // Split the 'b.c.d' into ['b', 'c', 'd']
    let current = obj;

    // Iterate over the keys except the last one
    for (let i = 0; i < keys.length - 1; i++) {
      if (!current[keys[i]]) {
        current[keys[i]] = {}; // Create an empty object if the key doesn't exist
      }
      current = current[keys[i]]; // Navigate to the nested object
    }

    // console.log('current', current, newValue, keys);
    // Update the final key with the new value
    current[keys[keys.length - 1]] = newValue;

    return current;
  };

  const handleChange = debounce((name, val) => {
    const obj = formik.values;
    const newData = updateNestedValue(obj, name, val);
    console.log('femil data', name, obj, newData);

    formik.setValues(obj);
  }, 200);

  return (
    <Input
      fontSize={'13px'}
      w="100%"
      type="text"
      name={name}
      placeholder={label}
      borderRadius="5px"
      onChange={(e) => handleChange(name, e.target.value)}
      defaultValue={value}
      focusBorderColor="#7f7f7f"
      borderColor={'#7f7f7f'}
      _hover={{ borderColor: '#7f7f7f' }}
      readOnly={isReadOnly}
      color={color}
      bgColor={bgColor}
    />
  );
};

export default AccordianInput;
