export const MAX_UPLOAD_SIZE = 2097152; //in binary size = 2 MB;

// export const PDF_CSV_EXECLE_TYPES = ['.pdf', '.csv', '.xlsx'];
export const PDF_CSV_EXECLE_TYPES = [
  '.xlsx',
  '.xls',
  '.csv',
  '.pdf',
  '.doc',
  '.docx',
];
