import { Box, Flex, Text } from '@chakra-ui/react';
import { Button, Modal } from '@mantine/core';

const ApplicantProfileUpdateAppListModal = ({
  isOpen,
  onClose,
  onConfirm,
  applicationList,
  onDecline,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  applicationList: IApplication[];
  onDecline: () => void;
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="List of Application"
      centered
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      // size={isLoading || appHistoryData.length === 0 ? 'lg' : 'auto'}
      size={'lg'}
      className="global_history"
    >
      <Box margin={'10px 10px 0 10px'} maxW={'725px'} maxH={'600px'}>
        <Text
          textAlign="start"
          color="black"
          fontWeight={'600'}
          fontSize={'14px'}
          mb={3}
        >
          Updating the profile may change the below applications which are in
          draft
        </Text>
        <Flex gap={1} flexWrap={'wrap'}>
          {applicationList &&
            applicationList?.length > 0 &&
            applicationList?.map((app: any, index: number) => {
              //   if (index === applicationList?.length - 1) {
              //     return <Text key={app?.id}>{app?.applicationName}</Text>;
              //   } else {
              return (
                <Text key={app?.id}>
                  {app?.applicationName}
                  {index === applicationList?.length - 1 ? '' : ','}
                </Text>
              );
              //   }
            })}
        </Flex>
        <Flex justifyContent={'flex-end'} gap={2} mt={4}>
          <Button type="button" onClick={onConfirm}>
            Save
          </Button>
          <Button type="button" onClick={onDecline}>
            Cancel
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ApplicantProfileUpdateAppListModal;
