import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Modal, Title } from '@mantine/core';
import { useFormik } from 'formik';
import { MRT_Row, MRT_TableInstance } from 'mantine-react-table';
import { useEffect, useState } from 'react';
import { useAppToast } from 'src/hooks';
import { updateARRecord } from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  preventAlphabetsTyping,
  removeFields,
  trimStringValues,
} from 'src/utils/helpers';
import { ARSchemaEdit } from 'src/ValidationSchema/Pages/Index';

interface EditARReportProps {
  row: MRT_Row<Record<string, any>> | null;
  table: MRT_TableInstance<Record<string, any>> | null;
  modalOpen: boolean;
  onClose: () => void;
}

const EditAccountReceivable = ({
  table,
  row,
  modalOpen,
  onClose,
}: EditARReportProps) => {
  const [recordId, setRecordId] = useState<number | null>(null);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);
  const [formSubmit, setFormSubmit] = useState<boolean>(false);
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const dispatch = useAppDispatch();
  const toast = useAppToast();

  interface EditARFormValues {
    question: string;
    holdbackRetention: number | null;
    retentionValue: number | null;
    tableRow: Record<string, any> | null;
    id: number;
  }

  const initialValues: EditARFormValues = {
    question: 'Amount',
    holdbackRetention: null,
    retentionValue: null,
    tableRow: row && row?.original,
    id: row && row?.original && row?.original?.id,
  };

  useEffect(() => {
    console.log('tableRow', formik.values.tableRow, row, table);
    formik.setValues({
      question: row?.original?.question ?? 'Amount',
      holdbackRetention: row?.original?.holdbackRetention,
      retentionValue: row?.original?.retentionValue,
      tableRow: row && row?.original,
      id: row && row?.original && row?.original?.id,
    });
  }, [row?.original]);

  const submitARHandler = async (values: EditARFormValues) => {
    try {
      console.log('submited', values);
      setFormSubmit(true);
      const updatedARData = trimStringValues(
        removeFields(values, ['tableRow'])
      );
      let payload = {
        id: recordId,
        updatedData: Array(updatedARData),
      };
      console.log('payload', payload);
      await dispatch(updateARRecord({ payload }) as any);
    } catch (err) {
      console.log('error', err);
      setFormSubmit(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ARSchemaEdit,
    onSubmit: submitARHandler,
  });

  useEffect(() => {
    return () => {
      if (!modalOpen) {
        setFormSubmit(false);
      }
    };
  }, [modalOpen]);

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'GET_ACCOUNT_RECEIVABLE_DATA') {
          if (accountPayableReport?.data?.reports[0]?.id) {
            setRecordId(accountPayableReport?.data?.reports[0]?.id as any);
          }
        } else if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(false);
          toast({
            title: 'Success',
            status: 'success',
          });
          onClose();
        }
        return;
      }
      case 'loading': {
        if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(true);
        }
        return;
      }
      case 'failed': {
        if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(false);
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
          onClose();
        }
        return;
      }
    }
  }, [accountPayableReport.status]);

  useEffect(() => {
    console.log('formik', formik);

    if (!!formik.values.holdbackRetention) {
      const retentionValue =
        (formik.values?.holdbackRetention *
          formik?.values?.tableRow?.['91 and over']) /
        100;
      if (formik.values.question === 'Percentage') {
        formik.setFieldValue('retentionValue', retentionValue);
      } else {
        formik.setFieldValue(
          'retentionValue',
          formik.values?.holdbackRetention
        );
      }
    } else {
      formik.setFieldValue('retentionValue', null);
    }
  }, [formik.values.holdbackRetention]);

  useEffect(() => {
    if (formik.values.question === (row?.original?.question || 'Amount')) {
      formik.setFieldValue(
        'holdbackRetention',
        row?.original?.holdbackRetention
      );
      formik.setFieldValue('retentionValue', row?.original?.retentionValue);
    }
  }, [formik.values.question]);

  const { values, handleChange, setFieldValue, errors, resetForm } = formik;
  console.log('formik', formik, recordId);

  const getRetention = (number: number | null) => {
    return number ? number?.toFixed(2) : null;
  };

  return (
    <Modal opened={modalOpen} onClose={onClose} withCloseButton={true}>
      <form onSubmit={formik.handleSubmit}>
        <Stack>
          <Title order={3}>Edit Data</Title>
          <Grid gridTemplateColumns={'1fr'} gap={4}>
            <Box>
              <Text
                fontWeight="600"
                display="flex"
                justifyContent="start"
                fontSize={13}
                flexDir={'column'}
              >
                How do you want to add Holdback / Retention?
              </Text>
              <RadioGroup
                value={values?.question ?? ''}
                onChange={(value) => {
                  setFieldValue('question', value);
                  setFieldValue('holdbackRetention', null);
                  setFieldValue('retentionValue', null);
                }}
              >
                <Stack
                  direction="row"
                  display={'flex'}
                  flexWrap={'wrap'}
                  mt={2}
                >
                  <Radio value="Amount" size="sm">
                    Amount
                  </Radio>
                  <Radio value="Percentage" size="sm">
                    Percentage
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Box display={'flex'} flexDir={'column'} gap={1}>
              <Text
                fontWeight="600"
                display="flex"
                justifyContent="start"
                fontSize={13}
                flexDir={'column'}
              >
                {values?.question === 'Amount' ? '$' : ''} Add{' '}
                {values?.question}{' '}
                {values?.question === 'Percentage' ? '(%)' : ''}
              </Text>
              <Input
                fontSize={'13px'}
                w="100%"
                type="number"
                placeholder={`${values?.question === 'Amount' ? '$' : ''} Add ${values?.question} ${values?.question === 'Percentage' ? '(%)' : ''}`}
                name="holdbackRetention"
                value={values?.holdbackRetention ?? ''}
                onChange={handleChange}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                onKeyDown={preventAlphabetsTyping}
              />
              {errors.holdbackRetention && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {errors.holdbackRetention}
                </div>
              )}
            </Box>
            {values?.question !== 'Amount' && (
              <Box display={'flex'} flexDir={'column'} gap={1}>
                <Text
                  fontWeight="600"
                  display="flex"
                  justifyContent="start"
                  fontSize={13}
                  flexDir={'column'}
                >
                  Holdback / Retention
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder={`Holdback / Retention`}
                  name="retentionValue"
                  // value={values?.retentionValue ?? ''}
                  value={getRetention(values?.retentionValue) ?? ''}
                  onChange={handleChange}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  onKeyDown={preventAlphabetsTyping}
                  isDisabled={true}
                  _disabled={{
                    opacity: 1,
                  }}
                />
              </Box>
            )}
          </Grid>
          <Flex justify="flex-end" mt="xl">
            <Button type="button" variant="white" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoader}>
              Submit
            </Button>
            {/* <MRT_EditActionButtons variant="text" table={table} row={row} /> */}
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditAccountReceivable;
