import {
  Divider,
  HStack,
  Skeleton,
  SkeletonText,
  VStack,
} from '@chakra-ui/react';

const PlanBillingSkeleton = () => {
  return (
    <VStack gap={'1'}>
      <VStack
        shadow={'sm'}
        borderWidth="1px"
        borderColor={'gray.400'}
        borderRadius={'xl'}
        p={[4, 6, 6]}
        gap={4}
        flex={1}
        w={'100%'}
        maxW={['100%', '100%', '100%', '100%', '60rem']}
      >
        <VStack alignItems={'flex-start'} w={'100%'} gap={2}>
          <HStack justifyContent={'space-between'} w={'100%'} flexWrap="wrap">
            <Skeleton height="24px" width="150px" />
            {/* <Skeleton height="24px" width="120px" /> */}
          </HStack>
          <HStack justifyContent={'space-between'} w={'100%'} flexWrap="wrap">
            <Skeleton height="16px" width="200px" />
            {/* <Skeleton height="32px" width="120px" /> */}
          </HStack>
        </VStack>
        <Divider borderColor="gray.400" />
        <VStack alignItems="flex-start" w="100%" spacing={4}>
          <HStack justifyContent="space-between" w="100%" flexWrap="wrap">
            <Skeleton height="24px" width="100px" />
            <HStack>
              <Skeleton height="32px" width="100px" />
              <Skeleton height="32px" width="100px" />
            </HStack>
          </HStack>
          <VStack w="100%" alignItems="stretch" spacing={4}>
            <VStack
              flex={1}
              p={4}
              border={1}
              borderColor="gray.300"
              borderStyle="solid"
              rounded="lg"
              alignItems="flex-start"
              w="100%"
            >
              <HStack justifyContent="space-between" w="100%">
                <VStack alignItems={'flex-start'}>
                  <Skeleton height="16px" width="80px" />
                  <SkeletonText noOfLines={2} spacing="2" width="100px" />
                </VStack>
                <Skeleton height="24px" width="60px" />
              </HStack>
            </VStack>
            <HStack
              spacing={4}
              flexWrap="wrap"
              justifyContent="space-between"
              w="100%"
            >
              <Skeleton height="60px" width="45%" />
              <Skeleton height="60px" width="45%" />
            </HStack>
          </VStack>
        </VStack>
        <Divider borderColor="gray.400" />
        <VStack alignItems="flex-start" w="100%" spacing={4}>
          <VStack alignItems="flex-start" gap={2}>
            <Skeleton height="24px" width="80px" />
            <Skeleton height="16px" width="150px" />
          </VStack>
          <VStack
            gap={4}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            border={1}
            borderStyle="solid"
            borderColor="gray.300"
            w={'100%'}
            rounded="lg"
            p={4}
          >
            <HStack alignItems={'center'}>
              <Skeleton height="40px" width="40px" borderRadius="100%" />
              <Skeleton height="16px" width="120px" />
            </HStack>
            <HStack
              alignItems={'stretch'}
              width={'100%'}
              flexWrap="wrap"
              spacing={4}
            >
              <Skeleton height="60px" width="30%" />
              <Skeleton height="60px" width="30%" />
              <Skeleton height="60px" width="30%" />
            </HStack>
          </VStack>
        </VStack>
      </VStack>
      <VStack
        shadow={'sm'}
        borderWidth="1px"
        borderColor={'gray.400'}
        borderRadius={'xl'}
        p={[4, 6, 6]}
        gap={4}
        flex={1}
        w={'100%'}
        maxW={['100%', '100%', '100%', '100%', '60rem']}
      >
        <VStack alignItems={'flex-start'} w={'100%'} gap={2}>
          <HStack justifyContent={'space-between'} w={'100%'} flexWrap="wrap">
            <Skeleton height="24px" width="150px" />
            {/* <Skeleton height="24px" width="120px" /> */}
          </HStack>
          <HStack justifyContent={'space-between'} w={'100%'} flexWrap="wrap">
            <Skeleton height="16px" width="200px" />
            {/* <Skeleton height="32px" width="120px" /> */}
          </HStack>
        </VStack>
        <Divider borderColor="gray.400" />
        <VStack alignItems="flex-start" w="100%" spacing={4}>
          <HStack justifyContent="space-between" w="100%" flexWrap="wrap">
            <Skeleton height="24px" width="100px" />
            <HStack>
              <Skeleton height="32px" width="100px" />
              <Skeleton height="32px" width="100px" />
            </HStack>
          </HStack>
          <VStack w="100%" alignItems="stretch" spacing={4}>
            <VStack
              flex={1}
              p={4}
              border={1}
              borderColor="gray.300"
              borderStyle="solid"
              rounded="lg"
              alignItems="flex-start"
              w="100%"
            >
              <HStack justifyContent="space-between" w="100%">
                <VStack alignItems={'flex-start'}>
                  <Skeleton height="16px" width="80px" />
                  <SkeletonText noOfLines={2} spacing="2" width="100px" />
                </VStack>
                <Skeleton height="24px" width="60px" />
              </HStack>
            </VStack>
            <HStack
              spacing={4}
              flexWrap="wrap"
              justifyContent="space-between"
              w="100%"
            >
              <Skeleton height="60px" width="45%" />
              <Skeleton height="60px" width="45%" />
            </HStack>
          </VStack>
        </VStack>
        <Divider borderColor="gray.400" />
        <VStack alignItems="flex-start" w="100%" spacing={4}>
          <VStack alignItems="flex-start" gap={2}>
            <Skeleton height="24px" width="80px" />
            <Skeleton height="16px" width="150px" />
          </VStack>
          <VStack
            gap={4}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            border={1}
            borderStyle="solid"
            borderColor="gray.300"
            w={'100%'}
            rounded="lg"
            p={4}
          >
            <HStack alignItems={'center'}>
              <Skeleton height="40px" width="40px" borderRadius="100%" />
              <Skeleton height="16px" width="120px" />
            </HStack>
            <HStack
              alignItems={'stretch'}
              width={'100%'}
              flexWrap="wrap"
              spacing={4}
            >
              <Skeleton height="60px" width="30%" />
              <Skeleton height="60px" width="30%" />
              <Skeleton height="60px" width="30%" />
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default PlanBillingSkeleton;
