import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Flex, Select, Spinner, Text } from '@chakra-ui/react';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useSelector } from 'react-redux';
import { ApplicantBrokerage } from '../../../Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from '../../../Redux/Broker/Reports/WorkInProgress/state';
import { getWIPDataByApplicantIdAndFilter } from '../../../Redux/Broker/Reports/WorkingCapital/slice';
import {
  ICurrentAssets,
  ICurrentLiabilities,
  TFilterOption,
  TWICData,
} from '../../../Redux/Broker/Reports/WorkingCapital/state';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../Redux/Store';
import useAppToast from '../../../hooks/useAppToast';
import { formatNumber } from '../components/MantineTable';
import MantineAssetsTable from '../components/capitalTables/MantineAssetsTable';
import MantineLiabilitiesTable from '../components/capitalTables/MantineLiabilitiesTable';

type Props = {};

export type TCapitalRow = {
  capitalId: number;
  keyName: string;
  amount: number;
  adjustment: number;
  adjustmentAmount: number;
};

export interface ITotalAssets {
  totalAssetsAmount: number;
  totalAssetsAdjustment: number;
  totalAssetsAdjustmentAmount: number;
}
export interface ITotalLiabilities {
  totalLiabilitiesAmount: number;
  totalLiabilitiesAdjustment: number;
  totalLiabilitiesAdjustmentAmount: number;
}

export interface ITotalWICData {
  totalAssets: ITotalAssets;
  totalLiabilities: ITotalLiabilities;
}

export interface ITotalCapital {
  totalCapitalAmount: number;
  totalCapitalAdjustment: number;
  totalCapitalAdjustmentAmount: number;
}

interface IGetCapitalTotalData {
  totalAssetsAmount: number;
  totalAssetsAdjustment: number;
  totalAssetsAdjustmentAmount: number;
  totalLiabilitiesAmount: number;
  totalLiabilitiesAdjustment: number;
  totalLiabilitiesAdjustmentAmount: number;
}

export const fixedTwoDigitAsNumber = (num: number) => Number(num.toFixed(2));

export const getCapitalTotalData = ({
  totalAssetsAmount,
  totalAssetsAdjustment,
  totalAssetsAdjustmentAmount,
  totalLiabilitiesAmount,
  totalLiabilitiesAdjustment,
  totalLiabilitiesAdjustmentAmount,
}: IGetCapitalTotalData) => {
  const totalCapitalAmount = fixedTwoDigitAsNumber(
    totalAssetsAmount - totalLiabilitiesAmount
  );

  const totalCapitalAdjustmentAmount = fixedTwoDigitAsNumber(
    totalAssetsAdjustmentAmount - totalLiabilitiesAdjustmentAmount
  );

  const totalCapitalAdjustment = fixedTwoDigitAsNumber(
    Number((1 - totalCapitalAdjustmentAmount / totalCapitalAmount) * 100)
  );

  return {
    totalCapitalAmount,
    totalCapitalAdjustment,
    totalCapitalAdjustmentAmount,
  };
};

const ReportWorkingCapital = (props: Props) => {
  const [selectedBrokerageId, setSelectedBrokerageId] = useState<string>('');
  const [selectedApplicantData, setSelectedApplicantData] =
    useState<IApplicantData>();
  const [progressInfoLoading, setProgressInfoLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TFilterOption>(
    'This Fiscal Year-to-date'
  );

  // table data state
  const [assetsData, setAssetsData] = useState<ICurrentAssets[]>([]);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [liabilitiesData, setLiabilitiesData] = useState<ICurrentLiabilities[]>(
    []
  );
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [totalAssetsAndLiabilities, setTotalAssetsAndLiabilities] =
    useState<ITotalWICData>({
      totalAssets: {
        totalAssetsAmount: 0,
        totalAssetsAdjustment: 0,
        totalAssetsAdjustmentAmount: 0,
      },
      totalLiabilities: {
        totalLiabilitiesAmount: 0,
        totalLiabilitiesAdjustment: 0,
        totalLiabilitiesAdjustmentAmount: 0,
      },
    });

  const [totalCapital, setTotalCapital] = useState<ITotalCapital>({
    totalCapitalAmount: 0,
    totalCapitalAdjustment: 0,
    totalCapitalAdjustmentAmount: 0,
  });

  const dispatch = useAppDispatch();
  const toast = useAppToast();
  // store state
  const brokerWorkingCapital = useAppSelector(
    (state) => state.brokerWorkingCapital
  );
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );

  useEffect(() => {
    if (selectedBrokerageId && selectedOption) {
      setIsLoadingData(true);
      dispatch(
        getWIPDataByApplicantIdAndFilter({
          applicantId: Number(selectedBrokerageId),
          filter: selectedOption,
        })
      );
    }
  }, [selectedBrokerageId, selectedOption]);

  useEffect(() => {
    if (brokerageDashboard.applicantsByBrokrageData) {
      const selectedApplicantData =
        brokerageDashboard.applicantsByBrokrageData.find(
          (elm: any) => elm.applicantId == selectedBrokerageId
        );
      setSelectedApplicantData(selectedApplicantData);
    }
  }, [selectedBrokerageId]);

  useEffect(() => {
    userType === 'applicant' &&
      setSelectedApplicantData(brokerageDashboard.user?.data);

    return () => {};
  }, []);

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData) {
      dispatch(ApplicantBrokerage());
    } else {
      setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
    }
    return () => setProgressInfoLoading(false);
  }, []);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
      }
    }
  }, [brokerageDashboard.status, selectedBrokerageId]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'keyName', //access nested data with dot notation
        header: '',
        enableColumnActions: false,
        enableSorting: false,
        size: 54,
        maxSize: 104,
      },
      {
        accessorKey: 'totalAmount',
        header: 'Amount',
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: 'totalAdjustment', //normal accessorKey
        header: 'Adjustment (%)',
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => <>{cell.getValue() as number} %</>,
      },
      {
        accessorKey: 'totalAdjAmount',
        header: 'Revised Amount',
        enableColumnActions: false,
        enableSorting: false,
      },
    ],
    []
  );

  const data = [
    {
      keyName: 'Working Capital',
      totalAmount: formatNumber(totalCapital.totalCapitalAmount),
      totalAdjustment: formatNumber(totalCapital.totalCapitalAdjustment),
      totalAdjAmount: formatNumber(totalCapital.totalCapitalAdjustmentAmount),
    },
  ];

  const table = useMantineReactTable({
    columns,
    data,
    mantineTableBodyRowProps: {
      sx: {
        fontWeight: 'bold',
      },
    },
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    defaultColumn: { minSize: 40, maxSize: 70, size: 40 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    enableStickyHeader: false,
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
    },
  });

  const calculateTotal = useCallback(
    (data: TWICData) => {
      const {
        totalAssetsAmount,
        // totalAssetsAdjustment,
        totalAssetsAdjustmentAmount,
      } = data.currentAssets.reduce(
        (acc, curr) => {
          acc.totalAssetsAmount += curr.amount;
          // acc.totalAssetsAdjustment += curr.adjustment;
          acc.totalAssetsAdjustmentAmount += curr.adjustmentAmount;
          return acc;
        },
        {
          totalAssetsAmount: 0,
          // totalAssetsAdjustment: 0,
          totalAssetsAdjustmentAmount: 0,
        }
      );

      const totalAssetsAdjustment = parseFloat(
        Number(
          (1 - totalAssetsAdjustmentAmount / totalAssetsAmount) * 100
        ).toFixed(2)
      );

      const {
        totalLiabilitiesAmount,
        // totalLiabilitiesAdjustment,
        totalLiabilitiesAdjustmentAmount,
      } = data.currentLiabilities.reduce(
        (acc, curr) => {
          acc.totalLiabilitiesAmount += curr.amount;
          // acc.totalLiabilitiesAdjustment += curr.adjustment;
          acc.totalLiabilitiesAdjustmentAmount += curr.adjustmentAmount;
          return acc;
        },
        {
          totalLiabilitiesAmount: 0,
          // totalLiabilitiesAdjustment: 0,
          totalLiabilitiesAdjustmentAmount: 0,
        }
      );

      const totalLiabilitiesAdjustment = parseFloat(
        Number(
          (1 - totalLiabilitiesAdjustmentAmount / totalLiabilitiesAmount) * 100
        ).toFixed(2)
      );

      const {
        totalCapitalAmount,
        totalCapitalAdjustment,
        totalCapitalAdjustmentAmount,
      } = getCapitalTotalData({
        totalAssetsAmount,
        totalAssetsAdjustment,
        totalAssetsAdjustmentAmount,
        totalLiabilitiesAmount,
        totalLiabilitiesAdjustment,
        totalLiabilitiesAdjustmentAmount,
      });

      setTotalCapital({
        totalCapitalAmount,
        totalCapitalAdjustment,
        totalCapitalAdjustmentAmount,
      });

      setTotalAssetsAndLiabilities({
        totalAssets: {
          totalAssetsAmount,
          totalAssetsAdjustment,
          totalAssetsAdjustmentAmount,
        },
        totalLiabilities: {
          totalLiabilitiesAmount,
          totalLiabilitiesAdjustment,
          totalLiabilitiesAdjustmentAmount,
        },
      });
    },
    [JSON.stringify(brokerWorkingCapital.data)]
  );

  useEffect(() => {
    switch (brokerWorkingCapital.status) {
      case 'succeed': {
        if (
          brokerWorkingCapital.type === 'GET_WIC_DATA_BY_APPLICATIONID_API' &&
          brokerWorkingCapital.data
        ) {
          setIsLoadingData(false);
          setAssetsData(brokerWorkingCapital.data.currentAssets);
          setLiabilitiesData(brokerWorkingCapital.data.currentLiabilities);
          brokerWorkingCapital.data &&
            calculateTotal(brokerWorkingCapital.data);
        }
        if (
          brokerWorkingCapital.type === 'ADD_WIC_ROW' ||
          brokerWorkingCapital.type === 'REMOVE_WIC_API' ||
          brokerWorkingCapital.type === 'UPDATE_WIC_ROW'
        ) {
          dispatch(
            getWIPDataByApplicantIdAndFilter({
              applicantId: Number(selectedBrokerageId),
              filter: selectedOption,
            })
          );
        }
        if (brokerWorkingCapital.type === 'REFRESH_WIC_API') {
          dispatch(
            getWIPDataByApplicantIdAndFilter({
              applicantId: Number(selectedBrokerageId),
              filter: selectedOption,
            })
          );
        }
        break;
      }
      case 'loading': {
        if (brokerWorkingCapital.type === 'GET_WIC_DATA_BY_APPLICATIONID_API') {
          // setIsLoadingData(true);
        }
        break;
      }
      case 'failed': {
        if (brokerWorkingCapital.type === 'GET_WIC_DATA_BY_APPLICATIONID_API') {
          setIsLoadingData(false);
          setAssetsData([]);
          setLiabilitiesData([]);
        }
        break;
      }
      default:
        break;
    }
  }, [brokerWorkingCapital.status]);

  const userType = localStorage.getItem('userType');

  // data from store
  const userData = useSelector(
    (state: RootState) => state.brokerageDashboard.user
  );

  let Applicantbrokerage = userData && userData.data;

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setSelectedBrokerageId(selectedId);
  };

  useEffect(() => {
    if (userType && userType === 'applicant') {
      const userID = localStorage.getItem('userID') || '';
      handleBrokerageSelect(userID);
    }
  }, []);

  const showTable =
    ((selectedBrokerageId !== '' && selectedApplicantData) ||
      userType === 'applicant') &&
    !progressInfoLoading;
  return (
    <Box py={5}>
      {!progressInfoLoading && !showTable && userType === 'broker' && (
        <Text fontSize={'medium'}>Please Select Applicant</Text>
      )}
      <Flex alignItems={'left'} flexDirection={'column'} columnGap={5} pb={5}>
        {
          <Flex alignItems={'center'} mb={5} gap={1}>
            {userType === 'broker' && (
              <Box maxW="max-content">
                <Select
                  w="100%"
                  defaultValue={selectedBrokerageId}
                  onChange={handleBrokerageSelect}
                  value={selectedBrokerageId}
                  name="userData.brokerageId"
                  borderRadius="5px"
                  bg={'#114684'}
                  color={'white'}
                  textAlign={'center'}
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Applicant"
                  />
                  {applicantOptions?.map((user?: any, index?: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={user?.applicantId ?? ''}
                      label={user?.companyName ?? ''}
                    />
                  ))}
                </Select>
              </Box>
            )}
            <Box maxW="max-content">
              <Select
                w="100%"
                onChange={(e) => setSelectedOption(e.target.value as any)}
                value={selectedOption ?? ''}
                name="userData.brokerageId"
                borderRadius="5px"
                bg={'#114684'}
                color={'white'}
                textAlign={'center'}
              >
                <option
                  value="This Fiscal Year-to-date"
                  style={{ color: 'black' }}
                  label="This Fiscal Year-to-date"
                />
                <option
                  value="Last Fiscal Year"
                  style={{ color: 'black' }}
                  label="Last Fiscal Year"
                />
              </Select>
            </Box>
          </Flex>
        }

        {progressInfoLoading && (
          <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
            <Spinner size="xl" />
          </Flex>
        )}

        <Flex direction={'column'} gap={12} alignItems={'center'}>
          {showTable && (
            <Box maxW={'1550px'} w={'100%'}>
              <MantineReactTable table={table} />
            </Box>
          )}
          {showTable && (
            <Box maxW={'1550px'} w={'100%'}>
              <MantineAssetsTable
                tableData={assetsData}
                filterType={selectedOption}
                selectedApplicantData={selectedApplicantData as any}
                isInitialLoading={isLoadingData}
                assetsData={assetsData}
                liabilitiesData={liabilitiesData}
                totalAssetsAndLiabilities={totalAssetsAndLiabilities}
                totalCapital={totalCapital}
              />{' '}
            </Box>
          )}
          {showTable && (
            <Box maxW={'1550px'} w={'100%'}>
              <MantineLiabilitiesTable
                tableData={liabilitiesData}
                filterType={selectedOption}
                selectedApplicantData={selectedApplicantData as any}
                isInitialLoading={isLoadingData}
                assetsData={assetsData}
                liabilitiesData={liabilitiesData}
                totalAssetsAndLiabilities={totalAssetsAndLiabilities}
                totalCapital={totalCapital}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ReportWorkingCapital;
