import { Box, Flex, Tag, TagCloseButton } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
// import styles from './Input.module.css';

const InputTag = ({ onChange }: { onChange: (data: any) => void }) => {
  const [dataInput, setDataIput] = useState<string[]>([]);
  const [sizeInput, setSizeInput] = useState(() => 1);
  const ref_input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref_input.current?.focus(); // auto focus input
    function handleKeyUp(event: any) {
      const newText = ref_input.current?.value.trim().replace(',', '');
      switch (event.key) {
        case ',':
          if (newText && newText?.length > 0) {
            const dataInputTemp = [...dataInput];
            dataInputTemp.push(newText);
            setDataIput([...dataInputTemp]);
            onChange(dataInputTemp);
          } else {
          }
          if (ref_input.current) {
            ref_input.current.value = '';
          }
          break;
        case 'Enter':
          if (newText && newText.length > 0) {
            const dataInputTemp = [...dataInput];
            dataInputTemp.push(newText);
            setDataIput(() => [...dataInputTemp]);
            onChange(dataInputTemp);
            if (ref_input.current) ref_input.current.value = '';
          }
          break;
        case 'Backspace':
          if (dataInput.length > 0 && newText && newText.length === 0) {
            const dataInputTemp = [...dataInput];
            dataInputTemp.pop();
            setDataIput([...dataInputTemp]);
            onChange(dataInputTemp);
          }
          break;
        default:
          break;
      }
    }
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [sizeInput, dataInput]);

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    if (value.trim().length > 0) {
      setSizeInput(value.length);
    } else {
      if (ref_input.current) ref_input.current.value = '';
    }
  };

  function handleDelItem(index: number) {
    const dataInputTemp = [...dataInput];
    dataInputTemp.splice(index, 1);
    setDataIput(() => [...dataInputTemp]);
  }

  return (
    <div>
      <Flex
        align={'start'}
        style={{
          border: '1px solid #114684',
          borderRadius: '5px',
          padding: '5px',
          gap: '5px',
        }}
        onClick={() => {
          if (ref_input.current) ref_input.current.focus();
        }}
      >
        <Box>
          {dataInput.map((text, i) => (
            <Tag
              key={i + '_' + text}
              colorScheme="cyan"
              style={{ border: 'none', marginRight: '5px', marginTop: '5px' }}
            >
              {text}
              <TagCloseButton onClick={() => handleDelItem(i)} />
            </Tag>
          ))}
          <input
            ref={ref_input}
            onChange={handleChangeInput}
            size={sizeInput}
            style={{ border: 'none', outline: 'none', marginTop: '5px' }}
          />
        </Box>
      </Flex>
    </div>
  );
};

export default InputTag;
