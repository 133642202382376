export type APIStatus = 'succeed' | 'failed' | 'loading';

export interface DocumentsData {
  status: APIStatus | null;
  error: any;
  msg?: string;
  data?: any;
  legalFormDocuments?: Record<string, any>;
  type?: 'GET_LEGAL_FORM_DOCUMENTS';
}

const initialState: DocumentsData = {
  error: null,
  status: null,
};

export default initialState;
