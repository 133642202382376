import {
  Badge,
  Box,
  Button,
  Tooltip as ChakraTooltip,
  Container,
  Flex,
  Select,
  Text,
} from '@chakra-ui/react';
import { ModalsProvider, modals } from '@mantine/modals';
import { useEffect, useMemo, useState } from 'react';

import { ActionIcon, Loader, Tooltip } from '@mantine/core';
import { useDidUpdate, useDisclosure } from '@mantine/hooks';
import { pdf, usePDF } from '@react-pdf/renderer';
import { IconCircleX, IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import JSZip from 'jszip';
import {
  MRT_ColumnDef,
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import 'rc-datepicker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FaFileDownload, FaRegShareSquare } from 'react-icons/fa';
import { FcApproval } from 'react-icons/fc';
import { MdOutlineFileUpload } from 'react-icons/md';
import { SiConvertio } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { getBondApploicationDetails } from 'src/Redux/Broker/BondApplicationForm/slice';
import {
  TBondList,
  TBondRequest,
} from 'src/Redux/Broker/BondRequest/bondRequest';
import {
  BondApplicationApprove,
  BondApplicationAssignTo,
  convertToFinalBond,
  deleteBondApplication,
  getBondApplicationDetails,
  removeBondRequestType,
} from 'src/Redux/Broker/BondRequest/slice';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast, useZipFromLinksWithFolder } from 'src/hooks';
import PDFForm from 'src/templates/pdfs/PDFForm';
import {
  TApplicationTypeEnum,
  TApplicationtype,
} from '../BondApplicationForm/data';
import BondRequestApproveModal from './components/BondRequestApproveModal';
import BondRequestRejectModal from './components/BondRequestRejectModal';

export interface DeleteModel {
  applicationId: number;
  applicantName: string;
  id: number;
  row: any;
}

interface BondTable {
  id: any;
  bondNumber: number;
  bondApplicationType: TApplicationtype;
  createdDate: string;
  status: string;
  applicantName: string;
  isRejected: number;
  applicationId: number | null;
}

const bondApplicationDatas = {
  status: 200,
  msg: 'Success',
  data: {
    id: 4,
    attention: 'Preferred Client',
    finalBondNumber: '967',
    brokerName: 'Cassandra Peterson',
    brokerPhone: 1222222222,
    brokerEmail: 'mobegabata@mailinator.com',
    applicantName: 'Emily Todd',
    applicantPhone: 1212222222,
    applicantEmail: 'dili@mailinator.com',
    dateOfRequisition: '2025-01-21T14:25:37.694Z',
    dateRequired: '2025-01-21T14:26:05.598Z',
    generalContractorName: 'Eaton Lowery',
    generalContractorPhone: 4344344344,
    generalContractorEmail: 'dozawaxar@mailinator.com',
    engineerOrArchitectName: 'Zachery Ruiz',
    engineerOrArchitectPhone: 4343434433,
    engineerOrArchitectEmail: 'tesizapywy@mailinator.com',
    ownerName: '434derer',
    ownerPhone: 3434444444,
    ownerEmail: 'dycu@mailinator.com',
    typeOfOwner: 'Government',
    typeOfWork: ['Paving'],
    workOnHandsAsOfDate: 'sdds',
    totalBondedJobs: {
      contractPrice: 23,
      costToComplete: 32,
    },
    totalUnbondedJobs: {
      contractPrice: 32,
      costToComplete: 32,
    },
    totalOutstandingBids: {
      contractPrice: 32,
      costToComplete: 32,
    },
    totalSum: {
      contractPrice: 87,
      costToComplete: 96,
    },
    projectName: 'Zane Elliott',
    projectLocation: 'Ontario, Canada',
    projectLocationCountry: 'Canada',
    projectStartDate: '2025-01-21T14:26:54.293Z',
    projectEndDate: '2025-01-22T14:26:56.422Z',
    uploadContract: [
      {
        linkId: '7bit',
        link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/documents/dummy.pdf-1737469636288?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOUTPGHTQU6%2F20250121%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20250121T142716Z&X-Amz-Expires=604800&X-Amz-Signature=22aab0367ba88301a7f29d1a4ec78ae2c2ee29aaff93438547af6b16033f4ccf&X-Amz-SignedHeaders=host&x-id=GetObject',
        urlKey: 'documents/dummy.pdf-1737469636288',
      },
    ],
    contractDetails: 'CCDC',
    contractDetailsOther: null,
    privateOwnersClause: false,
    contractType: 'FP',
    estimatedSubletBudget: 300,
    workSublet: [
      {
        name: 'swe',
        amount: 1,
        bonded: true,
      },
    ],
    paymentTerms: 'Milestone',
    holdback: false,
    holdbackPercentage: null,
    architectEstimate: 12,
    maintenanceWarrantyTerm: 12,
    penaltiesInContract: true,
    penaltyContranctReferences: [
      {
        linkId: '7bit',
        link: 'https://subtraid-insurance.s3.us-east-2.amazonaws.com/documents/sample.pdf-1737469644114?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA3PSX6IOUTPGHTQU6%2F20250121%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20250121T142724Z&X-Amz-Expires=604800&X-Amz-Signature=7263e25039c30a7c5cf74b197e81b90fc8ae44b929fa8b46c6894aba5d9ece52&X-Amz-SignedHeaders=host&x-id=GetObject',
        urlKey: 'documents/sample.pdf-1737469644114',
      },
    ],
    arePenaltiesCapped: false,
    liquidatedDamages: false,
    liquidatedDamagesReferences: null,
    liquidatedDamagesCapped: null,
    environmentalHazards: false,
    designResponsibility: false,
    bondForm: {
      bondFormValue: 'Federal',
      otherDetails: null,
    },
    bondApplicationType: 'Bid',
    bidBondDetails: {
      estimatedContractPrice: 12,
      tenderDate: '2025-01-21T14:27:30.389Z',
      bidBondAmount: 12,
      acceptancePeriod: 21,
      preQualificationLetter: null,
      isPreQualificationLetter: false,
      additionalComments: '21',
      attachments: [],
      performanceBondAmount: 21,
      paymentBondAmount: '21',
      maintenanceBondAmount: '21',
    },
    finalBondDetails: {
      contractPrice: 1200000,
      includesTaxes: true,
      taxType: 'Federal',
      contractDate: '2025-01-20',
      performanceBondAmount: 600000,
      paymentBondAmount: 600000,
      maintenanceBondAmount: 120000,
    },
    premiumCalculation: 1025,
    approvedDate: null,
    approver: null,
    approvalStamp: null,
    consentOfSurety: null,
    tenderResults: {
      isNegotiated: null,
      bidders: [
        {
          companyName: 'ABC Bidders Inc.',
          amount: 1150000,
        },
        {
          companyName: 'XYZ Competitive Ltd.',
          amount: 1180000,
        },
      ],
    },
    applicantId: 42,
    status: 'pending',
    brokerId: 17,
    createdBy: 17,
    createdByType: 'broker',
    createdAt: '2025-01-22T04:44:21.000Z',
  },
};

const BondRequest = () => {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<{
    applicantLoader: boolean;
    applicationLoader: boolean;
    contentLoader: boolean;
    btnLoader: boolean;
    isSubmitLoader: boolean;
    bondLoader: boolean;
    convertToFinalBond: boolean;
  }>({
    applicantLoader: false,
    applicationLoader: false,
    contentLoader: false,
    btnLoader: false,
    isSubmitLoader: false,
    bondLoader: false,
    convertToFinalBond: false,
  });
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [tableContentLoader, setTableContentLoader] = useState<boolean>(false);
  const [loadingRow, setLoadingRow] = useState<any>(null);
  const [applicantData, setApplicantData] = useState<TBondList[]>([]);
  const [bondApplicationsData, setBondApplicationsData] = useState<any>([]);
  const [applicantId, setApplicantId] = useState<number>();
  const [bondPdfDownload, setBondPdfDownload] = useState<boolean>(false);
  const [applicationOptions, setApplicationOptions] = useState<
    Record<string, any>[]
  >([]);
  const [bondRequestData, setBondRequestData] = useState<Record<
    string,
    any
  > | null>(null);
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const createApplicationForm = useAppSelector(
    (state) => state.createApplicationFormSlice
  );
  const bondRequestSlice = useAppSelector((state) => state.bondRequestSlice);

  const bondApplicationSlice: any = useAppSelector(
    (state) => state.bondApplicationSlice
  );
  const userData = useAppSelector((state) => state.broker.brokerProfileData);

  const { createZipFromFolderAndLinks, error, loading } =
    useZipFromLinksWithFolder();

  const navigate = useNavigate();
  const toast = useAppToast();
  const [
    isbondRequestApprovedModal,
    {
      open: openIsbondRequestApprovedModal,
      close: closeIsbondRequestApprovedModal,
    },
  ] = useDisclosure(false);
  const [
    isbonsRequestRejectedModal,
    {
      open: openIsbonsRequestRejectedModal,
      close: closeIsbonsRequestRejectedModal,
    },
  ] = useDisclosure(false);
  const [instance, updateInstance] = usePDF();

  const userType = localStorage.getItem('userType');
  const userID = localStorage.getItem('userID');

  const [initialValues, setInitialValues] = useState<TBondRequest>({
    createdby: Number(userID),
    createdByType: userType,
    applicantId: null,
    typeOfWorkOther: null,
    typeOfWork: [
      {
        type: null,
        otherType: null,
      },
    ],
    estimatedSubletBudget: null,
    ownerName: null,
    contractPrice: null,

    typeOfProjectOwner: {
      type: null,
      otherType: null,
    },
  });

  useDidUpdate(() => {
    if (!instance?.loading && instance?.url) {
      console.log('instance-pdf', instance);
      dispatch(
        BondApplicationApprove({
          status: 'downloaded',
          bondApplicationId: bondRequestData?.id!,
        })
      );
    }
  }, [instance?.loading, instance?.url]);

  useEffect(() => {
    console.log('userType', userType);

    if (userType === 'broker') {
      dispatch(ApplicantBrokerage(true));
    } else if (userType === 'applicant') {
      setApplicantId(Number(userID));
      getBondApplicationInfo(Number(userID));
    }

    return () => {
      dispatch(removeBondRequestType({}));
    };
  }, []);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    switch (status) {
      case 'loading': {
        if (type === 'GET_APPLICANT_BY_BROKERAGE') {
          setLoader({ ...loader, applicantLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (
          type === 'GET_APPLICANT_BY_BROKERAGE' &&
          !!applicantsByBrokrageData?.length
        ) {
          setApplicantOptions(applicantsByBrokrageData);
          setLoader({ ...loader, applicantLoader: false });
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICANT_BY_BROKERAGE') {
          setLoader({ ...loader, applicantLoader: false });
        }
        break;
      }
      default:
        break;
    }
  }, [brokerageDashboard.status]);

  useEffect(() => {
    const { status, type, applications } = createApplicationForm;
    switch (status) {
      case 'loading': {
        if (type === 'GET_APPLICATIONS_BY_APPLICANT_ID') {
          setLoader({ ...loader, applicationLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (
          type === 'GET_APPLICATIONS_BY_APPLICANT_ID' &&
          !!applications?.length
        ) {
          setApplicationOptions(applications);
          setLoader({ ...loader, applicationLoader: false });
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICATIONS_BY_APPLICANT_ID') {
          setLoader({ ...loader, applicationLoader: false });
        }
        break;
      }
      default:
        break;
    }
  }, [createApplicationForm.status]);

  useEffect(() => {
    const { status, type, error, msg } = bondRequestSlice;

    switch (status) {
      case 'loading': {
        if (type === 'GET_BOND_APPLICATION_DETAILS') {
          setTableContentLoader(true);
          setLoader({ ...loader, contentLoader: true });
        }
        if (type === 'DELETE_BOND_APPLICATION') {
          setTableContentLoader(true);
          setLoader({ ...loader, contentLoader: true });
        }
        if (type === 'BOND_APPLICATION_STATUS_UPDATE') {
          setLoader((prev) => ({ ...prev, isSubmitLoader: true }));
        }
        if (type === 'BOND_APPLICATION_CONVERT_TO_FINAL_BOND') {
          setLoader((prev) => ({ ...prev, convertToFinalBond: true }));
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_BOND_APPLICATION_DETAILS') {
          setLoader({ ...loader, contentLoader: false, isSubmitLoader: false });
          setApplicantData(bondRequestSlice?.bondList);
          setTableContentLoader(false);
        }
        if (type === 'DELETE_BOND_APPLICATION') {
          setLoader({ ...loader, contentLoader: false });
          getBondApplicationInfo(applicantId as number);
          toast({
            title: 'Application deleted successfully.',
            status: 'success',
          });

          setTableContentLoader(false);
        }
        if (type === 'BOND_APPLICATION_ASSIGN') {
          setLoader({ ...loader, contentLoader: false });
          getBondApplicationInfo(applicantId as number);
          toast({
            title: 'Application assigned successfully.',
            status: 'success',
          });
        }
        if (type === 'BOND_APPLICATION_STATUS_UPDATE') {
          setLoader((prev) => ({ ...prev, isSubmitLoader: false }));
          toast({
            title: msg,
            status: 'success',
          });
          closeIsbondRequestApprovedModal();
          closeIsbonsRequestRejectedModal();
          getBondApplicationInfo(applicantId as number);
        }
        if (type === 'BOND_APPLICATION_CONVERT_TO_FINAL_BOND') {
          setLoader((prev) => ({ ...prev, convertToFinalBond: false }));
          toast({
            title: msg,
            status: 'success',
          });
        }
        break;
      }
      case 'failed': {
        toast({
          title: error,
          status: 'error',
        });
        if (type === 'GET_BOND_APPLICATION_DETAILS') {
          setTableContentLoader(false);
          setLoader({ ...loader, contentLoader: false });
        }
        if (type === 'BOND_APPLICATION_ASSIGN') {
          setLoader({ ...loader, btnLoader: false });
        }
        if (type === 'BOND_APPLICATION_STATUS_UPDATE') {
          setLoader((prev) => ({ ...prev, isSubmitLoader: false }));
          closeIsbondRequestApprovedModal();
          closeIsbonsRequestRejectedModal();
        }
        if (type === 'BOND_APPLICATION_CONVERT_TO_FINAL_BOND') {
          setLoader((prev) => ({ ...prev, convertToFinalBond: false }));
        }
        break;
      }
      default:
        break;
    }
  }, [bondRequestSlice.status, bondRequestSlice.type]);

  const getBondApplicationInfo = (applicantId: number) => {
    dispatch(
      getBondApplicationDetails({
        applicantId: applicantId,
      })
    );
  };

  useEffect(() => {
    const { status, type, error, msg } = bondApplicationSlice;
    if (!!bondPdfDownload) {
      switch (status) {
        case 'loading': {
          if (type === 'GET_BOND_APPLICATION_FORM_DETAILS') {
            setLoader({ ...loader, bondLoader: true, contentLoader: true });
          }
          break;
        }
        case 'succeed': {
          if (type === 'GET_BOND_APPLICATION_FORM_DETAILS') {
            setLoader({ ...loader, bondLoader: false, contentLoader: false });
            setBondApplicationsData(bondApplicationSlice?.bondApplicationData!);
            DownloadPdf(bondApplicationSlice?.bondApplicationData);
            dispatch(
              BondApplicationApprove({
                status: 'downloaded',
                bondApplicationId: bondRequestData?.id!,
              })
            );
          }
          break;
        }
        case 'failed': {
          toast({
            title: error,
            status: 'error',
          });
          if (type === 'GET_BOND_APPLICATION_FORM_DETAILS') {
            setLoader({ ...loader, bondLoader: false, contentLoader: false });
          }
          break;
        }
        default:
          break;
      }
    }
  }, [bondPdfDownload, bondApplicationSlice.status]);

  const setUpdatedBondRequestValue = (formData: TBondRequest) => {
    console.log('formData', formData);
    if (formData) {
      const typeOfWork = formData?.typeOfWork
        ? formData?.typeOfWork?.map((contract1: any, i: number) => {
            if (contract1?.type === 'Others') {
              return {
                type: contract1.type,
                otherType: contract1.otherType,
              };
            } else {
              return {
                type: contract1.type,
              };
            }
          })
        : [];

      const formValues = {
        ...initialValues,
        createdby: formData?.createdby,
        createdByType: formData?.createdByType,
        applicantId: formData?.applicantId,
        typeOfWorkOther: getOtherType(
          formData?.typeOfWork,
          'type',
          'otherType'
        ),
        typeOfWork: typeOfWork,
        estimatedSubletBudget: formData?.estimatedSubletBudget,
        ownerName: formData?.ownerName,
        contractPrice: formData?.contractPrice,
        typeOfProjectOwner: {
          type: formData?.typeOfProjectOwner?.type,
          otherType: formData?.typeOfProjectOwner?.otherType,
        },
      };
      setInitialValues(formValues);
    }
  };

  const openDeleteConfirmModal = (row: MRT_Row<DeleteModel>) => {
    console.log('delete-row', row);
    return modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete{' '}
          <Box as="span" fontWeight={'600'}>
            This Bond application {row?.original?.id}
          </Box>
          ? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: {
        color: 'red',
      },
      onConfirm: async () => {
        if (row?.original?.id) {
          dispatch(
            deleteBondApplication({
              reportID: row?.original?.id,
            })
          );
        }
      },
    });
  };

  const openAssignConfirmModal = (row: MRT_Row<DeleteModel>) => {
    console.log('delete-row', row);
    return modals.openConfirmModal({
      title: `Assign`,
      children: (
        <Text>
          Are you sure you want to Assign{' '}
          <Box as="span" fontWeight={'600'}>
            This Bond application to Application
          </Box>
          ? This action cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Assign', cancel: 'Cancel' },
      confirmProps: {
        color: 'green',
      },
      onConfirm: async () => {
        if (row?.original?.id) {
          dispatch(
            BondApplicationAssignTo({
              applicantId: row?.original?.id,
            })
          );
        }
      },
    });
  };

  const handleDownloadPdf = async (id: string) => {
    if (id) {
      setBondPdfDownload(true);
      dispatch(getBondApploicationDetails({ id: Number(id) }));
    }
  };

  const DownloadPdf = async (bondApplicationData: any) => {
    try {
      const pdfBlob = await pdf(
        <PDFForm
          data={bondApplicationData}
          logo={bondApplicationData?.brokerageLogoUrl}
        />
      ).toBlob();
      const zip = new JSZip();

      const pdfFileName = `Bond Request-${bondApplicationData?.applicantName}-${bondApplicationData?.id}.pdf`;
      zip.file(pdfFileName, pdfBlob);

      const attachments = [
        ...(bondApplicationData?.bidBondDetails?.attachments || []),
        ...(bondApplicationData?.bidBondDetails?.preQualificationLetter || []),
        ...(bondApplicationData?.designResponsibilityAttachments || []),
        ...(bondApplicationData?.liquidatedDamagesReferences || []),
        ...(bondApplicationData?.penaltyContranctReferences || []),
        ...(bondApplicationData?.uploadContract || []),
      ];

      const folderWisedata = attachments.map((data) => {
        return {
          mainFolder: 'Attachments',
          subFolder: '',
          links: Array.isArray(data?.files)
            ? data.files.map((file: any) => file.link)
            : data?.link
              ? [data.link]
              : [],
        };
      });

      console.log('folderWisedata bond', folderWisedata);

      const docsZipData: any = await createZipFromFolderAndLinks({
        folderWisedata,
        zipName: pdfFileName,
        returnData: true,
        downloadZip: false,
        regexpattern: /.pdf/,
      });

      const docsZip = await JSZip.loadAsync(docsZipData);
      docsZip.forEach((attachment, file) => {
        zip.file(`${attachment}`, file.async('blob'));
      });

      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const zipUrl = URL.createObjectURL(zipBlob);

      const link = document.createElement('a');
      link.href = zipUrl;
      link.download = `Bond Request-${bondApplicationData?.applicantName}-${bondApplicationData?.id}.zip`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(zipUrl);

      setBondPdfDownload(false);
    } catch (error) {
      setBondPdfDownload(false);
      console.error('Error generating ZIP file:', error);
    }
  };

  const getOtherType = (data: any, key: string, returnKey: string) => {
    let otherIndex = data?.findIndex(
      (item: any) => item[`${key}`] === 'Others' || item[`${key}`] === 'Other'
    );
    return otherIndex >= 0 ? data[otherIndex][`${returnKey}`] : null;
  };

  const handleApproveBondRequest = (row: MRT_Row<BondTable>) => {
    setBondRequestData(row?.original);
    openIsbondRequestApprovedModal();
  };

  const handleRejectBondRequest = (row: MRT_Row<BondTable>) => {
    setBondRequestData(row?.original);
    openIsbonsRequestRejectedModal();
  };

  const handleConvertToFinalBond = (row: MRT_Row<BondTable>) => {
    dispatch(convertToFinalBond({ bondId: Number(row?.id) }));
  };

  const column = useMemo<MRT_ColumnDef<BondTable>[]>(
    () => [
      { accessorKey: 'id', header: 'Bond Number' },
      {
        accessorKey: 'bondApplicationType',
        header: 'Bond Application Type',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { bondApplicationType } = row?.original;
          return (
            <Text>
              {bondApplicationType === TApplicationTypeEnum?.Bid
                ? 'Bid Bond'
                : 'Fianl Bond'}
            </Text>
          );
        },
      },
      { accessorKey: 'applicantName', header: 'Applicant Name' },
      {
        accessorKey: 'applicationId',
        header: 'Application Id',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { applicationId } = row?.original;
          return <Text>{applicationId ?? 'N/A'}</Text>;
        },
      },
      {
        accessorKey: 'createdDate',
        id: 'Created Date',
        header: 'Created Date',
        size: 20,
        maxSize: 20,
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { createdAt } = row?.original;
          return moment(createdAt).format('DD-MM-YYYY hh:mm A');
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }: any) => {
          const { status } = row.original;
          let bgColor = '';
          let textColor = '';
          console.log('status', status);

          switch (status) {
            case 'pending':
              bgColor = 'yellow.100';
              textColor = 'yellow.800';
              break;
            case 'completed':
              bgColor = 'green.100';
              textColor = 'green.800';
              break;
            case 'assigned':
              bgColor = 'blue.100';
              textColor = 'blue.800';
              break;
            case 'approved':
              bgColor = 'green.300';
              textColor = 'green.800';
              break;
            case 'rejected':
              bgColor = 'red.100';
              textColor = 'red';
              break;
            default:
              bgColor = 'gray.100';
              textColor = 'gray.800';
          }

          return (
            <Badge
              bg={bgColor}
              color={textColor}
              px="2"
              py="1"
              borderRadius="md"
            >
              {status}
            </Badge>
          );
        },
      },
    ],
    [applicantData]
  );

  const table = useMantineReactTable({
    columns: column as any,
    data: applicantData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    // layoutMode: 'grid',
    createDisplayMode: 'modal',
    editDisplayMode: 'custom',
    enableEditing: false,
    getRowId: (row: any, index: any) => row?.id?.toString() ?? '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: false ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      className: 'ocr_report_table',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    // mantineTableHeadCellProps: {
    //   className: 'custom-column',
    // },
    mantineTableHeadCellProps: {
      className: 'custom-column',
      // sx: {
      //   '& .mantine-TableHeadCell-Content': {
      //     justifyContent: 'space-between',
      //   },
      // },
    },
    enableRowActions: true,
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 10, //make actions column wider
        minSize: 10,
        maxSize: 10,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
            boxShadow: 'inset 0 0 0 1px lightgray',
          },
        },
        // mantineTableBodyCellProps: {
        //   sx: {
        //     maxWidth: '200px',
        //     minWidth: '200px',
        //     width: '200px',
        //     // whiteSpace: 'nowrap',
        //     // overflow: 'hidden',
        //     // textOverflow: 'ellipsis',
        //   },
        // },
      },
    },
    renderRowActions: ({ row }: any) => {
      console.log('row', row);

      return (
        <Flex gap="2">
          <Tooltip label="View" position="right">
            <ActionIcon
              onClick={() => {
                navigate(`/${userType}/bond-application/${row?.original?.id}`, {
                  state: { applicantId, isViewMode: true },
                });
              }}
              size={'sm'}
            >
              <IconEye />
            </ActionIcon>
          </Tooltip>
          {['pending', 'assigned', 'completed', 'rejected'].includes(
            row?.original?.status
          ) && (
            <Tooltip label="Edit" position="right">
              <ActionIcon
                size="sm"
                onClick={() =>
                  navigate(
                    `/${userType}/bond-application/${row?.original?.id}`,
                    {
                      state: { applicantId, isViewMode: false },
                    }
                  )
                }
              >
                <IconEdit />
              </ActionIcon>
            </Tooltip>
          )}
          {['pending', 'assigned', 'completed'].includes(
            row?.original?.status
          ) && (
            <Tooltip label="Delete" position="right">
              <ActionIcon
                size="sm"
                color="red"
                onClick={() => {
                  openDeleteConfirmModal(row);
                }}
              >
                <IconTrash />
              </ActionIcon>
            </Tooltip>
          )}
          {['pending'].includes(row?.original?.status) &&
            userType !== 'applicant' && (
              <Tooltip label="Assign to Applicant" position="right">
                <ActionIcon
                  size="sm"
                  onClick={() => {
                    openAssignConfirmModal(row);
                  }}
                >
                  <FaRegShareSquare />
                </ActionIcon>
              </Tooltip>
            )}
          {['downloaded'].includes(row?.original?.status) && (
            <Tooltip label="Approve" position="right">
              <Box
                cursor={'pointer'}
                onClick={() => handleApproveBondRequest(row)}
              >
                <FcApproval size={20} />
              </Box>
            </Tooltip>
          )}
          {['downloaded'].includes(row?.original?.status) &&
            row?.original?.isRejected < 2 && (
              <Tooltip label="Reject" position="right">
                <Box
                  cursor={'pointer'}
                  onClick={() => handleRejectBondRequest(row)}
                >
                  <IconCircleX color="red" size={20} />
                </Box>
              </Tooltip>
            )}
          {['approved'].includes(row?.original?.status) &&
            row?.original?.bondApplicationType === 'Final' && (
              <Tooltip label="Upload" position="right">
                <ActionIcon
                  size="sm"
                  // onClick={() => handleRejectBondRequest(row)}
                >
                  <MdOutlineFileUpload size={20} />
                </ActionIcon>
              </Tooltip>
            )}
          {['completed', 'downloaded', 'approved', 'rejected'].includes(
            row?.original?.status
          ) &&
            (!loader?.bondLoader || loadingRow !== row?.original?.id) && (
              <Tooltip label="Download" position="right">
                <ActionIcon
                  size="sm"
                  onClick={() => {
                    setLoadingRow(row?.original?.id);
                    handleDownloadPdf(row?.original?.id);
                    setBondRequestData(row?.original);
                  }}
                >
                  <FaFileDownload />
                </ActionIcon>
              </Tooltip>
            )}
          {loader?.bondLoader && loadingRow === row?.original?.id && (
            <Flex alignItems={'center'}>
              <Loader size={'sm'} h={'100%'} />
            </Flex>
          )}
          {['approved'].includes(row?.original?.status) &&
            row?.original?.bondApplicationType === TApplicationTypeEnum?.Bid &&
            (!loader?.convertToFinalBond ||
              loadingRow !== row?.original?.id) && (
              <Tooltip label="Convet to Final Bond" position="right">
                <ActionIcon
                  size="sm"
                  onClick={() => {
                    setLoadingRow(row?.original?.id);
                    handleConvertToFinalBond(row?.original);
                  }}
                >
                  <SiConvertio />
                </ActionIcon>
              </Tooltip>
            )}
          {loader?.convertToFinalBond && loadingRow === row?.original?.id && (
            <Flex alignItems={'center'}>
              <Loader size={'sm'} h={'100%'} />
            </Flex>
          )}
        </Flex>
      );
    },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
      showProgressBars: tableContentLoader,
      // isLoading: tableContentLoader,
    },
    state: {
      showProgressBars: tableContentLoader,
      // isLoading: tableContentLoader,
    },
  });

  return (
    <>
      <Flex flexDirection={'column'} columnGap={5} py={5}>
        <Flex
          gap={3}
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {!!userData?.data?.suretyPurchasedPlanInfo && (
            <Text>
              Bonds remaining :{' '}
              {userData?.data?.suretyPurchasedPlanInfo?.remainingBonds ?? 0}
            </Text>
          )}
          {userType === 'broker' && (
            <Box maxW="max-content">
              <Select
                w="100%"
                onChange={(event) => {
                  setApplicantId(Number(event.target.value));
                  if (event.target.value) {
                    getBondApplicationInfo(Number(event.target.value));
                  }
                }}
                value={applicantId ?? ''}
                name="applicantId"
                borderRadius="5px"
                bg={'#114684'}
                color={'white'}
                textAlign={'center'}
              >
                <option
                  value={''}
                  style={{ color: 'black' }}
                  label="Select Applicant"
                />
                {Array.isArray(applicantOptions) &&
                  applicantOptions?.map((user?: any, index?: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={user?.applicantId ?? ''}
                      label={user?.companyName ?? ''}
                    />
                  ))}
              </Select>
            </Box>
          )}
          {loader?.applicantLoader && (
            <Flex alignItems={'center'}>
              <Loader size={'sm'} h={'100%'} />
            </Flex>
          )}
        </Flex>
        {!!applicantId && (
          <Box display="flex" gap={4} flexDirection={'column'} margin={10}>
            <Container
              maxW={'container.lg'}
              display={'flex'}
              justifyContent={'center'}
              p={0}
            >
              <ChakraTooltip
                label="Your brokerage company has not purchased any surety hub plan"
                isDisabled={!!userData?.data?.suretyPurchasedPlanInfo}
                placement="right"
                hasArrow
              >
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  type="button"
                  onClick={() =>
                    navigate(`/${userType}/bond-application`, {
                      state: { applicantId },
                    })
                  }
                  isDisabled={
                    !userData?.data?.suretyPurchasedPlanInfo ||
                    userData?.data?.suretyPurchasedPlanInfo?.remainingBonds <= 0
                  }
                >
                  Create New Bond Application
                </Button>
              </ChakraTooltip>
            </Container>
            <ModalsProvider>
              <Container
                maxW={'container.lg'}
                display={'flex'}
                justifyContent={'flex-end'}
                p={0}
              >
                <Box padding="4" sx={{ width: '100%' }}>
                  <Flex justifyContent="space-between" mb="4"></Flex>

                  <MantineReactTable table={table} key={'bond-list'} />
                </Box>
              </Container>
            </ModalsProvider>
          </Box>
        )}
      </Flex>
      <BondRequestRejectModal
        isOpen={isbonsRequestRejectedModal}
        onClose={() => {
          setBondRequestData(null);
          closeIsbonsRequestRejectedModal();
        }}
        isSubmitLoader={loader.isSubmitLoader}
        bondRequestData={bondRequestData!}
      />
      <BondRequestApproveModal
        isOpen={isbondRequestApprovedModal}
        onClose={() => {
          setBondRequestData(null);
          closeIsbondRequestApprovedModal();
        }}
        isSubmitLoader={loader.isSubmitLoader}
        bondRequestData={bondRequestData!}
      />
    </>
  );
};

export default BondRequest;
