import { Box, Flex } from '@chakra-ui/react';
import { ActionIcon, Divider, Progress, Tooltip } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WIPReprotTable } from 'src/Redux/Broker/Reports/WorkInProgress/state';

const WIPReportListTable = ({
  tableList,
  tableContentLoader,
}: {
  tableList: Record<string, any>[];
  tableContentLoader: boolean;
}) => {
  const userType = localStorage.getItem('userType');
  const userID = localStorage.getItem('userID');
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<WIPReprotTable[]>([]);

  useEffect(() => {
    const WIPTData = getWIPReportTableData(tableList);
    setTableData(WIPTData);
  }, [tableList]);

  const getWIPReportTableData = (
    wipData: Record<string, any>[]
  ): WIPReprotTable[] => {
    const data = wipData?.map((report) => ({
      id: report.id,
      applicantId: report.applicantId,
      updatedAt: report.updatedAt,
      reportDate: report.reportAsOf,
    }));
    return data;
  };

  const columns = useMemo<MRT_ColumnDef<WIPReprotTable>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 2,
      },
      {
        accessorKey: 'reportDate',
        id: 'reportDate',
        header: 'Report Date',
        size: 20,
        maxSize: 20,
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { reportDate } = row?.original;
          return moment(reportDate).format('MMM DD, YYYY');
        },
      },
      {
        accessorKey: 'updatedAtTime',
        id: 'updatedAtTime',
        header: 'Time',
        size: 20,
        maxSize: 20,
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { updatedAt } = row?.original;
          return moment(updatedAt).format('DD-MM-YYYY hh:mm A');
        },
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    // layoutMode: 'grid',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => row?.id?.toString() ?? '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
    },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        // overflow: false ? 'hidden' : 'auto',
        maxHeight: 'unset !important',
        overflow: 'visible !important',
      },
      className: 'ocr_report_table',
    },
    mantinePaperProps: {
      sx: {
        overflow: 'visible',
      },
    },
    mantineTableHeadProps: {
      sx: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
      },
    },
    // mantineTableHeadCellProps: {
    //   className: 'custom-column',
    // },
    mantineTableHeadCellProps: {
      className: 'custom-column',
      // sx: {
      //   '& .mantine-TableHeadCell-Content': {
      //     justifyContent: 'space-between',
      //   },
      // },
    },
    enableRowActions: true,
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 10, //make actions column wider
        minSize: 10,
        maxSize: 10,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
            boxShadow: 'inset 0 0 0 1px lightgray',
          },
        },
      },
    },
    renderRowActions: ({ row }) => (
      <Flex gap={'lg'} flexWrap={'wrap'}>
        <Tooltip label="View" position="right">
          <ActionIcon
            onClick={() => {
              console.log('row', row);
              const { applicantId, id } = row?.original;
              const navigateUrl = `/${userType}/report/working_progress?reportId=${id}&applicantId=${applicantId}`;
              navigate(navigateUrl);
            }}
            size={'sm'}
          >
            <IconEye />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbar: ({ table }) => {
      console.log('tableData', tableData);

      return (
        <Flex direction={'column'}>
          <Flex
            py={1}
            alignItems={'center'}
            flexWrap={'wrap'}
            justifyContent={'flex-end'}
          >
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box>
          </Flex>
          {tableContentLoader && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: tableContentLoader,
      isLoading: tableContentLoader,
    },
  });

  return (
    <Box maxW={'1550px'} w={'100%'} py={10} mx={'auto'}>
      <MantineReactTable table={table} key={'ocr-report-list'} />
    </Box>
  );
};

export default WIPReportListTable;
