import { Box, Button, Flex, Text, Textarea } from '@chakra-ui/react';
import { Modal, MultiSelect } from '@mantine/core';
import { useFormik } from 'formik';
import { BondApplicationApprove } from 'src/Redux/Broker/BondRequest/slice';
import { useAppDispatch } from 'src/Redux/Store';
import { bondRequestRejectYupSchema } from 'src/ValidationSchema/Bond/bond-application';
import {
  BondRequestRejectSchema,
  initialBondRequestRejectState,
  rejectBondRequestOptions,
} from './data';

const BondRequestRejectModal = ({
  isOpen,
  onClose,
  isSubmitLoader,
  bondRequestData,
}: {
  isOpen: boolean;
  onClose: () => void;
  isSubmitLoader: boolean;
  bondRequestData: Record<string, any>;
}) => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: initialBondRequestRejectState,
    validationSchema: bondRequestRejectYupSchema,
    onSubmit: async (values: BondRequestRejectSchema) => {
      try {
        console.log('onSubmit', values);
        const payload = {
          ...values,
          reasonOfRejection: values?.reasonOfRejection!,
          bondApplicationId: bondRequestData?.id!,
        };
        await dispatch(BondApplicationApprove(payload));
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  console.log('formik', formik);

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        formik.handleReset(formik.initialValues);
        onClose();
      }}
      title="Bond Request Reject"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'lg'}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box display={'flex'} flexDirection={'column'}>
          <Text
            fontWeight="600"
            color="#114684"
            textAlign="start"
            fontSize={13}
            mb={1}
          >
            <li>
              Rejection option {/* <span style={{ color: "red" }}>*</span> */}
            </li>
          </Text>

          <MultiSelect
            className="legalform_pdfPreference"
            data={rejectBondRequestOptions?.map((el: any) => ({
              label: el?.label,
              value: el?.value,
            }))}
            // label="Select Type Of Work"
            placeholder="Rejection option"
            maxDropdownHeight={160}
            styles={{
              input: {
                maxHeight: '60px',
                overflow: 'auto',
              },
            }}
            onChange={(value) => {
              console.log(
                'value',
                value.some((val) =>
                  rejectBondRequestOptions.some(
                    (option) => option.value === val && option.isOther
                  )
                )
              );
              if (
                value.some((val) =>
                  rejectBondRequestOptions.some(
                    (option) => option.value === val && option.isOther
                  )
                )
              ) {
                formik.setFieldValue('reasonOfRejectionOther', null);
              }
              formik.setFieldValue('reasonOfRejection', value);
            }}
            value={formik.values.reasonOfRejection ?? []}
            withinPortal={true}
          />
          {formik.touched.reasonOfRejection &&
            formik.errors.reasonOfRejection && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.reasonOfRejection}
              </div>
            )}
        </Box>
        {formik.values?.reasonOfRejection?.some((val) =>
          rejectBondRequestOptions.some(
            (option) => option.value === val && option.isOther
          )
        ) && (
          <Box display={'flex'} flexDirection={'column'}>
            <Text
              fontWeight="600"
              color="#114684"
              textAlign="start"
              fontSize={13}
              mb={1}
            >
              <li>Rejection Reason</li>
            </Text>

            <Textarea
              fontSize={'13px'}
              w="100%"
              placeholder="Rejection Reason"
              borderRadius="5px"
              //   focusBorderColor="#7f7f7f"
              borderColor={'#7f7f7f'}
              _hover={{ borderColor: '#7f7f7f' }}
              onChange={formik.handleChange}
              value={formik.values?.reasonOfRejectionOther ?? ''}
              name={`reasonOfRejectionOther`}
              resize={'none'}
            />
            {formik.touched.reasonOfRejectionOther &&
              formik.errors.reasonOfRejectionOther && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.reasonOfRejectionOther}
                </div>
              )}
          </Box>
        )}
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              formik.handleReset(formik.initialValues);
              onClose();
            }}
            isDisabled={isSubmitLoader}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme="red"
            loadingText="Reject"
            isDisabled={!formik.values?.reasonOfRejection?.length}
            isLoading={isSubmitLoader}
          >
            Reject
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
export default BondRequestRejectModal;
