import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useMemo } from 'react';

const BondActivityReport = () => {
  const data: any = [];
  const columns = useMemo(
    () => [
      {
        accessorKey: 'pname',
        header: 'Project Name',
      },
      {
        accessorKey: 'ploc',
        header: 'Project Location',
      },
      {
        accessorKey: 'obligee',
        header: 'Obligee Name',
      },
      {
        accessorKey: 'pstart',
        header: 'Project Start Date',
      },
      {
        accessorKey: 'pcompletiondate',
        header: 'Project Completion Date',
      },
      {
        accessorKey: 'initTotalContract',
        header: 'Initial Total Contract Value',
      },
      {
        accessorKey: 'currTotalContract',
        header: 'Current Total Contract Value',
      },
      {
        accessorKey: 'bondType',
        header: 'Bond Type',
      },
      {
        accessorKey: 'bondNo',
        header: 'Bond No.',
      },
      {
        accessorKey: 'bondAmt',
        header: 'Bond Amount',
      },
      {
        accessorKey: 'issueDate',
        header: 'Issue Date',
      },
      {
        accessorKey: 'maintenance',
        header: 'Maintenance Period',
      },
      {
        accessorKey: 'expiryDate',
        header: 'Expiry Date',
      },
      {
        accessorKey: 'bondStatus',
        header: 'Bond Status',
      },
      {
        accessorKey: 'indem',
        header: 'Indemnity & Security Conditions',
      },
      {
        accessorKey: 'queryForm',
        header: 'Status Query Form Provided',
      },
    ],
    []
  );

  const tableBond = useMantineReactTable({
    columns,
    data,
  });
  return <MantineReactTable table={tableBond} />;
};

export default BondActivityReport;
