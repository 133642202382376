import { Box, Flex, Heading, Select } from '@chakra-ui/react';
import { Tabs } from '@mantine/core';
import BondActivityReport from './BondActivityReport';
import ClientProfileReport from './ClientProfileReport';

const BondFacilityTracker = () => {
  return (
    <Flex direction={'column'} gap={5} p={5}>
      <Heading fontSize={'24px'} fontWeight={'600'}>
        Bond Facility Tracker
      </Heading>
      <Box maxW="max-content">
        <Select
          w="100%"
          name="applicantId"
          borderRadius="5px"
          bg={'#114684'}
          color={'white'}
          textAlign={'center'}
        >
          <option
            value={''}
            style={{ color: 'black' }}
            label="Select Applicant"
          />
        </Select>
      </Box>
      <Tabs defaultValue="bond">
        <Tabs.List>
          <Tabs.Tab value="bond">Bond Activity Report</Tabs.Tab>
          <Tabs.Tab value="client">Client Profile Report</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="bond">
          <Box p={'2em 0'}>
            <BondActivityReport></BondActivityReport>
          </Box>
        </Tabs.Panel>
        <Tabs.Panel value="client">
          <Box p={'2em 0'}>
            <ClientProfileReport></ClientProfileReport>
          </Box>
        </Tabs.Panel>
      </Tabs>
    </Flex>
  );
};

export default BondFacilityTracker;
