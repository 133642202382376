import { APIStatus } from 'src/types/unions';

export interface OCRhistoryInfo {
  id: string;
  message: string;
  user_id: number;
  row_id: string;
  date: string;
  changes?: { columnName: string; oldvalue: string; newValue: string }[];
}

export enum ocrReportStatus {
  AssignedtoApplicant = 'Assigned to Applicant',
  AssignedbyBroker = 'Assigned by Broker',
  Draft = 'Draft',
  Validated = 'Validated',
  Created = 'Created',
}

export enum BSCurrentLiabilitiesAndAssets {
  CurrentLiabilities = 'Current Liabilities',
  CurrentLiabilities1 = 'Current Liabilities Accounts Payable (A/P)',
  CurrentLiabilities2 = 'Current Liabilities Accounts Payable',
  CurrentAssets = 'Current Assets',
}

export enum BSReportTotalFixedAssets {
  TotalFixedAssets = 'Total Fixed Assets',
  TotalNonCurrentAssets = 'Total Non Current Assets',
  TotalNonCurrentAssets1 = 'Total Non-current Assets',
}

export enum BSReportTotalLongTermLiabilities {
  TotalLongTermLiabilities = 'Total Long-Term Liabilities',
  TotalNonCurrentLiabilities = 'Total Non-current Liabilities',
  TotalNonCurrentLiabilities1 = 'Total Non Current Liabilities',
}

export enum BSReportCalculation {
  TotalCurrentAssets = 'Total Current Assets',
  // TotalFixedAssets = 'Total Fixed Assets',
  TotalAssets = 'TOTAL ASSETS',
  TotalAccountsPayable = 'Total Accounts Payable',
  TotalCurrentLiabilities = 'Total Current Liabilities', //TotalCurrentLiabilities calculation of total start from TotalAccountsPayable
  // TotalLongTermLiabilities = 'Total Long-Term Liabilities',
  TotalLiabilities = 'Total Liabilities',
  TotalEquity = 'Total Equity',
  TotalLiabilitiesAndEquity = 'TOTAL LIABILITIES AND EQUITY',
}

export enum PLIncomeType {
  TotalIncome = 'Total Income',
  TotalRevenue = 'Total Revenue',
  NetIncome = 'Net Income',
  TotalSales = 'Total Sales',
}

export enum PLCostOfGoods {
  costOfSales = 'Cost of sales',
  costOfGoodsSold = 'Cost Of Goods Sold',
}

export enum PLTotalCostOfGoods {
  TotalCostofGoodsSold = 'Total Cost of Goods Sold',
  TotalCostofSales = 'Total Cost of Sales',
}

export enum PLExpenses {
  //expenses key
  TotalExpenses = 'Total Expenses',
  TotalOperatingExpenses = 'Total Operating Expenses',
}

export enum PLReportProfit {
  Profit = 'Profit',
  NetIncome = 'Net Income',
}

export enum PLReportIncomeTaxes {
  CurrentIncomeTaxes = 'Current income taxes',
  IncomeTaxes = 'Income Taxes',
}

export enum PLReportCalculation {
  // TotalIncome = PLIncomeType.NetIncome,
  // CostofGoodsSold = 'Cost of Goods Sold',
  // CostofSales = 'Cost of Sales',
  GrossProfit = 'Gross Profit',

  //other income key
  OtherIncome = 'Other Income',
  TotalOtherIncome = 'Total Other Income',
  OtherExpenses = 'Other Expenses',
  TotalOtherExpenses = 'Total Other Expenses',
  IncomeBeforeIncomeTaxes = 'lncome before income taxes',
}
export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_OCR_REPORT_LIST'
    | 'GET_OCR_REPORT_BY_ID'
    | 'UPDATE_OCR_REPORT'
    | 'ADD_OCR_REPORT'
    | 'DELETE_OCR_REPORT'
    | 'DELETE_OCR'
    | 'VALIDATE_OCR_REPORT'
    | 'ASSIGN_OCR_REPORT'
    | 'GET_OCR_REPORT_HISTORY'
    | null;
  ocrReportList: Record<string, any>[] | null;
  ocrHistoryData: OCRhistoryInfo[];
  data: Record<string, any> | null;
  msg: string | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  ocrReportList: null,
  data: null,
  msg: null,
  ocrHistoryData: [],
};

export default initialState;
